import styled, {css} from 'styled-components';
import {BlueJeans, DarkGray1, LightGray, LightGray1, LoadingZIndex} from "../../app/StyleCommon";

export const StrengthWrapper = styled.div`
  box-sizing: border-box;
  color: #090809;
  font-size: 18px;
  line-height: 1;
  position: relative;

  .ReactPasswordStrength-input {
    border: none;
    box-sizing: border-box;
    font-size: 18px;
    padding: 5px 0 5px 14px;
    width: calc(85% - 28px);
  }

  .ReactPasswordStrength-input:not(:focus).is-password-invalid { color: #D1462F; }
  .ReactPasswordStrength-input:focus { outline: none; }

  .ReactPasswordStrength-strength-desc {
    color: transparent;
    font-style: italic;
    padding: 14px 12px;
    position: absolute; top: 1px; right: 0;
    text-align: right;
    transition: color 250ms ease-in-out;
    width: 15%;
    top: -5px;
  }

  &.is-strength-0 .ReactPasswordStrength-strength-desc { color: #D1462F; }
  &.is-strength-1 .ReactPasswordStrength-strength-desc { color: #D1462F; }
  &.is-strength-2 .ReactPasswordStrength-strength-desc { color: #D1462F; }
  &.is-strength-3 .ReactPasswordStrength-strength-desc { color: #D1462F; }
  // &.is-strength-2 .ReactPasswordStrength-strength-desc { color: ${DarkGray1}; }
  // &.is-strength-3 .ReactPasswordStrength-strength-desc { color: ${DarkGray1}; }
  &.is-strength-4 .ReactPasswordStrength-strength-desc { color: #D1462F; }
  &.is-strength-5 .ReactPasswordStrength-strength-desc { color: #2FBF71; }

  .ReactPasswordStrength-strength-bar {
    box-sizing: border-box;
    height: 1px;
    top: -1px;
    position: relative;
    right: 1px;
    transition: width 300ms ease-out;
    width: 0;
  }

  &.is-strength-0 .ReactPasswordStrength-strength-bar {
    background:#AAB2BD;
    width: 20%;
  }

  &.is-strength-1 .ReactPasswordStrength-strength-bar {
    background:#AAB2BD;
    width: 40%;
  }

  &.is-strength-2 .ReactPasswordStrength-strength-bar {
    background:#AAB2BD;
    width: 60%;
  }

  &.is-strength-3 .ReactPasswordStrength-strength-bar {
    background: #AAB2BD;
    width: 80%;
  }

  &.is-strength-4 .ReactPasswordStrength-strength-bar {
    background:#AAB2BD;
    width: 90%;
  }
  &.is-strength-5 .ReactPasswordStrength-strength-bar {
    background: #2FBF71;
    width: calc(100% + 2px);
  }

`