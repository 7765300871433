import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {
  Wrapper,
  TableController,
  TableController1,
  KeyMetrics,
  ListFooter,
  Header, ColumnSelector,
} from './index.style';
import {Btn, Select, Table, TableScrollWrapper, ProgressBar, CondirmDialog} from "../uikit";
import {post} from "../utils/request";
import moment from "moment";
import {Link, useLocation} from "react-router-dom";
import {intFormatter, uniquesFormatter} from "../utils/formatter";
import {PaginationStyled} from "../uikit/pagination/index.style";
import FilterBox from "./FilterBox";
import {supportedColumns, columnTooltip} from "./columns";
import {StyledDropdown} from "../uikit/dropdown/styled";
import classNames from "classnames";
import {getFromCache, cacheResource} from '../utils/cache'
import {saveAs} from 'file-saver';
import {defaultTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import { paginationValues } from '../utils/metadata';

const CAMPAIGN_LIST_CACHE_KEY = 'CAMPAIGN_LIST_CACHE_KEY';
const getDefaultSelectedColumns = () => supportedColumns.filter(d => !d.hideDefault).map(d => d.key);
export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(CAMPAIGN_LIST_CACHE_KEY) || {};
    const queryParams = new URLSearchParams(window.location.search);
    const searchterm = queryParams.get("search");
    this.state = {
      page: 1,
      PAGE_SIZE: 5,
      selectedColumns: getDefaultSelectedColumns(),
      sortBy: 'start_date',
      sortDirection: 'desc',
      filters: [],
      ...cachedSettings
    };
    if (searchterm && searchterm !== "") {
      this.state.keyword = searchterm;
    }

    this.queryAgainDebounce = _.debounce(this.queryAgain.bind(this), 500);
  }


  render() {
    const {session = {}} = this.props.appState || {};
    const {data, error, loading} = this.state;
    const {keyword, filters, selectedColumns, sortBy, sortDirection, page, PAGE_SIZE} = this.state;
    const {advertiser = 'KFC', channel} = filters || {};
    const {campaigns = [], filterMetadata, total, stats} = data || {};
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let canViewList = session.rights.indexOf('View Campaign List Page') >= 0;
    let canEditCampaign = session.rights.indexOf('Setup/Update Campaign') >= 0;
    let canDownloadList = session.rights.indexOf('Download Campaign List') >= 0;
    const filterColumns = _.cloneDeep(supportedColumns).map(s => {
      if (s.key === "creator") {
          return { ...s, label: 'Creator' };
      }
      return { ...s };
    });
    return (
      <MainLayout activeItem="campaign" breadcrumb={[{path: '/campaigns', label: 'Campaigns'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryCampaignList`}
            params={this.buildInsightQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !!data &&
          <Wrapper>
            <Header>
              <h3>Campaigns</h3>
              <Link className="create-btn" to={`/campaign/setup`} onClick={e => null}>
                <Btn type="primary" disabled={session.rights.indexOf('Setup/Update Campaign') < 0}>
                  <i className="fa fa-plus"/> Campaign
                </Btn>
              </Link>
            </Header>
            <TableController1>
              <FilterBox
                key={JSON.stringify(filters)}
                filters={filters}
                supportedColumns={filterColumns}
                filterMetadata={filterMetadata}
                onChange={newFilters => this.setState({filters: newFilters, page: 1}, () => this.queryAgain())} />
              <div className="searchBox">
                <i className="fa fa-search"/>
                <input
                  type="text"
                  placeholder="Search campaign..."
                  value={keyword}
                  onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.queryAgainDebounce())}/>
                {this.state.keyword && this.state.keyword !== "" ? <i class="fa fa-times" aria-hidden="true" onClick={() => this.setState({keyword: '', page: 1}, () => this.queryAgainDebounce())}></i> : null}
              </div>
              <ColumnSelector
                onMouseOver={defaultTooltip.onMouseOver({txt: 'Customise Columns'})}
                onMouseLeave={defaultTooltip.onMouseOut()}
                selected={selectedColumns}
                showGroupBatch={true}
                multi={true}
                plainStyle={true}
                searchable={true}
                toggleLableFunc={d =><span className="material-symbols-outlined">view_column</span>}
                menuHeader={<div className="menu-header">Select Columns</div>}
                data={supportedColumns}
                onChange={newSelected => {
                  this.setState({selectedColumns: newSelected}, () => this.cacheParams())
                }}
                menuFooter={
                  <div className="custom-menu-btn" onClick={e => {
                    this.setState({sortBy: 'start_date', sortDirection: 'desc', selectedColumns: getDefaultSelectedColumns()}, () => this.cacheParams())
                  }}>
                    Reset Default
                  </div>
                } />
              {
                canDownloadList &&
                <a onClick={e => this.downloadCSV()} style={{cursor: 'pointer'}}
                   onMouseOver={defaultTooltip.onMouseOver({txt: 'Download CSV file'})}
                   onMouseLeave={defaultTooltip.onMouseOut()}>
                  <span className="material-symbols-outlined">download</span>
                </a>
              }
            </TableController1>

            <KeyMetrics className="no-border">
              {
                [
                  // {className: 'gray1', filterKey: 'inflight_status::ALL', metric: 'Total Campaigns', value: total, i: <i className="material-symbols-outlined">functions</i>},
                  {className: 'blue', filterKey: 'inflight_status::READY',  metric: 'Upcoming', value: stats.READY, i: <i className="material-symbols-outlined">new_releases</i>},
                  {className: 'green', filterKey: 'inflight_status::DELIVERING',  metric: 'Delivering', value: stats.DELIVERING, i: <i className="material-symbols-outlined">run_circle</i>},
                  {className: 'gray', filterKey: 'inflight_status::DONE',  metric: 'Completed', value: stats.DONE, i: <i className="material-symbols-outlined">done_all</i>},
                  {className: 'yellow', filterKey: 'health_status::OFF_TRACK',  metric: 'Off Track', value: stats.OFF_TRACK, i: <i className="material-symbols-outlined">info</i>},
                ].map(d => (
                  <div
                    key={d.filterKey}
                    className={classNames('metric-item', d.className)}
                    onClick={e => {
                      let {filters} = this.state;
                      let [statusField, statusValue] = d.filterKey.split('::');
                      filters = filters.filter(d => d.key !== statusField);
                      filters.push({key: statusField, op: '$in', value: [statusValue]});

                      this.setState({filters}, () => this.queryAgain());
                    }}>
                    <div className="label">{d.metric}</div>
                    <div className="value">{intFormatter(d.value || 0)}</div>
                  </div>
                ))
              }
            </KeyMetrics>

            <TableScrollWrapper className="middle" noAdjust={true}>
              {this.renderTable('main-table')}
              {!isSafari && this.renderTable('fixed-table')}
            </TableScrollWrapper>
            {
              canViewList &&
              <ListFooter>
                <span className="info 123333">
                    <strong>{total}</strong> campaigns found.
                    Show
                    <select
                      value={PAGE_SIZE}
                      onChange={e => this.setState({
                        PAGE_SIZE: Number(e.target.value),
                        page: 1,
                      }, () => this.queryAgain())}>
                    {paginationValues.map(d => {
                      return (
                        <option key={d} value={d}>{d}</option>
                      )
                    })}
                    </select>
                    per page
                </span>
                  <PaginationStyled
                    onChange={p => this.setState({page: p}, () => this.queryAgain())}
                    current={page}
                    pageSize={PAGE_SIZE}
                    total={total}
                    hideOnSinglePage={false}/>
                </ListFooter>
            }
          </Wrapper>
        }
      </MainLayout>
    )
  }

  renderTable(className) {
    const {session = {}} = this.props.appState || {};
    const {data, error, loading} = this.state;
    const {keyword, filters, selectedColumns, sortBy, sortDirection, page, PAGE_SIZE} = this.state;
    const {advertiser = 'KFC', channel} = filters || {};
    const {campaigns = [], users = [], filterMetadata, total, stats} = data || {};

    let canViewList = session.rights.indexOf('View Campaign List Page') >= 0;
    let canViewCampaign = session.rights.indexOf('View Campaign Dashboard') >= 0;
    let canViewCampaignRevisions = session.rights.indexOf('View Campaign History') >= 0;
    let canEditCampaign = session.rights.indexOf('Setup/Update Campaign') >= 0;
    let canDeleteCampaign = session.rights.indexOf('Delete Campaign') >= 0;
    let canDownloadList = session.rights.indexOf('Download Campaign List') >= 0;
    return (
      <Table
        className={classNames(className, 'campaign-table')}
        context={{page, PAGE_SIZE, session, users}}
        // resizable={true}
        columns={[
          ...(supportedColumns.filter(d => selectedColumns.indexOf(d.key) >= 0)),
          {
            key: 'actions',
            sortable: false,
            label: 'Actions',
            align: 'right',
            renderer: (d, dIndex) => {
              let notAllowedToEdit = [d.creator, ...(d.editors || [])].indexOf(session.userId) < 0;
              return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                  {
                    canEditCampaign && !notAllowedToEdit &&
                    <Link
                      className={classNames({disabled: notAllowedToEdit})}
                      to={`/campaign/settings?id=${d.id}`}
                      onMouseOver={columnTooltip.onMouseOver({txt: 'Edit Campaign Settings'})}
                      onMouseLeave={columnTooltip.onMouseOut()}
                    >
                      <i className="fa fa-edit"/>
                    </Link>
                  }
                  <StyledDropdown
                    mini={true}
                    useFixed={true}
                    fixedLeft={-100}
                    fixedTop={dIndex > 5 ? -140 : 10}
                    closeOnClick={true}
                    toggler={<i className="fa fa-ellipsis-v"/>}>
                    {/*<div className={`menu-item`}>*/}
                    {/*  <Link to={`/campaign/settings?id=${d.id}`}><i className="fa fa-edit"/>Settings</Link>*/}
                    {/*</div>*/}
                    {
                      canViewCampaign &&
                      <div className={`menu-item`}>
                      <Link to={`/campaign/details?id=${d.id}`}><i className="fa fa-bar-chart"/>Insights</Link>
                      </div>
                    }
                    {
                      canViewCampaignRevisions &&
                      <div className={`menu-item`}>
                        <Link to={`/campaign/history?id=${d.id}`}><i className="material-symbols-outlined gfont">history</i> History</Link>
                      </div>
                    }
                    {
                      canEditCampaign && d.type === 'blended_cpv' && !notAllowedToEdit &&
                      ['junjie.wu@mediacorp.com.sg'].indexOf(session.userId) >= 0
                      &&
                      <div className={`menu-item`}>
                        <Link to={`/campaign/ext-settings?id=${d.id}`}><i className="material-symbols-outlined gfont">settings</i> Blended CPV</Link>
                      </div>
                    }
                    {
                      canEditCampaign && !notAllowedToEdit && d.type === "performance_partnership" &&
                      <div className={`menu-item`}>
                        <Link to={`/campaign/client-data?id=${d.id}`}><i className="fa fa-database gfont"></i> Client Data</Link>
                      </div>
                    }
                    {
                      (canDeleteCampaign || d.creator === session.userId) && !notAllowedToEdit &&
                      <div className={`menu-item red`} onClick={e => this.deleteCampaign(d)}>
                        <a><i className="fa fa-trash gfont"></i> Delete</a>
                      </div>
                    }
                  </StyledDropdown>
                </div>
              )
            }
          },
        ]}
        rows={canViewList ? campaigns : []}
        sort={{sortColumn: sortBy, direction: sortDirection}}
        sortChange={sortBy => {
          this.setState({sortBy: sortBy}, () => this.queryAgain());
        }}
        directionChange={direction => {
          this.setState({sortDirection: direction}, () => this.queryAgain());
        }}
        noDataText={<div style={{height: '110px', marginTop: '50px'}}>{canViewList ? 'No Campaign Found.' : 'Not Allowed.'}</div>}
      />
    )
  }

  buildInsightQuery() {
    const {page, PAGE_SIZE, keyword, filters, sortBy, sortDirection} = this.state;
    const query = {
      keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filters,
      sortBy,
      sortDirection
    };
    return query;
  }

  cacheParams() {
    cacheResource(CAMPAIGN_LIST_CACHE_KEY, _.pick(this.state, ['page', 'PAGE_SIZE', 'selectedColumns', 'sortBy', 'sortDirection', 'filters', 'keyword']))
  }

  queryAgain() {
    this.cacheParams();
    this.setState({loading: true});
    return post(`/api/queryCampaignList`, this.buildInsightQuery()).then(data => {
      this.setState({data: data, loading: false});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }

  deleteCampaign(campaign) {
    CondirmDialog.showConfirm(`Do you really want to delete this campaign ?`, () => {
      this.setState({loading: true});
      post('/api/deleteCampaign', {id: campaign.id}).then(result => {
        if (result.success) {
          this.setState({loading: false, page: 1}, () => this.queryAgain())
          // this.props.history.push(`/campaigns`);
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }

  downloadCSV() {
    const { selectedColumns } = this.state;
    let query = this.buildInsightQuery();
    query.limit = 10000;
    query.offset = 0;
    query.labelMap = supportedColumns.reduce((ret, next) => ({...ret, [next.key]: next.label}));
    let missingLabels = {
      last_update_by: 'Last Updated by',
      impressions: 'Impressions',
      cpm: 'CPM',
      cpr: 'CPR',
      reach: 'Reach',
    }
    query.labelMap = {...query.labelMap, ...missingLabels};
    this.setState({loading: true});
    return post('/api/downloadCampaignList', {query, selectedColumns}, {responseType: 'blob'}).then(blob => {
      saveAs(blob, 'campaign-list.csv');
      this.setState({loading: false});
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
})
