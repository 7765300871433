import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {DashboardWrapper,} from './index.style';
import {DashboardWrapper as PlainDashboardWrapper} from './index.style.plain';
import Timeseries from "./timeseries";
import Barchart from "./barchart";
import {datePeriodWindows, campaignStatusMap, platformLabelMap, inflightStatusMap} from '../utils/metadata';
import {CondirmDialog, DateSelectorDropdown, Grid, ProgressBar} from "../uikit";
import classNames from "classnames";
import {Aladin_Blue_Dark, Aladin_Orange_Light} from "../app/StyleCommon";
import {SelectStyled} from "../campaigns/select.style";
import {Link, Redirect} from "react-router-dom";
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import Piechart from "./piechart";
import StreamTable from "./stream";
import Template from "../reporting/template";
import Table from "../uikit/table";
import {TableWrapper} from "../charts/index.style";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";
import {cacheResource, getFromCache} from "../utils/cache";

const HOME_CACHE_KEY = 'HOME_CACHE_KEY';
export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(HOME_CACHE_KEY) || {};
    this.state = {
      overallPeriod: 'last6months',
      breakdownPeriod: 'last6months',
      feedCategory: 'alert',
      showFutureRevenue: false,
      showFutureRevenue1: false,
      ...cachedSettings
    }
  }

  cacheParams() {
    cacheResource(HOME_CACHE_KEY, _.pick(this.state, ['overallPeriod', 'breakdownPeriod', 'showFutureRevenue', 'showFutureRevenue1']))
  }

  render() {
    const {session} = this.props.appState;
    const {overallPeriod, breakdownPeriod, showFutureRevenue, showFutureRevenue1, data, error, loading, feedCategory} = this.state;
    const {campaigns = [], timeseries=[], breakdown = {}, alerts = [], activities = []} = data || {};
    let UIWrapper = DashboardWrapper;
    if(window.location.search.indexOf('light') >= 0) {
      UIWrapper = PlainDashboardWrapper;
    }
    let canViewDashboard = session.rights.indexOf('View Home Dashboard') >= 0;

    return (
      <MainLayout activeItem="home" loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryDashboard`}
            params={this.buildInsightQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && canViewDashboard && <UIWrapper><ErrorMessage message={error}/></UIWrapper>
        }
        {
          !canViewDashboard && <UIWrapper><ErrorMessage message={'Not Allowed'}/></UIWrapper>
        }
        {
          !!data && canViewDashboard &&
          <UIWrapper>
            <div className="live-campaigns">
              <h3 style={{marginLeft: '10px'}}>Latest Campaigns</h3>
              <div className="items">
                {campaigns.map((d, campaignIndex) => {
                  let status = d.health_status;
                  return (
                    <Link
                      key={d.id + '_' + d.name}
                      to={`campaign/details?id=${d.id}`} className={classNames('item')}>
                      <div className="name">
                        {d.name}
                      </div>
                      <div className="advertiser">
                        {/*<img src={d.logo}/>*/}
                        <strong className="advertiser">{d.advertiser}</strong>
                      </div>
                      <div className="metric">
                        <div className={'inflight_status'}>{inflightStatusMap[d.inflight_status]}</div>
                        {/*<Gauge value = {_.random(20, 60)}/>*/}
                        {
                          d.inflight_status === 'READY' &&
                          <div className={classNames('status')}>{moment(d.start_date).diff(moment(), 'day')} days to start</div>
                        }
                        {
                          d.inflight_status !== 'READY' &&
                          <div className={classNames('status', status)}>{campaignStatusMap[status]}</div>
                        }

                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <Grid type="3-1">
              <div>
                <div className="revenue-chart">
                  <h3>
                    <span>Revenue Overview</span>
                    <SelectStyled
                      plainStyle={true}
                      defaultLabel="Select Period"
                      selected={overallPeriod}
                      menuFooter={
                      <div>
                        <label style={{whiteSpace: 'nowrap', padding: '5px 10px 5px 15px', marginTop: '5px', display: 'block', borderTop: '1px solid #dadbdc'}}>
                          <input type="checkbox" checked={showFutureRevenue}
                          onChange={e => this.setState({showFutureRevenue: e.target.checked}, () => this.refreshChart('timeseries'))}/>
                          Show future revenue
                        </label>
                      </div>
                      }
                      data={datePeriodWindows.reverse()}
                      onChange={period => this.setState({overallPeriod: period}, () => this.refreshChart('timeseries'))}/>
                  </h3>
                  <Timeseries data={timeseries}/>
                </div>
                <div className="key-breakdowns">
                  <h3>
                    <span>Revenue Channels</span>
                    <SelectStyled
                      plainStyle={true}
                      defaultLabel="Select Period"
                      selected={breakdownPeriod}
                      data={datePeriodWindows.reverse()}
                      menuFooter={
                        <div>
                          <label style={{whiteSpace: 'nowrap', padding: '5px 10px 5px 15px', marginTop: '5px', display: 'block', borderTop: '1px solid #dadbdc'}}>
                            <input type="checkbox" checked={showFutureRevenue1}
                                   onChange={e => this.setState({showFutureRevenue1: e.target.checked}, () => this.refreshChart('breakdown'))}/>
                            Show future revenue
                          </label>
                        </div>
                      }
                      onChange={period => this.setState({breakdownPeriod: period}, () => this.refreshChart('breakdown'))}/>
                  </h3>
                  <div className="items">
                    <div className="item" key="platform">
                      <p><strong>Platforms</strong></p>
                      <Piechart type="pie" data={breakdown.platform} labelMap={platformLabelMap}/>
                    </div>
                    {[
                      // {dimension: 'Platform', title: 'Platform', labelMap: platformLabelMap, data: breakdown.platform},
                      {dimension: 'Advertiser', title: 'Top Advertisers', data: breakdown.advertiser},
                      // {dimension: 'Sales Group', title: 'Sales Group', data: breakdown.sales_group},
                      {dimension: 'Industry', title: 'Top Industries', data: breakdown.industry},
                    ].map(d => {
                      return (
                        <div className="item" key={d.dimension}>
                          <p style={{textAlign: 'center'}}><strong>{d.title}</strong></p>
                          {!!d.data && <Barchart data={d.data} labelMap={d.labelMap}/>}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className="feeds">
                <h3>
                  <span>Live Feed</span>
                </h3>
                <div className="feeds-header">
                  {/*{*/}
                  {/*  [*/}
                  {/*    {key: 'alert', label: 'Alerts'},*/}
                  {/*    // {key: 'activity', label: 'Activities'},*/}
                  {/*  ].map(d => {*/}
                  {/*    return (*/}
                  {/*      <div*/}
                  {/*        key={d.key}*/}
                  {/*        onClick={e => this.setState({feedCategory: d.key})}*/}
                  {/*        className={classNames('header-tab', {selected: feedCategory === d.key})}>*/}
                  {/*        {d.label}*/}
                  {/*      </div>*/}
                  {/*    )*/}
                  {/*  })*/}
                  {/*}*/}
                </div>
                <div className="items">
                  {
                    !alerts || !alerts.length &&
                    <div className="title">No Data</div>
                  }
                  {(feedCategory === 'alert'? (alerts || []) : (activities || [])).map((d, itemIndex) => {
                    return (
                      <a className="item" key={itemIndex} href={d.click_through_link}>
                        <div>
                          <div className="title">
                            {
                              (d.level === 'WARNING' || d.level === 'WARNNING') &&
                              <i className="material-symbols-outlined yellow">info</i>
                            }
                            {
                              d.level === 'INFO' &&
                              <i className="material-symbols-outlined ok">info</i>
                            }
                            {
                              d.level === 'SUCCESS' &&
                              <i className="fa fa-solid fa-circle ok"></i>
                            }
                            {
                              d.level === 'ERROR' &&
                              <i className="fa fa-solid fa-circle bad"></i>
                            }
                            <strong>{d.type.replace(/_/g, ' ')}</strong>
                          </div>

                          <div className="desc">
                            {/* <div>{d.details}</div> */}
                            <div dangerouslySetInnerHTML={{__html: d.details.replace('Please change campaign settings according to the change.', '')}}></div>
                          </div>
                          <div className="date">
                            <div>{moment(d.date).fromNow()}</div>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                </div>
                {
                  alerts.length > 2 &&
                  <div className="feeds-footer" onClick={e => this.openStream()}>
                    Load More <i className="material-symbols-outlined">keyboard_double_arrow_right</i>
                  </div>
                }
              </div>
            </Grid>
          </UIWrapper>
        }
      </MainLayout>
    )
  }
  openStream() {
    const {data} = this.state;
    const {alerts = []} = data || {};
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: 'Live Feed',
      width: '900px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <StreamTable />
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  buildInsightQuery() {
    const {overallPeriod, breakdownPeriod, showFutureRevenue, showFutureRevenue1} = this.state;
    return {overallPeriod, breakdownPeriod, showFutureRevenue, showFutureRevenue1};
  }

  refreshChart(insightType) {
    this.cacheParams();
    this.setState({loading: true});
    const query = this.buildInsightQuery();
    query.requiredInsights = [insightType];
    post('/api/queryDashboard', query).then(newData => {
      let {data: oldData} = this.state;
      this.setState({data: {...oldData, ...(_.pick(newData, [insightType]))}, loading: false});
    }).catch(e => {
      this.setState({loading: false, error: e.message})
    });
  }
})
