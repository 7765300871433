import React from 'react'
import _ from 'lodash'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper, FormField, FormWrapper, UserProfileDailogWrapper} from './user_edit.style';
import classnames from "classnames";

import {
  Table,
  TableScrollWrapper,
  TabContainer,
  Select,
  Btn,
  ProgressBar,
  Dropdown,
  CondirmDialog
} from "../uikit/index";
import moment from "moment/moment";
import qs from "qs";
import Selectbox from "../uikit/selectbox";
import {DarkGray, MediumGray1} from "../app/StyleCommon";
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import ReactPasswordStrength from "../uikit/password";

const isValidEmail = email => {
  return /\S+@\S+\.\S+/.test(email);
}

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = qs.parse(window.location.search.slice(1));
    this.state = {
      user: {}
    }
    if(urlParams.id) {
      this.state.id = urlParams.id;
    }
  }

  buildQuery() {
    const urlParams = qs.parse(window.location.search.slice(1));
    return {id: urlParams.id};
  }

  render() {
    const {data, loading, error, errorMap = {}} = this.state;
    const {user = {}} = this.state;
    const {roles = [], privileges = []} = data || {};
    const urlParams = qs.parse(window.location.search.slice(1));
    const privilegeMap = _.flatten(privileges.map(d => d.data)).reduce((ret, next) => ({...ret, [next.id]: next.priv}), {});

    const {session} = this.props.appState;
    let canModifyUser = session.rights.indexOf('Modify User') >= 0;
    let canCreateUser = session.rights.indexOf('Create User') >= 0;
    let canChangePassword = session.roles.includes('Administrator');
    let createMode = !urlParams.id;

    return (
      <MainLayout activeItem="admin" breadcrumb={[{path: '/admin', label: 'Admin Settings'}, {path: '/admin/user/create', label: 'Create User'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryUserProfile`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data, user: data.user});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        <Wrapper>
          {!user.id && <h3>Create New User</h3>}
          {!!user.id && <h3>Update User Settings</h3>}
          <FormWrapper onSubmit={e => e.preventDefault()} autocomplete="off">
            {
              !!error &&
              <FormField>
                <div className="err-msg">{error}</div>
              </FormField>
            }
            {
              ((!canModifyUser && urlParams.id) || (!canCreateUser && !urlParams.id)) &&
              <FormField>
                <div className="err-msg">Not allowed</div>
              </FormField>
            }

            <FormField className={classnames('field', {error: !!errorMap.id})}>
              <label>Mediacorp Email</label>
              <input
                value={user.id || ''}
                name="off-1"
                autoComplete="off"
                type="text"
                // onChange={e => onEmailChange(e.target.value)}
                onChange={e => this.setState({user: {...user, id: e.target.value}, touched: true}, () => this.validator('id'))}
              />
              {
                !!errorMap.id &&
                <div className="error-msg">{errorMap.id}</div>
              }
            </FormField>
            <FormField>
              <label>User Full Name</label>
              <input
                value={user.name || ''}
                name="off-2"
                type="text"
                onChange={e => this.setState({user: {...user, name: e.currentTarget.value}}, () => this.validator('name'))}
              />
              {
                !!errorMap.name &&
                <div className="error-msg">{errorMap.name}</div>
              }
            </FormField>
            {
              canChangePassword && !createMode &&
              <FormField>
                <label>{createMode ? 'New Password' : 'Password'}</label>
                {/*<ReactPasswordStrength*/}
                {/*  disabled={!canChangePassword}*/}
                {/*  className="password-strength-wrapper"*/}
                {/*  style={{display: 'block'}}*/}
                {/*  minLength={8}*/}
                {/*  minScore={5}*/}
                {/*  defaultValue={user.password || ''}*/}
                {/*  changeCallback={v => {*/}
                {/*    this.setState({user: {...user, password: v.password}})*/}
                {/*  }}*/}
                {/*  inputProps={{name: "cc", autoComplete: "anyrandominvalidvalue", className: "form-control"}}*/}
                {/*/>*/}
                <input
                  value={user.password || ''}
                  name="off-2"
                  type='text'
                  placeholder={'********'}
                  onChange={e => this.setState({user: {...user, password: e.currentTarget.value}}, () => this.validator('password'))}/>
                {
                  !!errorMap.password &&
                  <div className="error-msg below">{errorMap.password}</div>
                }
              </FormField>
            }
            <FormField>
              <label>Department</label>
              <input
                value={user.department || ''}
                name="off-2"
                type="text"
                onChange={e => this.setState({user: {...user, department: e.currentTarget.value}}, () => this.validator('department'))}/>
              {
                !!errorMap.department &&
                <div className="error-msg">{errorMap.department}</div>
              }
            </FormField>
            <FormField>
              <label>Roles</label>
              <Selectbox label={<span/>}
                        caret={true}
                        data={roles.map(d => ({...d, key: d.role, label: d.role}))}
                        selected={(user.roles || [])[0]}
                       multi={false}
                     itemRenderer={d => {
                       return (
                         <div style={{margin: '5px 0'}}>
                           <strong>{d.role}</strong>
                           <div style={{fontSize: '12px', color: DarkGray, whiteSpace: 'normal', maxWidth: '100%'}}>
                             {d.privileges.map(p => {
                               return <span key={p} style={{marginRight: '10px'}}>{privilegeMap[p]}</span>
                             })}
                           </div>
                         </div>
                       )
                     }}
                       onChange={newRole => this.setState({user: {...user, roles: [newRole]}}, () => this.validator('roles'))}/>
              {
                !!errorMap.roles &&
                <div className="error-msg">{errorMap.roles}</div>
              }
            </FormField>
            <FormField>
              <label>Personnel Number (Required for sales person)</label>
              <input
                value={user.sales_code || ''}
                name="off-2"
                type="text"
                onChange={e => this.setState({user: {...user, sales_code: e.currentTarget.value}})}
              />
            </FormField>
            <FormField>
              <Btn disabled={!canModifyUser && !canCreateUser} type="primary" onClick={e => this.handleSubmit(e)}>
                {urlParams.id ? `Update` : 'Create'}
              </Btn>
            </FormField>
          </FormWrapper>

        </Wrapper>
      </MainLayout>
    )
  }

  renderProfileSavedDialog(user) {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      width: '600px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <UserProfileDailogWrapper>
          <h3>User Profile Created</h3>
          <div className='subhead'>User profile with email: <b>{user.id}</b> created successfully, What would you like to do ?</div>
          {/* <div className='mb16'>By Clicking <b>"Close"</b> will navigate to the Users Listing Page.</div>
          <div className='mb16'>By Clicking <b>"Create Next"</b>, close the dialog box and can continue to create new users.</div> */}
          <div className='buttons'>
            <button className='reset' onClick={e => {
              CondirmDialog.closeAll();
              this.props.history.push(`/admin/users`);
            }}>Close</button>
            <button className='primaryButton' onClick={() => {
              CondirmDialog.closeAll();
              this.setState({user: {}, errorMap: {}})
            }}>Create Next</button>
          </div>
      </UserProfileDailogWrapper>,
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  handleSubmit = () => {
    const errorMap = this.validator(null, true);
    if(Object.keys(errorMap).length) {
      this.setState({errorMap});
    } else {
      this.setState({errorMap: {}});
      const {user} = this.state;
      const urlParams = qs.parse(window.location.search.slice(1));
      this.setState({loading: true});
      let createMode = !urlParams.id;

      post(`/api/${createMode ? 'createUser': 'updateUser'}`, {user}).then(result => {
        this.setState({loading: false, error: null, mode: 'read'});
        if(!createMode) {
          CondirmDialog.showAlert('User profile saved.');
          this.props.history.push(`/admin/users`);
        } else {
          this.renderProfileSavedDialog(user);
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    }
  }

  onEmailChange (val) {
    const {user} = this.state;
    this.setState({user: {...user, id: val}})
  }

  validator (check_field, returnOnly) {
    const urlParams = qs.parse(window.location.search.slice(1));
    let createMode = !urlParams.id;
    const {user} = this.state;
    const {id, name, password, department, roles = []} = user || {};
    let errorMap = {};
    if (!id) {
      errorMap.id = 'Email is required';
    }
    if (!isValidEmail(id) && id !== undefined) {
      errorMap.id = 'Email is not Valid';
    }
    else if (!/mediacorp(.com)*.sg$/ig.test(id)) {
      errorMap.id = 'Only Mediacorp email is supported';
    }

    if(!createMode) {
      if(password && (!/[^a-z0-9]/ig.test(password) || !/[A-Z]/g.test(password) || !/[a-z]/g.test(password) || !/[0-9]/g.test(password))) {
        errorMap.password = 'Password should be a mixture of alphanumeric characters with at least one or more special characters, number, upper case and lower case characters.';
      }

      if (password && password.length < 8) {
        errorMap.password = 'Password should be at least 8 characters';
      }
    }


    if (!name) {
      errorMap.name = 'Name is required';
    }
    if (!department) {
      errorMap.department = 'Department is required';
    }

    if (department && department.length > 50) {
      errorMap.department = 'Department should be at more than 50 characters';
    }
    if (!roles || !roles.length) {
      errorMap.roles = 'Role is required';
    }

    if(!returnOnly) {
      this.setState({errorMap});
    }
    return errorMap;
  }
})
