import styled, {css} from 'styled-components';
import {
  Aladin_Orange_Dark, Aladin_Orange_Light,
  Aladin_Gray_Dark,
  Aladin_BG,
  Aladin_Purple_Dark,
  BitterSweet,
  Grass,
  LightBlue,
  LightGray,
  MediumGray,
  MediumGray1, Aladin_BG_1, MediumGray2, Aladin_BG_TV_1, LightGray1
} from "../app/StyleCommon";

export const Wrapper = styled.div`
  padding: 20px;

  .template-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background: ${Aladin_BG};
    padding: 10px 30px;
    border-radius: 30px;
    position: relative;
    min-height: 180px;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    .more-less-trigger {
      position: absolute;
      bottom: 10px;
      cursor: pointer;
      text-align: right;
      font-size: 12px;
      font-weight: normal;
      right: 30px;
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
        right: 25px;
      }

      i {
        font-size: 13px;
        vertical-align: middle;
      }
    }

    .template-list-left {
      //flex-grow: 1;
      //margin-right: 40px;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }
    }

    .report_intr {
      margin-top: 50px;
      flex-grow: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }

      img {
        width: 156px;
        transform: scaleX(-1);
      }
    }

    h4 {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      a {
        font-size: 14px;
        color: ${Grass};
        cursor: pointer;
      }

      i {
        font-size: 14px;
      }
    }

    .items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      margin: 20px 20px 20px 0;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 10px 0;
      }

      .item {
        cursor: pointer;
        flex-grow: 1;
        align-self: stretch;
        background: ${Aladin_BG};
        padding: 10px 10px 0px 15px;
        border-radius: 10px;
        margin-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(25% - 20px);
        min-height: 100px;
        margin-bottom: 10px;
        text-decoration: none;
        flex-wrap: wrap;
        flex: none;
        box-sizing: border-box;
        position: relative;
        border: 1px solid ${LightGray1};
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
        &.disabled {
          opacity: .5;
          cursor: not-allowed;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 15px auto;
        }

        p {
          font-size: 12px;
        }

        .delete-link {
          position: absolute;
          right: 10px;
          bottom: 0px;
          cursor: pointer;
          padding: 10px;

          &:hover {
            color: ${Aladin_Orange_Dark};
          }
        }

        &:hover {
            //background: ${Aladin_BG_1} !important;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
        }

        &:first-child {
          //border: 2px dashed ${Grass};
            // background: ${Aladin_BG};
          //background: linear-gradient(120deg, #cfcffc 0%, #fcdcf1 100%);
          //border: 1px solid transparent;
        }

        //background: linear-gradient(160deg, rgb(255, 253, 253) 0%, rgba(250, 214, 187, 0.44) 100%);
        //background-color: rgba(243, 228, 216, 0.28);

        //&:nth-child(n + 1) {
        //  //background-color: rgba(241, 223, 221, 0.15);
        //  background: linear-gradient(120deg, rgba(244, 193, 104, 0.04) 0%, rgba(110, 179, 196, 0.04) 100%);
        //  //background: linear-gradient(120deg, rgba(248, 245, 246, 0.57) 0%, rgba(248, 190, 180, 0.24) 100%);
        //}
        //
        //&:nth-child(n + 2) {
        //  //background-color: rgba(231, 210, 194, 0.28);
        //  background: linear-gradient(120deg, rgba(110, 179, 196, 0.04) 0%, rgba(236, 104, 92, 0.04) 100%);
        //}
        //
        //&:nth-child(n + 3) {
        //  //background-color: rgba(201, 217, 218, 0.24);
        //  background: linear-gradient(120deg, rgba(236, 104, 92, 0.04) 0%, rgba(253, 227, 169, 0.04) 100%);
        //}
        //
        //
        //&:nth-child(n + 4) {
        //  //background-color: rgba(186, 201, 204, 0.29);
        //  background: linear-gradient(120deg, rgba(253, 227, 169, 0.04) 0%, rgba(110, 179, 196, 0.04) 100%);
        //}

        //
        //&:nth-child(2) {
        //  //background: linear-gradient(120deg, rgba(247, 245, 250, 0.44) 0%, rgba(236, 193, 248, 0.37) 100%);
        //  background: linear-gradient(120deg, rgba(243, 196, 160, 0.2) 0%, rgba(246, 226, 189, 0.28) 100%);
        //}
        //
        //&:nth-child(3) {
        //  background: linear-gradient(120deg, rgba(244, 193, 104, 0.25) 0%, rgba(110, 179, 196, 0.29) 100%);
        //  //background: linear-gradient(120deg, rgba(246, 250, 252, 0.49) 0%, rgba(198, 179, 245, 0.37) 100%);
        //}

        .item-inner {
          display: flex;
          align-items: flex-start;
        }

        .icon {
          text-align: center;
          font-size: 32px;
          margin-right: 20px;
        }

        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }
  }

  .report-table {
    margin-bottom: 20px;

    table {
      margin-top: 20px;
      thead th {
        background: white;
        border: none;
        white-space: nowrap;
        border-top: none;
        border-left: 1px solid white;
        border-right: 1px solid white;
        border-bottom: 1px solid ${MediumGray};
      }
      tbody td {
        vertical-align: top;
      }
      .time {

      }

      .desc {
        font-size: 12px;
        color: ${MediumGray2};
      }
      .creator{
        max-width: 180px;
        white-space: normal;
        * {
          word-break: break-all;
        }
      }
      .actions {
        .dropdown {
          width: 30px;
          .dropdown-toggle {
            height: 20px;
          }
        }
      }
      .report-name {
        max-width: 300px;
        word-break: break-word;
      }
      .campaign-info {
        max-width: 320px;
        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

  }
`

export const TableController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 30px 10px 0 0;
  h4 {
    margin: 0;
    white-space: nowrap;
  }
  .searchBox {
    margin-right: 20px;
    position: relative;
    min-width: 250px;
    i {
      position: absolute;
      left: 10px;
      top: 6px;
      color: ${Aladin_Orange_Dark};
    }
    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border: none;
      outline: none;
      border-bottom: 1px solid ${MediumGray};
        //background: ${LightGray};
      box-sizing: border-box;
      outline: none;
      width: 100%;
      border-radius: 0px;
      background: rgba(255,255,255, .5);
      &:focus {
        border-radius: 0px;
        border-color: ${Aladin_Orange_Dark};
      }
    }
  }
`

export const TableController1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: ${Aladin_BG};
  background: #fbfbfb;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  ${props => props.whiteBG && css`
    background: white;
  `}
  >a {
    cursor: pointer;
    &:hover {
      color: ${Aladin_Orange_Dark} !important;
    }
  }
  .searchBox {
    margin-right: 20px;
    position: relative;
    min-width: 250px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 10px 0;
    }
    i.fa-search {
      position: absolute;
      left: 10px;
      top: 6px;
      color: ${Aladin_Orange_Dark};
    }
    i.fa-times {
      position: absolute;
      right: 10px;
      top: 6px;
      color: ${Aladin_Gray_Dark};
      cursor: pointer;
    }
    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border: none;
      outline: none;
      border-bottom: 1px solid ${MediumGray};
        //background: ${LightGray};
      box-sizing: border-box;
      outline: none;
      width: 100%;
      border-radius: 5px;
      background: rgba(255,255,255, .5);
      &:focus {
        border-radius: 0px;
        border-color: ${Aladin_Orange_Dark};
      }
    }
  }
  >.select-box {
    .toggler {
      border: none;
      i {
        display: none;
      }
    }
  }
  .dropdown-toggle {
    background: transparent !important;
  }
`
