import styled, {css} from 'styled-components';
import {
  Aladin_Orange_Dark, Aladin_Orange_Lighter,
  Aqua1,
  BlueJeans,
  LightGray,
  LightGray1,
  LoadingZIndex,
  MeOrange
} from "../../app/StyleCommon";

export const LoaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${LoadingZIndex};
  background: rgba(0,0,0,.1);
  ${props => props.type === 'absolute' && css`
    position: absolute;
    background: rgba(0,0,0,.05);
    ${Spinner} {
      height: 18px;
      width: 18px;
      border-width: 3px !important;
    }
  `}
  ${props => props.size === 'normal' && css`
    ${Spinner} {
      height: 36px;
      width: 36px;
      border-width: 6px !important;
    }
  `}
  ${props => props.transparent && css`
    background: transparent;
  `}
  .lds-grid {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-grid div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${Aladin_Orange_Dark};
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    top: 4px;
    left: 4px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 4px;
    left: 16px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 4px;
    left: 28px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 16px;
    left: 4px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 16px;
    left: 16px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 16px;
    left: 28px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 28px;
    left: 4px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 28px;
    left: 16px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 28px;
    left: 28px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
      background: ${Aladin_Orange_Dark};
    }
    50% {
      opacity: 0.2;
      background: ${Aladin_Orange_Lighter};
    }
  }
`

export const Spinner = styled.div`
    height: 36px;
    width: 36px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    animation: rotation 1.2s infinite linear;
    border-left: 6px solid ${LightGray1};
    border-right: 6px solid ${LightGray1};
    border-bottom: 6px solid ${LightGray1};
    border-top: 6px solid ${Aladin_Orange_Dark};
    border-radius: 100%;
    margin: 0 5px;
    ${props => props.type === 'small' && css`
      height: 16px;
      width: 16px;
      border-width: 3px;
    `}
    ${props => props.type === 'mini' && css`
      height: 12px;
      width: 12px;
      border-width: 2px;
    `}

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

`