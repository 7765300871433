import axios from 'axios';

function request(method) {
  return (url, data, config = {}) => {
    return axios({
      url: url,
      credentials: 'include',
      cache: 'no-store',
      method: method,
      data: data,
      timeout: 250 * 1000,
      ...config,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...(config.headers || {})
      },
    }).then(response => {
      const {data, status, statusText} = response;
      if (status === 401 && window.location.href.indexOf('/login') < 0) {
        window.location = "/login"
      }
      if (status !== 200) {
        throw new Error(statusText);
      }
      return data;
    }).catch(err => {
      if (err.response && err.response.status === 401 && window.location.href.indexOf('/login') < 0) {
        return window.location = "/login";
      }
      if(err.response && err.response.statusText) {
        throw new Error(err.response.statusText);
      }
      if (err.message !== 'Unauthorized') {
        console.error(err);
      }
      throw err;
    });
  };
}

export const get = request('GET');
export const post = request('POST');
export const del = request('DELETE');