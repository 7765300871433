import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_BG_1,
  Aladin_Orange_Dark,

} from "../../app/StyleCommon";

export const Container = styled.div`
`;

export const Wrapper = styled.div`
    border-bottom: 1px solid ${MediumGray};
    overflow: hidden;

    .question-container{
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        background: transparent;
        border: none;
        cursor: pointer;
        color: #434A54;
      }
      .question-container.active{
        color: ${Aladin_Orange_Dark};
        background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
      }
      .question-container:hover{
        background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
      }
      .arrow{
        font-size: 2rem;
        transition: .5s ease-in-out;
      }
      .arrow.active{
        rotate: 180deg;
        color: #1db954;
      }
        .answer-container{
        padding: 0 1rem;
        transition: height .7s ease-in-out;
      }
      .answer-content{
        padding: 10px 0;
        margin: 0;
        font-size: 12px;
        ul {
          list-style-type: disc;
          li {
            padding: 2px 0;
            margin-left: 15px;
          }
        }
      }
`;


 