import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans, BlueJeans1,
  Dark,
  DarkGray, DarkGray1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1, Grass1, BitterSweet1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const FilterTagWrapper = styled.div`
  display: flex;
  //margin: 10px 0;
  align-items: center;
  position: relative;
  flex-grow: 1;
  border-bottom: 1px solid ${MediumGray};
  >i {
      margin-right: 10px;
  }
  .select-box {
    width: auto !important;
    flex-grow: 1;
    > .toggler {
      border: none !important;
      color: ${MediumGray1};
      i {
        display: none !important;
      }
    }
    >.menu {
      right: 0;
      left: 0;
    }
  }
  .share-box {
    border-bottom: none !important;
    input {
      border-bottom: none;
      background-color: white !important;
      max-width: 250px;
    }
    .auto-complete-dropdown {
      min-width: 400px;
    }
  }
  .clearAll {
    align-self: center;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
    @media screen and (max-width: 768px) {
      position: static;
      background-color: ${LightGray1};
      padding: 5px;
      width: 100%;
      border: none;
    }
  }
  .tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .tag {
    padding: 2px 10px;
    background-color: ${LightGray};
    border: 1px solid ${LightGray1};
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    margin: 5px 10px 5px 0;
    @media screen and (max-width: 768px) {
      margin: 5px 0;
    }
    .toggler {
      border: none;
      font-size: 12px;
    }
    .criteria {
      position: absolute;
      top: 30px;
      left: 0;
      right: auto;
      border: 1px solid ${LightGray1};
    }
    strong{
      color: ${DarkGray};
      margin: 0 5px;
    }
    > i {
      margin-left: 10px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 50%;
      font-size: 10px !important;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${LightGray1};
      &:hover {
        color: ${BlueJeans1};
      }
    }
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .tag {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
    }
  }

  .criteria {
    padding: 20px;
    min-width: 120px;
    z-index: 1;
    background: white;
    .criteria-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      font-weight: bold;
      a {
        cursor: pointer;
      }
    }
    .criteria-op {
      margin: 10px 0;
    }
    .criteria-value {
      margin: 10px 0;
      > input {
        box-sizing: border-box;
        width: 100%;
        padding: 5px 10px;
        outline: none;
        border: none;
        border-bottom: 1px solid ${LightGray1};
      }
    }
    .apply-btn {
      text-align: right;
      padding: 10px 0;
    }
  }

`