import React, {PureComponent} from 'react';
import {HintWrapper, HintPopup} from './index.style';
import {FixedHook} from './hooks';
import classNames from "classnames";

export default class extends PureComponent {

  constructor(props) {
    super(props);
    this.popupRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  render() {
    const {content, pos = 'top', width = 'auto', label = '', type, useFixed} = this.props;
    return (
      <HintWrapper pos={pos} className={classNames(this.props.className, 'hint')} ref={this.wrapperRef}>
        <div className="hint-anker" ref="anker">
          {
            !!label &&
            <span>{label}</span>
          }

          {
            type === 'error'
              ?
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
              :
              <i className="fa fa-question-circle" aria-hidden="true"></i>
          }

        </div>
        <HintPopup ref={this.popupRef} className={`hint-popup ${pos}`} style={{width: width}}>{content}</HintPopup>
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.popupRef} offset={{left: 0, top: 5}}/>}
      </HintWrapper>
    );
  }
};