import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {
  Aladin_BG,
  BlueJeans,
  DarkGray1,
  FontSizeBig, Grass1,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  MenuBlue
} from "../app/StyleCommon";
import {SideMenuZIndex, BodyMaskZIndex, HeaderZIndex} from "../app/StyleCommon";

const HeaderHeight = '50px';
const SideMenuWidth = '250px';

export const ChartWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 55px;
  border: 1px solid ${LightGray1};
  background-color: ${Aladin_BG};
  padding: 10px 15px 20px 15px;
  width: 300px;
  z-index: 10000;
  border-top: 1px solid ${LightGray};

  .chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;

    span {
      font-weight: bold;
      font-size: 16px;
    }

    i {
      font-size: 20px;
    }
  }

  .message {
    border-radius: 10px;
    border: 1px solid ${MediumGray1};
    padding: 5px 10px;
    margin: 10px 0;
    max-width: 70%;
    font-size: 12px ;
    i {
      margin-right: 5px;
    }
    .fa {
      color: ${Grass1};
    }

    .fa-signal {
      transform: scaleX(-1);
      color: ${BlueJeans};
      vertical-align: middle;
      margin-top: -5px;
    }
  }

  .message-left {
    float: left;
  }

  .message-right {
    float: right;
    background: rgba(110, 179, 196, 0.13);
  }

  .chat-box {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .chat-input {
      border: 1px solid ${DarkGray1};
      border-radius: 5px;
      padding: 10px 8px;
      background: white;

      textarea {
        width: 100%;
        border: none;
        margin-bottom: 10px;
        background: transparent;
      }

      .fly {
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
      }

    }
  }
`;
