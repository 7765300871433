import styled, {css} from 'styled-components';
import {Aladin_Orange_Dark, Aladin_BG, DarkGray1, MediumGray1} from "../../app/StyleCommon";

export const MSQListWrapper = styled.div`
  // min-height: 430px !important;
  max-height: 430px !important;
  .data-table {
    min-height: 350px !important;
    max-height: 350px !important;
    overflow: auto;
    thead th{
      background: white;
      border-bottom: 1px solid ${Aladin_Orange_Dark};
    }
  }
`

export const SelectedRow = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  span {
    color: ${Aladin_Orange_Dark};
  }
`

export const List = styled.div`
  max-height: 420px;
  overflow: auto;
`

export const DropdownItem = styled.div`
  cursor: pointer;
  white-space: normal;
  position: relative;
  padding: 4px 10px;
  .msq-item-option {
    margin-bottom: 10px;
  }
  .msq-name {
    font-weight: bold;
    font-size: 16px;
    color: ${Aladin_Orange_Dark};
    word-break: break-word;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  .msq-tag-list {
    margin-bottom: 4px;
    line-height: 20px;
  }
  .msq-platform-list {
    margin-left: 14px;
    list-style-type: square;
  }
  .msq-tag {
    padding: 1px 5px 1px 0;
    border-radius: 4px;
    margin: 0 0 2px 0;
    font-size: 14px;
    color: ${DarkGray1};
  }
`;