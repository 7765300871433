import styled, {css} from 'styled-components';
import Select from './index';
import {
  DarkGray,
  DarkGray1,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1
} from "../../app/StyleCommon";

export const SelectStyled = styled(Select)`
  margin-left: 10px;
  .toggler {
    background-color: ${LightGray};
    color: ${DarkGray1};
    //filter: drop-shadow(0 1px 1px rgba(0,0,0,.2));
    border: 1px solid ${MediumGray};
    border-radius: 4px;
    padding: 4px 10px;
    padding-right: 30px;
    font-size: ${FontSizeMedium};
    &:hover {
      background-color: ${LightGray1};
    }
  }
  .menu {
    right: 0;
    top: 30px;
    left: auto;
    max-height: 400px;
    min-width:  200px;
    overflow-y: auto;
    ${props => props.menuAlign === 'right' && css`
      text-align: right;
    `}
    ${props => props.overflow && css`
      overflow: ${props.overflow};
    `}
    @media (max-width: 667px) {
      left: 0;
      right: 0;
    }
  }
`

export const StyledSelectBox = SelectStyled;

export const SelectInlineStyled = styled(SelectStyled)`
  &.small .toggler {
    font-size: 12px !important;
    font-weight: bold;
  }
  &.small .menu {
    right: 0 !important;
    left: auto;
  }
  .toggler {
    border: none;
    display: inline;
    background: none;
    padding: 0 25px 3px  0;
    margin: 0;
    border-radius: 0;
    background-color: white;
    color: ${DarkGray};
    i {
      color: ${MediumGray1};
      right: 5px;
    }
    &:hover {
      background: none;
    }
    img {
      display: none;
    }
  }
  .menu {
    top: 25px;
    text-align: left;
    left: auto !important;
    right: auto !important;
    ${props => props.mobileAdjust && css`
      @media screen and (max-width: 667px) {
        left: 50px !important;
        right: 10px !important;
        width: calc(100vw - 60px) !important;
      }
    `}

    .dimension-item {
        display: flex;
        align-items: flex-start;
        padding: 5px 20px;
        white-space: normal;
        padding: 5px 0;
        width: 300px;
        img {
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .description {
           font-size: 11px;
           color: ${DarkGray1};
           font-weight: 300;
        }
    }
  }
`