import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import classnames from 'classnames';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  moneyFormatterGenerator,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Dark1, Aladin_Blue_Light,
  Aladin_Orange_Dark,
  Aladin_BG_1, Aladin_Purple_Light,
  BlueJeans1,
  DarkGray,
  Grass1,
  MediumGray,
  MediumGray1
} from "../app/StyleCommon";
import _ from "lodash";
import {siteLabelMap} from "../utils/metadata";
import {getContextChartScale} from "./util";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      unit: 'daily'
    }
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    const {unit} = this.state;
    return (
      <ChartWrapper className="chart">
        <div className="chart-header">
          <div className="chart-legent">
          </div>
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {

    let {data, cfg, context, currency} = this.props;
    let {metric, dimension, metrics = [], type: visType = 'bar', type1: visType1 = 'line', yAxisStyle} = cfg;
    const container = this.containerRef.current;
    let metricMeta = context.mixedMetaData.find(d => d.key === metric);
    let metricMap = context.mixedLabelMap;
    data = _.cloneDeep(data);
    data.forEach(d => {
      if(d.label) {
        d.label = d.label + ` (${d.key})`;
      } else {
        d.label = siteLabelMap[d.key] || d.key;
      }
    })
    if(dimension === 'timebelt') {
      data = _.sortBy(data, d => {
        let [hour, range]  = (d.key || '').split(' ');
        if(hour && range) {
          if(d.key === '12 PM') {
            return Number(hour);
          }
          return Number(hour) + {AM: 0, PM: 12}[range];
        } else {
          return -1;
        }
      });
    } else {
      data = _.sortBy(data, d => d[metric]).reverse();
    }

    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    const height = Math.max(container.clientHeight || 280,  280);
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    let tooltipIndex = -1, allMetrics = [metric, ...metrics];
    metrics = metrics.filter(m => m!== metric);
    const colors = ['#4f8f98', '#f1a164', '#F29727', '#F24C3D', '#9B72CF', '#40916C'];
    // const colors = ['#4f8f98', "#ec685c", "#868686", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif', overflow: 'breakAll'},
      color: colors,
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        extraCssText: 'max-width:auto; white-space:pre-wrap;',
        valueFormatter: (value, i) => {
          let currentMetric = allMetrics[++tooltipIndex % allMetrics.length];
          let metricMeta = context.mixedMetaData.find(d => d.key === currentMetric);
          return getMetricFormatter(metricMeta, currency)(value)
        },
      },
      grid: {
        bottom: data.length > 10 ? '50' : '40',
        top: '50',
        left: '45px',
        // right: 40 + metrics.length * 50
        right: 60
      },
      legend: {
        left: 10,
        show: true,
        itemWidth: 16,
        itemHeight: 10,
      },
      xAxis: {
        type: 'category',
        data: data.map(d => (d.label || d.date)),
        axisLabel: {
          rotate: data.length > 5 ? 20 : undefined,
          formatter: function (value) {
            if(dimension === 'sites') {
              value = metricMap[value] || value;
            }
            if(value.length > 18){
              return value.substring(0, 15) + '...';
            }
            return value;
          }
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            fontWeight: 'bold'
          }
        },
      },
      yAxis: _.compact([
        {
          type: 'value',
          position: 'left',
          splitNumber: 2,
          // name: metricMap[metric],
          nameLocation: 'start',
          nameGap: 20,
          nameTextStyle: {
            align: 'left',
            color: colors[0]
          },
          axisTick: {
            inside: false, length: 5
          },
          splitLine: {
            show: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              fontWeight: 'bold'
            }
          },
          axisLabel: {
            inside: true,
            margin: -30,
            color: colors[0],
            fontWeight: 'bold',
            formatter: value => {
              return (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(value);
            }
          }
        },
        yAxisStyle === 'combine' ? null :
          ((m, mIndex) => {
            return {
              type: 'value',
              position: 'right',
              splitNumber: 2,
              offset: 0,
              // offset: 30 + 50 * mIndex,
              alignTicks: true,
              // name: (() => {
              //   if(metrics.length === 1) {
              //     return metricMap[m];
              //   } else if(metrics.some(m => /vcr_[0-9]+pct/g.test(m))){
              //     return 'Video Completion Rate';
              //   } else if(metrics.some(m => /video_completions_[0-9]+pct/g.test(m))){
              //     return 'Video Completion';
              //   } else {
              //     return '';
              //   }
              // })(),
              axisLine: { onZero: false },
              // nameGap: 0,
              // nameLocation: 'start',
              nameRotate: metrics.length > 1 ? 20 : 0,
              nameTextStyle: {
                align: 'center',
                // align: metrics.length > 1 ? 'middle' : 'start',
                color: colors[mIndex + 1]
              },
              axisTick: {
                inside: true,
                length: 5,
              },
              splitLine: {
                show: true
              },
              axisLabel: {
                inside: true,
                margin: -30,
                fontWeight: 'bold',
                padding: 2,
                color: colors[mIndex + 1],
                backgroundColor: metrics.length > 1 ? 'white' : undefined,
                formatter: value => {
                  if(!value) return '';
                  let mMeta = context.mixedMetaData.find(d => d.key === m);
                  return getMetricFormatter(mMeta, currency)(value);
                }
              }
            };
        })((metrics || [])[0], 0),
      ]),
      series: [
        {
          name: metricMap[metric],
          type: visType || 'bar',
          barMaxWidth: 100,
          barGap: '20%',
          emphasis: {
            focus: 'series'
          },
          data: data.map(d => d[metric]),
          label: {
            show: data.length < 15,
            position: 'top',
            formatter: d => (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(d.value)
          },
          itemStyle: {
            color: '#6eb3c4',
            borderRadius: 0
          },
          // itemStyle: {
          //   color: colors[0],
          //   borderRadius: 4
          // },
          areaStyle: {opacity: 0.08},
        },
        ...((metrics || []).map((secondaryMetric, secondaryMetricIndex) => {
          let secondaryMetricMeta = context.mixedMetaData.find(m => m.key === secondaryMetric);
          return {
            name: metricMap[secondaryMetric],
            type: visType1 || 'line',
            // yAxisIndex: 1 + secondaryMetricIndex,
            yAxisIndex: yAxisStyle === 'combine' ? 0 : 1,
            symbolSize: 5,
            splitNumber:3,
            color: colors[1 + secondaryMetricIndex],
            smooth: metrics.length > 1 ? false : false,
            emphasis: {
              focus: 'series'
            },
            data: data.map(d => d[secondaryMetric]),
            label: {
              formatter: d => (secondaryMetricMeta.formatter2 || getMetricFormatter(secondaryMetricMeta, currency))(d[secondaryMetric])
            },
          };
        }))
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
  .chart-actions {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 1;
    .toggler {
      border: none;
      background: ${Aladin_BG_1};
      padding: 2px 40px 2px 10px;
      width: auto;
    }
  }
`;

export const UnitItem = styled.span`
  padding: 0 5px;
  cursor: pointer;
  color: ${DarkGray};
  &:after {
    content: '';
    display: inline-block;
    height: 10px;
    width: 2px;
    margin-left: 8px;
    background: ${MediumGray};
  }
  &:last-child:after {
    display: none;
  }
  &.selected {
    color: ${BlueJeans1};
    font-weight: bold;
  }
`;
