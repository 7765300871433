import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {DialogWrapper} from "./index.style";
import {Btn} from '../btn/index';
import Loader from '../Loader/index';
import {createRoot} from "react-dom/client";

const defaultRoot = createRoot(document.querySelector('#global-confirm'));

const ReactDOMAdapter = {
  render: (comp, element) => {
    let globalEle = document.querySelector('#global-confirm');
    if(element === globalEle) {
      return defaultRoot.render(comp);
    } else {
      return createRoot(element).render(comp);
    }
  }
}


class Dialog extends React.Component {
  static defaultProps = {
    active: true,
    onConfirm: _ => undefined,
    onCancel: _ => undefined,
  }

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.documentClickHandler = this.handleOutsideClick.bind(this);
    this.documentKeypressHandler = this.handleEscKeypress.bind(this);
  }

  componentDidMount() {
    // setTimeout(() => {
    //   let wrapper = this.wrapperRef.current;
    //   if(wrapper) {
    //     wrapper.classList.add('dialog--open');
    //   }
    // })
    if (this.props.backgroundClose) {
      document.addEventListener('click', this.documentClickHandler, true); //use capture
      document.addEventListener('keyPress', this.documentKeypressHandler);
    }
  }

  componentWillUnmount() {
    if (this.props.backgroundClose) {
      document.removeEventListener('click', this.documentClickHandler, true);
      document.removeEventListener('keyPress', this.documentKeypressHandler);
    }
  }

  handleClose(callback) {
    if (typeof callback === 'function') {
      callback();
    } else {
      this.props.onCancel && this.props.onCancel();
    }
  }

  render() {
    const {
      confirmEnabled, children, title, dialogBody, active,
      onConfirm, onCancel, onIgnore, ignoreLabel, cancelLabel, okLabel, confirmInfo,
      loading, closeIconTemplate, noCloseBtn
    } = this.props;
    const {hideCancel, hideOK} = this.props;
    const wrapperProps = _.pick(this.props, ['type', 'fullScreen', 'width']);
    return (
      <DialogWrapper {...wrapperProps} title="" ref={this.wrapperRef}>
        {children}
        <div ref="dialog" className="dialog">
          {
            loading && <Loader type="absolute"/>
          }
          {
            !noCloseBtn &&
            <div className="close" onClick={e => this.handleClose()}>
              {closeIconTemplate || <i className="fa fa-times"/>}
            </div>
          }
          <div className="title" ref="title">
            {title}
          </div>
          <div className="main-screen">{dialogBody}</div>
          <div className="second-screen">

          </div>
          <div className="buttons">
            {
              !hideCancel &&
              <Btn type="reset" onClick={e => this.handleClose(onCancel)}>{cancelLabel || 'Cancel'}</Btn>
            }

            {
              !!ignoreLabel && !!onIgnore &&
              <Btn type="reset" onClick={e => this.handleClose(onIgnore)}>{ignoreLabel}</Btn>
            }
            {
              !hideOK &&
              <Btn type="primary" onClick={e => this.handleClose(onConfirm)}>{okLabel || 'OK'}</Btn>
            }

          </div>
        </div>
      </DialogWrapper>
    )
  }

  handleOutsideClick(e) {
    if (!this.props.backgroundClose) {
      return;
    }
    var dialog = this.refs.dialog;
    if (!dialog) {
      return;
    }
    if (!dialog.contains(e.target)) {
      this.handleClose(e);
    }
  }

  handleEscKeypress(e) {
    if (e.keyCode === 27) {
      if (!this.props.backgroundClose) {
        return;
      }
      this.handleClose(e);
    }
  }
}

Dialog.closeAll = function (containerID) {
  containerID = containerID || 'global-confirm'
  ReactDOMAdapter.render(<div/>, document.getElementById(containerID));
}

Dialog.closeDealog = function () {
  defaultRoot.render(<div/>);
}

Dialog.showConfirm = function (title, callback, cancleCallback) {
  const confirmInfo = {
    title: <div style={{
      display: 'block',
      textAlign: 'center',
      margin: '20px 20px 10px 20px',
      lineHeight: '30px'
    }}>{title}</div>,
    onConfirm: () => {
      ReactDOMAdapter.render(<div/>, document.getElementById('global-confirm'));
      callback();
    },
    onCancel: () => {
      ReactDOMAdapter.render(<div/>, document.getElementById('global-confirm'));
      cancleCallback && cancleCallback();
    }
  };
  ReactDOMAdapter.render(
    <Dialog fullScreen={true} width={'500px'} {...confirmInfo} />, document.getElementById('global-confirm')
  );
}
Dialog.showAlert = function (title, callback) {
  const confirmInfo = {
    title: <div style={{
      display: 'block',
      textAlign: 'center',
      margin: '20px 20px 10px 20px',
      lineHeight: '30px'
    }}>{title}</div>,
    hideCancel: true,
    backgroundClose: true,
    onConfirm: () => {
      ReactDOMAdapter.render(<div/>, document.getElementById('global-confirm'));
      (typeof callback === 'function') && callback();
    },
    onCancel: () => {
      ReactDOMAdapter.render(<div/>, document.getElementById('global-confirm'));
    }
  };
  ReactDOMAdapter.render(
    <Dialog fullScreen={true} width={'500px'} {...confirmInfo} />, document.getElementById('global-confirm')
  );
}

Dialog.showNextScreen = function (component, bread) {
  document.querySelector(`#global-confirm .main-screen`).classList.add('hide')
  const secondScreen = document.querySelector(`#global-confirm .second-screen`);
  const closeBtn = document.querySelector(`#global-confirm .dialog > .close`);
  ReactDOMAdapter.render(component, secondScreen);
  ReactDOMAdapter.render(<div>{bread.map((d, i) => {
    if(i === 0) {
      return <a className="bread" onClick={e => {
        ReactDOMAdapter.render(<span>{d}</span>, document.querySelector(`#global-confirm .title`));
        Dialog.showMainScreen();
      }}>{d}</a>
    } else {
      return <span className="bread">{d}</span>
    }
  })}</div>, document.querySelector(`#global-confirm .title`));
  secondScreen.classList.add('hide');
  closeBtn.style.display = 'none';
  setTimeout(() => {
    secondScreen.classList.remove('hide');
  }, 150);
}

Dialog.showMainScreen = function () {
  const secondScreen = document.querySelector(`#global-confirm .second-screen`);
  const closeBtn = document.querySelector(`#global-confirm .dialog > .close`);
  secondScreen.classList.add('hide');
  setTimeout(() => {
    ReactDOMAdapter.render(<div />, secondScreen);
    document.querySelector(`#global-confirm .main-screen`).classList.remove('hide')
    closeBtn.style.display = 'block';
    let firstTitleBread = document.querySelector(`#global-confirm .title .bread`);
    if(firstTitleBread) {
      ReactDOMAdapter.render(<div>{firstTitleBread.innerHTML}</div>, document.querySelector(`#global-confirm .title`));
    }

  }, 300);
}

Dialog.defaultRoot = defaultRoot;

export default Dialog;