import _ from 'lodash';
export const TvAudiences = [
  {key : 'People 4+', group: 'People Age Groups'},
  {key : 'People 15+', group: 'People Age Groups'},
  {key : 'People 25+', group: 'People Age Groups'},
  {key : 'People 35+', group: 'People Age Groups'},
  {key : 'People 45+', group: 'People Age Groups'},
  {key : 'People 55+', group: 'People Age Groups'},
  {key : 'People 65+', group: 'People Age Groups'},
  {key : 'People 15-24', group: 'People Age Groups'},
  {key : 'People 25-34', group: 'People Age Groups'},
  {key : 'People 35-44', group: 'People Age Groups'},
  {key : 'People 45-54', group: 'People Age Groups'},
  {key : 'People 55-64', group: 'People Age Groups'},
  {key : 'Male 4+', group: 'Male Age Groups'},
  {key : 'Male 15+', group: 'Male Age Groups'},
  {key : 'Male 25+', group: 'Male Age Groups'},
  {key : 'Male 35+', group: 'Male Age Groups'},
  {key : 'Male 45+', group: 'Male Age Groups'},
  {key : 'Male 55+', group: 'Male Age Groups'},
  {key : 'Male 65+', group: 'Male Age Groups'},
  {key : 'Male 15-24', group: 'Male Age Groups'},
  {key : 'Male 25-34', group: 'Male Age Groups'},
  {key : 'Male 35-44', group: 'Male Age Groups'},
  {key : 'Male 45-54', group: 'Male Age Groups'},
  {key : 'Male 55-64', group: 'Male Age Groups'},
  {key : 'Female 4+', group: 'Female Age Groups'},
  {key : 'Female 15+', group: 'Female Age Groups'},
  {key : 'Female 25+', group: 'Female Age Groups'},
  {key : 'Female 35+', group: 'Female Age Groups'},
  {key : 'Female 45+', group: 'Female Age Groups'},
  {key : 'Female 55+', group: 'Female Age Groups'},
  {key : 'Female 65+', group: 'Female Age Groups'},
  {key : 'Female 15-24', group: 'Female Age Groups'},
  {key : 'Female 25-34', group: 'Female Age Groups'},
  {key : 'Female 35-44', group: 'Female Age Groups'},
  {key : 'Female 45-54', group: 'Female Age Groups'},
  {key : 'Female 55-64', group: 'Female Age Groups'},
  {key : 'Chinese 4+', group: 'Ethnicity'},
  {key : 'Chinese 15+', group: 'Ethnicity'},
  {key : 'Chinese 18+', group: 'Ethnicity'},
  {key : 'Chinese 18-29', group: 'Ethnicity'},
  {key : 'Chinese 30-54', group: 'Ethnicity'},
  {key : 'Chinese 35-49', group: 'Ethnicity'},
  {key : 'Malay 4+', group: 'Ethnicity'},
  {key : 'Malay 15+', group: 'Ethnicity'},
  {key : 'Malay 18+', group: 'Ethnicity'},
  {key : 'Malay 18-29', group: 'Ethnicity'},
  {key : 'Malay 30-54', group: 'Ethnicity'},
  {key : 'Malay 35-49', group: 'Ethnicity'},
  {key : 'Indian 4+', group: 'Ethnicity'},
  {key : 'Indian 15+', group: 'Ethnicity'},
  {key : 'Indian 18+', group: 'Ethnicity'},
  {key : 'Indian 18-29', group: 'Ethnicity'},
  {key : 'Indian 30-54', group: 'Ethnicity'},
  {key : 'Indian 35-49', group: 'Ethnicity'},
  {key : 'Indian 35+', group: 'Ethnicity'},
  {key : 'Parents with kids 0-3 years', group: 'Parents with Kids'},
  {key : 'Parents with kids 0-9 years', group: 'Parents with Kids'},
  {key : 'Parents with kids <15 years', group: 'Parents with Kids'},
  {key : 'PMEB', group: 'Occupation'},
  {key : 'PMEB PI 3k+', group: 'Occupation'},
  {key : 'PMEB PI 5k+', group: 'Occupation'},
  {key : 'White Collar', group: 'Occupation'},
  {key : 'Blue Collar', group: 'Occupation'},
  {key : 'Student', group: 'Occupation'},
  {key : 'Housewife', group: 'Occupation'},
  {key : 'Working adults (PMEB, WC, BC)', group: 'Occupation'},
  {key : 'PI up to $2,000', group: 'Personal Income'},
  {key : 'PI $2,001 - $5,000', group: 'Personal Income'},
  {key : 'PI $5,001 - $8,000', group: 'Personal Income'},
  {key : 'PI $8,001 - $10,000', group: 'Personal Income'},
  {key : 'PI above $10,000', group: 'Personal Income'},
  {key : 'PI above $5,000', group: 'Personal Income'},
  {key : 'HHI up to $3,000', group: 'Household Income'},
  {key : 'HHI $3,001 - $5,000', group: 'Household Income'},
  {key : 'HHI $5,001 - $8,000', group: 'Household Income'},
  {key : 'HHI $8,001 - $10,000', group: 'Household Income'},
  {key : 'HHI $10,001 - $15,000', group: 'Household Income'},
  {key : 'HHI above $15,000', group: 'Household Income'},
  {key : 'HHI above $7,000', group: 'Household Income'},
  {key : 'Singapore Citizens P15+', group: 'Citizenship'},
  {key : 'Grocery buyer', group: 'Other'},
]
const grouped = _.groupBy(TvAudiences, _.property('group'));
export const TvTAOptions = Object.keys(grouped).map(groupKey => {
  return {
    key: groupKey, label: groupKey,
    data: grouped[groupKey].map(d => {
      return {
        key: d.key, label: d.key
      }
    })
  }
})