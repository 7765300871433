import styled, {css} from 'styled-components';
import {
  LightGray1,
  BlueJeans1,
  BitterSweet,
  Sunflower,
  Grass1,
  LoadingZIndex,
  Aladin_Red_Dark
} from '../../app/StyleCommon';

const BarWidth = '150px';
const BarHeight = '15px';


export const ProgressWrapper = styled.div`
  width: 70px;
  z-index: ${LoadingZIndex};
  ${props => !props.visible && css`
    display: none;
  `}

  ${props => props.fixcenter && css`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
  svg circle {
    //transition: stroke-dashoffset .5s linear;
    fill: rgba(255,255,255,.2);
  }
  svg text {
    font-size: 6px;
    font-weight: bold;
    fill: ${Aladin_Red_Dark};
  }
`;