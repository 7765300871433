import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {Wrapper, StepWrapper, AlertBox, ConfirmBox} from './build.style';
import {StyledSelectBox} from '../uikit/selectbox/customized.style';
import DatePicker from 'react-datepicker';
import {Btn, Hint, ProgressBar, Select, CondirmDialog} from "../uikit";
import NP from 'number-precision';
import classnames from "classnames";
import {SelectStyled} from "./select.style";
import Highlighter from 'react-highlight-words';
import {
  Aladin_Blue_Dark1, Aladin_Orange_Dark,
  Aladin_Purple_Dark,
  Aladin_Red_Dark,
  Aladin_Red_Light, Grass1,
  LightGray,
  MediumGray1
} from "../app/StyleCommon";
import ShareBox from "./ShareBox";
import {post} from "../utils/request";
import {
  campaignTypeMap,
  CampaignTypeOptions, KPI_CATS,
  KPIOptions,
  platformKPIOptions,
  platformLabelMap,
  platformMeta, platformSorted,
  TargetAudienceOptions
} from "../utils/metadata";
import {TvTAOptions} from "../utils/ta";
import qs from "qs";
import createTooltip, {defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import SearchBox from "../uikit/search/index";
import MsqSearch from "../uikit/search/msqSearch";
import {floatFormatter, moneyFormatter, uniquesFormatter} from "../utils/formatter";
import platforms from "./platforms";
import {findPlatformMetrics} from "../charts";
import {Link} from "react-router-dom";
import {StyledDropdown} from "../uikit/dropdown/styled";
import Errorbox from "../uikit/errorbox";
import {currencySymbalMap} from "../utils/symbals";
import { HandleAddClientData } from "./clientData.common";
NP.enableBoundaryChecking(false);


const DEFAULT_TA = 'People 15+';

export function Hightlight(props) {
  return (
    <Highlighter searchWords={(props.keyword || '').split(' ')}
                 autoEscape={true}
                 textToHighlight={props.text || (props.children.join(' '))}/>
  )
}

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      step: 'edit',  //edit|review
      refreshKey: -1, //used for force update some field dom key.
      campaign: {
        platforms: [],
        eidtors: [],
        viewers: []
      },
      showClientMetrics: {
        val: false,
        platform: null
      },
      clientMetrics: []
    }
    this.tooltip = createTooltip({targetSelector: 'dynamic',tipCreator: d => `<div>${d.txt}</div>`,directionFunc: 'bottom'});
  }


  getUrlParams() {
    const urlParams = qs.parse(window.location.search.slice(1));
    return urlParams || {};
  }
  buildQuery() {
    const urlParams = this.getUrlParams();
    return {id: urlParams.id};
  }

  render() {
    const {data, error, loading, refreshKey} = this.state;
    const {campaign, alerts = [], errorMap = {}, touched, step} = this.state;
    const {msq, id_links = [], id_filters = []} = campaign || {};
    const {session = {}} = this.props.appState || {};
    const diffMap = this.diffCampaign();
    const hasRight = session.rights.indexOf('Setup/Update Campaign') >= 0;
    const freezed = !hasRight || step === 'review';
    const createMode = !campaign.id;

    const fromDetailPage = this.getUrlParams().fr === 'campaign_dashboard';
    let notAllowed = campaign.id && [campaign.creator, ...(campaign.editors || []), 'Mike.Lin@mediacorp.com.sg', 'satvik.nandal@mediacorp.com.sg'].indexOf(session.userId) < 0;
    let canSetupCampaign = session.rights.indexOf('Setup/Update Campaign') >= 0;
    const isSalesPerson = ['Expert User Level 2', 'Administrator'].every(role => (session.roles || []).indexOf(role) < 0);
    return (
      <MainLayout activeItem="campaign" breadcrumb={_.compact([
        {path: '/campaigns', label: 'Campaigns'},
        fromDetailPage ? {path: `/campaign/details?id=${campaign.id}`, label: 'Campaign Insights'} : null,
        {path: '/campaign/builder', label: createMode ? 'Campaign Setup' : (
            <div>
              <strong>{data.campaign.name}</strong>
              &nbsp;(ID: {campaign.id} ,
              {/*created:{moment(campaign.create_time).fromNow()},*/}
              last update: {moment(campaign.update_time).fromNow()},
              creator: {campaign.creator_name})
            </div>
        )}
      ])} loading={loading}>
        {
          !data && !error &&
          <>
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/campaignBuilderQuery`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
              if(data.campaign) {
                this.setState({campaign: _.cloneDeep(data.campaign), alerts: _.cloneDeep(data.alerts)});
              }
            }}
            errorHandler={e => this.setState({error: e.message})}/>
            </>
        }
        <Wrapper className={hasRight ? '' : 'disabled'}>
          {
            !!error &&
            <Errorbox message={error} />
          }
          {
            ((!!campaign && campaign.release_status === 'DELETED') || (!!data && data.notFound) ) &&
            <div className="campaign404 fixedNexttoMenu">Campaign Not Found</div>
          }
          {
            !canSetupCampaign &&
            <div className="campaign404 fixedNexttoMenu">
              You are not allowed to setup omni channel campaign. &nbsp; <a className="button" href="#" onClick={e => window.history.back()}>Go Back</a>
            </div>
          }
          {
            !!campaign && (notAllowed || (data && data.notAllowed)) &&
            <div className="campaign404 fixedNexttoMenu">
              You are not allowed to change this campaign settings. &nbsp; <a className="button" href="#"  onClick={e => window.history.back()}>Go Back</a>
            </div>
          }
          {
            !!data &&
            <>
              <div className="header">
                {createMode &&<p>Set up an omni-channel campaign to track campaign performances and build reports across platforms.</p>}
                {!createMode &&<div className='settings'><strong>Campaign Settings:</strong></div>}
                {
                  alerts.map(alert => {
                    return (
                      <AlertBox key={alert.id} className="alert-box">
                        <div className="title" dangerouslySetInnerHTML={{__html: alert.title}}></div>
                        <div className="desc" dangerouslySetInnerHTML={{__html: alert.details.replace('Please change campaign settings according to the change.', '')}}></div>
                        {
                          alert.status === 'NEED_RESOLVE' &&
                          <div className="actions">
                            {
                              !alert.applied && alert.data?.msq?.mbfStatus !== 'Terminated' &&
                              <Btn type="success" size="mini" onClick={e => this.applyMsqChanges(alert)}>
                                Patch change to settings.
                              </Btn>
                            }
                            {
                              !!alert.applied &&
                              <Btn type="link" size="mini" onClick={e => {
                                this.setState({campaign: _.cloneDeep(data.campaign), alerts: _.cloneDeep(data.alerts), touched: false, errorMap: {}})
                              }}>
                                <i className="material-symbols-outlined">Undo</i>
                                Reset to old campaign settings.
                              </Btn>
                            }

                            {/*<Btn type="primary" size="mini" onClick={e => this.markAlertSolved(alert)}>*/}
                            {/*  Mark as resolved.*/}
                            {/*</Btn>*/}
                          </div>
                        }
                      </AlertBox>
                    )
                  })
                }
                {diffMap.no_change &&
                  <AlertBox type="info">Seems the MSQ change is not affecting current campaign settings. Please save the campaign so it will point to the latest MSQ version.</AlertBox>
                }
                {/*{*/}
                {/*  !!campaign.used_by &&*/}
                {/*  <AlertBox type="info" key={alert.id}>*/}
                {/*    <div className="title">Note: This MSQ ID is used by Campaign "{campaign.used_by.name}" previously.</div>*/}
                {/*  </AlertBox>*/}
                {/*}*/}
              </div>
              <StepWrapper>
                <div className="step">
                  <h3>Campaign Details</h3>
                  <div className='step-container'>
                    <div className={classnames('field', {error: !!errorMap.msq_id, changed: diffMap.msq_id})}>
                      <label>
                        <span>MSQ ID</span>
                        <Hint
                          width={350}
                          pos="top"
                          content={
                            <div>
                              Use MSQ ID to link campaigns across different platforms such as TV, Digital, Radio and Social. Add sub-campaigns from other MSQ by clicking on the link icon on the right.
                              {
                                session.rights.indexOf('Setup/Update Campaign') >= 0 &&
                                <div>If your campaign don't have a MSQ ID yet, you can register a <Link to={'/virtual-links'}>Virtual MSQ</Link>
                                </div>
                              }

                            </div>
                          }/>
                      </label>
                      {/* MSQ Search */}
                      {<MsqSearch
                        className={classnames(`searchBox msq-search-box`, {disabled: freezed})}
                        api="/api/msq_search"
                        limit={500}
                        disabled={false}
                        placeholder="Search MSQ..."
                        selected={campaign.msq_id}
                        selectedRaw={campaign.msq}
                        selectedItemRenderer={d => (
                          <span>
                            {d.id}
                            <span className="desc">
                            ({d.name}, {d.mbfStatus},
                            <span className="msq-tag-list">
                              <span className="msq-tag">Period: {moment(d.start_date).format('DD MMM YYYY')} - {moment(d.end_date).format('DD MMM YYYY')} </span>
                              <span className="msq-tag">Net: {moneyFormatter(d.budget, d.currency)} {d.currency}</span>
                            </span>
                            )
                            </span>
                          </span>
                        )}
                        searchWithoutKeyword={isSalesPerson}
                        closeOnItemClick={true}
                        key={campaign.msq_id}
                        onSearch={data => this.setState({...this.state, searchResults: data})}
                        type="default"
                        queryParams={this.getUrlParams()}
                        searchResults={this.state.searchResults}
                        onChange={d => {
                          campaign.msq = d;
                          campaign.id_links = campaign.id_links || []; //reset
                          campaign.id_filters = campaign.id_filters || []; //reset
                          this.initializeCampaign(true);
                          this.setState({campaign, errorMap: {}, touched: true});
                        }}
                        itemRenderer={(d, id, keyword, data) => this.renderMsqOption(d, id, campaign.msq_id, keyword, e => {})}
                      />}
                      <div>
                        <a  className={classnames("linkage-plus", {disabled: !campaign.msq_id})}
                            onMouseOver={this.tooltip.onMouseOver({txt: 'Link other MSQ(s) as sub-campaigns'})}
                            onMouseLeave={this.tooltip.onMouseOut()}
                            onClick={e => {
                              if(freezed) return;
                              campaign.id_links = campaign.id_links || [];
                              this.setState({campaign: {...campaign, id_links: [...campaign.id_links,  {key: 'overall', op: '$eq'}]}, touched: true})
                            }}><span className="material-symbols-outlined">add_link</span></a>
                      </div>
                      <div>
                        <a  className={classnames("id-filters", {disabled: !campaign.msq_id})}
                            onMouseOver={this.tooltip.onMouseOver({txt: 'Exclude sub campaigns'})}
                            onMouseLeave={this.tooltip.onMouseOut()}
                            onClick={e => {
                              if(freezed) return;
                              campaign.id_filters = campaign.id_filters || [];
                              this.setState({campaign: {...campaign, id_filters: [...campaign.id_filters, {op: '$eq'}]}, touched: true})
                            }}>
                          {/*<span className="material-symbols-outlined">filter_alt</span>*/}
                          <span className="material-symbols-outlined">remove_road</span>
                        </a>
                      </div>
                      {
                        !!errorMap.msq_id  &&
                        <div className="error-msg">{errorMap.msq_id}</div>
                      }
                    </div>
                    {
                      !!campaign.id_links && !!campaign.id_links.length &&
                      <div className={classnames('field', {error: !!errorMap.id_links, changed: diffMap.id_links})}>
                        <label></label>
                        <div className="linkage-list">
                          {
                            campaign.id_links.map((la, laIndex) => {
                              return (
                                <div key={la.key + laIndex} className="linkage-item">
                              <span className="sub-item-label" style={{whiteSpace: 'nowrap', marginRight: '20px', color: Aladin_Blue_Dark1}}>
                                <i className="material-symbols-outlined">add_link</i>
                                Include Campaign:{laIndex + 1}
                              </span>
                                  <SelectStyled
                                    plainStyle={true}
                                    disabled={freezed}
                                    defaultLabel="Linkage Type"
                                    selected={la.key || 'MSQ_ID'}
                                    data={[
                                      {key: 'overall', label: 'MSQ ID'},
                                      {key: 'tv', label: 'TV Campaign ID'},
                                      {key: 'digital', label: 'GAM JIRA ID'},
                                      {key: 'radio', label: 'Radio Campaign ID'},
                                      {key: 'social', label: 'Social Campaign ID'},
                                    ]}
                                    onChange={k => {
                                      la.key = k;
                                      delete la.value;
                                      delete la.campaign;
                                      this.initializeCampaign();
                                      this.setState({campaign, touched: true}, () => this.validator('id_links'))
                                    }}/>
                                  <span className="sep" style={{margin: 'auto 15px'}}>Equals</span>

                                  {/* Include Campaign Search */}
                                  <MsqSearch
                                      className="searchBox search-box"
                                      api={`/api/platform_id_search/${la.key}`}
                                      apiParams={{exclude_msq_id: campaign.msq_id}}
                                      limit={500}
                                      disabled={!la.key}
                                      placeholder={`Search ${la.key ? this.titleCase(la.key) : ""}`}
                                      selected={la.value}
                                      selectedRaw={la.value ? la.campaign : null}
                                      selectedItemRenderer={d => {
                                        return <span>{['tv', 'digital', 'social', 'radio'].indexOf(d.platform) >= 0 ? d.platform_campaign_id : d.id} <span className="desc">({d.name})</span></span>}
                                      }
                                      searchWithoutKeyword={false}
                                      campaign={this.state.campaign}
                                      closeOnItemClick={true}
                                      key={Date.now()}
                                      onSearch={data => this.setState({...this.state, [la.key]: data})}
                                      type="default"
                                      queryParams={this.getUrlParams()}
                                      searchResults={this.state[la.key]}
                                      itemRenderer={(d, id, keyword, data) => {
                                        if(la.key === 'overall') {
                                          return this.renderMsqOption(d, id, campaign.msq_id, keyword, e => {})
                                        }
                                        return (
                                          <div className="msq-name">{d.name} ('MSQ ID :' ${d.id}  | {this.titleCase(la.key)} Id : {d.platform_campaign_id})</div>
                                        )
                                      }}
                                      onChange={d => {
                                        la.value = d.id;
                                        la.campaign = d;
                                        this.initializeCampaign();
                                        this.setState({campaign, touched: true}, () => this.validator('id_links'));
                                      }}
                                    />
                                  <a
                                    className="sub-item-btn"
                                    onClick={e => {
                                      campaign.id_links = campaign.id_links || [];
                                      campaign.id_links.splice(laIndex, 1);
                                      this.initializeCampaign();
                                      this.setState({campaign, touched: true})
                                    }}><i className="material-symbols-outlined">delete</i></a>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                    !!campaign.id_filters && !!campaign.id_filters.length &&
                      <div className={classnames('field', {error: !!errorMap.id_filters, changed: diffMap.id_filters})}>
                        <label></label>
                        <div className="idfilter-list">
                          {
                            campaign.id_filters.map((filter, laIndex) => {
                              return (
                                <div key={filter.key + laIndex} className="filter-item">
                                  <span className="sub-item-label" style={{whiteSpace: 'nowrap', marginRight: '20px', color: Aladin_Orange_Dark}}>
                                    <i className="material-symbols-outlined">filter_alt</i>
                                    Exclude Campaign {laIndex + 1}
                                  </span>
                                  <SelectStyled
                                    plainStyle={true}
                                    disabled={freezed}
                                    defaultLabel="Filter Type"
                                    selected={filter.key}
                                    data={[
                                      {key: 'tv', label: 'TV Campaign ID'},
                                      {key: 'digital', label: 'GAM JIRA ID'},
                                      {key: 'radio', label: 'Radio Campaign ID'},
                                      {key: 'social', label: 'Social Campaign ID'},
                                    ]}
                                    onChange={k => {
                                      filter.key = k;
                                      delete filter.value;
                                      delete filter.campaign;
                                      this.initializeCampaign();
                                      this.setState({campaign, touched: true}, () => this.validator('id_links'))
                                    }}/>
                                  <span  className="sep" style={{margin: 'auto 15px'}}>
                                Equals
                              </span>
                                  {/* Exclude MSQ */}
                                  <MsqSearch
                                      key={Date.now()}
                                      className="searchBox search-box"
                                      api={`/api/platform_id_search/${filter.key}`}
                                      apiParams={{exclude_msq_id: campaign.msq_id}}
                                      isSearchable={false}
                                      staticSearchableKey={filter.key}
                                      limit={500}
                                      disabled={!filter.key}
                                      placeholder={`Search ${filter.key}`}
                                      selected={filter.value}
                                      selectedRaw={filter.campaign}
                                      selectedItemRenderer={d => {
                                        return <span>{['tv', 'digital', 'social', 'radio'].indexOf(d.platform) >= 0 ? d.platform_campaign_id : d.id} <span className="desc">({d.name})</span></span>}
                                      }
                                      searchWithoutKeyword={true}
                                      closeOnItemClick={true}
                                      onSearch={data => {
                                        this.setState({...this.state, [filter.key]: data})
                                      }}
                                      queryParams={this.getUrlParams()}
                                      type="default"
                                      searchResults={this.state.campaign}
                                      itemRenderer={d => <div className="msq-name">{d.name} ({d.id || d.platform_campaign_id})</div>}
                                      onChange={d => {
                                        filter.value = d.id || d.platform_campaign_id;
                                        filter.campaign = d;
                                        this.initializeCampaign();
                                        this.setState({campaign, touched: true}, () => this.validator('id_filters'));
                                      }}
                                    />
                                  <a
                                    className="sub-item-btn"
                                    onClick={e => {
                                      campaign.id_filters = campaign.id_filters || [];
                                      campaign.id_filters.splice(laIndex, 1);
                                      this.initializeCampaign();
                                      this.setState({campaign, touched: true})
                                    }}><i className="material-symbols-outlined">delete</i></a>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      !!errorMap.msq_id_ext  &&
                      <div className={classnames('field', {error: !!errorMap.msq_id_ext})}>
                        <label></label>
                        <div></div>
                        <div className="error-msg left static">{errorMap.msq_id_ext}</div>
                      </div>
                    }
                    <div className={classnames('field', {error: !!errorMap.name, changed: diffMap.name})}>
                    <label>Campaign Name</label>
                    <input
                      type="text"
                      placeholder="Campaign Name"
                      value={campaign.name || ''}
                      disabled={freezed}
                      className='fieldBox'
                      onChange={e => {
                        this.setState({campaign: {...campaign, name: e.target.value}, touched: true}, () => this.validator('name'));
                      }}/>
                    {
                      !!errorMap.name &&
                      <div className="error-msg">{errorMap.name}</div>
                    }
                    </div>
                    <div className={classnames('field', {error: !!errorMap.start_date, changed: diffMap.start_date})}>
                      <label>Start Time</label>
                      <DatePicker
                        selected={campaign.start_date ? moment(campaign.start_date).toDate() : null}
                        onChange={date => {
                          let newDate = date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
                          this.setState({campaign: {...campaign, start_date: newDate}, touched: true}, () => this.validator('start_date'))
                        }}
                        // disabled={freezed}
                        disabled={true}
                        showTimeSelect={false}
                        placeholderText={'Campaign start date'}
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="time"
                        isClearable={false}
                        dateFormat="MMMM d, yyyy"/>
                      {
                        !!errorMap.start_date &&
                        <div className="error-msg">{errorMap.start_date}</div>
                      }
                    </div>
                    <div className={classnames('field', {error: !!errorMap.end_date, changed: diffMap.end_date})}>
                    <label>End Time</label>
                    <DatePicker
                      selected={campaign.end_date ? moment(campaign.end_date).toDate() : null}
                      onChange={date => {
                        let newDate = date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
                        this.setState({campaign: {...campaign, end_date: newDate}, touched: true}, () => this.validator('end_date'))
                      }}
                      // disabled={freezed}
                      disabled={true}
                      showTimeSelect={false}
                      placeholderText={'Campaign end date'}
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      isClearable={false}
                      dateFormat="MMMM d, yyyy"/>
                    {
                      !!errorMap.end_date &&
                      <div className="error-msg">{errorMap.end_date}</div>
                    }
                    </div>

                    <div className={classnames('field', {error: !!errorMap.type, changed: diffMap.type})}>
                      <label>
                        <span>Campaign Type</span>
                        <Hint
                          width={350}
                          pos="top"
                          content={
                          <div>
                            <div>When campaign type is:</div>
                            <ul style={{padding: '10px 0 10px 20px'}}>
                              <li><strong>Guaranteed Impressions</strong>, impressions KPI is required for all platforms.</li>
                              <br/>
                              <li><strong>Guaranteed Reach or Blended CPV</strong>, reach goal is required for all sub platforms.</li>
                            </ul>
                          </div>
                          }/>
                      </label>
                      <SelectStyled
                        plainStyle={true}
                        className="fieldBox"
                        defaultLabel="Campaign Type"
                        selected={campaign.type}
                        disabled={freezed  || !campaign.msq_id}
                        disableFunc = {d => {
                          return d.disabled ||
                            (d.key === 'blended_cpv' && !campaign.allow_blendcpv) ||
                            (d.key === 'guaranteed_page_views' && !campaign.allow_guaranteed_page_views)
                        }}
                        data={CampaignTypeOptions}
                        onChange={type => {
                          campaign.type = type;
                          this.initializeCampaignType(campaign);
                          Object.keys(errorMap).forEach(k => {
                            if(k === 'type' || k === 'platforms' || k.startsWith('kpi')) {
                              delete errorMap[k];
                            }
                          })
                          this.setState({campaign, errorMap, touched: true});
                        }}/>
                      {
                        !!errorMap.type &&
                        <div className="error-msg">{errorMap.type}</div>
                      }
                    </div>

                  </div>







                </div>
                <div className="step platform">
                  {!campaign.msq_id && <div className="step-mask"></div>}
                  <h3>
                    <span>Platforms</span>
                  </h3>
                  {
                    !!errorMap.platforms &&
                    <div className={classnames('field')}>
                      <div className="label"></div>
                      <div className="error-msg static" style={{bottom: "15px"}}>{errorMap.platforms}</div>
                    </div>
                  }
                  <div className={classnames('field header')}>
                    <label></label>
                    <div className="platform-cfg header">
                      <div className="budget">Budget <span className="desc">({campaign.currency || 'SGD'})</span></div>
                      <div className="ta">TA</div>
                      <div className="goal">KPI</div>
                    </div>
                  </div>
                  {
                    (campaign.platforms || []).map((d, platformIndex) => {
                      if(campaign.isSinglePlatform && d.key === 'overall') {
                        return null;
                      }
                      let budgetEditable = freezed || (d.key === 'overall' && (!id_links.length) && !id_filters.length);
                      return (
                        <div key={d.key + refreshKey} className={classnames('field', 'platform-item')}>
                          <div className={classnames('label', {top: d.goals && d.goals.length > 1})}>
                            {platformLabelMap[d.key]}
                          </div>
                          {/*<div className={classnames('platform-cfg', {disabled: ['ooh', 'others'].includes(d.key)})}>*/}
                          <div className={classnames('platform-cfg')}>
                            <div className={classnames('budget', {top: d.goals && d.goals.length > 1})}>
                              <div className="calculator" onClick={e => this.autoCalcuateBudget(d)}
                                   onMouseOver={defaultWhiteTooltipTop.onMouseOver({tipContent: <span>Automatically calculate budget<br/> using other platforms number.</span>})}
                                   onMouseLeave={defaultWhiteTooltipTop.onMouseOut()}>
                                <span className="material-symbols-outlined">function</span>
                              </div>
                              <i>{currencySymbalMap[campaign.currency || 'SGD']}</i>
                              {/*<input*/}
                              {/*  className={classnames("budget-editor editor", {error: errorMap[`budget_${d.key}`]})}*/}
                              {/*  type="number"*/}
                              {/*  min={0}*/}
                              {/*  value={d.budget}*/}
                              {/*  disabled={freezed}*/}
                              {/*  tabIndex={10 + platformIndex}*/}
                              {/*  onChange={e => {*/}
                              {/*    d.budget = e.target.value;*/}
                              {/*    this.setState({campaign, touched: true})*/}
                              {/*  }}*/}
                              {/*/>*/}
                              <NumericFormat
                                className={classnames("budget-editor editor", {error: errorMap[`budget_${d.key}`], changed: diffMap[`budget_${d.key}`]})}
                                min={0}
                                thousandSeparator=","
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 0;
                                // }}
                                allowNegative={false}
                                disabled={freezed}
                                tabIndex={10 + platformIndex}
                                value={floatFormatter(Number(d.budget))}
                                decimalScale={2}
                                defaultValue={''}
                                onValueChange={(e, sourceInfo) => {
                                  d.budget = e.value;
                                  this.setState({campaign, touched: true})
                                }}
                              />
                              {

                              }
                              {
                                !!errorMap[`budget_${d.key}`] &&
                                <div className="error-msg">{errorMap[`budget_${d.key}`]}</div>
                              }
                            </div>
                            <div className={classnames('goal', {disabled: ['ooh', 'others'].includes(d.key)})}>
                              {
                                !!errorMap[`kpi_${platformIndex}`] &&
                                <div className="error-msg">{errorMap[`kpi_${platformIndex}`]}</div>
                              }
                              {
                                ((d.goals && d.goals.length) ? d.goals : [{key: 'placeholder'}]).map((goal, goalIndex) => {
                                  let taOptions = TvTAOptions;
                                  // if(d.key === 'overall') {
                                  //   taOptions = TvTAOptions.filter(g => ['People Age Groups', 'Male Age Groups', 'Female Age Groups'].indexOf(g.key) >= 0);
                                  // }
                                  let clientMetricsData = this.state.campaign.client_metrics || [];
                                  let clientKPIMetricsData = this.state.campaign.client_kpi_metrics || [];
                                  return (
                                    <div
                                      key={goal.ta + goal.kpi}
                                      className={classnames('goal-item', {
                                        error: !!errorMap[`kpi_${platformIndex}_${goalIndex}`] || !!errorMap[`kpi_value_${platformIndex}_${goalIndex}`],
                                        changed: diffMap[`kpi_value_${platformIndex}_${goalIndex}`]
                                      })}>
                                      {
                                        goal.key !== 'placeholder' &&
                                        <>
                                          <SelectStyled
                                            className="ta"
                                            selected={goal.ta || DEFAULT_TA}
                                            plainStyle={true}
                                            defaultLabel=""
                                            searchable={true}
                                            tabIndex={20 + platformIndex}
                                            disabled={freezed}
                                            menuHeader={<div style={{padding: '10px 20px', background: LightGray, borderBottom: '1px solid #f1f2f3', marginBottom: '10px'}}>Select TA</div>}
                                            groups={taOptions}
                                            groupClosable={true}
                                            onChange={k => {
                                              goal.ta = k;
                                              this.setState({campaign, touched: true})
                                            }}/>
                                             {this.state.showClientMetrics.val === true ? <HandleAddClientData clientKPIMetrics={clientKPIMetricsData} onSave={(e) => {
                                                clientKPIMetricsData.push(e[0]);
                                                campaign['client_kpi_metrics'] = clientKPIMetricsData;
                                                this.setState({showClientMetrics: false});
                                                if(e[0]) {
                                                  campaign.platforms[this.state.showClientMetrics.platform].goals = [{kpi: e[0].key}]
                                                }
                                                this.setState({campaign, touched: true})
                                                CondirmDialog.closeAll();
                                              }}
                                              onDelete={(obj) => {
                                                clientKPIMetricsData = clientKPIMetricsData.filter(x => x.key !== obj.key);
                                                campaign['client_kpi_metrics'] = clientKPIMetricsData;
                                                campaign.platforms[this.state.showClientMetrics.platform].goals = [{kpi: ''}];
                                                this.setState({campaign, touched: true, showClientMetrics: false});
                                                CondirmDialog.closeAll();
                                              }}
                                              handleCancel={() => {
                                                this.setState({showClientMetrics: false});
                                                CondirmDialog.closeAll()
                                              }}
                                            /> : null}
                                          <SelectStyled
                                            className={classnames('goal-kpi-key', {error: !!errorMap[`kpi_${platformIndex}_${goalIndex}`]})}
                                            selected={goal.kpi}
                                            plainStyle={true}
                                            disabled={freezed}
                                            tabIndex={30 + platformIndex}
                                            defaultLabel="Select Metric"
                                            // menuHeader={<div style={{padding: '10px 20px', background: LightGray, borderBottom: '1px solid #f1f2f3', marginBottom: '10px'}}>Select Metric</div>}
                                            menuHeader={
                                              <>
                                              <div className='menuHeader'>
                                                Select Metric  {campaign.type === "performance_partnership" &&
                                                <i
                                                  className={classnames('add-metric fa', {
                                                    'fa-cog': clientKPIMetricsData && clientKPIMetricsData.length > 0,
                                                    'fa-plus-circle': clientKPIMetricsData && clientKPIMetricsData.length === 0,
                                                  })}
                                                  aria-hidden="true"
                                                  onClick={() => this.setState({showClientMetrics: {val: true, platform: platformIndex}})}>
                                                </i>}
                                              </div>
                                              {/* {campaign.type === "performance_partnership" ?
                                              <div style={{
                                                  padding: '10px 20px',
                                                  color: Aladin_Orange_Dark,
                                                  borderBottom: '1px solid #f1f2f3',
                                                  cursor: 'pointer',
                                                  fontWeight: 'bold',
                                                  fontSize: '12px',
                                                  marginTop: '-10px'
                                                }}
                                                onClick={() => this.setState({showClientMetrics: {val: true, platform: platformIndex}})}>
                                                Add Client Metric
                                              </div>:  null} */}
                                            </>}
                                            // data={platformKPIOptions[d.key] || KPIOptions}
                                            data={platformKPIOptions[d.key] ? [...platformKPIOptions[d.key], ...clientMetricsData, ...clientKPIMetricsData] : [...KPIOptions, ...clientMetricsData, ...clientKPIMetricsData]}
                                            disableFunc={item => !!(d.goals || []).find(g => {
                                              return g.kpi === item.key && (g.ta || DEFAULT_TA) === (goal.ta || DEFAULT_TA);
                                            })}
                                            // disableFunc={item => d.goals.map(x => x.kpi).indexOf(item.key) >= 0}
                                            onChange={k => {
                                              goal.kpi = k;
                                              this.setState({campaign, touched: true})
                                            }}/>
                                          <span className="sep"> Equals </span>
                                          <NumericFormat
                                            disabled={freezed || !goal.kpi}
                                            className={classnames('editor', {
                                              error: !!errorMap[`kpi_value_${platformIndex}_${goalIndex}`],
                                              changed: diffMap[`kpi_value_${platformIndex}_${goalIndex}`]
                                            })}
                                            min={0}
                                            thousandSeparator=","
                                            disabled={freezed}
                                            allowNegative={false}
                                            tabIndex={40 + platformIndex}
                                            value={goal.value}
                                            defaultValue={''}
                                            suffix={KPI_CATS.PCT_KPIS.indexOf(goal.kpi) >= 0 ? ' %' : ''}
                                            isAllowed={(values) => {
                                              const { floatValue } = values;
                                              if(!floatValue) return true;
                                              // if(KPI_CATS.PCT_KPIS.indexOf(goal.kpi) >= 0) {
                                              //   return floatValue <= 100 && floatValue >= 0;
                                              // } else {
                                              //   return floatValue  >= 0;
                                              // }
                                              return floatValue  >= 0;
                                            }}
                                            onValueChange={(e, sourceInfo) => {
                                              goal.value = e.value;
                                              if(goal.value) {
                                                delete errorMap[`kpi_value_${platformIndex}_${goalIndex}`];
                                              } else {
                                                errorMap[`kpi_value_${platformIndex}_${goalIndex}`] = 'KPI value not set';
                                              }
                                              this.setState({campaign, touched: true})
                                            }}
                                          />
                                          {
                                            d.key === 'social' && !!goal.value &&
                                            <span style={{marginRight: '-20px'}}>
                                            <Hint
                                              width={250}
                                              pos="bottom"
                                              content={
                                                <div style={{padding: '10px 0 10px 20px'}}>
                                                  Note: Social metrics will be excluded from Overall KPI metric. ( due to the lack of a proxy solution at present.)
                                                </div>
                                              }/>
                                            </span>
                                          }
                                          {
                                            !!errorMap[`kpi_${platformIndex}_${goalIndex}`] &&
                                            <div className="error-msg">{errorMap[`kpi_${platformIndex}_${goalIndex}`]}</div>
                                          }
                                          {
                                            !!errorMap[`kpi_value_${platformIndex}_${goalIndex}`] &&
                                            <div className="error-msg">{errorMap[`kpi_value_${platformIndex}_${goalIndex}`]}</div>
                                          }
                                        </>
                                      }
                                      {
                                        goal.key === 'placeholder' &&
                                        <div>Click the '+' button to add goal for platform.</div>
                                      }
                                      {
                                        goal.key !== 'placeholder' &&
                                        <a
                                          className={(goal.value > 1000) ? 'with-desc' : ''}
                                          onMouseOver={this.tooltip.onMouseOver({txt: 'Remove this KPI'})}
                                          onMouseLeave={this.tooltip.onMouseOut()}
                                          onClick={e => {
                                            if(freezed) return;
                                            d.goals.splice(goalIndex, 1);
                                            this.setState({campaign, touched: true})
                                          }}><i className="material-symbols-outlined">delete</i></a>
                                      }
                                      <a
                                        onMouseOver={this.tooltip.onMouseOver({txt: 'Add a new KPI'})}
                                        onMouseLeave={this.tooltip.onMouseOut()}
                                        onClick={e => {
                                          if(freezed) return;
                                          d.goals.push({ta: DEFAULT_TA})
                                          this.setState({campaign, touched: true})
                                        }}><i className="material-symbols-outlined">add</i></a>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                          {/*{*/}
                          {/*  d.key !== 'overall' &&*/}
                          {/*  <div className="platform-action">*/}
                          {/*    <a*/}
                          {/*      onMouseOver={this.tooltip.onMouseOver({txt: 'Remove Platform'})}*/}
                          {/*      onMouseLeave={this.tooltip.onMouseOut()}*/}
                          {/*      onClick={e => {*/}
                          {/*      campaign.platforms.splice(platformIndex, 1);*/}
                          {/*      this.setState({campaign, touched: true})*/}
                          {/*    }}><i className="material-symbols-outlined">delete</i></a>*/}
                          {/*  </div>*/}
                          {/*}*/}
                        </div>
                      )
                    })
                  }

                </div>
                <div className="step step-recipients">
                  {!campaign.msq_id && <div className="step-mask"></div>}
                  <h3>Access Settings</h3>
                  <div className={classnames('field', {error: !!errorMap.editors})}>
                    <label>
                      Campaign Planners:
                      <Hint
                        width={350}
                        pos="top"
                        content={
                          <div style={{padding: '10px 0 10px 20px'}}>
                            Campaign planners can edit and view campaign settings, dashboards and build reports using this campaign as the data source.
                          </div>
                        }/>
                    </label>
                    <ShareBox
                      key={Date.now()}
                      className="share-box"
                      users={campaign.editors}
                      usersMap={data.users}
                      excludeRole={'Viewer'}
                      advertiser_id={isSalesPerson ? (msq || {}).advertiser_id : null}
                      searchWithoutKeyword={isSalesPerson}
                      data={[]}
                      disabled={freezed}
                      onChange={(users, usersMap) => {
                        campaign.editors = users;
                        data.users = usersMap;
                        this.setState({campaign, data, touched: true});
                      }}
                    />
                  </div>
                  <div className={classnames('field', {error: !!errorMap.viewers})}>
                    <label>
                      Campaign Viewers
                      <Hint
                        width={350}
                        pos="top"
                        content={
                          <div style={{padding: '10px 0 10px 20px'}}>
                            Campaign viewers can view campaign dashboards and build reports using this campaign as the data source.
                          </div>
                        }/>
                    </label>
                    <ShareBox
                      key={Date.now()}
                      className="share-box"
                      users={campaign.viewers}
                      usersMap={data.users}
                      advertiser_id={isSalesPerson ? (msq || {}).advertiser_id : null}
                      searchWithoutKeyword={isSalesPerson}
                      data={[]}
                      disabled={freezed}
                      onChange={(users, usersMap) => {
                        campaign.viewers = users;
                        data.users = usersMap;
                        this.setState({campaign, data, touched: true});
                      }}
                    />
                  </div>

                </div>
                <br/>
                {
                  !!data &&
                  <div className="actions fixedNexttoMenu">
                    {
                      step === 'edit' && createMode &&
                      <>
                        <Btn type="reset" onClick={e => {
                          return this.props.history.push(`/campaigns`);
                        }}>
                          Cancel
                        </Btn>
                        <Btn
                          type="reset"
                          disabled={!touched}
                          onClick={e => {
                            campaign.release_status = 'DRAFT';
                            this.saveCampaign('draft');
                          }}>
                          Save As Draft
                        </Btn>
                        <Btn
                          type="primary"
                          disabled={!campaign.msq_id}
                          onClick={e =>{
                            const errorMap = this.validator(null, true);
                            if(Object.keys(errorMap).length) {
                              this.setState({errorMap});
                            } else {
                              this.setState({errorMap: {}, saveMode: 'create', step: 'review'});
                            }
                          }}>
                          Review & Create
                        </Btn>
                      </>
                    }
                    {
                      step === 'review' &&
                      <Btn
                        type="reset"
                        onClick={e =>{
                          this.setState({saveMode: null, step: 'edit'});
                        }}>
                        Back
                      </Btn>
                    }
                    {
                      (!createMode || step === 'review') &&
                      <>
                        {
                          step !== 'review' &&
                          <Btn type="reset" disabled={!touched} onClick={e => {
                            if(data.campaign) {
                              this.setState({campaign: _.cloneDeep(data.campaign), alerts: _.cloneDeep(data.alerts), errorMap: {}})
                            } else {
                              this.setState({campaign: {platforms: [],eidtors: [],viewers: []}, errorMap: {}})
                            }
                          }}>
                            Cancel
                          </Btn>
                        }

                        <Btn
                          type="primary"
                          disabled={!touched}
                          onClick={e => {this.saveCampaign()}}>
                          Save
                        </Btn>
                        {
                          campaign.release_status === 'DRAFT'&&
                          <Btn
                            type="primary"
                            onClick={e => {
                              campaign.release_status = 'RELEASED';
                              this.saveCampaign();
                            }}>
                            Release
                          </Btn>
                        }
                      </>
                    }
                  </div>
                }
              </StepWrapper>
            </>
          }
        </Wrapper>
      </MainLayout>
    )
  }

  titleCase(str) {
    if (str) {
      return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    }
  }

  renderMsqOption(d, id, selectedId, keyword, onClick) {
      let platformsGrouped = _.values(_.mapValues(_.groupBy(d.platforms || [], item => {
        return item.platform + item.platform_campaign_id + item.name;
      }), (arr, key) => {
        return arr.reduce((ret, next) => {
          if(ret) {
            return {
              ...ret,
              net: Number(ret.net || 0) + Number(next.net || 0),
              start_date: _.min([ret.start_date, next.start_date]),
              end_date: _.max([ret.end_date, next.end_date]),
            }
          } else {
            return next;
          }
          return ret;
        }, null)
      }));
      return (
        <>
          <div>
            <span className="msq-name">
              <Hightlight keyword={keyword} text={`${d.name}, MSQ ID: ${d.id}`}/>
            </span>
            <span className="msq-tag-list">
              <span className="msq-tag">
                <Hightlight keyword={keyword}>(Period: {moment(d.start_date).format('DD MMM YYYY')} - {moment(d.end_date).format('DD MMM YYYY')}</Hightlight>
              </span>
              <span className="msq-tag">
                <Hightlight keyword={keyword}>Advertiser: {d.advertiser}</Hightlight>
              </span>
              <span className="msq-tag">
                <strong>Net: </strong>
                <Hightlight keyword={keyword}>{moneyFormatter(d.budget, d.currency)} {d.currency}</Hightlight>
              </span>
              <span className="msq-tag">
                <strong>Sales Person: </strong>
                <Hightlight keyword={keyword} text={`${d.sales_person}`}/>
              </span>
              {!!d.mbfStatus &&
                <span className="msq-tag">
                <strong>Status: </strong>
                <Hightlight keyword={keyword} text={`${d.mbfStatus}`}/>
              </span>
              }

            </span>
          </div>

          <ul className="msq-platform-list">
            {_.sortBy(platformsGrouped, _.property('platform')).filter(p => p.platform !== 'overall').map(p => {
              return (
                <li key={p.platform_campaign_id}>
                  <div className="msq-tag-list">
                    <span className="msq-tag">
                      <strong>{platformLabelMap[p.platform]}</strong>
                      <Hightlight keyword={keyword}>
                       - {p.platform_campaign_id} - {p.name} - {p.product}
                      </Hightlight>
                    </span>
                    <span className="msq-tag">
                      <strong>Period:</strong> <Hightlight keyword={keyword}>
                      {moment(p.start_date).format('DD MMM YYYY')} - {moment(p.end_date).format('DD MMM YYYY')}
                      </Hightlight>
                    </span>
                    <span className="msq-tag">
                      <strong>Net: </strong><Hightlight keyword={keyword}>
                      {moneyFormatter(p.net, p.currency)} {p.currency}
                      </Hightlight>
                    </span>
                  </div>
                </li>
              )
            })}
          </ul>
        </>
      )

  }

  initializeCampaign(resetPlatforms) {
    const {campaign} = this.state;
    const {msq, id_links = [], id_filters = []} = campaign || {};
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    const platformCampaigns = _.compact(
      msq.platforms.concat(_.flatten(id_links.map(link => {
        if(!link.campaign) return [];
        return link.campaign.platforms ? link.campaign.platforms : [link.campaign];
      })))
    ).filter(d => {
      let filter = id_filters.find(f => f.key === d.platform && f.value === d.platform_campaign_id);
      return !filter;
    }).filter(d => {
      // return !!d.platform_campaign_id && ['tv', 'digital', 'social', 'radio', 'ooh'].indexOf(d.platform) >= 0; // ooh is not supported.
      return ['tv', 'digital', 'social', 'radio', 'ooh', 'others'].indexOf(d.platform) >= 0; // ooh is not supported.
    });
    const platformCountMap = platformCampaigns.reduce((ret, next) => {
      return {...ret, [next.platform]: (ret[next.platform] || 0) + 1};
    }, {});
    const msqInvolved = _.compact(_.flatten([msq, id_links.map(d => {
      return d.key === 'overall' ? d.campaign : null
    })]))
    const onlyTvDigital = Object.keys(platformCountMap).every(k => ['tv', 'digital'].indexOf(k) >= 0);
    const onlyDigital = Object.keys(platformCountMap).every(k => ['digital'].indexOf(k) >= 0);
    const numTvCampaign = _.uniq(platformCampaigns.filter(d => d.platform === 'tv').map(d => d.name)).length;
    const digitCampaign = _.uniq(platformCampaigns.filter(d => d.platform === 'digital').map(d => d.name)).length;
    const radioCampaign = _.uniq(platformCampaigns.filter(d => d.platform === 'radio').map(d => d.name)).length;
    const socialCampaign = _.uniq(platformCampaigns.filter(d => d.platform === 'social').map(d => d.name)).length;
    const numOFCampaign = _.uniq(platformCampaigns.map(d => d.platform_campaign_id)).length;
    if (msq.mbfStatus === 'Terminated'){
      return CondirmDialog.showAlert(`This MSQ is terminated.`)
    }
    else if(!numTvCampaign && !digitCampaign && !radioCampaign && !socialCampaign) {
      // return CondirmDialog.showAlert(`OOH or Others platform is currently not supported.`)

      const confirmInfo = {
        type: 'form',
        backgroundClose: true,
        title: '',
        width: '500px',
        hideCancel: false,
        hideOK: false,
        onCancel: () => CondirmDialog.closeAll(),
        onConfirm: () => CondirmDialog.closeAll(),
        dialogBody: (
          <ConfirmBox>
            <div className='title'>MSQ Associated Bookings Not Found, or Unsupported Platforms Identified, e.g. OOH.</div>
            <div className='desc'>Do you still want to create a campaign with other MSQs or supported platforms?</div>
          </ConfirmBox>
        ),
      }
      return CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)


    }
    if(!numOFCampaign) {
      return CondirmDialog.showAlert(`At lease one platform level sub campaign is required.`)
    }

    campaign.allow_blendcpv = onlyTvDigital && numTvCampaign === 1 && digitCampaign === 1;
    campaign.allow_guaranteed_page_views = onlyDigital;
    campaign.msq_id = msq.id;
    campaign.name = msq.name;
    campaign.currency = msq.currency;
    campaign.msq = msq;
    // campaign.used_by = msq.used_by;
    campaign.start_date = _.min(msqInvolved.map(p => p.start_date)) || msq.start_date;
    campaign.end_date = _.max(msqInvolved.map(p => p.end_date)) || msq.end_date;
    campaign.hasMultipleTv = _.uniq(platformCampaigns.filter(d => d.platform === 'tv').map(d => d.name)).length > 1;
    campaign.isSinglePlatform = _.uniq(_.compact(platformCampaigns.map(d => d.platform))).length  === 1;
    const platformGrouped = _.groupBy(platformCampaigns, _.property('platform'));
    const oldPlatforms = resetPlatforms ? [] : (campaign.platforms || []);
    campaign.platforms = Object.keys(platformGrouped).map(platformKey => {
      let campaignsInGroup = platformGrouped[platformKey] || [];
      let oldPlatform = oldPlatforms.find(p => p.key === platformKey) || {};
      let defaultGoal = {};
      let goal_impressions = _.sum(campaignsInGroup.map(d => (d.camapignDetails || {}).goal || d.goal_impressions || 0));
      let goal_clicks = _.sum(campaignsInGroup.map(d => (d.goal_clicks || 0)));
      let goal_pv = _.sum(campaignsInGroup.map(d => (d.goal_pv || 0)));
      ['impressions', 'clicks', 'pv', 'video_starts', 'video_completions', 'video_engaged_views'].forEach(m => {
        let goal = _.sum(campaignsInGroup.map(d => {
          if(m === 'impressions') {
            return (d.camapignDetails || {}).goal || d.goal_impressions || 0;
          } else {
            return (d.camapignDetails || {})[`goal_${m}`] || d[`goal_${m}`] || 0;
          }
        }));
        if(goal && now < campaign.end_date) {
          defaultGoal = {kpi: m, value: goal};
        }
      })
      if(goal_impressions && now < campaign.end_date) {
        defaultGoal = {kpi: 'impressions', value: goal_impressions };
      } else if (goal_clicks && now < campaign.end_date) {
        defaultGoal = {kpi: 'clicks', value: goal_clicks };
      } else if (goal_pv && now < campaign.end_date) {
        defaultGoal = {kpi: 'pv', value: goal_pv };
      }
      let platformBudget = NP.strip(_.sum(msqInvolved.map(msq => (msq.budget_breakdown || {})[platformKey] || 0)));
      if(!platformBudget) {
        if(campaign.isSinglePlatform) {
          platformBudget = NP.strip(_.sum(msqInvolved.map(msq => msq.budget)));
        }
        else if(now < campaign.end_date) {
          platformBudget = 0;
        } else {
          platformBudget = NP.strip(_.sum(campaignsInGroup.map(p => Number(p.budget) || Number(p.net) || 0)));
          platformBudget = platformBudget || null;
        }
      }
      return {
        key: platformKey,
        budget: platformBudget || oldPlatform.budget,
        ta: oldPlatform.ta || DEFAULT_TA,
        goals: oldPlatform.goals || [defaultGoal]
      }
    });
    let oldOverall = oldPlatforms.find(p => p.key === 'overall') || {};
    let overallBudget = NP.strip(_.sum(msqInvolved.map(msq => msq.budget || 0)));
    if(!overallBudget) {
      if(now >= campaign.end_date) {
        overallBudget = 0;
      } else {
        overallBudget = NP.strip(_.sum(platformCampaigns.map(c => Number(c.budget || c.net) || 0)));
      }
    }
    let overall = {
      key: 'overall',
      // budget: Math.max(msq.budget|| 0,  _.sum(platformCampaigns.map(c => Number(c.budget || c.net) || 0))), //can't use this one.
      budget: overallBudget,
      ta: oldOverall.ta || DEFAULT_TA,
      goals: oldOverall.goals || [{}]
    };
    campaign.platforms.unshift(overall);
    if(campaign.type) {
      this.initializeCampaignType(campaign);
    }
    this.setState({refreshKey: Date.now()});
    campaign.platforms = _.sortBy(campaign.platforms, d => platformSorted.indexOf(d.key));
    return campaign;
  }

  initializeCampaignType(campaign) {
    const {type} = campaign || {};
    let {kpi_metrics = []} = CampaignTypeOptions.find(d => d.key === type) || {};
    if(kpi_metrics.length) {
      campaign.platforms.forEach(p => {
        // p.goals = (p.goals || []).filter(d => d.kpi === metric);
        p.goals = (p.goals || []).filter(d => d.kpi && d.value);
        let goal = (p.goals || []).find(d => kpi_metrics.indexOf(d.kpi) >= 0);
        if(!goal) {
          p.goals = p.goals || [];
          p.goals.unshift({kpi: kpi_metrics[0]});
          p.goals = p.goals.filter(g => g.kpi);
        }
      })
    }
    this.setState({refreshKey: Date.now()});
    return campaign;
  }

  autoCalcuateBudget(platform) {
    const {campaign} = this.state;
    if(platform.key === 'overall') {
      platform.budget = _.sum(campaign.platforms.filter(d => d.key !== 'overall').map(d => Number(d.budget) || 0));
    } else {
      let overall = campaign.platforms.find(d => d.key === 'overall');
      let otherPlatforms = campaign.platforms.filter(d => (d.key !== 'overall' && d.key !== platform.key));
      platform.budget = (Number(overall.budget) || 0) - _.sum(otherPlatforms.map(d => Number(d.budget) || 0));
    }
    if(platform.budget < 0 || isNaN(platform.budget)) {
      platform.budget = 0;
    }
    platform.budget = NP.strip(platform.budget);
    this.setState({campaign, refreshKey: Date.now()});
  }

  copySinglePlatformToOverall() {
    const {campaign} = this.state;
    let overall = campaign.platforms.find(d => d.key === 'overall');
    let single = campaign.platforms.find(d => d.key !== 'overall');
    overall.budget = single.budget;
    let {kpi_metrics = []} = CampaignTypeOptions.find(d => d.key === campaign.type) || {};

    if(kpi_metrics.length) {
      overall.goals = (single.goals || []).filter(g => kpi_metrics.indexOf(g.kpi) >= 0);
      this.setState({campaign});
    } else {
      overall.goals = single.goals || [];
      this.setState({campaign});
    }
  }

  diffCampaign() {
    const {campaign: oldCampaign} = this.state.data || {};
    const {campaign: newCampaign, alerts = []} = this.state;
    const diffMap = {};
    if(oldCampaign && alerts.some(alert => alert.applied)) {
      diffMap.msq_id = oldCampaign.msq.id !== newCampaign.msq.id;
      ['name', 'type'].forEach(field => {
        diffMap[field] = oldCampaign[field] !== newCampaign[field];
      });
      ['start_date', 'end_date'].forEach(field => {
        diffMap[field] = String(oldCampaign[field]).substring(0, 10) !== String(newCampaign[field]).substring(0, 10);
      });
      newCampaign.platforms.forEach((newPlatform, pIndex) => {
        let oldPlatform = oldCampaign.platforms.find(p => p.key === newPlatform.key);
        diffMap[`budget_${newPlatform.key}`] = !oldPlatform || oldPlatform.budget !== newPlatform.budget;
        (newPlatform.goals || []).forEach((newGoal, gIndex) => {
          let oldGoal = (oldPlatform ? oldPlatform.goals : []).find(g => g.kpi === newGoal.kpi && g.ta === newGoal.ta) || {};
          diffMap[`kpi_value_${pIndex}_${gIndex}`] = !oldPlatform || !oldGoal || oldGoal.value !== newGoal.value;
        })
      })
      oldCampaign.platforms.forEach((oldPlatform, pIndex) => {
        let newPlatform = newCampaign.platforms.find(p => p.key === oldPlatform.key);
        if(!newPlatform) {
          diffMap[oldPlatform.key] = true;
        }
      })

      if(Object.values(diffMap).every(v => !v)) {
        diffMap.no_change = true;
      }
    }
    return diffMap;
  }

  validator(check_field, returnOnly) {
    const {campaign} = this.state;
    if(campaign.isSinglePlatform) {
      this.copySinglePlatformToOverall();
    }
    const {msq_id, msq, id_links = [], id_filters = [], name, start_date, end_date, type, platforms, editors, viewers} = campaign || {};
    let errorMap = {};
    if (!msq_id) {
      errorMap.msq_id = 'Campaign MSQ ID is required';
    }
    if (!name) {
      errorMap.name = 'Campaign Name is required';
    }
    if (String(name).length > 255) {
      errorMap.name = 'Campaign Name max length is 255 characters';
    }
    // if (!/^[a-z0-9_-]+$/ig.test(name)) {
    //   errorMap.name = 'Campaign Name should only contains letters, number, underscore and dash.';
    // }
    if (!type) {
      errorMap.type = 'Campaign type is required';
    }
    if (!start_date) {
      errorMap.start_date = 'Start Time is required';
    }
    if (!end_date) {
      errorMap.end_date = 'End Time is required';
    }
    if (end_date && end_date < start_date) {
      errorMap.end_date = 'End Time should not be ahead of Start Time';
    }
    // if(msq.platforms.find(p => p.platform === 'tv') && id_links.find(d => d.key === 'tv')) {
    //   errorMap.platforms = 'Multiple TV campaigns not supported (system is not able to process unique reach for multiple tv campaigns)';
    // }
    // if (campaign.hasMultipleTv) {
    //   errorMap.msq_id_ext = 'More than 1 TV campaign found in the setup. currently we don\'t support cross TV campaign aggregation.';
    // }
    if (!platforms || !platforms.length) {
      errorMap.platforms = 'Platform level settings are required.';
    } else if (!!platforms && !platforms.find(d => d.key !== 'overall')) {
      errorMap.platforms = 'At least one sub platform (such as TV, digital, radio, social) setting required.';
    }
    // if(!campaign.allow_blendcpv && campaign.type === 'blended_cpv') {
    //   errorMap.type = 'Blended CPV campaign only support 1 TV campaign + 1 Digital campaign setup';
    // } else
    CampaignTypeOptions.forEach(option => {
      if(!option.ignoreValidator && type === option.key && option.kpi_metrics.length && (platforms || []).some(p => ['ooh', 'others', 'social'].indexOf(p.key) < 0 && !(p.goals || []).find(g => option.kpi_metrics.indexOf(g.kpi)>= 0 && g.value))) {
        errorMap.type = `For ${option.label} Campaign, ${option.kpi_metrics_summary} goal is required. `;
      }
    })
    if (!!platforms) {
      let ovrallBudget = Number((platforms.find(d => d.key === 'overall') || {}).budget) || 0;
      let subBudgets = NP.strip(_.sum(platforms.filter(d => d.key !== 'overall').map(d => Number(d.budget))));
      // if(platforms.length > 1 && ovrallBudget !== subBudgets) {
      //   errorMap[`budget_overall`] = 'Overall budget is not equals to sum of sub platform budgets.';
      // }
      if(platforms.length > 1 && ovrallBudget < subBudgets) {
        errorMap[`budget_overall`] = 'Overall budget is smaller than sum of sub platform budgets.';
      }
      platforms.forEach((p, index) => {
        if(p.key === 'ooh' || p.key === 'others') {
          return;
        }
        if(Number(p.budget) < 0) {
          errorMap[`budget_${p.key}`] = 'Budget should be positive integer';
        } else if(!Number(p.budget)) {
          errorMap[`budget_${p.key}`] = 'Overall budget is not set.';
        }
      })
    }


    if(!!platforms) {
      let overall =  (platforms.find(d => d.key === 'overall') || {});
      let subPlatforms =  platforms.filter(d => d.key !== 'overall');
      let getPlatformMetric = (p, metric) => Number(((p.goals || []).find(d => d.kpi === metric) || {}).value || 0);

      //when subPlatforms.length === 1, overall budget will be hidden, so not need to valid overall
      let hasSocial = !!platforms.find(d => d.key === 'social');
      if(subPlatforms.length > 1) {
        KPIOptions.forEach(metricOption => {
          let overallValue = getPlatformMetric(overall, metricOption.key);
          let subValue = _.sum(subPlatforms.filter(d => d.key !== 'social').map(p => getPlatformMetric(p, metricOption.key)));
          let maxSubValue = _.max(subPlatforms.filter(d => d.key !== 'social').map(p => getPlatformMetric(p, metricOption.key)));
          let gIndex = (overall.goals || []).findIndex(d => d.kpi === metricOption.key);
          if(gIndex>= 0) {
            if((overallValue || subValue) && subValue !== overallValue && ['impressions', 'grp_pct'].indexOf(metricOption.key) >= 0) {
              errorMap[`kpi_value_0_${gIndex}`] = `Sub platform${hasSocial ? '(excluded social)': ''} ${metricOption.label} goals value not add up.`;
            }
            if((overallValue || subValue) && subValue > overallValue && ['impressions', 'grp_pct'].indexOf(metricOption.key) >= 0) {
              errorMap[`kpi_value_0_${gIndex}`] = `Overall ${metricOption.label} should not be smaller than sub platform${hasSocial ? '(excluded social)': ''} ${metricOption.label}.`;
            }
            if((overallValue || subValue) && overallValue > subValue && metricOption.key.indexOf('reach') >= 0) {
              errorMap[`kpi_value_0_${gIndex}`] = `Overall ${metricOption.label} should not be bigger than sum of sub platform${hasSocial ? '(excluded social)': ''} ${metricOption.label}.`;
            }
            if((overallValue || subValue) && overallValue < maxSubValue && metricOption.key.indexOf('reach') >= 0) {
              errorMap[`kpi_value_0_${gIndex}`] = `Overall ${metricOption.label} should not be smaller than any sub platform${hasSocial ? '(excluded social)': ''} ${metricOption.label}.`;
            }
          }
        })
      }

      platforms.forEach((p, platformIndex) => {
        if(p.key === 'ooh' || p.key === 'others') {
          return;
        }
        if(!p.goals || !p.goals.length) {
          errorMap[`kpi_${platformIndex}`] = `KPI not set`;
        }
        (p.goals || []).forEach((g,gIndex) => {
          if(!g.kpi) {
            errorMap[`kpi_${platformIndex}_${gIndex}`] = `KPI not selected`;
          } else if(!Number(g.value) || isNaN(Number(g.value))) {
            errorMap[`kpi_value_${platformIndex}_${gIndex}`] = `KPI value not set`;
          } else if(['reach_pct', 'reach_3_pct'].includes(g.kpi) && Number(g.value) > 100) {
            errorMap[`kpi_value_${platformIndex}_${gIndex}`] = `Reach % can not exceed 100%`;
          }
        })
      })
    }
    if(check_field) {
      let check_fields = Array.isArray(check_field) ? check_field : [check_field];
      let currentErrorMap = _.cloneDeep(this.state.errorMap || {});
      check_fields.forEach(check_field => {
        currentErrorMap[check_field] =  errorMap[check_field];
      })
      errorMap = currentErrorMap;
    }
    if(!returnOnly) {
      this.setState({errorMap});
    }
    return errorMap;
  }

  saveCampaign(saveMode) {
    const {step, alerts, campaign: editingCampaign} = this.state;
    if(saveMode !== 'draft' && editingCampaign.release_status !== 'DRAFT') {
      const errorMap = this.validator();
      if (Object.keys(errorMap).length > 0) {
        this.setState({errorMap: errorMap});
        return;
      }
    }
    this.setState({loading: true});
    let createMode = !editingCampaign.id;

    let msqVersions = {...editingCampaign.msq_versions};

    let filteredMsqVersions = {};
    Object.keys(msqVersions).forEach((key, value) => {
      if(key !== undefined && key !== "undefined" && key !== null && key !== "null") {
        if(msqVersions[key] !== null && msqVersions[key] !== undefined) {
          filteredMsqVersions[key]=msqVersions[key];
        }
      }
    });

    let shareMap = {...editingCampaign.shareMap};
    let filteredShareMap = {};
    Object.keys(shareMap).forEach((key, value) => {
      if(key !== undefined && key !== "undefined" && key !== null && key !== "null") {
        if(shareMap[key] !== null && shareMap[key] !== undefined) {
          filteredShareMap[key]=shareMap[key];
        }
      }
    });
    editingCampaign.msq_versions = filteredMsqVersions;
    editingCampaign.shareMap = filteredShareMap;

    post(`/api/${createMode ? 'createCampaign' : 'updateCampaign'}`, editingCampaign).then(result => {
      this.setState({loading: false});
      let {success, campaign} = result;
      campaign = campaign || editingCampaign;
      if (success) {
        this.setState({
          touched: false,
          errorMap: {},
          campaign: _.cloneDeep(campaign),
        }, () => {
          if (createMode) {
            if(saveMode === 'draft') {
              CondirmDialog.showAlert('Draft saved successfully.')
            } else {
              CondirmDialog.showAlert('Campaign saved successfully.', () => {
                this.props.history.push(`/campaigns`);
              })
            }

          } else {
            CondirmDialog.showAlert('Campaign information updated successfully.');
            let alert = (alerts || [])[0];
            if(alert && alert.applied) {
              this.markAlertSolved(alerts[0]);
            } else if (alert.data?.msq?.mbfStatus === 'Terminated' && alert.data?.msq.id !== campaign.msq_id){
              this.markAlertSolved(alerts[0]);
            }
          }
        })
      } else {
        this.setState({error: 'save campaign information failed'})
      }
    }).catch(e => {
      CondirmDialog.showAlert('Save Campaign failed.');
      this.setState({loading: false, error: e.message});
    });
  }

  applyMsqChanges(alert) {
    const {msq: newMsq} = alert.data;
    const {alerts, campaign} = this.state;
    const {msq, id_links = [], id_filters = []} = campaign || {};
    if(campaign.msq_id === newMsq.id) {
      campaign.msq = newMsq;
    }
    (campaign.id_links || []).concat(campaign.id_filters || []).forEach(link => {
      link.campaign = newMsq.platforms.find(p => p.platform_campaign_id === link.value) || link.campaign;
      if(link.value === newMsq.id) {
        link.campaign = newMsq;
      }
    })
    if(newMsq.id && newMsq.update_time) {
      campaign.msq_versions[newMsq.id] = newMsq.update_time;
    }
    alert.applied = true;
    this.setState({alerts, touched: true})
    this.initializeCampaign();
  }

  markAlertSolved(alert) {
    const {alerts, campaign} = this.state;
    const {msq: newMsq} = alert.data;
    if(newMsq.id && newMsq.update_time) {
      campaign.msq_versions[newMsq.id] = newMsq.update_time;
    }
    this.setState({campaign, loading: true});
    post(`/api/markAlertSolved`, {id: alert.id}).then(result => {
      this.setState({loading: false, alerts: []});
      // CondirmDialog.showAlert('Alert Resolved.');
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
})
