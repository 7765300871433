import styled, {css} from 'styled-components';
import {
  DropdownZIndex,
  FontSizeBig,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  BlueJeans, DarkGray, BlueJeans1, FontSizeSmall, DarkGray1, Grass1, BitterSweet1, Grass
} from "../app/StyleCommon";

export const FaqWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const FaqToggler = styled.div`
  position: relative;
  cursor: pointer;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FontSizeMedium};
  background-color: transparent;
  border-left: 1px solid rgba(255,255,255, .1);
  border-right: 1px solid rgba(255,255,255, .1);
  font-size: 18px;
  color: ${DarkGray};
  &:hover {
    background-color: ${LightGray1};
  }
  ${props => props.active && css`
    // background: white !important;
    // color: ${DarkGray1};
    // .count {
    //   border: 1px solid ${MediumGray}
    // }
  `}
`;

export const FaqMenu = styled.div`
  position: fixed;
  top: 55px;
  left: auto;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all .3s ease;
  opacity: 0;
  z-index: ${DropdownZIndex};
  max-width: 400px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0,0,0,.3);
  background-color: white;
  max-height: 0;
  overflow-y: auto;
  height: 100vh;
  ul ul{
    list-style-type: disc !important;
    margin-left: 40px;
    li a {
      border: none !important;
    }
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    color: ${MediumGray};
    cursor: pointer;
    display: block;
    padding: 10px;
    &:hover {
      color: ${Grass1};
    }
  }
  @media (max-width: 667px) {
    left: 0;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: auto;
    max-width: none;
    max-height: inherit;
  }
  ${props => props.active && css`
     padding: 10px 20px;
     opacity: 1;
     max-height: 1500px;
     max-height: 100vh;
  `}
  ${props => !props.active && css`
     pointer-events: none !important;
  `}
  > h3 {
    margin: 0;
    padding: 10px 10px 15px 0px;
    color: ${DarkGray1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
`
export const MenuItem = styled.a`
  padding: 10px 10px;
  display: block !important;
  font-size: 12px;
  color: ${DarkGray1};
  border-top: 1px solid ${LightGray1};
  position: relative;
  cursor: pointer;
  width: 100% !important;
  text-decoration: none;
  ${props => props.isNew && css`
    background-color: rgba(255, 188, 0, 0.11);
  `}
  &:hover {
    background-color: rgba(255, 188, 0, 0.11);
  }
  .noti-header {
    color: ${Grass1};
    font-size: 12px;
    i {
      margin-right: 10px;
      &.fa-check {
        color: ${Grass1};
      }
      &.fa-close {
        color: ${BitterSweet1};
      }
    }
  }
  .noti-body {
    font-size: 12px;
    margin: 10px 0;
    .creator {
      display: flex;
      align-items: center;
      .avatar {
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        border: 1px solid ${MediumGray};
        background-color: ${LightGray1};
      }
    }
  }
  .noti-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${DarkGray}
  }
  .del {
    position: absolute;
    display: inline-block;
    padding: 5px;
    right: 15px;
    top: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EmptyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  // font-weight: bold;
`;

export const FaqDocWrapper = styled.div`
  margin: 30px 30px;
  margin-top: 80px;
  h3 {
    margin: 20px 0;
  }
  h4 {
    margin: 20px 0 10px 0;
    font-size: 16px;
  }
  p {
    line-height: 1.5em;
  }
  ul {
    margin-left: 40px;
    margin: 20px 10px 20px 40px;
    list-style-type: disc;
    li {
      margin: 20px 0;
      line-height: 1.5em;
    }
  }
  ol {
    list-style-type: decimal;
    margin: 20px 10px 20px 40px;
    li {
      line-height: 1.5em;
    }
  }
  img {
    max-width: 100%;
    margin: 20px;
  }
  table {
    min-width: 60%;
    border: 1px solid ${MediumGray};
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
    thead {
      th {
        font-weight: bold;
        padding: 10px;
        border: 1px solid ${MediumGray};
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid ${MediumGray};
          &:first-child {
            text-align: center;
            vertical-align: top;
          }
        }
      }
    }
    &.partner-table td {
      text-align: center;
      padding: 10px;
    }
    &.ratecard-table td {
      text-align: center;
      padding: 10px;
      img {
        max-width: 80%;
        display: block;
      }
      &:nth-child(2) {
        text-align: left;
        text-align: left;
      }
    }
  }
  pre {
    //margin: 0 !important;;
    //background: white !important;
    font-size: 12px;
    span {
      word-break: break-all;
      white-space: pre-line;
      background: transparent !important;
    }
  }
  .react-syntax-highlighter-line-number {
    display: block;
    text-align: right;
    color: rgba(0,0,0,.5);
    margin-right: 15px;
  }
`;
