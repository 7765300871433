import React, {Component, useEffect} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Aladin_BG, Aladin_BG_1, Aladin_Orange_Dark,
  BlueJeans,
  DarkGray, DarkGray1, Grass1, LightBlue,
  MediumGray1,
} from "../app/StyleCommon";
import Table from "../uikit/table";
import classnames from "classnames";
import styled, {css} from 'styled-components';
import {pctFormatter, intFormatter, uniquesFormatter} from "../utils/formatter";
import {BarWrapper, TableWrapper} from "./index.style";
import {metricMap} from "../utils/metadata";
import {defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import MonthPanel from "../uikit/DateSelector/MonthPanel";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  box-sizing: border-box;

  .month-panel {
    width: calc(30% - 30px);
    box-sizing: border-box;
    min-width: 150px;
    flex-grow: 1;
    margin-right: 30px;

    .month-nav {
      .month-nav-btn {
      }
    }

    .date {
      background: rgba(110, 179, 196, 0.16);
      &.showLeftIndicate, &.showRightIndicate {
        &:after, &:before {
          animation: none;
          display: none;
        }
      }

      &.selected.selected.selected {
        color: white !important;
        background-color: #4f8f98 !important;
      }

      &.inboundry {
        background: rgba(110, 179, 196, 0.16) !important;
      }

      &.invalid {
        pointer-events: inherit;
      }
    }
  }

  .data-table {
    width: 70%;
    max-height: 350px;
    min-height: 150px;
    overflow: auto;

    > table {
      position: relative;
      overflow: auto;

      thead tr th {
        position: sticky;
        z-index: 1;
        top: 0px;
      }
    }
  }
`;

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10
    }
  }
  render() {
    let {data, cfg} = this.props;
    let {page, pageSize} = this.state;
    const {sortBy, sortDirection, customSortedColumns} = cfg;
    const today = moment().format('YYYY-MM-DD');
    const {period, date, data: programData} = data;
    const monthDate = date || period.start;

    let sorted = programData || [];
    sorted.forEach(d => {
      d.booked_number = (d.campaigns || []).length;
    })
    if(sortBy && sortDirection) {
      sorted = _.sortBy(sorted, _.property(sortBy));
      if(sortDirection === 'desc') {
        sorted = sorted.reverse();
      }
    }
    const channelMap = {
      'CH5': 'Channel 5',
      'CH8': 'Channel 8',
      'CHU': 'Channel U',
      'SUR': 'Suria',
      'CEN': 'Vasantham',
      'CNAS': 'CNA',
    };
    const columns = [
      {
        key: 'channel',
        label: 'Channel',
        align: 'left',
        sortable: true,
        renderer: (d, rowIndex) => {
          let isParentRow = false;
          if(sortBy === 'channel') {
            let firstRowIndex = sorted.findIndex(r => r.channel === d.channel);
            let lastRowIndex = _.findLastIndex(r => r.channel === d.channel);
            if(rowIndex > firstRowIndex && firstRowIndex>= 0) {
              return <div title={d.channel} className="child-row"></div>;
            } else if(lastRowIndex > firstRowIndex) {
              isParentRow = true;
            }
          }
          return channelMap[d.channel] || d.channel;
        }
      },
      {
        key: 'program',
        label: 'Programme',
        align: 'left',
        sortable: true,
        renderer: d => {
          return d.title;
        }
      },
      {
        key: 'time',
        label: 'Time',
        align: 'left',
        sortable: true,
        renderer: d => {
          return moment(d.date).format('hh:mm A');
        }
      },
      {
        key: 'ratings',
        label: 'Rating',
        align: 'right',
        sortable: true,
        renderer: d => {
          return d.ratings || '--';
        }
      },
      {
        key: 'bought',
        label: 'Booked',
        align: 'center',
        sortable: true,
        renderer: d => d.bought ? 'Yes' : 'No'
      },
      {
        key: 'booked_number',
        label: 'Booked Campaigns',
        align: 'right',
        sortable: true,
        renderer: d => {
          if(!d.campaigns || !d.campaigns.length) return '--';
          return (
            <div
              onMouseOver={defaultWhiteTooltip.onMouseOver({tipContent: (
                  <ol>
                    {
                      (d.campaigns || []).map(c => <li style={{whiteSpace: 'nowrap', borderBottom: '1px solid #f1f2f3'}}>{c.name}</li>)
                    }
                  </ol>
                )})}
              onMouseLeave={defaultWhiteTooltip.onMouseOut()}>
              {(d.campaigns ||[]).length}
            </div>
          )
        }
      },
    ];
    const visibleColumnKeys = columns.map(d => d.key);
    const columnsSorted = _.sortBy(columns, c => {
      return (customSortedColumns || []).indexOf(c.key);
    });
    return (
      <Wrapper>
        <MonthPanel
          freeNav={true}
          futureDateAllowed={true}
          historyDateAllowed={true}
          onMonthUpdated={monthDate => {

          }}
          onDateSelected={date => {
            this.props.chartStateChanged({date: date});
          }}
          onDragSelection={e => null}
          dragingDates={e => null}
          date={date || period.start}
          period={{start: date, end: date}}
          boundryPeriod={period}
        />
        <TableWrapper className="data-table">
          <Table
            rowSortable={true}
            onReplacePosition={(colum1, colum2) => {
              let newCustomSortedColumns = customSortedColumns || _.cloneDeep(visibleColumnKeys);
              if(newCustomSortedColumns.length !== visibleColumnKeys.length) {
                newCustomSortedColumns = _.sortBy(visibleColumnKeys, k => newCustomSortedColumns.indexOf(k));
              }
              let x = newCustomSortedColumns.indexOf(colum1);
              let y = newCustomSortedColumns.indexOf(colum2);
              newCustomSortedColumns[x] = null;
              newCustomSortedColumns.splice(y + (x < y ? 1 : 0), 0, colum1); // insert position.
              newCustomSortedColumns = _.compact(newCustomSortedColumns);
              this.props.chartStateChanged({customSortedColumns: newCustomSortedColumns}, true);
            }}
            columns={columnsSorted}
            // rows={programData.slice((page - 1) * pageSize,  page * pageSize)}
            rows={sorted}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={sortBy => {
              this.props.chartStateChanged({sortBy: sortBy}, true);
            }}
            directionChange={direction => {
              this.props.chartStateChanged({sortDirection: direction}, true);
            }}
            noDataText={'No data found'}
          />
        </TableWrapper>
        {/*<ListFooter>*/}
        {/*    <span className="info">*/}
        {/*        /!*{data.length} items.*!/*/}
        {/*    </span>*/}
        {/*  <PaginationStyled*/}
        {/*    onChange={p => this.setState({page: p})}*/}
        {/*    current={page}*/}
        {/*    pageSize={pageSize}*/}
        {/*    total={programData.length}*/}
        {/*    hideOnSinglePage={false}/>*/}

        {/*</ListFooter>*/}
      </Wrapper>
    )
  }
}