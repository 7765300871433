import styled, {keyframes, css} from 'styled-components'
import {
  Aladin_Orange_Dark,
  Aladin_Red_Dark,
  Aladin_Red_Light,
  Aqua1,
  BlueJeans,
  MeOrange,
  Mint
} from "../../app/StyleCommon";

export const SplashWrapper = styled.div`
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const Spinner = styled.div`
  height: 36px;
  width: 36px;
  display: inline-block;
  position: relative;
  animation: ${rotation} .8s infinite linear;
  border-left: 6px solid lightgrey;
  border-right: 6px solid lightgrey;
  border-bottom: 6px solid lightgrey;
  border-top: 6px solid ${Aladin_Red_Light};
  border-radius: 100%;

  ${props => props.size === 'S' && css`
    height: 12px;
    width: 12px;
    border-width: 3px;
  `}


`;
export const Spinner1 = styled.div`
  .lds-grid {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-grid div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${Aladin_Orange_Dark};
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    top: 4px;
    left: 4px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 4px;
    left: 16px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 4px;
    left: 28px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 16px;
    left: 4px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 16px;
    left: 16px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 16px;
    left: 28px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 28px;
    left: 4px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 28px;
    left: 16px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 28px;
    left: 28px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

`;

