import React from 'react'
import _ from 'lodash'
import moment from "moment";
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './index.style';
import {Table, TableScrollWrapper, TabContainer, Select, Btn, ProgressBar, Loader} from "../uikit/index";
import {TableController} from "./index.style";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter, TableController1} from "../campaigns/index.style";
import {supportedColumns} from "./audit.columns";
import FilterBox from "../campaigns/FilterBox";
import {Link} from "react-router-dom";
import saveAs from "file-saver";
import {post} from "../utils/request";
import {defaultTooltip} from "../uikit/tooltip";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {cacheResource, getFromCache} from "../utils/cache";
import {intFormatter} from "../utils/formatter";
import { paginationValues } from '../utils/metadata';

const AUDIT_LIST_CACHE_KEY = 'AUDIT_LIST_CACHE_KEY';
export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(AUDIT_LIST_CACHE_KEY) || {};
    this.state = {
      filters: [],
      PAGE_SIZE: 10,
      page: 1,
      ...cachedSettings
    }

    this.searchDebounce = _.debounce(this.queryAgain.bind(this), 500);
  }

  cacheParams() {
    cacheResource(AUDIT_LIST_CACHE_KEY, _.pick(this.state, ['page', 'PAGE_SIZE', 'sortBy', 'filters', 'sortDirection', 'keyword']))
  }

  detectStringOrJSON(str) {
    try {
      JSON.parse(str);
      return "JSON";
    } catch (error) {
      return "String";
    }
  }

  render() {
    const {data, loading, error, filters, keyword, PAGE_SIZE, page, sortBy, sortDirection} = this.state;
    const {auditLogs, filterMetadata = {}, total} = data || {};
    if (auditLogs) {
      auditLogs.forEach(ele => {
        if(this.detectStringOrJSON(ele['details']) === "JSON") {
          ele.details = JSON.parse(ele.details);
        }
      });
    }

    const {session} = this.props.appState;
    let canViewAuditLogs = session.rights.indexOf('View System Audit Logs') >= 0;
    let canExportAuditLogs = session.rights.indexOf('Export System Audit Logs') >= 0;

    return (
      <div className="audit-module">
        {
          !data && !error && canViewAuditLogs &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryAuditLog`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          loading && <Loader/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !canViewAuditLogs && <ErrorMessage message={'Not Allowed'}/>
        }
        {
          !!data && canViewAuditLogs &&
          <>
          <TableController1 whiteBG={true}>
            <FilterBox
              filters={filters}
              filterMetadata={filterMetadata}
              supportedColumns={supportedColumns}
              onChange={newFilters => this.setState({filters: newFilters, page: 1}, () => {
                this.queryAgain();
                this.cacheParams();
              })} />
            <div className="searchBox">
              <i className="fa fa-search"/>
              <input
                type="text"
                placeholder="Search by User ID, Name, UA or action ..."
                value={keyword}
                onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.searchDebounce())}/>
              {this.state.keyword && this.state.keyword !== "" ? <i class="fa fa-times" aria-hidden="true" onClick={() => this.setState({keyword: '', page: 1}, () => this.searchDebounce())}></i> : null}
            </div>
            {
              canExportAuditLogs &&
              <a onClick={e => this.downloadCSV()} style={{cursor: 'pointer'}}
                 onMouseOver={defaultTooltip.onMouseOver({txt: 'Download top 10K logs'})}
                 onMouseLeave={defaultTooltip.onMouseOut()}>
                <span className="material-symbols-outlined">download</span>
              </a>
            }
          </TableController1>
          <TableScrollWrapper className="audit-table">
            <Table
              context={{page, PAGE_SIZE}}
              columns={[
                ...supportedColumns
              ]}
              rows={auditLogs || []}
              sort={{sortColumn: sortBy, direction: sortDirection}}
              sortChange={sortBy => {
                this.setState({sortBy: sortBy}, () => {
                  this.queryAgain();
                  this.cacheParams();
                });
              }}
              directionChange={direction => {
                this.setState({sortDirection: direction}, () => {
                  this.queryAgain();
                  this.cacheParams();
                });
              }}
              noDataText={<div style={{height: '110px', marginTop: '50px'}}>No users</div>}
            />
          </TableScrollWrapper>
            <ListFooter>
              <span className="info">
                <strong>{intFormatter(total)}</strong> items found.
                Show
                <select
                  value={PAGE_SIZE}
                  onChange={e => this.setState({
                    PAGE_SIZE: Number(e.target.value),
                    page: 1,
                  }, () => {
                    this.queryAgain();
                    this.cacheParams();
                  })}>
                {paginationValues.map(d => {
                  return (
                    <option key={d} value={d}>{d}</option>
                  )
                })}
                </select>
                per page
            </span>
              <PaginationStyled
                onChange={p => this.setState({page: p}, () => {
                  this.cacheParams();
                  this.queryAgain();
                })}
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                hideOnSinglePage={false}/>
            </ListFooter>
          </>
        }
      </div>
    )
  }

  queryAgain(){
    this.cacheParams();
    this.setState({loading: true});
    return post(`/api/queryAuditLog`, this.buildQuery()).then(data => {
      this.setState({data: data, loading: false});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }

  buildQuery() {
    const {page, PAGE_SIZE, keyword, filters, sortBy, sortDirection} = this.state;
    const query = {
      keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filters,
      sortBy,
      sortDirection
    };
    return query;
  }

  downloadCSV() {
    let query = this.buildQuery();
    this.setState({loading: true});
    return post('/api/downloadAuditLog', query, {responseType: 'blob'}).then(blob => {
      saveAs(blob, `audit-logs-latest-${moment().format('YYYYMMDDHHmm')}.csv`);
      this.setState({loading: false});
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
})