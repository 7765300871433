import styled, {css} from 'styled-components';

import {Aqua1, BlueJeans, DarkGray1, DialogMaskZIndex, DialogZIndex, ElementMaskZIndex} from "../../app/StyleCommon";

export const DialogWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${DialogMaskZIndex};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 220px;
  transition: all cubic-bezier(0, 0, 0, 0.76) .3s;
  background-color: rgba(0,0,0,.5);
  ${props => props.type === 'fixed' && css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: ${DialogMaskZIndex};
  `}
  ${props => props.fullScreen && css`
    height: 100%;
  `}
  &:before {
    display: block;
    content: '';
    position: fixed;
    background-color: rgba(0,0,0,.1);
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    z-index: ${DialogMaskZIndex};
  }
  //&.dialog--open {
  //  background-color: rgba(0,0,0,.5);
  //  .dialog {
  //    opacity: 1;
  //  }
  //}
  .dialog {
    //width: 350px;
    padding: 20px 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0,0,0, .1);
    z-index: ${DialogMaskZIndex + 1} !important;
    //transition: all cubic-bezier(0, 0, 0, 0.76) .3s;
    opacity: 1;
    ${props => `width: ${props.width};`}
    max-width: 95%;
    max-width: 95vw;
    max-width: calc(100vw - 40px);
    position: relative;
    @media screen and (max-width: 667px) {
      padding: 10px;
      max-height: 90vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 5px;
      color: ${DarkGray1};
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        color: ${BlueJeans};
      }
    }
    > .title {
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
      a {
        color: ${Aqua1} !important;
        text-decoration: underline;
        cursor: pointer;
      }
      .bread {
        font-weight: bold;
        &:first-child:after {
          content: '/';
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > .main-screen {
      &.hide {
        display: none;
      }
    }
    > .second-screen {
      opacity: 1;
      transition: all .2s ease;
      opacity: 1;
      &.hide {
        opacity: 0;
        overflow: hidden;
      }
    }
    ${props => props.type === 'form' && css`
      .title {
        text-align: left;
      }
      .buttons {
        justify-content: flex-end;
      }
    `}
    ${props => props.type === 'info' && css`
      .title {
        text-align: left;
      }
      button:first-child {
        display: none;
      }
    `}
  }
`;