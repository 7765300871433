import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  GrapeFruit,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG,
  Aladin_BG_1,
  Aladin_BG_2, Aladin_Blue_Dark, Aladin_Orange_Lighter, Aladin_BG_TV_1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const DashboardWrapper = styled.div`
  padding: 20px 20px 20px 30px;

  .live-campaigns {
    .items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .item {
        background: ${LightGray};
        padding: 15px;
        border-radius: 10px;
        margin-right: 35px;
        width: 25%;
        box-sizing: border-box;
        align-self: stretch;
        text-decoration: none;
        color: white;
        font-weight: bold;
        position: relative;
        padding-bottom: 50px;
        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 15px auto;
        }

        &:hover {
          box-shadow: 3px 5px 13px rgba(0, 0, 0, .1);
          cursor: pointer;
        }

        //@media (prefers-color-scheme: light) {
        //  filter: brightness(90%);
        //}

        &:nth-child(1) {
          background: linear-gradient(120deg, #ec685c 0%, #e8a88c 100%);
        }

        &:nth-child(2) {
          background: linear-gradient(120deg, #4f8f98 0%, #6eb3c4 100%);
        }

        &:nth-child(3) {
          background: linear-gradient(120deg, #a85efa 0%, #fd8bd9 100%);
        }

        &:nth-child(4) {
          background: linear-gradient(120deg, #f1a164 0%, #f4c168 100%);
          margin-right: 0;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .advertiser {
          font-size: 12px;
          font-weight: bold;
        }

        .name {
          margin: 10px 0;
          font-size: 14px;
          color: white;
        }

        .metric {
          justify-content: space-between;
          color: white;
          background: rgba(245, 233, 233, 0.18);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          z-index: 1;
          border-radius: 0 0 10px 10px;
          padding: 0 10px;

          .inflight_status {
            padding: 5px 10px 5px 0;
            font-size: 12px;
            font-weight: bold;
          }

          .status {
            border-radius: 5px;
            padding: 2px 5px;
            font-weight: bold;
            font-size: 14px;
            white-space: nowrap;

            &.ON_TRACK {
              background: ${Grass1};
              color: white;
            }

            &.OFF_TRACK {
              background: ${BitterSweet1};
              color: white;
            }

            &.WARNING {
              background: ${Sunflower};
              color: ${GrapeFruit};
            }
          }
        }

        > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .revenue-chart,
  .key-breakdowns {
    margin-top: 35px;
    background: white;
    background: ${Aladin_BG} !important;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgba(186, 181, 243, 0.51);
    @media screen and (max-width: 768px) {
      padding: 10px 5px;
    }

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select-box {
        width: auto;
        font-size: 14px;

        .toggler {
          min-width: inherit;
          border: none;
          background: #f1f2f3;
          font-size: 12px;
          border-radius: 10px;
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          width: auto;
          min-width: auto;
          i {
            position: static;
            margin-left: 10px;
            vertical-align: middle;
            transform: none;
          }
        }
        .menu {
          right: auto;
        }
      }
    }
  }

  .key-breakdowns {
    .items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .item {
        flex-grow: 1;
        width: 100%;
        //background: white;
        padding: 20px;
        border-radius: 15px;
        margin-right: 20px;
          //border-right: 1px solid ${Aladin_BG_2};
        background: rgba(255, 255, 255, .5);
        min-height: 250px;
        @media screen and (max-width: 768px) {
          border-radius: 0;
          &:not(:last-child) {
            border-bottom: 1px solid ${Aladin_BG_1};
          }
        }

        &:last-child {
          margin-right: 0px;
          border-right: none;
        }
      }
    }
  }

  .feeds {
    margin-top: 20px;
    height: 840px;
    overflow-y: hidden;
    position: relative;
    transform: translateX(10px);

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 90px;
      width: 100%;
      left: 0;
      background: linear-gradient(to bottom, transparent, white, white);
    }

    &:hover .feeds-footer {
      opacity: 1;
    }

    .feeds-footer {
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      height: 20px;
      z-index: 10;
      font-size: 12px;
      text-decoration: underline;
      padding-left: 30px;
      cursor: pointer;
      opacity: .5;

      i {
        font-size: 18px;
        vertical-align: middle;
      }
    }

    .feeds-header {
      margin: 10px 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-right: 30px;
      border-bottom: 1px dashed ${Aladin_BG_1};

      .header-tab {
        padding: 3px 5px;
        borderRadius: 5px;
        marginRight: 15px;
        cursor: pointer;
        margin-right: 10px;

        &:hover {
          text-decoration: underline;
        }

        &.selected {
          background: #f4c168;
          color: white;
        }

      }
    }

    .item {
      border-left: 1px dashed ${MediumGray};
      padding: 0px 20px 10px 20px;
      margin: 0;
      margin-left: 14px;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: underline;
      }

      > div {
        padding-bottom: 18px;
      }

      .title {
        transform: translate(-28px, -5px);

        i {
          margin-right: 10px;
          transform: translateY(4px) !important;
          &.material-symbols-outlined {
            font-size: 18px;
            background: white;
            transform: translate(-2px, 2px);
          }

          &.ok {
            color: #4f8f98;
          }

          &.bad {
            color: ${BitterSweet1};
          }

          &.red {
            color: ${BitterSweet1};
          }

          &.yellow {
            color: ${Sunflower1};
          }
        }
      }

      .desc {
        color: ${DarkGray};
        font-size: 12px;
        margin: 5px 0;

        b {
          font-weight: bold;
        }

        i {
          font-weight: bold;
          text-decoration: none;
          font-style: normal;
        }
        .type {
          font-weight: bold;
        }
        .pretext {
          color: #7a7878;
          //text-decoration: line-through;
        }
        .update, .create, .delete, .type {
          //color: #6EB3C4;
        }
        ul {
          list-style-type: disc;
          margin-left: 15px;
          li {
            margin: 2px 0
          }
        }
        label {
          font-weight: bold;
        }
      }

      .date {
        color: ${MediumGray1};
        font-size: 12px;
      }
    }
  }

`
