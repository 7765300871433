import React from 'react';
import axios from 'axios';
import {ProgressWrapper} from "./index.style";
import {BlueJeans} from "../../app/StyleCommon";
import Loader from '../Loader/index';

export default class extends React.Component {
  state = {finished: false}

  render() {
    const {done, finished} = this.state;
    return (
      <ProgressWrapper visible={!finished} {...this.props} >
        {<Loader {...(this.props.loaderProps || {})}/>}
      </ProgressWrapper>
    )
  }

  componentDidMount() {
    const {url, params, successHandler, errorHandler} = this.props;
    axios({
      url: url,
      method: params ? 'POST' : 'GET',
      data: params,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(response => {
      const {data, status, statusText} = response;
      if (status !== 200) {
        throw new Error(statusText);
      }
      this.setState({finished: true});
      successHandler(data);
    }).catch(e => {
      this.setState({finished: true});
      if (e.response && e.response.statusText) {
        e.message = e.response.statusText;
      }
      errorHandler(e);
      if(e.response && e.response.status === 401) {
        window.location = '/login';
      }
    });
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

}