import React, {useState, useRef, useEffect, useCallback} from 'react';
import classnames from "classnames";
import _ from 'lodash';
import {post} from '../../utils/request';
import {
  AutoCompleteWrapper,
  SearchBox,
  NoData,
  Dropdown,
  DropdownContainer,
  DropdownLoading,
  DropdownGroupTitle,
} from "./index.style";
import {Hint} from '../index'
import {Spinner} from "../splash/index.style";
import classNames from "classnames";
import {withConsumer} from "../../app/ApplicationContext";

const MsqSearch = (props) => {
    const {placeholder, disabled, selected, onChange, campaign, type, isSearchable=true, staticSearchableKey="", queryParams, onSearch, searchResults, selectedRaw, selectedItemRenderer, itemRenderer, closeOnItemClick, api = '/api/search', apiParams = {}, searchWithoutKeyword, limit} = props;
    const searchResultsRef = useRef();
    const searchboxRef = useRef();
    const selectedValueRef = useRef();
    const scrollObserver = useRef();

    const [searchState, setSearchState] = useState({
      keyword: '',
      focused: false,
      data: {},
      filterList: [],
      loading: false,
      error: false,
      searchType: "",
      isSearchResultsOpen: false,
    })
    const [pageNumber, setPageNumber] = useState(1);
    const [inputKey, setInputKey] = useState('');
    const [isStopSearch, setIsStopSearch] = useState(false);
    const [isEditModeChanged, setIsEditModeChanged] = useState(false);

    // can be moved into custom hooks folder later
    const useDebounce = (value, delay = 1000) => {
      const [debouncedValue, setDebouncedValue] = useState("");
      const timerRef = useRef();
      useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay);
        return () => {
          clearTimeout(timerRef.current);
        };
      }, [value, delay]);
      return debouncedValue;
    };

    const useOutsideClick = (ref, callback) => {
      const handleClick = e => {
        if (selectedValueRef && selectedValueRef.current) {
          selectedValueRef.current.style.display= "block"
          searchboxRef.current.style.opacity = "0";
        }
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      };

      useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
          document.removeEventListener("mousedown", handleClick);
        };
      });
    };

    const debouncedInputValue = useDebounce(searchState.keyword, 1000);

    const lastItemRef = useCallback((node) => {
      if(searchState.loading) return;
      if(scrollObserver.current) {
        scrollObserver.current.disconnect();
      }
      scrollObserver.current = new IntersectionObserver(entries => {
        if((isEditMode() && isEditModeChanged && type.toLowerCase() === "default") || (!isEditMode() && type.toLowerCase() === "default")){
          if(entries[0].isIntersecting && !isStopSearch) {
            setPageNumber(pageNumber+1);
          }
        }
      });
      if(node) {
        scrollObserver.current.observe(node);
      }
    }, [searchState.loading]);

    useEffect(() => {
      if((inputKey !== "" || searchWithoutKeyword) && isSearchable) {
        handleSearchQuery(inputKey);
      }
    }, [pageNumber])

    // will trigger on input change
    // useEffect(() => {
    //   if (debouncedInputValue !== "" && isSearchable) {
    //     handleSearchQuery();
    //   }
    // }, [debouncedInputValue]);

    useEffect(() => {
      if(isEditMode()) {
        setIsEditModeChanged(false)
      }
    }, [])

    useEffect(() => {
      if(searchResults && searchResults.data && isSearchable && !staticSearchableKey) {
        setSearchState({...searchState, data: searchResults.data, searchType: searchResults.searchType})
      }
      let linkedMsqData = [];
      if(staticSearchableKey) {
        let linkedMsg = searchResults.id_links;
        linkedMsg.forEach(element => {
          linkedMsqData.push(...(element.campaign?.platforms || []))
        });
      }
      if (!isSearchable && searchResults) {
        let msq = searchResults.latestMsq || searchResults.msq;
        if(msq && msq.platforms) {
          let allPlatforms = msq.platforms;
          if (['tv', 'digital', 'social', 'radio'].indexOf(staticSearchableKey) >= 0) {
            allPlatforms = [...msq.platforms, ...linkedMsqData]
          };
          let filteredPlatform = allPlatforms.filter(x => x.platform === staticSearchableKey);
          filteredPlatform = [...new Map(filteredPlatform.map(item => [item['platform_campaign_id'], item])).values()]
          setSearchState({...searchState, data: filteredPlatform, filterList: filteredPlatform});
        }
      }
    }, [searchResults])

    useOutsideClick(searchResultsRef, () => {
      if (searchResultsRef) {
        setSearchState({...searchState, isSearchResultsOpen: false})
      }
    });

    const handleSelectedItem = (e) => {
      if (selectedRaw && selectedRaw.id) {
        let searchRawId = ['tv', 'digital', 'social', 'radio'].indexOf(selectedRaw.platform) >= 0 ? selectedRaw.platform_campaign_id : selectedRaw.id;
        if(isEditMode() && !searchResults) {
          setInputKey(searchRawId);
          newHandler(searchRawId);
          if (selectedValueRef && selectedValueRef.current) {
            selectedValueRef.current.style.display = "none";
            searchboxRef.current.style.opacity = "100%";
            searchboxRef.current?.focus();
          }
        } else {
          if (selectedValueRef && selectedValueRef.current) {
            selectedValueRef.current.style.display= "none"
          }
          searchboxRef.current.style.opacity = "100%";
          searchboxRef.current.focus();
        }
        if (searchRawId && searchResults && searchResults.data) {
          setTimeout(() => {
            if (searchRawId) {
              searchboxRef.current.value = searchRawId;
              searchboxRef.current.select();
            }
          }, 10)
        }
      }
    }

    const isEditMode = () => {
      return queryParams && queryParams.id ? true: false
    }

    const render = () =>  {
      const {isSearchResultsOpen, keyword, focused, loading, error} = searchState;
      return (
        <AutoCompleteWrapper className={props.className}>
          <SearchBox
            searching={loading}
            onClick={e => handleSelectedItem(e)}
          >
          {!!loading && <Spinner className="spinner" size="S"/>}

          {!loading && <i className="fa fa-search" onClick={e => {
            searchboxRef.current.focus();
          }}/>}
          {!!selectedRaw && !focused && !!selectedItemRenderer &&
            <div className="select-value1" ref={selectedValueRef} title={selectedItemRenderer(selectedRaw)}>
              {selectedItemRenderer(selectedRaw)}
            </div>
          }
            <input
              type="text"
              ref={searchboxRef}
              disabled={disabled}
              className={classNames({show: (focused || !selected)})}
              placeholder={placeholder}
              value={inputKey || ''}
              onChange={e => {
                setInputKey(e.target.value)
                setIsStopSearch(false);
                setSearchState({...searchState, keyword: e.target.value});
                setPageNumber(1);
                if(isEditMode()) {
                  setIsEditModeChanged(true)
                }
                handleInputState(e.target.value);
              }}
              onFocus={e => {
                e.preventDefault();
                searchState && searchState.data && searchState.data.length > 0 && !searchState.isSearchResultsOpen && setSearchState({...searchState, isSearchResultsOpen: true})
              }}
              onDoubleClick={e => {
                e.preventDefault();
                searchboxRef.current.select()
              }}
              onBlur={e => {}}
              />

            {!!error && <Hint className="hint-wrapper" pos="bottom" width="100px" type="error" content={error}/>}
          </SearchBox>

          {/* render search results */}
          {isSearchResultsOpen && searchState.data && renderDropdown()}
        </AutoCompleteWrapper>
      )
    }

    const handleInputState = ele => {
      if(isSearchable) {
        setTimeout(() => newHandler(ele))
      } else {
        let prevStateData = [...searchState.filterList];
        let filteredItem = prevStateData.filter(x => x.name.toLowerCase().includes(ele));
        setSearchState({
          ...searchState, data: filteredItem, keyword: ele
        })
      }
    }

    const renderDropdown = () => {
      const {data, searchType} = searchState;
      let selectedItemId = selectedRaw ? selectedRaw.id : "";
      if(selectedRaw && ['tv', 'digital', 'social', 'radio'].indexOf(selectedRaw.platform) >= 0 ) {
        selectedItemId = selectedRaw.platform_campaign_id
      }
      // if(data && data.length === 0 && debouncedInputValue !== "" && !searchState.loading) {
      if(data && data.length === 0 && inputKey !== "" && !searchState.loading) {
        return renderNoData()
      }
      if(data && data.length === 0 && !isSearchable && !searchState.loading) {
        return renderNoData()
      }
      let type = "";
      if (searchType[0] && searchType[0].toLowerCase() === "tv campaign id" || searchType[0] && searchType[0].toLowerCase() === "gam jira id" || searchType[0] && searchType[0].toLowerCase() === "social campaign id") {
        // type = `${searchType[0]} '${searchState.keyword}'`
        type = `${searchType[0]} '${inputKey}'`
      } else {
        type = searchType[0];
      }
      return (
        <Dropdown
          className="auto-complete-dropdown"
          ref={searchResultsRef}
          >
          <DropdownContainer key={Date.now}>
            {data && data.length > 0 && <>
              {isSearchable && <DropdownGroupTitle>{_.capitalize(type)} :</DropdownGroupTitle>}
              {renderDropdownItems(data, selectedItemId)}
            </>}
            {searchState.loading ? <DropdownLoading>Loading Data ...</DropdownLoading>: null}
            {!searchState.loading && Object.keys(data).length === 0 ? <div>{searchState.error || "No Data Found"}</div>: null}
          </DropdownContainer>

        </Dropdown>
      )
    }

    const renderDropdownItems = (data, selectedItemId) => {
       return data.map((d, dIndex) => {
          if(itemRenderer) {
            if(closeOnItemClick) {
              return itemContentRenderer(data, d, selectedItemId, dIndex)
            }else {
              return itemRenderer(d, selectedItemId);
            }
          }
        })
    }

    const itemContentRenderer = (items, d, selectedId, index) => {
      if (items.length === index+1) {
        return (
          <div
            key={d.id+index}
            ref={lastItemRef}
            id={d.id}
            className={classnames('msq-item-option', {selected: selectedRaw && ['tv', 'digital', 'social', 'radio'].indexOf(selectedRaw.platform) >= 0 ? selectedId === d.platform_campaign_id : selectedId === d.id})}
            onClick={() => {
              onChange(d)
              onSearch({
                data: searchState.data,
                page: pageNumber,
                lastNode: d.id,
                searchType: searchState.searchType
              })

            }}>
              {/* {itemRenderer.call(this, d, d.id, searchState.keyword)} */}
              {itemRenderer.call(this, d, d.id, inputKey)}

          </div>
        )
      } else {
        return (
          <div
            key={d.id+index}
            id={d.id}
            className={classnames('msq-item-option', {selected: selectedRaw && ['tv', 'digital', 'social', 'radio'].indexOf(selectedRaw.platform) >= 0 ? selectedId === d.platform_campaign_id : selectedId === d.id})}
            onClick={() => {
              onChange(d)
              onSearch({
                data: searchState.data,
                page: pageNumber,
                lastNode: d.id,
                searchType: searchState.searchType
              })
            }}>
              {/* {itemRenderer.call(this, d, d.id, searchState.keyword)} */}
              {itemRenderer.call(this, d, d.id, inputKey)}
          </div>
        )
      }
    }

    const renderNoData = () => {
      return (
        <Dropdown className="auto-complete-dropdown" ref={searchResultsRef}>
          <NoData>No Data Found...</NoData>
        </Dropdown>
      )
    }

    const handleSearchQuery = (val) => {
      let {keyword} = searchState;
      let campaign = props.campaign;
      if( props.campaign &&  props.campaign.msq) {
        let isValExists = campaign.msq.platforms.find(x => x.platform_campaign_id.includes(val));
        if(isValExists) {
          setSearchState({
            ...searchState,
            loading: false,
            error: `${val} already exits in the MSQ`,
            isSearchResultsOpen: true
          })
          return;
        }
      }
      if (val || searchWithoutKeyword) {
        setSearchState({
          ...searchState,
          keyword: val,
          loading: true,
          isSearchResultsOpen: true
        })
        post(api, {keyword: val, limit: 10, offset: (pageNumber-1)*10, isFallBack: false, ...apiParams})
          .then(searchResult => {
            let {result} = searchResult;
            let type= Object.keys(result).map(type => type);
            let searchItems = Object.keys(result).map(type => result[type]);
            if (searchItems[0] && searchItems[0].length > 0) {
              if (searchState.data && searchState.data.length > 0) {
                setSearchState({
                  ...searchState,
                  loading: false,
                  isSearchResultsOpen: true,
                  searchType: type,
                  data: [...searchState.data, ...searchItems[0]]
                })
              } else {
                setSearchState({
                  ...searchState,
                  loading: false,
                  isSearchResultsOpen: true,
                  searchType: type,
                  data: searchItems[0]
                })

              }
            } else {
              setIsStopSearch(true);
              setSearchState({...searchState, loading: false, isSearchResultsOpen: true})
            }

          })
          .catch(e => {
            setSearchState({
              ...searchState,
              loading: false,
              error: e.message,
              isSearchResultsOpen: true
            })
          });
      } else {
        setSearchState({
          ...searchState,
          loading:false,
          data: null
        })
      }
    }

    const newHandler = useCallback(_.debounce(handleSearchQuery, 500), []);

    return (
      render()
    )
}

export default withConsumer(React.memo(MsqSearch));
