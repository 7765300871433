import moment from 'moment';
import numbro from 'numbro'; //https://numbrojs.com/format.html
import {currencySymbalMap} from './symbals';
import * as d3 from "d3-format";

export const intFormatter = (num, digit) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  return d3.format(',.0f')(num)
  // return numbro(num).format({thousandSeparated: true, mantissa: digit || 0})
  // return Number(num).toLocaleString(undefined, { maximumFractionDigits: digit || 1});
}

export const floatFormatter = (num, digit) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '--';
  } else if (!num) {
    return '0.00';
  }
  return d3.format(',.2f')(num);
}

export const uniquesFormatter = (num, digits) => {
  if (isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  return d3.format('.3s')(num);
}

export const pctFormatter = (num, digit = 1) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '--';
  }
  return d3.format(`,.${digit || 0}%`)(num);
  // return numbro(num).format({output: 'percent', mantissa: digit || 0})
  // return (Number(num) * 100).toLocaleString(undefined, { maximumFractionDigits: digit || 1}) + '%';
}

export const pct2Formatter = (num, digit = 2) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '--';
  }
  return d3.format(`,.${digit || 0}%`)(num);
}

export const pctFormatterWithoutSign = (num, digit = 1) => {
  return pctFormatter(num, digit).replace('%', '');
}

export const moneyFormatter = (num, currency) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '--';
  }
  let symbal = currencySymbalMap[currency] || '$';
  if(Number.isInteger(num)) {
    return symbal + d3.format(',.0f')(num);
  } else {
    return symbal + d3.format(',.2f')(num);
  }
}


export const moneyFormatterGenerator = (currency) => {
  return num => {
    if (num === Infinity || num === null || num === undefined || isNaN(num)) {
      return '--'
    }
    else if (!num) {
      return '--';
    }
    let symbal = currencySymbalMap[currency] || '$';
    if(Number.isInteger(num)) {
      return symbal + d3.format(',.0f')(num);
    } else {
      return symbal + d3.format(',.2f')(num);
    }
  }
}

export const getMetricFormatter = (metricMeta, currency) => {
  let {formatter} = metricMeta || {};
  if(metricMeta && metricMeta.isMoneyType && currency) {
    return moneyFormatterGenerator(currency);
  } else if(metricMeta && metricMeta.formatter) {
    return metricMeta.formatter;
  } else {
    return intFormatter;
  }
}

export const periodFormat = (d, type) => {
  if(typeof d === 'string') {
    return {
      last7days: 'Last 7 Days',
      last30days: 'Last 30 Days',
      last60days: 'Last 60 Days',
      last90days: 'Last 90 Days',
      last180days: 'Last 180 Days'
    }[d] || d;
  }
  else if (!d.end || d.end === 'Unlimited') {
    return moment(d.start).format('DD MMM, YYYY') + ' - Unlimited';
  } else {
    let start = moment(d.start);
    let end = moment(d.end);
    if (start.year() === end.year() && type === 'short') {
      return start.format('DD MMM') + ' - ' + end.format('DD MMM')
    }
    else if (start.year() === end.year()) {
      return start.format('DD MMM') + ' - ' + end.format('DD MMM, YYYY')
    }
    else {
      return start.format('DD MMM, YYYY') + ' - ' + end.format('DD MMM, YYYY')
    }
  }
}

export const hash = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for(let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

