import React, {useEffect} from 'react';

export const FixedHook = (props) => {
  const {wrapperRef, fixedRef, offset} = props;
  const adjust = () => {
    const menu = fixedRef.current;
    const wrapper = wrapperRef.current;
    if (!menu || !wrapper) return;
    let rect = wrapper.getBoundingClientRect();
    const {left, top, width} = rect;
    menu.style.position = 'fixed';
    menu.style.left = left + (offset.left || 0) + 'px';
    menu.style.right = 'auto';
    menu.style.minWidth = width + 'px';
    menu.style.top = (top + (offset.top || 0)) + 'px';
  }
  useEffect(() => {
    adjust();
    window.addEventListener('scroll', adjust);
    let timer = setInterval(() => adjust(), 100);
    return () => {
      window.removeEventListener('scroll', adjust);
      clearInterval(timer);
    };
  });
  return <div/>;
}