import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans, BlueJeans1,
  Dark,
  DarkGray, DarkGray1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1, Grass1, BitterSweet1, PrimaryLightBlue, MediacorpPrimary, Levander
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  padding: 20px;
  @media print {
    .no-print {
      display: none !important;
    }
  }
  [contentEditable] {
    outline: none;
    min-width: 100px;
    border: none;
    &:focus {
    }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 25px;
    padding: 5px 20px;
    .title {
      line-height: 1em;
      p {
        color: ${MediumGray1};
        font-size: 12px;
      }
    }
    a {
      margin-right: 15px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 14px;
        margin-right: 5px;
      }
    }
    .setting-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dropdown-toggler {
        display: flex;
        align-items: center;
      }
      .dropdown {
        margin-left: 30px;
      }
      a {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
          font-size: 16px;
          font-weight: bold;
          margin-right: 5px;
        }
        &.active {
          color: ${Grass};
        }
        &:hover {
          color: ${Grass1};
        }
      }
    }
  }
  .grid-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    >div {
      overflow: hidden;
      .note {
        margin: 0 0 10px 0;
      }
    }
  }

  .rdw-editor-toolbar {
    display: none;
  }
  .rdw-editor-main {
    background: transparent;
  }
  .rdw-editor {
    user-select: none;
    background: transparent;
  }
`