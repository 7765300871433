import React from 'react';
import {Link} from 'react-router-dom';
import createTooltip from '../uikit/tooltip/index';
import _ from 'lodash';

export const supportedColumns = [
  {
    hideDefault: true,
    key: 'index',
    label: 'No.',
    align: 'center',
    sortable: true,
    style: {paddingTop: '12px', maxWidth: '20px'},
    renderer: (d, i, context) => ((context.page - 1) * context.PAGE_SIZE) + i + 1
  },
  {
    key: 'userId',
    label: 'User',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: d => {
      let tooltip = createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => d,
        directionFunc: 'right'
      });
      return (
        <div 
          onMouseOver={tooltip.onMouseOver(d.userId)}
          onMouseLeave={tooltip.onMouseOut()}
        >
          {d.user || 'System'}
        </div>
      )
    }
  },
  {
    key: 'action',
    label: 'Action',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
  },
  {
    key: 'details',
    label: 'Details',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: d => {
      const mapdataObj = (obj) => {
        return Object.keys(obj).map((key, index) => {
          return <div>{key} : {obj[key]}</div>
        })
      }

      const handleArrayObjectData = (data) => {
        if (_.isArray(data)) {
          return data.length === 0 ? '[]' : data.map(obj => mapdataObj(obj))
        } else if (_.isObject(data)) {
          return mapdataObj(data)
        }
      };

      const isActionExists = (action) => {
        let actionsList = ['open report', 'create report', 'create user', 'update user', 'login failed', 'save campaign dashboard layout', 'open campaign dashboard', 'create campaign', 'update campaign', 'download campaign list'];
        action = action.toLowerCase();
        if(actionsList.includes(action)) {
          return true;
        }
        return false;
      }

      return (
        <div title={_.isObject(d.details) ? JSON.stringify(d.details) : d.details}>
          {isActionExists(d.action) ? <div>
            {'User'} : {d.user} <br/>
            {'UserID'} : {d.userId} <br/>
            {'Date'} : {d.date}
          </div> : _.isObject(d.details) ? Object.keys(d.details).map((key, index) => {
            return <div>{key} : {_.isString(d.details[key]) ? d.details[key]: handleArrayObjectData(d.details[key])}</div>
          }) : d.details.length < 100 ? d.details : (d.details.substring(0, 100) + '...')}
        </div>
      )
     
    }
  },
  {
    key: 'ip',
    label: 'IP Address',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: d => {
      return (
        <div>
          <div>{(d.ip || '').split(':')[0]}</div>
          <div>{d.country}</div>
        </div>
      )
    }
  },
  {
    key: 'ua',
    label: 'User Agent',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
  },
  {
    key: 'date',
    label: 'Date',
    align: 'left',
    sortable: true,
    filterOps: ['$lt', '$gt'],
    datatype: 'datetime',
  },
]