import styled from 'styled-components';
import {LightGray1, LightGray} from "../../app/StyleCommon";

export const ScrollTableWraper = styled.div`
  > div:first-child {
    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 667px) {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  position: relative;
  .main-table {
    border: 1px solid ${LightGray1};
    > tbody {
      > tr {
        // background-color: white !important;
        // > td {
          //   border-bottom: 1px solid ${LightGray1} !important;
        // }
        // &:last-child td {
        //   border-bottom: none !important;
        // }
      }
    }
  }
  .fixed-table {
    position: absolute;
    right: 0;
    top: 0;
    right: -1px;
    pointer-events: none;
    background: transparent;
    border: 1px solid transparent;
    border-right: 1px solid ${LightGray};
    td, th {
      background: transparent;
      visibility: hidden;
    }
    td:last-child,
    th:last-child {
      visibility: visible;
      pointer-events: auto;
      background: white;
    }
    tbody tr {
      background: white;
      &:nth-child(odd) {
      }
    }
  }
`;