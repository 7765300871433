import React, {useState, useEffect, useRef} from 'react';
import _ from 'lodash';
import axios from 'axios';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import Dropzone from 'react-dropzone'
import {SampleFileWrapper, ActionButtons, DailogWrapper, MainDailogWrapper, DeleteDailogWrapper, ConversionHeader, SelectedFileWrapper, AddCustomMetricBtn, DropdownToggler, PageWrapper, NoDataFound, DropzoneWrapper, DownloadBtn, DeleteBtn, UploadError} from './clientData.style';
import Accordion from '../uikit/accordion/accordion';
import {Btn, CondirmDialog} from "../uikit";
import {defaultTooltip} from "../uikit/tooltip";
import qs from "qs";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import Table from "../uikit/table";
import {TableWrapper} from "../charts/index.style";
import {BlueJeans} from "../app/StyleCommon";
import {post} from "../utils/request";
import classnames from "classnames";
import { EvaluateTokens } from "../uikit/formulaParser/Evaluator";
import { CreateCustomMetricForm, ColumnDropDownToggler,  intFormatter, floatFormatter, pctFormatter, moneyFormatter } from "./clientData.common";
import { saveAs } from 'file-saver';

const ClientData = (props) => {
  const {session = {}} = props.appState || {};
  const [compState, setCompState] = useState({
    file: '',
    loading: false,
    error: '',
    selectedFile: null,
    data: {},
    files: []
  });
  const columnTooltip = defaultTooltip;
  const [excelData, setExcelData] = useState(null);
  const [clientKPIData, setClientKPIData] = useState([]);
  const [uploadedFileData, setUploadedFileData] = useState([]);
  const [selectedDataType, setSelectedDataType] = useState(undefined);

  useEffect(() => {
    let url=`/api/campaignClientData`;
    let clientDataUrl = `/api/clientDataByCampaign`;
    let params = buildQuery();
    getCampaignData(url, params);;
    getClientDataByCampaign(clientDataUrl, params);
  }, []);

  const getCampaignData = async(url, params) => {
    axios({
      url: url,
      method: params ? 'POST' : 'GET',
      data: params,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(response => {
      const {data, status, statusText} = response;
      if(data && data.client_kpi_metrics) {
        setClientKPIData(data.client_kpi_metrics)
      }
      if (status !== 200) {
        throw new Error(statusText);
      }
      setCompState({...compState, data: data, loading: false});
    }).catch(e => {
      if (e.response && e.response.statusText) {
        e.message = e.response.statusText;
      }
      setCompState({...compState, error: e, loading: false});
      if(e.response && e.response.status === 401) {
        window.location = '/login';
      }
    });
  }

  const handleFileDrop = async (file, filesData) => {
    const {data, files} = compState;
    let msqData = data;
    let isFileExists = filesData && filesData.filter(x => x.file_name === file.name);
    if (isFileExists && isFileExists.length > 0) {
      renderFileExistsDialog(file);
      return;
    }
    if(filesData && filesData.length > 0 && isFileExists && isFileExists.length === 0) {
      renderErrorDialog('filetype', 'Currently, only one file upload is supported. Please delete the already submitted file to proceed.')
      return;
    }
    let fileextension = file.name.split('.').pop().toLowerCase();
    let allowedTypes = ['xls', 'xlsx'];

    // if(allowedTypes.indexOf(fileextension) === -1) {
    //     renderErrorDialog('filetype', 'Invalid File Type, only .xslx file format is supported.')
    //     return;
    // }

    if(isFileExists && isFileExists.length === 0) {
      setCompState({...compState, data: {}, loading: true});
      const formData = new FormData();
      formData.append('file', file);
      return post(`/api/getClientDataByFile`, formData,{
        body: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if(res.success) {
          let {columns, data, metrics, dimension, otherCols} = res.response;
          let formatteddata = {columns, data, metrics, dimension, otherCols, defCols: columns};
          renderFileUploadDialog(file, filesData, msqData, formatteddata);
        }
        if(!res.success) {
          renderErrorDialog('worksheet', res.error)
        }
        setCompState({...compState, loading: false});
      }).catch(e => {
        console.log("e", e);
      })
    }
  }

  const getClientDataByCampaign = async(url, params) => {
    axios({
      url,
      method: params ? 'POST' : 'GET',
      data: params,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(response => {
      const {data, status, statusText} = response;
      if (status !== 200) {
        throw new Error(statusText);
      }
      setUploadedFileData(data.data);
    }).catch(e => {
    });
  }

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  const downloadClientDataFile = async(url, d) => {
    const formData = new FormData();
    let ext = d.file_name.substr(d.file_name.lastIndexOf('.') + 1);
    formData.append('id', `${d.id}.${ext}`);
    try {
      const data = await post(url, formData, {body: formData}, {
        responseType: 'arraybuffer',
        headers: {'Accept': 'blob'}
      });
      const blob = new Blob([s2ab(data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      saveAs(blob, d.file_name);
    } catch (e) {
      console.log("error", e);
    }
  }

  const handleFileDelete = async (d, fileData) => {
    let campaignId = compState.data.id;
    setCompState({...compState, loading: true});
    const formData = new FormData();
    formData.append('fileId', d.id);
    formData.append('fileName', d.file_name);
    formData.append('campaignId', campaignId);
    try {
      const res = await post(`/api/deleteClientData`, formData, {
        body: formData
      });
      let dfiles = uploadedFileData.filter(file => {
        return file.id !== res.fileId
      });
      if (res.success) {
        setCompState({ ...compState, loading: false, selectedFile: null});
        setUploadedFileData(dfiles)
      }
      CondirmDialog.closeAll();
    } catch (e) {
      setCompState({ ...compState, loading: false, error: e, selectedFile: null });
    }
  }

  const buildQuery = () => {
    const urlParams = qs.parse(window.location.search.slice(1));
    return {
      id: urlParams.id,
    };
  }

  const renderSampleFile = () => {
    return (
      <SampleFileWrapper>
          {/* <h4>You did not upload client data yet, please follow the following Excel format to upload.</h4> */}
          <div className='download-file'>
            <span className="material-symbols-outlined">download</span>
            <a href="/doc/sample_client_data.xlsx" target="_blank">
              Example File
            </a>
          </div>
          <img src="/img/assets/client_data_excel_example.png"/>
      </SampleFileWrapper>
    )
  }

  const openDialog = () => {
    let {selectedFile} = compState;
    let fileData = uploadedFileData.find(x => x.id === selectedFile.id);
    if (fileData) {
      let {data, defCols, metrics, dimension, otherCols, file_name, id} = fileData;
      let formatteddata = {columns: defCols, data, metrics, dimension, otherCols:[]};
      let file = {
        name: file_name,
        id: id
      };
      renderFileUploadDialog(file, uploadedFileData, compState.data, formatteddata, true);
    }
  }

  const renderDuplicateFileContents = (file) => {
    return (
      <MainDailogWrapper>
          <h3>Duplicate File</h3>
          <div className='subhead'>A file with this name <b>{file.name}</b> already exists, What would you like to do ?</div>
          <div className='mb16'>By Clicking <b>"OK"</b> will replace the existing file </div>
          <div className='mb16'>By Clicking <b>"Cancel"</b>, close the dialog box and select file again.</div>
          <div className='buttons'>
            <button className='reset' onClick={e => {
              CondirmDialog.closeAll();
              setCompState({ ...compState, loading: false, file: null, selectedFile: null});
            }}>Cancel</button>
            <button className='primaryButton' onClick={() => {
              handleContinueFileUpload(file);
              setCompState({...compState, selectedFile: null});
            }}><i className="material-symbols-outlined uploadIcon">Upload</i>Continue, Upload</button>
          </div>
      </MainDailogWrapper>
    )
  }

  const renderFileDeleteContents = (d) => {
    return (
      <DeleteDailogWrapper>
          <div className='subhead'>Do you really want to delete this file <b>{d.file_name}</b> ?</div>
          <div className='buttons'>
            <button className='reset' onClick={() => CondirmDialog.closeAll()}>Cancel</button>
            <button className='primaryButton' onClick={() => handleFileDelete(d)}>OK</button>
          </div>
      </DeleteDailogWrapper>
    )
  }

  const renderFileDeleteDialog = (d) => {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      width: '500px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: renderFileDeleteContents(d),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  const renderFileExistsDialog = (file) => {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      width: '600px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: renderDuplicateFileContents(file),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  const renderErrorDialog = (type, message) => {
    // if(type === 'date') {
    //   text = `Date Missing! selected file don't contains the required fields. Date is mandatory`
    // }
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      width: '400px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <DailogWrapper>
        <h3 className='error'>Error</h3>
        <div className='subhead'>{message}</div>
        <div className='buttons'>
          <button className='primaryButton' onClick={e => {
            setCompState({...compState, file: ''})
            CondirmDialog.closeAll()
          }}>Okay</button>
        </div>
    </DailogWrapper>,
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  const renderFileUploadDialog = (file, filesData, msqData, data, alreadyUploaded = false) => {
    console.log("renderFileUploadDialog")
    const handleLoading = (e) => {
      setCompState({...compState, loading: e})
    }
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      maxWidth: '500px',
      style: {maxWidth: '500px'},
      hideCancel: true,
      hideOK: true,
      onCancel: () => {
        CondirmDialog.closeAll()
      },
      dialogBody: <RenderDialogContents
        key={new Date()}
        file={file}
        msqData={msqData}
        clientKpiMetrics={clientKPIData}
        data={data}
        session={session}
        excelData={excelData}
        showLoading={(e) => handleLoading(e)}
        alreadyUploaded = {alreadyUploaded}
        handleCancel={() => {
          setCompState({ ...compState, loading: false, file: null, selectedFile: null});
        }}
        handleUploadedFile={data => {
          let filesUploaded = [...filesData];
          filesUploaded.push(data);
          if(alreadyUploaded) {
            filesUploaded = filesUploaded.filter(x =>  x.id !== file.id);
          }
          setUploadedFileData(filesUploaded)
          setCompState({...compState, file: ''});
          setTimeout(() => {
            CondirmDialog.closeAll();
          }, 100);
        }}
      />,
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  const getColsLabel = (key, d) => {
    let {metrics, dimension} = d;
    let isMetric = metrics.find(x => x.key === key);
    let isDimension = dimension.find(x => x.key === key);
    if(isMetric) {
      return isMetric.label
    }
    if(isDimension) {
      return isDimension.label
    }
  }

  const renderFilesUploaded = () => {
    let dataResults = uploadedFileData;
    return (
      <div className='filesUploaded'>
          <h4>List of Files Uploaded</h4>
          <TableWrapper className="data-table">
            <Table
              columns={[
                {
                  key: 'file_name',
                  label: 'File Name',
                  align: 'left',
                  sortable: true
                },
                {
                  key: 'uploadedBy',
                  label: 'Uploaded By',
                  align: 'left',
                  sortable: true
                },
                {
                  key: 'updatedTime',
                  label: 'Uploaded Time',
                  align: 'left',
                  sortable: true
                },
                {
                  key: 'action',
                  label: 'Actions',
                  align: 'center',
                  style: {maxWidth: '130px'},
                  hidden: (dataResults || []).every(d => d.uploadedBy !== session.userId),
                  renderer: (d, i) => {
                    if(d.uploadedBy !== session.userId) {
                      return '--';
                    }
                    let ext = d.file_name.substr(d.file_name.lastIndexOf('.') + 1);
                    return (
                      <div className='alignCenter'>
                        <a className='link' onClick={e => {
                          e.preventDefault();
                          setCompState({...compState, selectedFile: d});
                        }}>View</a>
                        <DownloadBtn
                           onMouseOver={columnTooltip.onMouseOver({txt: 'Download'})}
                           onMouseLeave={columnTooltip.onMouseOut()}
                           onClick={e => downloadClientDataFile(`/api/downloadClientFileData`, d)}
                        >
                          <span className="material-symbols-outlined">download</span>
                        </DownloadBtn>
                        <DeleteBtn
                          onMouseOver={columnTooltip.onMouseOver({txt: 'Delete'})}
                          onMouseLeave={columnTooltip.onMouseOut()}
                          onClick={() => renderFileDeleteDialog(d)}
                        >
                          <i className="fa fa-trash"/>
                        </DeleteBtn>
                      </div>
                  )}
                }
              ]}
              rows={dataResults || []}
              // rowClicker={e => {
              //   setCompState({...compState, selectedFile: e})
              // }}
              noDataText={'No data found'}
            />
          </TableWrapper>
      </div>
    )
  }

  const renderDropZone = () => {
    let { file } = compState;
    return (
      <div className='dropZone'>
          {/* <h4>Upload Client data:</h4> */}
          <p>Please select an Excel file to upload. Kindly foolow the instructions and keep the data in the desired format will work perfectly</p>
          <DropzoneWrapper className="dropzone-wrapper">
            <Dropzone
              onDrop={files => {
                setCompState({...compState, file: files[0]})
                handleFileDrop(files[0], uploadedFileData);
                // uploadFile(files[0], uploadedFileData);
              }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              {
                !file &&
                <div className='dropzone-content'>
                  <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                  <div className="desktop-upload">
                    <p>Drag client data file here to upload.</p>
                    <p>or</p>
                    <a style={{color: BlueJeans, textDecoration: 'underline'}} onClick={e => {}}>browse</a>
                  </div>
                  <p>
                    You can upload any .xslx file with any set of columns as long as it has Date column and 1 record per row.
                    <br/>
                    Please clean up any corrupted data before upload.
                  </p>
                </div>
              }
              {
                !!file &&
                <div className="droped-file">
                  <div>
                    <i className="fa fa-file"></i>
                    <span>{file.name}</span>
                  </div>
                </div>
              }
            </Dropzone>
          </DropzoneWrapper>
        </div>
    )
  }

  const renderDefineCustomMetric = () => {
    return (
      <ul>
        Custom Metrics can be defined in 2 ways:
        <li>Once a .xlsx file is selected, clicking on 'Add Client Metric' inside dialog box will create a new metric.</li>
        <li>Create Campaign supports creation of Client Metric, user can click on 'Add Client Metric' from the KPI Dropdown to create a custom metric.</li>
      </ul>
    )
  }

  const render = () => {
    const {data, error, loading, files, selectedFile, file} = compState;

    let accordionData =[
      {
        question: "What is Client Data ?",
        children:
          "Client data is the data received from our partner clients containing classified information about the leads and others."
      },
      {
        question: "What all file formats are supported ?",
        children:
          "Currently we are supporting .xlsx file format."
      },
      {
        question: "Why Start Date is mandatory in excel file",
        children:
          "Computation of Metrics, dimesions are based on start date, an error will throw in case Start Date is missing."
      },
      {
        question: "How to define Custom Client Metric ?",
        children: renderDefineCustomMetric(),
      },
      {
        question: "Download sample file ?",
        children: renderSampleFile()
      }
    ];
    const urlParams = qs.parse(window.location.search.slice(1));
    const fromDetailPage = urlParams.fr === 'campaign_dashboard';
    return (
      <MainLayout activeItem="campaign" breadcrumb={_.compact([
        {path: '/campaigns', label: 'Campaigns'},
        fromDetailPage ? {path: `/campaign/details?id=${urlParams.id}`, label: 'Campaign Insights'} : null,
        {path: '/campaign/client-data', label: 'Client Data'}
      ])} loading={loading}>
        <PageWrapper><h3>Upload Client data</h3></PageWrapper>
        {uploadedFileData && uploadedFileData.length === 0 && <NoDataFound>
            Client Data is not uploaded for this campaign, click on browse button below to upload.
        </NoDataFound>}
        {uploadedFileData && uploadedFileData.length > 0 && <PageWrapper>
          {renderFilesUploaded()}
        </PageWrapper>}
        <PageWrapper>
          {renderDropZone()}
          <div className='instructionzone'>
            <h4>Instructions</h4>
            <ul className='instructions'>
              <li>File should include a 'date' field. </li>
              <li>The columns in the excel file will be classified as Metrics.</li>
              <li>Columns such as day, holiday, week and date will not be included in metrics.</li>
              <li>Cell spreading across multiple rows will be considered as aggregated, hence it should be avoided.</li>
              <li>Columns with formulas in cells will be taken into consideration.</li>
              <li>Columns names shouldn't contain special characters except - (Hyphen).</li>
            </ul>
            <h4>FAQs</h4>
            <Accordion data={accordionData}/>
            {!!error && <ErrorMessage message={error}/>}
            {selectedFile && openDialog()}
          </div>
        </PageWrapper>
      </MainLayout>
    )
  }

  const handleContinueFileUpload = async (f) => {
    let files = uploadedFileData;
    let isFileExists = files.find(x => x.file_name === f.name);
    let fileId = '';
    if(isFileExists) {
      fileId = isFileExists.id;
      await handleFileDelete({id: isFileExists.id, file_name: f.name});
      handleFileDrop(f, files.filter(x => x.file_name !== f.name));
    }
  }
  return render()
};

const RenderDynamicSelectedTable = ({dataObj, tableData, showSelect=false, handleMetricsChange}) => {
  let dataOrig = _.cloneDeep(dataObj);
  tableData.columns = _.uniqBy(tableData.columns, d => d.key);
  if(!tableData.columns.find(d => d.key === 'client_date')) {
    tableData.columns.unshift({
      key: 'client_date',
      value: 'Date',
      isDefault: true
    })
  }

  const [dataToDisplay, setDataToDisplay] = useState([...tableData.data]);
  const [columnsToDisplay, setColumnsToDisplay] = useState([...tableData.columns]);

  useEffect(() => {
    let newData = dataOrig.data.map(x => {
      Object.keys(x).forEach(key => {
        if(x[key] == " "){
          // x[key] = '-'
        }
      })
      return x
    })
    let data = getRowsWithFormatters(newData, _.cloneDeep(tableData.columns));

    setDataToDisplay(data);
    setColumnsToDisplay([...tableData.columns]);
  }, [tableData]);

  let isDimension = (d) => dataOrig.dimension.find(x => (x && d) && x.key === d.key);

  const formatRowsWithFormatters = (data, column) => {
    let newData = _.cloneDeep(data);
    let tableRawData = _.cloneDeep(tableData.data);
    newData.map((row, index) => {
      Object.keys(row).forEach(colKey => {
        if(colKey === column.key) {
          let getRawCol = tableRawData[index];
          let formatter = column.formatter || '';
          if (formatter === "percentage") {
            row[colKey] = pctFormatter(getRawCol[colKey])
          } else if (formatter === "money") {
            row[colKey] = getRawCol[colKey] !== "" ? moneyFormatter(getRawCol[colKey]) : "";
          } else if (formatter === "integer") {
            row[colKey] = intFormatter(getRawCol[colKey])
          } else if (formatter === "float") {
            row[colKey] = floatFormatter(getRawCol[colKey])
          }
          return colKey;
        }
      })
    })
    return newData;
  }

  const getRowsWithFormatters = (data, columns) => {
    let newData = _.cloneDeep(data);
    newData.map(row => {
      Object.keys(row).forEach(colKey => {
        let formatter = columns.find(c => c.key === colKey) ? columns.find(c => c.key === colKey).formatter : '';
        if (formatter === "percentage") {
          row[colKey] = pctFormatter(row[colKey])
        } else if (formatter === "money") {
          row[colKey] = row[colKey] !== "" ? moneyFormatter(row[colKey]) : "";
        } else if (formatter === "integer") {
          row[colKey] = intFormatter(row[colKey])
        } else if (formatter === "float") {
          row[colKey] = floatFormatter(row[colKey])
        }
        return colKey;
      })
    })
    return newData;
  }

  const handleChange = (e,x) => {
    let columns =  _.cloneDeep(tableData.columns);
    let data =  _.cloneDeep(dataToDisplay);

    columns.map(col => {
      if(col.key === x.key){
        col.formatter = e.id;
        col.formula = x.formula
      }
    });
    let column = columns.find(c => c.key === x.key);
    let formattedData = formatRowsWithFormatters(data || [], column);
    setDataToDisplay(formattedData);
    setColumnsToDisplay(columns)
    handleMetricsChange(e,x);
  }
  let columns = columnsToDisplay.map((x, index) => {
    return {
      key: x.key,
      label: <ConversionHeader>
                <label>{x.value}</label>
                <div className='dropdown-toggle' >
                  {showSelect && !isDimension(x) && x.key !== "client_date" && <ColumnDropDownToggler col={x} data={dataObj} handleMetricsChange={handleChange} dIndex={index}/>}
                </div>
            </ConversionHeader>,
      align: 'left',
    }
  });
  return (
    <TableWrapper className="data-table">
      <Table
        columns={columns}
        rows={dataToDisplay}
        noDataText={'No data found'}
      />
    </TableWrapper>
  )
}


const RenderDialogContents = ({file, msqData, clientKpiMetrics, data, session, excelData, showLoading, handleUploadedFile, handleCancel, alreadyUploaded}) => {
  const uploadErrorRef = useRef(null);
  const [tableData, setTableData] = useState(data);
  const [errorMap, setErrorMap] = useState({});
  const [saveClicked, setSaveClicked] = useState(false);
  const [isCreateMetric, setIsCreateMetric] = useState({
    state: false,
    type: ''
  });

  useEffect(() => {
    setTableData(data);
  }, [data])

  useEffect(() => {
    if(clientKpiMetrics && clientKpiMetrics.length > 0 && !alreadyUploaded) {
      let colsTobeAdded = [];
      let metricsTobeAdded = [];
      let clientTableData = _.cloneDeep(data);
      clientKpiMetrics.forEach(c => {
        let isColExists = data.columns.find(d => d.key === c.key);
        !isColExists && colsTobeAdded.push({
          exRef: {row: null, col: null},
          description: c.description,
          formatter: c.formatter,
          formula: undefined,
          isDefault: false,
          key: c.key,
          value: c.value
        });
        metricsTobeAdded.push({
          formatter: c.formatter,
          isDefault: false,
          key: c.key,
          label: c.value
        })
        // clientTableData.data = data.data.map(d => {
        //   return {...d, [c.key]: ''};
        // })
      })
      clientTableData.columns = [...clientTableData.columns, ...colsTobeAdded];
      clientTableData.metrics = [...clientTableData.metrics, ...metricsTobeAdded];

      setTableData(clientTableData)
    }
  }, [clientKpiMetrics]);

  const [customMetricForm, setCustomMetricForm] = useState({
    label: '',
    metric: '',
    description: '',
    formatter: '',
    formula: '',
    token: ''
  });

  const generateValuesByFormula = (x, key, formula, token, cols) => {
    let val ={}
    Object.keys(x).forEach(s => {
      val[cols.find(x => x.key === s)?.value] = Number(x[s])
    })
    const result = EvaluateTokens(token, val);
    return Number(result)
  }

  const handleCustomMetricSave = (form) => {
    let data = {...tableData};
    const {value, label, key, description, formatter, formula, formulaClient, formulaDeps, token} = form;
    let colExists = data.columns.find(x => x.key === key);
    // // check if already exists and add in columns
    if(!colExists) {
      data.columns.push({formula, token, description, formatter, key, value});
      // add in metrics
      data.metrics.push({formatter, isDefault: false, description, key, label: value, formula: formulaClient, formulaDeps })
      //add object in data
      if(formula) {
        data.data = data.data.map(x => {
          return {...x, [key]: generateValuesByFormula(x, key, formula, token, data.columns)}
        })
      }
    } else {
      data.columns.forEach(x => {
        if(x.key === key) {
          x.value = value;
          x.formatter = formatter;
          x.formula = formula;
          x.description = description;
        }
      })
      data.metrics.forEach(x => {
        if(x.key === key) {
          x.formatter = formatter;
          x.formula = formula;
        }
      })
      if(formula) {
        data.data = data.data.map(x => {
          x[key] = generateValuesByFormula(x, '', formula, token, data.columns);
          return x;
        })
      }
      if(formula === "") {
        data.data = data.data.map(x => {
          return {...x, [key]: null}
        })
      }
    }
    let defCols = [...data.columns];
    data.defCols = defCols;
    setTableData(data);
    setIsCreateMetric({state: false, type: ''});
  }

  const handleMetricEdit = (obj) => {
    setCustomMetricForm(obj)
    setIsCreateMetric({state: true, type: 'edit'});
  }

  const handleMetricDelete = (obj) => {
    let data = {...tableData};
    // delete from columns
    data.columns = data.columns.filter(x => x.key !== obj.key);
    // delete from metrics
    data.metrics = data.metrics.filter(x => x.key !== obj.key);
    // delete object in data
    data.data = data.data.map(x => {
      return _.omit(x, obj.key)
    })
    // add in other cols
    let isColExists = data.otherCols.find(x=> x.key === obj.key);
    if(!isColExists) {
      data.otherCols.push(obj);
    }
    setTableData(data);
  }

  const validateCustomMetrics = (d) => {
    let customCols = tableData.columns.filter(x => x.isDefault === false);
    let isValid = true;
    customCols.forEach(c => {
      if(c.formula === undefined || c.formula === "") {
        isValid = false
      }
    })
    return isValid;
  }

  const handleFileDelete = async (d) => {
    const formData = new FormData();
    formData.append('fileId', d.id);
    formData.append('fileName', d.file_name);
    formData.append('campaignId', d.campaignId);
    try {
      const res = await post(`/api/deleteClientData`, formData, {
        body: formData
      });
      if (res.success) {

      }
      CondirmDialog.closeAll();
    } catch (e) {
    }
  }

  const uploadFileApi = async(d, formatterMap) => {
     // validation - formula should not be null in case of custom metrics
    if(!d.defCols) {
      d.defCols = [...d.columns]
    }

     if (!validateCustomMetrics(d)) {
      return;
    }
    // d.metrics = formatterMap;
    d.columns = d.columns.map(x => x.key);
    d.data.map(x => {
      Object.keys(x).forEach(key => {
        if(x[key] == "-"){
          x[key] = ''
        }
      })
      return x
    });
    showLoading(true);
    const formData = new FormData();
    formData.append('campaignId', msqData.id);
    formData.append('file', file);
    formData.append('client_data', JSON.stringify(d));
    if(alreadyUploaded) {
      formData.append('selectedFile', JSON.stringify(file));
    }
    try {
      if(alreadyUploaded) {
        // await handleFileDelete({id: file.id, file_name: file.name, campaignId: msqData.id});
      }
      const resp = await post(`/api/uploadClientData`, formData, {
        body: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      });
      showLoading(false);
      handleUploadedFile(resp.modelResult);
      CondirmDialog.closeAll();
    } catch (e) {
      showLoading(false)
    }
  }

  const renderUploadedDynamicFile = (metricsChange) => {
    return <SelectedFileWrapper>
      <RenderDynamicSelectedTable
        dataObj={tableData}
        tableData ={tableData}
        showSelect={true}
        handleMetricsChange={metricsChange}
      />
    </SelectedFileWrapper>
  }

  const uploadFileContents = () => {
    let formatterMap = tableData.metrics;

    const metricsChange = (e, x) => {
      if (e.type === "selectformatter") {
        x.formatter = e.id;
        formatterMap.forEach(f => {
          if(f.key === x.key) {
            f.formatter = e.id
          }
        });
      }
      if (e.type === "edit") {
        handleMetricEdit(x)
      }
      if (e.type === "delete") {
        handleMetricDelete(x)
       }
    }

    const checkCustomMetrics = () => {
      return tableData.columns.filter(x => !x.isDefault && !x.formula && x.key !== 'client_date');
    }

    const handleAddCustomMetric = () => {
      setIsCreateMetric({state: true, type: 'create'});
    }

    const renderError = () => {
      return <UploadError ref={uploadErrorRef} className={classnames('hide', {show: !validateCustomMetrics(formatterMap)})}>
        <b>Error:</b> All custom metrics should contain a formula and data should not be empty, please check columns mentioned below
        <ul>
          {checkCustomMetrics().map(x => {
            return <li key={x.key}>{x.value}</li>
          })}
        </ul>
      </UploadError>
    }

    return (
      <DailogWrapper>
          <h3>Upload File</h3>
          <div className='subText'>
            <p>Table is auto generated based on the File selected.</p>
            <ActionButtons>
              <AddCustomMetricBtn onClick={handleAddCustomMetric}>
                <i className='fa fa-plus'></i> Custom Metric
              </AddCustomMetricBtn>
            </ActionButtons>
          </div>
          {isCreateMetric.state && <div className='custom-metric-wrapper'>
            <h3>Custom Metric</h3>
            <CreateCustomMetricForm
              data={tableData}
              editObj={isCreateMetric.type === 'create' ? null : customMetricForm}
              onCancel={e => {
                setIsCreateMetric({state: false, type: ''});
                setCustomMetricForm(e);
                setSaveClicked(false);
              }}
              onSave={e => {
                handleCustomMetricSave(e);
                setCustomMetricForm(e);
                setSaveClicked(false);
              }}
              handleMetricsDropdown={() => {}}
            />
          </div>}
          {!isCreateMetric.state && <div>
            {tableData && renderUploadedDynamicFile(metricsChange)}
            {saveClicked && renderError()}
            <div className='buttons'>
              <button className='reset' onClick={e => {
                  handleCancel();
                  CondirmDialog.closeAll();
                  setSaveClicked(false);
                }}>Cancel</button>
              <button className='primaryButton' onClick={() => {
                  setSaveClicked(true);
                  uploadFileApi(tableData, formatterMap);
              }}>OK
              </button>
            </div>
          </div>}
      </DailogWrapper>
    )
  }

  return uploadFileContents()
};

export default withConsumer(React.memo(ClientData));
