import styled, {css} from 'styled-components';
import {
  Aladin_BG, Aladin_BG_1,
  Aladin_Orange_Dark, Aladin_Orange_Light, Aladin_Orange_Lighter,
  BitterSweet1,
  BlueJeans, BlueJeans1,
  Dark,
  DarkGray, DarkGray1, Grass, Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";

export const TooltipWrapper = styled.div`
  position: relative;
  .tooltip-row {
    .dot,
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      vertical-align: middle;
      border-radius: 50%;
      background-color: ${BlueJeans};
      margin-right: 10px;
    }
    &.nodot:before{
      content: none;
    }
    &.green:before {
      background-color: ${Grass};
    }
    &.yellow:before {
      background-color: ${Sunflower};
    }
    .key {
      display: inline-block;
    }
    .value {
      display: inline-block;
      text-align: right;
    }
  }
`;

export const BarWrapper = styled.div`
  position: relative;
  line-height: 12px;
  text-align: left;
  width: 120px;
  margin: 5px 0;
  background: ${LightGray1};
  &.small {
    width: 60px;
  }
  .bar {
    background: ${LightGray1};
    height: 12px;
    border-radius: 3px;
    position: relative;
    width: 1px;
    &.absolute {
      position: absolute;
    }
    //position: absolute;
    min-width: 1px;
    top: 0;
    z-index: 0;
    height: 5px;
    //margin-top: 3px;
    &.green {
      background: ${Aladin_Orange_Lighter};
    }
    &.yellow {
      background: ${Sunflower1};
    }
    &.red {
      background: ${BitterSweet1};
    }
  }
  .num {
    display: block;
    font-size: 14px;
    position: relative;
    z-index: 1;
    &.green {
      color: ${Grass};
    }
    &.red {
      color: ${BitterSweet1};
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto;

  .load-more {
    margin: 20px 0;
    cursor: pointer;

    a {
      cursor: pointer;
    }
  }

  table {
    clear: both;
    border: none;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,.5);
    tr:nth-child(odd) {
      background-color: rgba(255,255,255,.2);
    }
    tr:nth-child(even) {
      background-color: rgba(255,255,255,.5);
    }
    tr:hover {
      background-color: #f1f2f3;
    }
    td {
      border: none;

      &:not(:last-child) {
        border-right: 1px dashed #eceff3;
      }
    }

    th {
      background-color: ${Aladin_BG};
      border-bottom: 1px solid ${Aladin_BG_1};
      font-size: 12px;
      //white-space: nowrap;
      &:not(:last-child) {
        border-right: 1px dashed #dce2ea;
      }
    }

    .site-logo {
      width: 16px;
    }

    .desc {
      color: ${MediumGray1};
    }

    .sort-arrow {
      background-color: transparent !important;
    }

    img {
      width: 48px;
      height: auto;
      vertical-align: middle;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;