import styled, {css} from 'styled-components';
import {Dropdown} from "../uikit/index";
import Pagination from 'rc-pagination';
import {
  Aladin_BG,
  BitterSweet1,
  BlueJeans, BlueJeans1, DarkGray, DarkGray1, Grass, Grass1, LightGray, LightGray1, MediumGray, MediumGray1,
  PrimaryDarkBlue
} from "../app/StyleCommon";

export const WidgetHistoryWrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  min-height: calc(100vh - 50px);
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  @media screen and (max-width: 667px) {
    padding: 20px 10px;
  }
  > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 16px;
      vertical-align: middle;
    }
  }
`;


export const RevisionList = styled.div`
  border-top: 1px solid ${LightGray1};
  > ul > li {
    //border-left: 1px solid ${MediumGray1};
    padding: 20px 10px 10px 20px;
    min-height: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    @media screen and (max-width: 667px) {
      padding: 10px 0px;
      .time {
        width: 80px !important;
        margin-right: 10px !important;
        padding-left: 5px !important;
        font-size: 12px;
        i {
          display: none;
        }
      }
      .basic {
        padding: 0 !important;
        align-items: flex-start !important;
        .brief {
          display: block;
          margin-left: 0 !important;
        }
      }
      button {
        margin-left: 10px !important;
      }
    }
    &:after {
      display: block;
      content: '';
      height: 0px;
      border-bottom: 1px dashed ${MediumGray}
      position: absolute;
      left: 250px;
      right: 20px;
      bottom: 10px;
      @media screen and (max-width: 667px) {
        left: 90px;
      }
    }
    &:before {
      content: '';
      width: 0px;
      border-left: 1px dashed ${MediumGray};
      position: absolute;
      top: 45px;
      left: 100px;
      bottom: -15px;
      @media screen and (max-width: 667px) {
        left: 50px;
      }
    }
    &.day-break:before {
      top: 60px;
      bottom: -20;
    }
    .time {
      width: 170px;
      text-align: center
      background-color: white;
      margin-right: 60px;
      font-weight: bold;
      white-space: nowrap;
      position: relative;
      flex: none;
      i {
        color: ${MediumGray1};
      }
      &.day-break-time {
        border-radius: 5px;
        padding: 5px;
        padding-left: 50px;
        text-align: left;
        box-sizing: border-box;
        background: ${Aladin_BG};
        border-radius: 5px;
        .time1 {
          font-size: 12px;
          font-weight: normal;
        }
        i {
          position: absolute;
          left: 20px;
          top: 8px;
          color: ${DarkGray};
        }
      }
      &.successive {
        font-weight: normal;
        margin-left: 35px;
        margin-right: 25px;
      }
    }
    .info {
      font-size: 12px;
      width: 90%;
      overflow-x:auto;
      .basic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        cursor: pointer;
        transition: all .3s ease;
        background-color: ${LightGray};
        button {
          margin-left: 30px;
        }
        strong {
          font-weight: normal
          color: ${DarkGray1};
        }
        .brief {
          margin-left: 10px;
          .remove {
            color: ${BitterSweet1};
          }
          .add {
            color: ${Grass};
          }
          .replace {
            color: ${BlueJeans};
          }
        }
      }
    }
    pre {
      //max-width: 90%;
    }
    code {
      font-size: 12px;
    }

  }
`

export const DetailView = styled.div`
  cursor: text;
  width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
`
export const NoData = styled.div`
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DarkGray};
  a {
    display: inline-block;
    margin: 0 10px;
  }
`
export const ShowMore = styled.div`
  margin: 40px 10px 20px 60px;
  i {
    margin-left: 5px;
  }
  a  {
    cursor: pointer;
    color: ${BlueJeans1};
  }
`
export const CampaignDetails = styled.div`
  // background-color: ${LightGray};
  padding: 2px;
  .attr {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    @media screen and (max-width: 667px) {
      display: block;
      background: #fafbfc;
      border: 1px solid #f1f2f3;
      padding: 5px;
      >label {
        width: 100% !important;
        text-align: left !important;
      }
      > div {
        width: 100%;
        padding: 5px 0 !important;
      }
    }
    > label {
      flex: none;
      display: inline-block;
      width: 200px;
      text-align: left;
      margin-right: 20px;
      margin-left: 0px;
      padding: 5px 20px;
      color: ${DarkGray1}
      height: 100%;
      font-size: 13px;
      background: ${Aladin_BG};
    }
    .attr-value {
      padding: 5px 10px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      word-break: break-all;
      .old {
        color: ${MediumGray1};
        text-decoration: line-through;
        margin: 10px 20px;
      }
      .new {
        margin: 10px 20px;
      }
      .tag-item {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${LightGray};
      }
      .client-attr {
        dispkay: flex;
        flex-direction: column;
        b {
          margin-left: 3px;
          font-weight: normal;
        }
        span {
          margin-right: 4px;
        }
      }
    }
  }
`
