import React from 'react';
import _ from 'lodash';
import {visualisations, visualisationsForReach, overallMetrics} from "../charts/index";
import {metricMetaData, dimensionLabelMap} from "../utils/metadata";
import { getClientMetrics } from "../campaigns/clientData.common";

const generateDashboardContext = (data) => {
  const {campaign } = data;
  const {client_dimensions = [], client_kpi_metrics = [], client_metrics = []} = campaign || {};
  let clientMetricsData = getClientMetrics(client_metrics) || [];
  let clientKPIMetricsData = [];
  if (campaign) {
    clientKPIMetricsData = getClientMetrics(client_kpi_metrics) || [];
  }
  let mixedMetaData = _.uniqBy([...metricMetaData, ...clientMetricsData, ...clientKPIMetricsData], function (e) {
    return e.key;
  });
  let mixedVisualisations = generateVisualization(data, mixedMetaData, clientMetricsData, clientKPIMetricsData, client_dimensions);
  let mixedLabelMap = mixedMetricMap(mixedMetaData);
  let allMixedLabelMap = mixedMetricMap([...mixedMetaData, ...client_dimensions]);
  let mixedDimensionsLabelMap = dimensionMixedLabelsMap([...mixedMetaData, ...client_dimensions]);
  return {
    mixedVisualisations, mixedMetaData, clientKPIMetricsData, client_dimensions, allMixedLabelMap, mixedLabelMap, mixedDimensionsLabelMap
  }
}

const generateVisualization = (data, metadata = [], clientMetricsData = [], clientKPIMetricsData = [], dimensions = []) => {
  let ret = visualisations;
  let {report = {}, campaign} = data;
  if(!campaign) {
    campaign = ((report.datasource || {}).campaigns ||[])[0];
  }
  if(campaign) {
    ret = visualisationsForClientData(metadata, clientMetricsData, clientKPIMetricsData,  dimensions);
    if(campaign.type === 'guaranteed_reach' || campaign.type === 'blended_cpv'){
      ret = visualisationsForReach;
    }
  }
  // if(campaign && campaign.type !== 'guaranteed_page_views') {
  //   ret = ret.filter(d => d.key !== 'keymetric_pv');
  // }
  ret = _.uniqBy(ret, function (e) {
    return e.key;
  });
  return ret;
}

export const visualisationsForClientData = (clientMetaData, clientMetricsData = [], clientKPIMetricsData = [], client_dimensions = []) => {
  let clientDataMetrics = [...clientMetricsData, ...clientKPIMetricsData].filter(d => d.key !== 'client_date').map(d => d.key);
  const clientMetricMetaData = [...metricMetaData, ...clientMetricsData, ...clientKPIMetricsData];
  let clientDataMetricMap = mixedMetricMap(clientMetricsData);

  let visualisation = [..._.cloneDeep(visualisations)].map(vis => {
    if(vis.supportClientData) {
      let checkAndSet = (vis, field, ext) => {
        if(Array.isArray(vis[field]) && (vis.supportClientDataExceptions || []).indexOf(field) < 0) {
          vis[field] = vis[field].concat(ext);
        }
      };
      if(vis.dimensionOptionsWithClient) {
        vis.dimensionOptions = vis.dimensionOptionsWithClient;
      }
      if(vis.hideClientMetricifNoClientDimension && client_dimensions.length > 0) {
        vis.hideClientMetricifNoClientDimension = false;
      }
      !vis.hideClientMetricifNoClientDimension && checkAndSet(vis, 'metricOptions' , clientDataMetrics);
      !vis.hideClientMetricifNoClientDimension && checkAndSet(vis, 'secondaryMetricOptions' , clientDataMetrics);
      checkAndSet(vis, 'dimensionOptions' , client_dimensions.map(d => d.key));
      checkAndSet(vis, 'secondaryDimensionOptions' , client_dimensions.map(d => d.key));
    }
    return vis
  });

  return [...visualisation, ...clientDataMetrics.map(metric => {
    let example = _.cloneDeep(visualisations.find(d => d.key === 'keymetric_impressions'));
    return {
    ...example,
    key: `keymetric_${metric}`,
    cfg: {...example.cfg, metric: `${metric}`},
    type: 'client',
    supportClientData: true,
    metricOptions: [...example.metricOptions, ...clientDataMetrics],
    demoData: {value: clientMetricMetaData.eg || 10000},
    searchKeywords: [metric, clientDataMetricMap[metric]].map(s => s?.toLowerCase()).join(' ')
    }
  })]
}

const dimensionMixedLabelsMap = (data) => {
  let labels = {};
  data.forEach(obj => {
    labels[obj.key]= obj.label
  })
  return {...dimensionLabelMap, ...labels};
};

const mixedMetricMap = (data) => {
  let metricData = mixedMetricMetaData(data);
  return metricData.reduce((ret, next) => {
    return {...ret, [next.key]: next.label};
}, {})
}

const mixedMetricMetaData = (data) => {
  return [...metricMetaData, ...data]
}


export {
  generateDashboardContext
};