import styled, {css} from 'styled-components';

export const GridContainer = styled.div.attrs({
  'data-rp': 'simple-grid'
})`
  margin-bottom: 15px;
  ${props => props.noMargin && css`
    margin-bottom: 0;
  `}
  ${props => props.type === '1-1-1-1' && css`
    > div {
      margin-bottom: 10px;
      width: calc(25% - 15px);
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:not(:last-child) {
        margin-right: 20px;
      }
      @media (max-width: 992px) {
        width: calc(50% - 10px);
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      @media (max-width: 667px) {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  `}
  ${props => props.type === '1-1-1-1-1' && css`
    > div {
      margin-bottom: 10px;
      width: calc(20% - 10px);
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:not(:last-child) {
        margin-right: 12px;
      }
      @media (max-width: 992px) {
        width: calc(50% - 10px);
        &:nth-child(odd) {
          margin-right: 12px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      @media (max-width: 667px) {
        width: 100%;
        margin-right: 0;
      }
    }
  `}

  ${props => props.type === '1-1-1-1-1-1' && css`
    > div {
      margin-bottom: 10px;
      width: calc(12% - 16px);
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:not(:last-child) {
        margin-right: 20px;
      }
      @media (max-width: 992px) {
        width: calc(33% - 10px);
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      @media (max-width: 667px) {
        width: 100%;
        margin-right: 0;
      }
    }
  `}

  ${props => props.type === '1-1-1' && css`
    > div {
      margin-bottom: 10px;
      width: calc(33.333% - 13.33px);
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:not(:nth-child(3n+3)) {
        margin-right: 20px;
      }
      @media (max-width: 992px) {
        width: calc(50% - 10px);
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      @media (max-width: 667px) {
        width: 100%;
        margin-right: 0;
      }
    }
  `}

  ${props => props.type === '1-1' && css`
    > div, >fieldset {
      margin: 0;
      box-sizing: border-box;
      margin-bottom: 10px;
      width: calc(50% - 10px);
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:not(:nth-child(2n)) {
        margin-right: 20px;
      }
      @media (max-width: 992px) {
        width: 100%;
        margin-right: 0;
      }
    }
    >fieldset {
      padding: 5px 5px;
      &:first-child {
        margin-right: 18px;
      }
    }
  `}
  ${props => props.type === '1-2' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:first-child {
        width: calc(33.3% - 10px);
        margin-right: 20px;
      }
      &:last-child {
        width: calc(66.6% - 10px);
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '2-1' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:first-child {
        width: calc(66.6% - 10px);
        margin-right: 20px;
      }
      &:last-child {
        width: calc(33.3% - 10px);
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '3-7' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:first-child {
        width: calc(30% - 10px);
        margin-right: 20px;
      }
      &:last-child {
        width: calc(70% - 10px);
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '1-3' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:first-child {
        width: calc(25% - 10px);
        margin-right: 20px;
      }
      &:last-child {
        width: calc(75% - 10px);
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '3-1' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      &:first-child {
        width: calc(75% - 10px);
        margin-right: 20px;
      }
      &:last-child {
        width: calc(25% - 10px);
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '1-1-2' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      width: calc(25% - 10px);
      margin-right: 10px;
      &:last-child {
        width: calc(50% - 10px);
        margin-right: 0;
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '1-2-1' && css`
    > div {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: top;
      flex: none;
      width: calc(50% - 10px);
      margin-right: 10px;
      &:first-child {
        width: calc(25% - 10px);
      }
      &:last-child {
        width: calc(25% - 10px);
        margin-right: 0;
      }
      @media (max-width: 900px) {
        width: 100% !important;
        margin-right: 0;
      }
    }
  `}
  ${props => props.type === '1' && css`
    > div {
       margin-bottom: 10px;
       width: 100%;
       box-sizing: border-box;
    }
  `}
`;