import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {MonthPanelWrapper} from './index.style';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.receiveProps(props);
  }

  componentWillReceiveProps(props) {
    this.setState(this.receiveProps(props));
  }

  receiveProps(props) {
    return {
      date: props.date,
      period: props.period
    };
  }

  render() {
    const {name = 'firstPanel', boundry, boundryPeriod, futureDateAllowed, dateRenderer, freeNav} = this.props
    var ankerDate = moment(this.state.date);
    var weekdays = moment.weekdaysShort();
    weekdays.push(weekdays.shift()); // moment use Sun as the first day
    var monthday = ankerDate.format('MMM YYYY');
    var month = ankerDate.format('MMMM');
    var year = ankerDate.format('YYYY');
    var period = this.state.period;
    let {dragingDates = []} = this.props;
    if (dragingDates.length > 1) { //we are in the middle of dragging selection.
      let [start, end] = [dragingDates[0], dragingDates[dragingDates.length - 1]].sort();
      period = {start, end};
    }

    var prevMonthEnabled = true, nextMonthEnabled = true;
    if (name === 'firstPanel') {
      nextMonthEnabled = freeNav || moment(ankerDate).endOf('month').add(1, 'month') < moment(boundry).startOf('month');
    } else if (name === 'secondPanel') {
      prevMonthEnabled = freeNav || moment(ankerDate).startOf('month').add(-1, 'month') > moment(boundry).endOf('month');
      nextMonthEnabled = freeNav || futureDateAllowed || moment(ankerDate).startOf('month') < moment().startOf('month');
    }

    var today = moment().format('YYYY-MM-DD');
    var date0 = ankerDate.clone().date(1).startOf('isoWeek');
    // var date1 = ankerDate.clone().endOf('month').endOf('isoWeek');
    var date1 = ankerDate.clone().endOf('month');
    var rows = [], row = [];
    while (date0 < date1) {
      if (row.length === 7) {
        row = [];
      }
      if (row.length === 0) {
        rows.push(row);
      }
      var dayItem = {
        date: date0.format('YYYY-MM-DD'),
        displayValue: date0.date(),
        valid: date0.month() === ankerDate.month(),
        inCurrentMonth: date0.month() === ankerDate.month(),
      };
      if (dayItem.date > today && !this.props.futureDateAllowed) {
        dayItem.valid = false;
      }
      if (dayItem.date < today && !this.props.historyDateAllowed) {
        dayItem.valid = false;
      }
      dayItem.isInboundry = boundryPeriod && dayItem.date >= boundryPeriod.start && dayItem.date <= boundryPeriod.end;

      dayItem.isToday = dayItem.date === today;
      if (period) {
        var periodStart = moment(period.start);
        var periodEnd = moment(period.end);
        if (period.start && period.end) {
          dayItem.selected = date0 <= periodEnd && date0 >= periodStart;
          dayItem.isRangeStart = date0 >= periodStart && date0 <= periodStart;
          dayItem.isRangeEnd = date0 >= periodEnd && date0 <= periodEnd;
        } else if (period.start) {
          dayItem.selected = date0 <= periodStart && date0 >= periodStart;
          dayItem.isRangeStart = dayItem.selected;
        }
      }
      dayItem.seleected = dayItem.seleected || (dragingDates[0] === dayItem.date);
      row.push(dayItem);
      date0.add(1, 'days');
    }

    return (
      <MonthPanelWrapper className={classNames('month-panel', this.props.theme)}>
        <div className="month-nav">
          <a className={`month-nav-btn prevMonth ${prevMonthEnabled ? '' : 'disabled'}`}
             onClick={e => this.navMonth(-1)}>
            {'<'}
          </a>
          {monthday}
          <a className={`month-nav-btn nextMonth ${nextMonthEnabled ? '' : 'disabled'}`}
             onClick={e => this.navMonth(1)}>
            {'>'}
          </a>
        </div>
        <table>
          <thead>
          <tr>
            {weekdays.map(weekday => {
              return <th key={weekday} className="weekday">{weekday.slice(0, 2)}</th>;
            })}
          </tr>
          </thead>
          <tbody>
          {rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {row.map((item, cIndex) => {
                  const isAnker = item.isRangeStart || item.isRangeEnd;
                  let showLeftIndicate = isAnker && dragingDates.length && (!dragingDates[1] || dragingDates[1] < dragingDates[0]);
                  let showRightIndicate = isAnker && dragingDates.length && (!dragingDates[1] || dragingDates[1] > dragingDates[0]);
                  let showEnd = item.isRangeEnd;
                  let showStart = item.isRangeStart;
                  if(item.isRangeEnd && dragingDates.length > 1 && dragingDates[1] > dragingDates[0]) {
                    showRightIndicate = false;
                    showEnd = false;
                  }
                  if(item.isRangeStart && dragingDates.length > 1 && dragingDates[1] < dragingDates[0]) {
                    showLeftIndicate = false;
                    showStart = false;
                  }

                  return (
                    <td key={cIndex}
                        onClick={() => this.props.onDateSelected(item.date)}
                        // onMouseDown={() => this.props.onDragSelection(item.date, 'start')}
                        onMouseOver={() => this.props.onDragSelection(item.date, 'update')}
                        // onMouseUp={() => this.props.onDragSelection(item.date, 'end')}
                        className={classNames('date', {
                          today: item.isToday,
                          invalid: !item.valid,
                          inCurrentMonth: !!item.inCurrentMonth,
                          inboundry: !!item.isInboundry,
                          selected: item.selected,
                          start: showStart,
                          end: showEnd,
                          showLeftIndicate: showLeftIndicate,
                          showRightIndicate: showRightIndicate,
                          [`d${item.date}`]: true
                        })}>
                      {!dateRenderer && item.displayValue}
                      {!!dateRenderer && dateRenderer(item.date, item.displayValue, cIndex)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </MonthPanelWrapper>
    );
  }

  navMonth(month) {
    let newMoment = moment(this.state.date);
    if (typeof month !== 'string') {
      newMoment.add(month, 'month');
    } else {
      newMoment.set('month', month);
    }
    let newDate = newMoment.format('YYYY-MM-DD');
    this.setState({date: newDate});
    this.props.onMonthUpdated(newDate);
  }
}