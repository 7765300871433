import styled, {css} from 'styled-components';
import {BitterSweet1, DarkGray, DarkGray1, LightGray, LightGray1, Sunflower} from "../../app/StyleCommon";

export const ErrorBoxWrapper = styled.div.attrs({
  'data-rp': 'error-box'
})`
  border: 1px solid ${LightGray1};
  background-color: ${LightGray1};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  color: ${BitterSweet1};
  margin: 5px 0;
  img {
    width: 24px;
    height: auto;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const InfoWrapper = styled.div`
  padding: 5px 10px;
  background-color: #d9edf7;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  color: #31708f;
  margin: 10px 0;
  ${props => props.theme === 'success' && css`
    background-color: #e0f7c7;
    border: 1px solid #bbda99;
  `}
  ${props => props.theme === 'warning' && css`
    background-color: #ffeec4;
    border: 1px solid ${Sunflower};
    color: ${DarkGray1};
  `}
  ${props => props.theme === 'normal' && css`
    background-color: #d9edf7;
    font-size: 14px;
  `}
  button {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: underline !important;
  }
  img {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  i {
    vertical-align: middle;
  }
  i.info {
    font-size: 18px;
    margin-right: 8px;
  }
  a {
    text-decoration: underline !important;
    cursor: pointer;
    color: #31708f !important;
    font-weight: 600;
    margin: 0 5px;
  }
  .checkbox-item {
    margin-left: 10px;
    input {
      margin-right: 5px;
    }
  }
`