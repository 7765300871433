import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {StyledTable} from "./index.style";
import {Transition} from '../index';
import Sortable from '../Sortable/index';
import {Resizable} from 're-resizable';
import classNames from "classnames";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resizePositions: {}
    }
  }
  render() {
    const {
      columns,
      rows = [],
      sort: {sortColumn, direction} = {},
      noDataText = 'No Data',
      sortChange,
      directionChange,
      placeHolderRowCount,
      rowClicker,
      rowSortable,
      onReplacePosition,
      rowEventHandlers,
      headerGroups,
      context,
      isDangeouslyBinded = false,
      resizable = false,
    } = this.props;

    const {resizePositions} = this.state;

    let placeHolderRows = [];
    if (placeHolderRowCount && (placeHolderRowCount - rows.length) > 0) {
      placeHolderRows = _.range(0, placeHolderRowCount - rows.length);
    }
    const THead = rowSortable ? Sortable : props => <thead {...props}>{props.children}</thead>;
    const {onMouseOver, onMouseOut} = this.props.rowEventHandlers || {};
    let visibleColumns = columns.filter(r => !r.hidden);
    return (
      <StyledTable cellspacing="0" className={this.props.className}>
        <THead tag="thead" onReplacePosition={(index1, index2) => {
          if(onReplacePosition) {
            if(Math.abs(index2 - index1) === 1) {
              onReplacePosition((visibleColumns[index1].key), (visibleColumns[index2].key), 'swap');
            } else {
              onReplacePosition((visibleColumns[index1].key), (visibleColumns[index2].key), 'insert');
            }
          }
        }}>
          {
            !!headerGroups &&
            <tr>
              {headerGroups.map(r => (
                <th key={r.key} colSpan={r.colSpan}>{r.label}</th>
              ))}
            </tr>
          }
          <tr>
            {columns.filter(r => !r.hidden).map((column, columnIndex) => {
              let columnPos = resizePositions[column.key];
              if(!!columnPos) {
                column.style = column.style || {};
                column.style = {...column.style, maxWidth: columnPos};
                console.log('maxWidth', column.style.maxWidth);
              }
              return (
                <th key={column.key + columnIndex}
                    style={column.style}
                    draggable={rowSortable}
                    className={classNames(
                      'sortable-item',
                      (column.align || '') + (column.sortable ? ' sortable' : ''),
                      column.align,
                      {sortable: column.sortable, isSortColumn: sortColumn === column.key})}
                    onClick={e => {
                      if (column.sortable && sortChange && sortColumn !== column.key) {
                        sortChange(column.key);
                      }
                      if (column.sortable && directionChange && sortColumn === column.key) {
                        directionChange(direction === 'desc' ? 'asc' : 'desc');
                      }
                    }}>
                  <Resizable
                    size={{}}
                    minWidth={50}
                    className="resizable"
                    enable={{
                      top: false,
                      right: resizable && true,
                      bottom: false,
                      left: false,
                      topRight: false,
                      bottomRight: false,
                      bottomLeft: false,
                      topLeft: false
                    }}
                    handleClasses={{right: 'resizeHandler'}}
                    onResizeStart={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onResizeStop={(e, dir, refToElement, delta) => {
                      this.setState({resizePositions: {
                          ...resizePositions,
                          // [column.key]: columnPos + delta.width
                          [column.key]: refToElement.style.width
                        }});
                    }}>
                    {column.label}
                    {
                      sortColumn === column.key &&
                      <div className={`sort-arrow ${direction || 'asc'}`}>
                        <i className="sort-icon fa fa-caret-up"/>
                        <i className="sort-icon fa fa-caret-down"/>
                      </div>
                    }
                  </Resizable>
                </th>
              )
            })}
          </tr>
        {/*<tr><th style={{textAlign: 'right'}}>Roles</th><th colSpan={7} style={{verticalAlign: 'bottom', borderBottom: '1px solid #f1f2f3'}}></th></tr>*/}
        </THead>
        <tbody>
        {rows.map((row, index) => (

          <tr className={row.uiClassName} key={row.key + index}
              onClick={e => rowClicker && rowClicker(row)}
              onMouseOver={e => onMouseOver && onMouseOver(e, row)}
              onMouseOut={e => onMouseOut && onMouseOut(e, row)}
          >
            {columns.filter(r => !r.hidden).map((column, columnIndex) => (
              isDangeouslyBinded ?
              <td key={column.key + columnIndex + row[column.key]} className={column.align} style={column.style} dangerouslySetInnerHTML={{__html: column.renderer ? column.renderer(row, index, context) : row[column.key]}}></td> :
              <td key={column.key + columnIndex + row[column.key]} className={column.align} style={column.style}>
                {column.renderer ? column.renderer(row, index, context) : row[column.key]}
              </td>
            ))}
          </tr>
        ))}
        {placeHolderRows.map((row, index) => (
          <tr key={'placeholder' + index} className="placeholder">
            {columns.filter(r => !r.hidden).map((column, index) => (
              <td key={column.key}>&nbsp;</td>
            ))}
          </tr>
        ))}
        {
          !rows.length &&
          <tr className="no-data">
            <td colSpan={columns.filter(d => !d.hidden).length}>{noDataText}</td>
          </tr>
        }
        </tbody>
      </StyledTable>
    )
  }
}
