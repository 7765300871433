import React from 'react';
import {Link} from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';
import {platformLabelMap} from '../utils/metadata';
import createTooltip, {defaultTooltip, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";


export const supportedColumns=[
    {
      key: 'name',
      label: 'Report Name',
      align: 'left',
      sortable: true,
      filterOps: ['$match', '$nmatch'],
      style: {verticalAlign: 'top', paddingTop: '12px'},
      renderer: (d, i, context) => {
        const {session} = context;
        let users = _.uniq(_.flattenDeep(d.shares || []).map(s => s.users || []));
        users = users.filter(email => email !== session.userId);
        let canView = session.rights.indexOf('View Report') >= 0;
        let canBuildReport = session.rights.indexOf('Build Report') >= 0;
        let hasWriteAccess = (d.creator === session.userId || (d.shares ||[]).some(s => s.users.indexOf(session.userId) >= 0));
        let {campaigns = [], benchmark_campaigns = []} = d.datasource || {};
        let campaign = campaigns[0] || {};
        let benchmark = benchmark_campaigns[0];
        return (
          <div className="report-name">
            {canBuildReport && hasWriteAccess && <Link to={`/report/builder?id=${d.id}`}>{d.name || 'Untitled Report'}</Link>}
            {canView && (!canBuildReport || !hasWriteAccess) && <Link to={`/report/readonly?id=${d.id}`}>{d.name  || 'Untitled Report'}</Link>}
            {!canView && (!canBuildReport || !hasWriteAccess) && <a>{d.name || 'Untitled Report'}</a>}

            <div className="desc">
              {(campaign.platform_keys || []).map(k => platformLabelMap[k] || k).join(', ')}
              {!!users.length && <span className="desc">, Shared to {users.length} users.</span>}
            </div>
          </div>
        );
      }
    },
    {
      key: 'datasource.campaigns',
      label: 'Campaign ID',
      align: 'left',
      sortable: true,
      filterOps: ['$match', '$nmatch'],
      style: {verticalAlign: 'top', paddingTop: '12px'},
      renderer: (d, i) => {
        let {campaigns = [], benchmark_campaigns = []} = d.datasource || {};
        let campaign = campaigns[0];
        let benchmark = benchmark_campaigns[0];
        return (
          <div className="campaign-info">
            {!campaign && <div>No Campaign Seleted.</div>}
            {!!campaign && <Link to={`/campaign/details?id=${campaign.id}`}>{campaign.name} ({campaign.id})</Link>}
            <div className="desc">
              {!!campaign && <span className="desc">Period: {moment(d.start_date).format('MMM DD YYYY')} - {moment(d.end_date).format('MMM DD YYYY')}</span>}
            </div>
            {!!benchmark && <div className="desc">benchmark: <Link to={`/campaign/details?id=${campaign.id}`}>{benchmark.name}</Link></div>}
          </div>
        )
      }
    },
    {
      key: 'create_time',
      sortable: true,
      label: 'Created Date',
      align: 'left',
      filterOps: ['$lt', '$gt'],
      datatype: 'datetime',
      renderer: d => {
        return (
          <div className="time">
            <div>{moment(d.create_time).format('MMM DD hh:mm A')}</div>
            <div className="desc">{moment(d.create_time).fromNow()}</div>
          </div>
        )
      }
    },
    {
      key: 'update_time',
      sortable: true,
      label: 'Last Updated Date',
      align: 'left',
      filterOps: ['$lt', '$gt'],
      datatype: 'datetime',
      renderer: d => {
        return (
          <div className="time">
            <div>{moment(d.update_time).format('MMM DD hh:mm A')}</div>
            <div className="desc">{moment(d.update_time).fromNow()}</div>
          </div>
        )
      }
    },
    {
      key: 'creator',
      sortable: true,
      label: 'Creator',
      align: 'left',
      filterOps: ['$match', '$nmatch'],
      renderer: d => {
        let tooltip = createTooltip({
          targetSelector: 'dynamic',
          tipCreator: d => d.creator,
          directionFunc: 'top'
        });
        return <span
          onMouseOver={tooltip.onMouseOver(d)}
          onMouseLeave={tooltip.onMouseOut()}>
            {(d.creatorName)}
          </span>
      }
    }
]