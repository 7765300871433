import React from 'react'
import _ from 'lodash'
import moment from "moment";
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './index.style';
import {Table, TableScrollWrapper, TabContainer, Select, Btn, ProgressBar, Loader} from "../uikit/index";
import {TableController} from "./index.style";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter, TableController1} from "../campaigns/index.style";
import FilterBox from "../campaigns/FilterBox";
import {Link} from "react-router-dom";
import saveAs from "file-saver";
import {post} from "../utils/request";
import {defaultTooltip} from "../uikit/tooltip";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import styled from "styled-components";
import {Aladin_Orange_Dark} from "../app/StyleCommon";
import { paginationValues } from '../utils/metadata';

const LiveStreamWrapper = styled.div`
  min-height: 420px;
  .data-table {
    max-height: 400px;
    overflow: auto;
    thead th{
      background: white;
      border-bottom: 1px solid ${Aladin_Orange_Dark};
    }
  }
`

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      PAGE_SIZE: 10,
      page: 1,
    }

    this.searchDebounce = _.debounce(this.queryAgain.bind(this), 500);
  }
  render() {
    const {data, loading, error, filters, keyword, PAGE_SIZE, page, sortBy, sortDirection} = this.state;
    const {alerts, count} = data || {};

    const {session} = this.props.appState;

    return (
      <LiveStreamWrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryStream`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          loading && <Loader/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !!data &&
          <>
          {/*<TableController1 whiteBG={true}>*/}
          {/*  <div className="searchBox" style={{width: '100%'}}>*/}
          {/*    <i className="fa fa-search"/>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      placeholder="Search..."*/}
          {/*      value={keyword}*/}
          {/*      onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.searchDebounce())}/>*/}
          {/*  </div>*/}
          {/*</TableController1>*/}
          <div className="data-table">
            <Table
              context={{page, PAGE_SIZE}}
              columns={[
                {
                  key: 'index',
                  label: '',
                  align: 'left',
                  sortable: true,
                  style: {maxWidth: '20px'},
                  renderer: (d, i) => (PAGE_SIZE * (page - 1) + i + 1)
                },
                {
                  key: 'type',
                  label: 'Alert Type',
                  align: 'left',
                  sortable: true,
                },
                {
                  key: 'title',
                  label: 'Title',
                  align: 'left',
                  sortable: true,
                  renderer: d => {
                    if(d.click_through_link) {
                      return `<a href="${d.click_through_link}" style="text-decoration: none">${d.title}</a>`;
                    }else {
                      return d.title
                    }
                  }
                },
                {
                  key: 'details',
                  label: 'Details',
                  align: 'left',
                  sortable: true,
                },
                {
                  key: 'date',
                  label: 'Date',
                  align: 'left',
                  sortable: true,
                },
              ]}
              rows={alerts || []}
              sort={{sortColumn: sortBy, direction: sortDirection}}
              sortChange={sortBy => {
                this.setState({sortBy: sortBy}, () => this.queryAgain());
              }}
              directionChange={direction => {
                this.setState({sortDirection: direction}, () => this.queryAgain());
              }}
              noDataText={<div style={{height: '110px', marginTop: '50px'}}>No data</div>}
              isDangeouslyBinded={true}
            />
          </div>
            <ListFooter>
              <span className="info">
                <strong>{count}</strong> items found.
                Show
                <select
                  value={PAGE_SIZE}
                  onChange={e => this.setState({
                    data: null,
                    PAGE_SIZE: Number(e.target.value),
                    page: 1,
                  })}>
                {paginationValues.map(d => {
                  return (
                    <option key={d} value={d}>{d}</option>
                  )
                })}
                </select>
                per page
            </span>
              <PaginationStyled
                onChange={p => this.setState({page: p, data: null})}
                current={page}
                pageSize={PAGE_SIZE}
                total={count}
                hideOnSinglePage={false}/>
            </ListFooter>
          </>
        }
      </LiveStreamWrapper>
    )
  }

  queryAgain(){
    this.setState({loading: true});
    return post(`/api/queryStream`, this.buildQuery()).then(data => {
      this.setState({data: data, loading: false});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }

  buildQuery() {
    const {page, PAGE_SIZE, keyword, filters, sortBy, sortDirection} = this.state;
    const query = {
      keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filters,
      sortBy,
      sortDirection
    };
    return query;
  }
})