import React, {Component} from 'react';
import {ChartWrapper} from "./chat.style";

export default class extends Component {

  render() {
    return (
      <ChartWrapper>
        <div className="chat-header">
          <span>Aladdin Copilot</span>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>

        <div className="message message-right">What is the campaign budget.</div>
        <div className="message message-left"><i className="fa fa-signal" aria-hidden="true"></i> The campaign budget is as follows: TV: S$101,830, Digital: S$77,740, Radio: S$1,317,039, Total: $2,300,000.</div>

        <div className="message message-right">Add a gender distribution chart to the digital report.</div>
        <div className="message message-left"><i className="fa fa-signal" aria-hidden="true"></i>A new chart has been added to the dashboard.</div>

        <div className="message message-right">Please suggest an optimised TV spot allocation to maximize overall impressions per spot.</div>
        <div className="message message-left"><i className="fa fa-file-excel-o" aria-hidden="true"></i> An optimised TV spot schedule CSV has been generated</div>

        <div className="chat-box">
          <div className="chat-input">
            <textarea placeholder="Ask a question or request, or type '/' for suggestions">

            </textarea>
            <div className="fly">
              <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
            </div>
          </div>
          <p>AI-generated content can  have mistakes, make sure it’s accurate and appropriate before using it.</p>
        </div>
      </ChartWrapper>
    );
  }
}