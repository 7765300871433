import React from 'react'
import _ from 'lodash'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './index.style';
import {Table, TableScrollWrapper, TabContainer, Select, Btn, ProgressBar, CondirmDialog, Loader} from "../uikit/index";
import {supportedColumns} from "./users.columns";
import moment from "moment/moment";
import {MediumGray1} from "../app/StyleCommon";
import {Link} from "react-router-dom";
import {roleMatrix, roles, demoUserList} from "./metadata";
import {TableController} from "./index.style";
import {post} from "../utils/request";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ColumnSelector, KeyMetrics, ListFooter, TableController1} from "../campaigns/index.style";
import FilterBox from "../campaigns/FilterBox";
import classNames from "classnames";
import {intFormatter, uniquesFormatter} from "../utils/formatter";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {cacheResource, getFromCache} from "../utils/cache";
import { paginationValues } from '../utils/metadata';

const USER_LIST_CACHE_KEY = 'USER_LIST_CACHE_KEY';
export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(USER_LIST_CACHE_KEY) || {};
    const queryParams = new URLSearchParams(window.location.search);
    const searchterm = queryParams.get("search");
    this.state = {
      page: 1,
      PAGE_SIZE: 10,
      sortBy: 'register_date',
      sortDirection: 'desc',
      filters: [],
      ...cachedSettings
    }
    if (searchterm && searchterm !== "") {
      this.state.keyword = searchterm;
    }
    this.searchDebounce = _.debounce(this.queryAgain.bind(this), 500);
  }

  cacheParams() {
    cacheResource(USER_LIST_CACHE_KEY, _.pick(this.state, ['page', 'PAGE_SIZE', 'sortBy', 'filters', 'sortDirection', 'keyword']))
  }

  render() {
    const {data, loading, error, keyword, filters, PAGE_SIZE, page, sortBy, sortDirection} = this.state;
    const {total, users, filterMetadata} = data || {};
    const {session} = this.props.appState;
    let canViewList = session.rights.indexOf('View System User List') >= 0;
    let canCreateUser = session.rights.indexOf('Create User') >= 0;
    let canModifyUser = session.rights.indexOf('Modify User') >= 0;
    let canDeleteUser = session.rights.indexOf('Delete User') >= 0;

    return (
      <div className="user-management-module">
        {
          !data && !error && canViewList &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryUserList`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          loading && <Loader/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !canViewList && <ErrorMessage message={'Not Allowed'}/>
        }
        {
          !!data &&
          <>
            <TableController1 whiteBG={true}>
              <FilterBox
                filters={filters}
                filterMetadata={filterMetadata}
                supportedColumns={supportedColumns}
                onChange={newFilters => this.setState({filters: newFilters, page: 1}, () => {
                  this.queryAgain();
                  this.cacheParams();
                })} />
              <div className="searchBox">
                <i className="fa fa-search"/>
                <input
                  type="text"
                  placeholder="Search..."
                  value={keyword}
                  onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.searchDebounce())}/>
                {this.state.keyword && this.state.keyword !== "" ? <i class="fa fa-times" aria-hidden="true" onClick={() => this.setState({keyword: '', page: 1}, () => this.searchDebounce())}></i>: null}
              </div>
              {/*<a onClick={e => null} style={{cursor: 'pointer'}}>*/}
              {/*  <span className="material-symbols-outlined">download</span>*/}
              {/*</a>*/}
              {
                <Link to={'/admin/user/create'}>
                  <Btn disabled={!canCreateUser} type="primary"><i className="fa fa-plus"/> Create User</Btn>
                </Link>
              }
            </TableController1>

          <TableScrollWrapper className="user-table">
            <Table
              context={{page, PAGE_SIZE, session}}
              columns={[
                ...supportedColumns,
                {
                  key: 'action',
                  label: 'Action',
                  align: 'center',
                  sortable: true,
                  renderer: d => {
                    if(!canDeleteUser && !canModifyUser) {
                      return '--'
                    }
                    return (
                      <div className="actions">
                        {canDeleteUser && <a onClick={e => this.deleteUser(d)}><i className="material-symbols-outlined">delete</i></a>}
                        {canModifyUser && <Link to={`/admin/user/settings?id=${d.id}`}><i className="material-symbols-outlined">edit_note</i></Link>}
                      </div>
                    )
                  }
                },
              ]}
              rows={users || []}
              sort={{sortColumn: sortBy, direction: sortDirection}}
              sortChange={sortBy => {
                this.setState({sortBy: sortBy}, () => {
                  this.queryAgain();
                  this.cacheParams();
                });
              }}
              directionChange={direction => {
                this.setState({sortDirection: direction}, () => {
                  this.queryAgain();
                  this.cacheParams();
                });
              }}
              noDataText={<div style={{height: '110px', marginTop: '50px'}}>No users</div>}
            />
          </TableScrollWrapper>
            <ListFooter>
              <span className="info">
                <strong>{intFormatter(total)}</strong> users found.
                Show
                <select
                  value={PAGE_SIZE}
                  onChange={e => this.setState({
                    PAGE_SIZE: Number(e.target.value),
                    page: 1,
                  }, () => {
                    this.queryAgain();
                    this.cacheParams();
                  })}>
                {paginationValues.map(d => {
                  return (
                    <option key={d} value={d}>{d}</option>
                  )
                })}
                </select>
                per page
            </span>
              <PaginationStyled
                onChange={p => this.setState({page: p}, () => {
                  this.queryAgain();
                  this.cacheParams();
                })}
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                hideOnSinglePage={false}/>
            </ListFooter>
          </>
        }
      </div>
    )
  }

  buildQuery() {
    const {page, PAGE_SIZE, keyword, filters, sortBy, sortDirection} = this.state;
    const query = {
      keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filters,
      sortBy,
      sortDirection
    };
    return query;
  }

  queryAgain() {
    this.cacheParams();
    this.setState({loading: true});
    return post(`/api/queryUserList`, this.buildQuery()).then(data => {
      this.setState({data: data, loading: false});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }

  deleteUser(user) {
    CondirmDialog.showConfirm(`Do you really want to delete this user ?`, () => {
      this.setState({loading: true});
      post('/api/deleteUser', {id: user.id}).then(result => {
        if (result.success) {
          this.setState({loading: false, data: null})
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }
})