import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import {Wrapper} from './platforms.style';
import classnames from "classnames";
import {SelectStyled} from "./select.style";
import {LightGray, MediumGray1} from "../app/StyleCommon";
import {
  CampaignTypeOptions,
  KPIOptions,
  metricMetaData,
  platformLabelMap,
  TargetAudienceOptions
} from "../utils/metadata";
import {TvTAOptions} from "../utils/ta";
import {intFormatter} from "../utils/formatter";
import {currencySymbalMap} from "../utils/symbals";

export default class extends React.Component {

  render() {
    const {campaign} = this.props;
    return (
      <Wrapper>
        <p style={{margin: '10px 0 0 0'}}><strong>Platforms and Goals:</strong></p>
        <div className={classnames('field header')}>
          <label></label>
          <div className="platform-cfg header">
            <div className="budget">Budget</div>
            <div className="ta">TA</div>
            <div className="goal">KPI</div>
          </div>
        </div>
        {
          (campaign.platforms || []).map((d, platformIndex) => {
            return (
              <div key={d.key} className={classnames('field')}>
                <div className={classnames('label', {top: d.goals && d.goals.length > 1})}>
                  {
                    d.key === 'overall' && <div>Overall</div>
                  }
                  {
                    d.key !== 'overall' &&
                    platformLabelMap[d.key]
                  }
                </div>
                <div className="platform-cfg">
                  <div className={classnames('budget', {top: d.goals && d.goals.length > 1})}>
                    {/*<i className="material-symbols-outlined">attach_money</i>*/}
                    <i>{currencySymbalMap[campaign.currency || 'SGD']}</i>
                    <input
                      className="budget-editor editor"
                      type="text"
                      min={0}
                      value={intFormatter(d.budget)}
                      disabled={true}
                    />
                  </div>
                  <div className="goal">
                    {
                      ((d.goals && d.goals.length) ? d.goals : [{key: 'placeholder'}]).map((goal, goalIndex) => {
                        let metricMeta = metricMetaData.find(m => m.key === goal.kpi) || {};
                        let formatter = metricMeta.formatter || intFormatter;
                        return (
                          <div key={goal.kpi + goalIndex} className="goal-item">
                            {
                              goal.key !== 'placeholder' &&
                              <>
                                <SelectStyled
                                  className="ta"
                                  selected={goal.ta || 'People 15+'}
                                  plainStyle={true}
                                  defaultLabel=""
                                  disabled={true}
                                  menuHeader={<div style={{padding: '10px 20px', background: LightGray, borderBottom: '1px solid #f1f2f3', marginBottom: '10px'}}>Select TA</div>}
                                  groups={TvTAOptions}
                                  groupClosable={true}
                                  onChange={k => {
                                    goal.ta = k;
                                    this.setState({campaign, touched: true})
                                  }}/>
                                <SelectStyled
                                  selected={goal.kpi}
                                  plainStyle={true}
                                  disabled={true}
                                  defaultLabel="Select Metric"
                                  menuHeader={<div style={{
                                    padding: '10px 20px',
                                    background: LightGray,
                                    borderBottom: '1px solid #f1f2f3',
                                    marginBottom: '10px'
                                  }}>Select Metric</div>}
                                  data={KPIOptions}
                                  // disableFunc={item => d.goals.map(x => x.kpi).indexOf(item.key) >= 0}
                                  />
                                <span className="sep"> Equals </span>
                                <input
                                  disabled={true}
                                  className="editor"
                                  min={0}
                                  type="text"
                                  value={formatter(goal.value)}
                                />
                              </>
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            )
          })
        }

        {/*<div className="step-recipients">*/}
        {/*  <div className={classnames('field')}>*/}
        {/*    Campaign Managers:  {(campaign.eidtors || ['Not Set']).join(', ')}*/}
        {/*  </div>*/}
        {/*  <div className={classnames('field',)}>*/}
        {/*    Readonly Users:  {(campaign.viwers || ['Not Set']).join(', ')}*/}
        {/*  </div>*/}

        {/*</div>*/}
      </Wrapper>
    )
  }
}