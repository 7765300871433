import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import {Wrapper} from './linkage.style';
import classnames from "classnames";
import {SelectStyled} from "./select.style";
import {platformLabelMap} from "../utils/metadata";

export default class extends React.Component {

  render() {
    const {campaign} = this.props;
    const filteredLinks = (campaign.id_links || []).filter(la => !!la.key && !!la.value);
    const filtereFilters = (campaign.id_filters || []).filter(la => !!la.key && !!la.value);
    if(!filteredLinks.length && !filtereFilters.length) {
      return null;
    }

    return (
      <Wrapper>
        <p style={{margin: '0 0 20px 0'}}><strong>Extra MSQ ID Mapping & filtering:</strong></p>
        {/* {
          !!campaign.id_links && !!campaign.id_links.length &&
          <div className={classnames('field')}>
            <div className="linkage-list">
              {
                campaign.id_links.map((la, laIndex) => {
                  return (
                    <div className="linkage-item">
                      <span className="sub-item-label">
                              <i className="material-symbols-outlined">add_link</i>
                              Include Campaign:{laIndex + 1}
                            </span>
                      <SelectStyled
                        plainStyle={true}
                        disabled={true}
                        defaultLabel="Linkage Type"
                        selected={la.key || 'MSQ_ID'}
                        data={[
                          {key: 'overall', label: 'MSQ ID'},
                          {key: 'tv', label: 'TV Campaign ID'},
                          {key: 'digital', label: 'GAM JIRA ID'},
                          {key: 'radio', label: 'Radio Campaign ID'},
                          {key: 'social', label: 'Social Campaign ID'},
                        ]}/>
                      <span className='equal'>Equals</span>
                      <SelectStyled
                        className="last-item"
                        plainStyle={true}
                        disabled={true}
                        defaultLabel="ID"
                        selected={la.value}
                        data={[{key: la.value, label: la.campaign ? `${la.campaign.name} (${la.value})` : la.value}]}
                        />
                    </div>
                  )
                })
              }
            </div>
          </div>
        } */}

        {
          !!filteredLinks.length &&
          <div className="campaignList">
            {filteredLinks.map((la, laIndex) => {
              let platformKeys = _.uniq(_.compact((la.campaign.platforms || []).map(p => p.platform)));
              return (
                <div>
                <label>
                  <i className="material-symbols-outlined">add_link</i> Include
                  <SelectStyled
                    plainStyle={true}
                    disabled={true}
                    defaultLabel="Linkage Type"
                    selected={la.key || 'MSQ_ID'}
                    data={[
                      {key: 'overall', label: 'MSQ ID'},
                      {key: 'tv', label: 'TV Campaign ID'},
                      {key: 'digital', label: 'GAM JIRA ID'},
                      {key: 'radio', label: 'Radio Campaign ID'},
                      {key: 'social', label: 'Social Campaign ID'},
                    ]}/>:</label>
                  <div>{la.campaign ?<>
                      {la.campaign.name}
                      &nbsp;(MSQ ID: <b>{la.value}</b>
                      {platformKeys.map(pk => {
                        let ids = _.uniq(la.campaign.platforms.filter(p => p.platform === pk).map(p => p.platform_campaign_id));
                        ids = ids.filter(id => id && id !== 'underfined');
                        return <>&nbsp;{platformLabelMap[pk]}: <b>{ids.join(', ')}</b></>
                      })} )
                    </>: la.value}
                  </div>
                </div>
              )
            })}
          </div>
        }

        {
          !!filtereFilters.length &&
          <div className="campaignList">
            {filtereFilters.map((filter, laIndex) => {
              const toTitleCaseString = (str) => str.replace(/(^[a-z])|(\s+[a-z])/g, txt => txt.toUpperCase());
              return (
                <div>
                  <label>
                    <i className="material-symbols-outlined">filter_alt</i> Exclude
                    <SelectStyled
                      plainStyle={true}
                      disabled={true}
                      defaultLabel="Filter Type"
                      selected={filter.key}
                      data={[
                        {key: 'tv', label: 'TV Campaign ID'},
                        {key: 'digital', label: 'GAM JIRA ID'},
                        {key: 'radio', label: 'Radio Campaign ID'},
                        {key: 'social', label: 'Social Campaign ID'},
                      ]}/>:</label>
                  <div>{filter.campaign.name} (Platform Level Campaign IDs: {toTitleCaseString(filter.key)}: <b>{filter.campaign.platform_campaign_id}</b>)</div>
                </div>
              )
            })}
          </div>
        }

        {/* {
          !!campaign.id_filters && !!campaign.id_filters.length &&
          <div className={classnames('field')}>
            <div className="idfilter-list moreInfo">
              {
                campaign.id_filters.map((filter, laIndex) => {
                  return (
                    <div className="filter-item">
                            <span className="sub-item-label"  style={{whiteSpace: 'nowrap'}}>
                              <i className="material-symbols-outlined">filter_alt</i>
                              Exclude Campaign:{laIndex + 1}
                            </span>
                      <SelectStyled
                        plainStyle={true}
                        disabled={true}
                        defaultLabel="Filter Type"
                        selected={filter.key}
                        data={[
                          {key: 'tv', label: 'TV Campaign ID'},
                          {key: 'digital', label: 'GAM JIRA ID'},
                          {key: 'radio', label: 'Radio Campaign ID'},
                          {key: 'social', label: 'Social Campaign ID'},
                        ]}/>
                      <span>Equals</span>
                      <SelectStyled
                        plainStyle={true}
                        disabled={true}
                        selected={filter.key}
                        data={[
                          {key: filter.key, label: `${filter.campaign?.name} (${filter.key})`},
                        ]}/>
                    </div>
                  )
                })
              }
            </div>
          </div>
        } */}
      </Wrapper>
    )
  }
}
