import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {InFlightStatus, AttrOverview, VisCanvas, DropArea, VisPanel, Wrapper} from './details.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, TabContainer, Loader, ProgressBar} from "../uikit";
import {visualisations, visualisationsForReach, visualisationsForClientData} from "../charts/index";
import PlatformViewer from "./platforms";
import LinkageViewer from "./linkage";
import ErrorBoundary from "../charts/errorBoundary";
import classnames from "classnames";
import {LightGray, MediumGray} from "../app/StyleCommon";
import {periodFormat, pctFormatter} from "../utils/formatter";
import {campaignTypeMap, campaignStatusMap, inflightStatusMap, platformLabelMap, platformSorted, metricMap, metricMetaData} from "../utils/metadata";
import {Link} from "react-router-dom";
import qs from "qs";
import {post} from "../utils/request";
import createTooltip, {defaultTooltip, defaultWhiteTooltipLeft, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import {findDOMNode} from "react-dom";
import {TvTAOptions} from "../utils/ta";
import {SelectStyled} from "./select.style";
import DNDMixin from "../charts/dndMixin";
import {AlertBox} from "./build.style";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {generateDashboardContext} from "../charts/dndMixin.client";
import {HealthStatus} from "./index.style";

export default withConsumer(class extends DNDMixin {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      mode: 'display', //'customize', //customize
      loadingMap: {},
      visPanelClose: false,
      showMoreSettings: false,
    }
  }

  buildQuery() {
    const {tv_ta} = this.state;
    const urlParams = qs.parse(window.location.search.slice(1));
    return {id: urlParams.id, tv_ta};
  }

  renderHealthStatus(d) {
      if(!d.health_status || d.health_status === '-' || !d.health_status_details  || !d.health_status_details.length) {
        return '--';
      }
      let health = d.health_status === 'ON_TRACK' ? 'greenHealthStatus' : 'redHealthStatus';
      d.health_status = d.health_status;
      d.health_status_details = d.health_status_details || [];
      d.txt = `<div style="font-size: 12px; min-width: 250px">
        <h3>${d.health_status}</h3>
        ${d.health_status_details.map(item => {
          let meta = metricMetaData.find(m => m.key === item.metric) || {formatter: v => v};
          return `<p>${platformLabelMap[item.platform]} KPI:  ${meta.formatter(item.total)} ${metricMap[item.metric]},
                  ${campaignStatusMap[item.status]}.
                  <strong style="color: ${item.status === 'ON_TRACK' ? '#4f8f98' : '#ec685c'}">
                    <br/>
                    expected: ${meta.formatter(item.target)},
                    actual: ${meta.formatter(item.actual)}
                    (
                      ${pctFormatter(Math.abs(item.actual  - item.target) / item.target)}
                      ${item.actual  > item.target ? 'over-deliver' : 'under-deliver'}
                    )
                  </strong>
                  </p>`
        }).join('')}
    </div>`
      return (
        <div className='attr healthStatus'>
          <label>Health Status:</label>
          <HealthStatus
            className={health}
            onMouseOver={defaultWhiteTooltip.onMouseOver(d)}
            onMouseLeave={defaultWhiteTooltip.onMouseOut()}>
            {campaignStatusMap[d.health_status]}
          </HealthStatus>
        </div>
      )
  }

  render() {
    const {session = {}} = this.props.appState || {};
    const {data, error, loading, refreshKey, touched} = this.state;
    const {showMoreSettings, clientData, dashboard, mode, tabIndex, historyStack = [], platformKeys, overall_platforms, tv_ta} = this.state;
    const {campaign, reportData, alerts = []} = data || {};
    console.log("campaign", campaign)
    const visualisationGroups = _.groupBy(visualisations, _.property('category'));
    const customiseMode = mode === 'customize';
    const currentPlatformKey = (platformKeys || [])[tabIndex];

    let canBuildReport = session.rights?.indexOf('Build Report') >= 0;
    let canCustomiseCampaign = session.rights.indexOf('Customise Campaign Dashboard') >= 0;
    let canCustomiseForAll = session.rights.indexOf('Customise Campaign Dashboard for Everybody') >= 0;
    let canSetupCampaign = session.rights.indexOf('Setup/Update Campaign') >= 0;
    let viewCampaignRevisions = session.rights.indexOf('View Campaign History') >= 0;
    let viewClientData = campaign && campaign.type === "performance_partnership";
    let noActionAllowed = !canBuildReport && !canSetupCampaign && !viewCampaignRevisions;

    let isEditor = campaign && campaign.editors && campaign.editors.includes(session.id);
    let isViewer = campaign && campaign.viewers && campaign.viewers.includes(session.id);
    let isUIAdmin = campaign && campaign.viewers && ['Mike.Lin@mediacorp.com.sg', 'satvik.nandal@mediacorp.com.sg'].includes(session.id);
    let isCampaignOwner = campaign && campaign.creator === session.id;

    return (
      <MainLayout activeItem="campaign" breadcrumb={[{path: '/campaigns', label: 'Campaigns'}, {path: '/campaign/details', label: 'Campaign Insights'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/campaignInsightsQuery`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
              if(data.campaign) {
                let platformKeys = data.campaign.platforms.map(d => d.key);
                if(data.campaign.isSinglePlatform) {
                  platformKeys = platformKeys.filter(k => k !== 'overall');
                }
                this.setState({
                  campaign: data.campaign,
                  reportData: data.reportData,
                  clientData: data.clientData,
                  context: generateDashboardContext(data),
                  digitalOptionMetadata: data.digitalOptionMetadata,
                  tvOptionMetadata: data.tvOptionMetadata,
                  dashboard: _.cloneDeep(data.dashboard),
                  platformKeys: _.sortBy(platformKeys, k => platformSorted.indexOf(k)),
                  originalCampaign: _.cloneDeep(data.campaign)
                });


              }
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !data && !!error &&
          <Wrapper><ErrorMessage message={error}/></Wrapper>
        }
        {
          !!data &&
          <Wrapper>
            {
              !!error && <ErrorMessage message={error}/>
            }
            {
              ((!!campaign && campaign.release_status === 'DELETED') || (!!data && data.notFound) ) &&
              <div className="campaign404 fixedNexttoMenu">Campaign Not Found. <Link className="button" to={`/campaigns`}>Go Back</Link></div>
            }
            {
              (data && data.notAllowed) &&
              <div className="campaign404 fixedNexttoMenu">
                You are not allowed to view this campaign dashboard. &nbsp; <Link className="button" to={`/campaigns`}>Go Back</Link>
              </div>
            }
            {
              !!campaign &&
              <>
                <div className="detail-header-wrapper">
                  <div className="detail-header">
                    <div className="title">
                    <div className="title-txt">{campaign.name} - ({(campaign.id).toUpperCase()})</div>

                      {/* {
                        mode === 'display' &&
                        canSetupCampaign &&
                        <Link to={`/campaign/settings?fr=campaign_dashboard&id=${campaign.id}`}>
                          <i className="material-symbols-outlined">edit</i> Settings
                        </Link>
                      } */}
                      {this.displayEditSettingButton(mode, canSetupCampaign, campaign, isEditor, isViewer)}
                      {/* {
                        mode !== 'display' &&
                        <a className="attr more-settings" onClick={e => this.setState({showMoreSettings: !showMoreSettings})}>
                          {!showMoreSettings && <>More Information <i className="material-symbols-outlined">keyboard_double_arrow_down</i></>}
                          {!!showMoreSettings && <>Less Information <i className="material-symbols-outlined">keyboard_double_arrow_up</i></>}
                        </a>
                      } */}
                    </div>
                    <div className="setting-container">
                      <div className="setting-controls">
                        {
                          !customiseMode && !!touched &&
                          <Btn size="small" type="reset" className='resetBtn'
                              style={{marginLeft: '20px', marginRight: '0'}}
                              onClick={e => this.setState({touched: false, dashboard: _.cloneDeep(data.dashboard)})}>Reset
                          </Btn>
                        }
                        {
                          customiseMode && !!canCustomiseCampaign &&
                          <>
                            {
                              <StyledDropdown
                                closeOnClick={true}
                                toggler={
                                  <span className="dropdown-toggler-inner">
                                    <Btn size="small" type="primary" style={{display: 'flex', alignItems: 'center'}}>
                                      <span>Save</span>
                                      <span className="material-symbols-outlined" style={{fontSize: '16px', marginLeft: '5px'}}>keyboard_arrow_down</span>
                                    </Btn>
                                </span>
                                }>
                                <div className={`menu-item`}>
                                  <a onClick={e => this.saveDashboard('user_campaign')}>
                                    Save layout for my current campaign.
                                  </a>
                                </div>

                                <div className={`menu-item`}>
                                  <a onClick={e => this.saveDashboard('user')}>
                                    Save layout for all my {campaignTypeMap[campaign.type]} campaigns.
                                  </a>
                                </div>
                                {
                                  !!canCustomiseForAll &&
                                  <div className={`menu-item`}>
                                    <a onClick={e => this.saveDashboard('default')}>
                                      Save layout for all users {campaignTypeMap[campaign.type]} campaigns.
                                    </a>
                                  </div>
                                }
                              </StyledDropdown>
                            }
                            {
                              (dashboard.id || '').startsWith('user') &&
                              <StyledDropdown
                                closeOnClick={true}
                                toggler={
                                  <span className="dropdown-toggler-inner">
                                    <Btn size="small" type="reset" style={{display: 'flex', alignItems: 'center'}}>
                                      <span>Reset</span>
                                      <span className="material-symbols-outlined" style={{fontSize: '16px', marginLeft: '5px'}}>keyboard_arrow_down</span>
                                    </Btn>
                                </span>
                                }>
                                {
                                  (dashboard.id || '').startsWith('user_campaign') &&
                                  <div className={`menu-item`}>
                                    <a onClick={e => this.deleteTemplate('user_campaign')}>
                                      Delete my current campaign customised layout.
                                      { (dashboard.id || '').startsWith('user_campaign') && ' (Current)'}
                                    </a>
                                  </div>
                                }
                                {
                                  (data.templateAvailability || {}).user &&
                                  <div className={`menu-item`}>
                                    <a onClick={e => this.deleteTemplate('user')}>
                                      Delete my customised {campaignTypeMap[campaign.type]} campaign layout.
                                      { !(dashboard.id || '').startsWith('user_campaign') && ' (Current)'}
                                    </a>
                                  </div>
                                }
                              </StyledDropdown>
                            }
                            <Btn size="small" type="reset" onClick={e => {
                              this.setState({mode: 'display', dashboard: _.cloneDeep(data.dashboard)}, () => {
                                window.dispatchEvent(new Event('resize'));
                              })
                            }}>Cancel</Btn>
                            {
                              !!historyStack.length &&
                              <a
                                className='undo'
                                onClick={e => this.rollback()}>
                                <i className="material-symbols-outlined">Undo</i>
                              </a>
                            }
                          </>
                        }
                        {
                          (canCustomiseCampaign || canCustomiseForAll) && campaign.inflight_status !== 'READY' &&
                          <a
                            className={mode === 'display' ? '' : 'active'}
                            onClick={e => {
                              this.setState({mode: mode === 'display' ? 'customize' : 'display'}, () => {
                                window.dispatchEvent(new Event('resize'));
                              })
                            }}
                            onMouseOver={defaultWhiteTooltipLeft.onMouseOver({tipContent: (
                                <div style={{minWidth: '150px', wordBreak: 'break-word'}}>
                                  <p>Customise charts and layout</p>
                                  <div style={{borderTop: '1px solid #777', margin: '10px 5px'}}></div>
                                  {dashboard.id.indexOf('default') >= 0 && <p>Currently using default template set by admin.</p>}
                                  {dashboard.id.indexOf('default') < 0 && <p>Current template id: {dashboard.id}</p>}
                                </div>
                              )})}
                            onMouseLeave={defaultWhiteTooltipLeft.onMouseOut()}>
                            <i className="material-symbols-outlined">dashboard_customize</i>
                          </a>
                        }
                        {
                          !noActionAllowed &&
                          <StyledDropdown
                            closeOnClick={true}
                            toggler={
                              <span className="material-symbols-outlined"
                                    onMouseOver={this.tooltip.onMouseOver({txt: 'More Options'})}
                                    onMouseLeave={this.tooltip.onMouseOut()}>more_vert</span>
                            }>
                            {
                              canBuildReport && campaign.inflight_status !== 'READY' &&
                              <div className={`menu-item`}>
                                <a onClick={e => this.exportReport()}>
                                  <i className="material-symbols-outlined">analytics</i> Generate report
                                </a>
                              </div>
                            }
                            {
                              canCustomiseCampaign &&
                              <div className={`menu-item`}>
                                <Link to={`/campaign/settings?id=${campaign.id}&fr=campaign_dashboard`}>
                                  <i className="material-symbols-outlined">settings</i> Campaign Settings
                                </Link>
                              </div>
                            }

                            {
                              viewCampaignRevisions &&
                              <div className={`menu-item`}>
                                <Link to={`/campaign/history?id=${campaign.id}`}>
                                  <i className="fa fa-history"/> Campaign History
                                </Link>
                              </div>
                            }
                            {
                              viewClientData && (isCampaignOwner || isEditor || isViewer || isUIAdmin) &&
                              <div className={`menu-item`}>
                                <Link to={`/campaign/client-data?id=${campaign.id}&fr=campaign_dashboard`}>
                                  <i className="fa fa-database gfont"/> Client Data
                                </Link>
                              </div>
                            }
                          </StyledDropdown>
                        }
                      </div>
                    </div>
                  </div>
                  <AttrOverview>
                      <div className="basic-info">
                        {
                          [
                            // {key: 'Advertiser', value: 'Advertiser: ' + campaign.advertiser},
                            {key: 'Campaign Period', value: 'Period: ' + periodFormat({start: campaign.start_date, end: campaign.end_date}), tooltip: this.calculateDayLeft()},
                            {key: 'Campaign Type', value: 'Type: ' + campaignTypeMap[campaign.type]},
                            {key: 'currency', value: 'Currency: ' + (campaign.currency || 'SGD')},
                            {key: 'inflight_status', value: <span>Flight Status: <InFlightStatus className={campaign.inflight_status}>{inflightStatusMap[campaign.inflight_status]}</InFlightStatus></span>, tooltip: this.calculateDayLeft()},
                          ].map(attr => (
                            <div
                              className="attr"
                              key={attr.key}
                              onMouseOver={attr.tooltip ? this.tooltip.onMouseOver({txt: attr.tooltip}) : undefined}
                              onMouseLeave={attr.tooltip ? this.tooltip.onMouseOut() : undefined}
                            >
                              {attr.value}
                            </div>
                          ))
                        }
                        {this.renderHealthStatus(campaign)}
                      {
                        <a className={classnames('attr more-settings', {'more-settings-link': !!showMoreSettings})}  onClick={e => this.setState({showMoreSettings: !showMoreSettings})}>
                          {!showMoreSettings && <>More Information <i className="material-symbols-outlined">keyboard_double_arrow_down</i></>}
                          {!!showMoreSettings && <>Less Information <i className="material-symbols-outlined">keyboard_double_arrow_up</i></>}
                        </a>
                      }
                      </div>
                    </AttrOverview>
                  {
                    showMoreSettings &&
                    <div className="more-settings more-settings-overlay">
                      <div>
                        Advertiser: <strong>{campaign.advertiser}</strong>,&nbsp;
                        {/*Currency: <strong>{campaign.currency || 'SGD'}</strong>,*/}
                        Creator: <strong>{campaign.creator}</strong>,&nbsp;
                        MSQ_ID: <strong>{campaign.msq_id}</strong>,&nbsp;
                        Platform Level Campaign IDs:
                        {!Object.keys(campaign.platform_campaign_ids).filter(pk => !!campaign.platform_campaign_ids[pk]).length && ' No id included.'}
                        {Object.keys(campaign.platform_campaign_ids).filter(pk => !!campaign.platform_campaign_ids[pk]).map(pk => {
                          return (
                            <span style={{margin: '0 10px'}}>
                          <span style={{marginRight: '10px'}}>{platformLabelMap[pk]}:</span>
                              {(campaign.platform_campaign_ids[pk] || []).filter(id => id && id !== 'undefined').join(', ')}
                        </span>
                          )
                        })}
                      </div>
                      <LinkageViewer campaign={campaign}/>
                      <PlatformViewer campaign={campaign}/>
                    </div>
                  }
                  {
                    mode === 'display' && alerts.map(alert => {
                      return (
                        <AlertBox key={alert.id}>
                          <div className="title" dangerouslySetInnerHTML={{__html: alert.title}}></div>
                          <div className="desc" dangerouslySetInnerHTML={{__html: alert.details.replace('Please change campaign settings according to the change.', '')}}></div>
                          {
                            canSetupCampaign && alert.recipients.indexOf(session.userId) >= 0 && alert.status === 'NEED_RESOLVE' &&
                            <div className="actions">
                              <Link className="button" to={`/campaign/settings?id=${campaign.id}`}>
                                <Btn type="primary" size="mini">
                                  {alert.data?.msq?.mbfStatus === 'Terminated' ? 'Change MSQ' : 'Update settings'}
                                </Btn>
                              </Link>
                            </div>
                          }
                        </AlertBox>
                      )
                    })
                  }

                  {
                    !customiseMode && !!data.dataFreshness && campaign.inflight_status !== 'READY' &&
                    <div className="data-freshness">
                      {
                        !!data.dataFreshness[currentPlatformKey] &&
                        <div className="data-freshness-inner" onMouseOver={defaultTooltip.onMouseOver({tipContent: (
                            <div>
                              {Object.keys(data.dataFreshness).map(p => {
                                return <p key={p} style={{margin: '10px 0'}}> - <strong style={{marginRight: '10px'}}>{platformLabelMap[p]}:</strong> {data.dataFreshness[p]}</p>
                              })}
                              {data.dataFreshness.tv &&
                                <p>
                                  * TV report data usually has 3 days of delay
                                </p>}
                            </div>
                          )})}
                             onMouseLeave={defaultTooltip.onMouseOut()}>
                          <i className="material-symbols-outlined">avg_pace</i>
                          Data as of {moment(data.dataFreshness[currentPlatformKey]).format('DD MMM YYYY')}
                        </div>
                      }

                      {
                        (['tv', 'radio', 'overall'].indexOf(currentPlatformKey) < 0 || (currentPlatformKey === 'overall' && platformKeys.includes('digital')))  &&
                        <div style={{fontSize: '12px', display: 'flex', alignItems: 'center', marginLeft: '15px'}}
                             onMouseOver={defaultTooltip.onMouseOver({tipContent: (
                                 <div>
                                   Target Audience {currentPlatformKey}
                                 </div>
                               )})}
                             onMouseLeave={defaultTooltip.onMouseOut()}>
                          <span className="material-symbols-outlined" style={{fontSize: '16px', marginRight: '5px'}}>group_work</span>
                          <span>{this.getDefaultTVTA('overall')}</span>
                        </div>
                      }
                      {
                        (['tv', 'radio'].includes(currentPlatformKey) || (currentPlatformKey === 'overall' && !platformKeys.includes('digital')))  &&  //radio use tv audience as well.
                        <SelectStyled
                          selected={tv_ta || this.getDefaultTVTA(currentPlatformKey)}
                          plainStyle={true}
                          defaultLabel=""
                          menuHeader={<div className="menu-header">Target Audience</div>}
                          onMouseOver={defaultWhiteTooltipTop.onMouseOver({tipContent: (
                              <div>
                                Target Audience
                              </div>
                            )})}
                          onMouseLeave={defaultWhiteTooltipTop.onMouseOut()}
                          toggleLableFunc={() => (
                            <div className='searchable-dropdown'>
                              <span className="material-symbols-outlined" style={{fontSize: '16px', marginRight: '5px'}}>group_work</span>
                              <span>{tv_ta || this.getDefaultTVTA(currentPlatformKey)}</span>
                            </div>
                          )}
                          searchable={true}
                          groups={TvTAOptions}
                          groupClosable={true}
                          onChange={k => {
                            const reloadKeys = ['tv', 'radio'].filter(k => platformKeys.includes(k));
                            if(reloadKeys.length === 2) {
                              reloadKeys.push('overall');
                            }
                            this.setState({tv_ta: k}, () => this.reloadDataForPlatform(reloadKeys));
                          }}/>
                      }
                    </div>
                  }
                </div>
                <div className="dashboard-wrapper">
                  <TabContainer
                    activeIndex={tabIndex}
                    onChange={tabIndex => this.setState({tabIndex: tabIndex})}
                    tabTitles={platformKeys.map(k => platformLabelMap[k] || k)}>
                    {platformKeys.map((platformKey, platformIndex) => {
                      let platformDashboard = dashboard[platformKey] || {sections: []};
                      //hide overall tab for single platform campaign.
                      let statusReady =  campaign.inflight_status !== 'READY';
                      let dataReady = Object.values(data.dataFreshness || {}).some(v => !!v);
                      let platformDataReady = !!data.dataFreshness[platformKey];
                      let {status_msg: platformStatusMsg} = (campaign.report_status || {})[platformKey] || {};
                      return (
                        <div className={classnames('campaign-dashboard')} key={platformKey}>
                          {!statusReady && <div className="campaign-dashboard-no-data">Campaign Not Start Yet</div>}
                          {statusReady && !dataReady && <div className="campaign-dashboard-no-data">
                            {!platformStatusMsg && <span>Campaign data is not available.</span>}
                            {!!platformStatusMsg && <span>Campaign dashboard is not available. {platformStatusMsg}</span>}
                          </div>}
                          {
                            statusReady && dataReady && !platformDataReady &&
                            (
                              <div className="campaign-dashboard-no-data">
                                {!['ooh', 'others'].includes(platformKey) &&
                                  <div>
                                    {platformLabelMap[platformKey]} campaign dashboard is not available to view.
                                    {!!platformStatusMsg && <span> {platformStatusMsg}.</span>}
                                  </div>
                                }
                                {['ooh', 'others'].includes(platformKey) && <div>{platformLabelMap[platformKey]} platform report is not supported by Aladdin at this moment. Pls stay tune..</div>}
                                {platformKey === 'overall' && <span>&nbsp;Only {Object.keys(data.dataFreshness).map(k => platformLabelMap[k]).join(', ')} data is available</span>}
                              </div>
                            )
                          }
                          {this.renderDashboard(platformKey, platformDashboard)}
                        </div>
                      )
                    })}
                  </TabContainer>
                  {
                    customiseMode && this.renderVisPanel({campaignType: campaign.type})
                  }
                </div>
              </>
            }
          </Wrapper>
        }
      </MainLayout>
    )
  }

  displayEditSettingButton = (mode, canSetupCampaign, campaign, isEditor, isViewer) => {
    return mode === 'display' &&
        (canSetupCampaign && isEditor) && (canSetupCampaign && isViewer) &&
        <Link to={`/campaign/settings?fr=campaign_dashboard&id=${campaign.id}`}>
          <i className="material-symbols-outlined">edit</i> Settings
        </Link>
  }

  calculateDayLeft() {
    const {campaign = {}} = this.state;
    if(campaign.inflight_status === 'READY') {
      return moment(campaign.start_date).diff(moment(), 'day') + ' days before campaign starting';
    }
    else if(campaign.inflight_status === 'DELIVERING') {
      return moment(campaign.end_date).diff(moment(), 'day') + ' days left';
    }
    else if(campaign.inflight_status === 'DONE') {
      return 'Completed ' + moment().diff(moment(campaign.end_date), 'day') + ' days ago';
    }
    return '';
  }

  getDefaultTVTA(platform) {
    const {campaign = {}, data, reportData = [], dashboard, tv_ta} = this.state;
    if(platform) {
      let {goals} = campaign.platforms.find(d => d.key === platform) || {};
      return (goals[0] || {}).ta || 'People 15+';
    }
    else {
      return 'People 15+'
    }
  }

  reloadDataForPlatform(reloadPlatformKeys) {
    const {campaign = {}, data, reportData = [], dashboard, tv_ta} = this.state;
    this.setState({loading: true});
    post(`/api/loadDataForPlatform`, {campaign, dataFreshness: data.dataFreshness, reloadPlatformKeys, dashboard, tv_ta}).then(results => {
      for(let item of results) {
        let idx = reportData.findIndex(d => d.key === item.key);
        if(idx >=0){
          item.reloadKey = Date.now(); //force update react key
          reportData.splice(idx, 1, item);
        }
      }
      this.setState({reportData, loading: false});
    }).catch(e => {
      this.setState({error: e.message, loading: false});
    });
  }

  saveDashboard(scope) {
    const {dashboard, data} = this.state;
    const {campaign} = data || {};
    this.setState({loading: true});
    let updateData = {campaignId: campaign.id, campaignType: campaign.type, scope, dashboard};
    return post(`/api/saveCampaignDashboard`, updateData).then(resp => {
      if(resp.templateId) {
        dashboard.id = resp.templateId;
      }
      data.dashboard = _.cloneDeep(dashboard);
      (data.templateAvailability || {})[scope] = true;
      this.setState({data, dashboard, mode: 'display', loading: false, touched: false, historyStack: []}, () => {
        window.dispatchEvent(new Event('resize'));
      });
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }

  exportReport() {
    const {campaign, dashboard, data} = this.state;
    const report = {
        datasource: {
          campaigns: [campaign], benchmark_campaigns: [],
        },
      name: `${campaign.name} Report (Exported from campaign dashboard)`,
      title: `${campaign.name} Report`,
      sections: _.flatten(Object.keys(dashboard).sort(k => platformSorted.indexOf(k)).filter(k => {
        let platformKeys = campaign.platform_keys || [];
        if(k === 'overall' && platformKeys.length === 1) {
          return false;
        }
        if(!data.dataFreshness || !data.dataFreshness[k]) {
          return false;
        }
        return !!dashboard[k].sections && (k === 'overall' || platformKeys.indexOf(k) >= 0);
      }).map(platformKey => {
        let sections = _.cloneDeep(dashboard[platformKey].sections);
        sections.forEach(section => {
          (section.charts || []).forEach(chart => {
            chart.cfg.platform = platformKey;
          });
        })
        let firstSection = {charts: [
            {key: 'seperator', pkey: Date.now(), cfg: {platform: 'common', size: 'size100', data: platformLabelMap[platformKey] + ' Report'}}
        ]};
        sections.unshift(firstSection);
        return sections;
      }))
    }
    this.setState({loading: true});
    post(`/api/createReport`, report).then(result => {
      this.setState({loading: false});
      this.props.history.push(`/report/builder?id=${result.report.id}&fr=campaign_dashboard`);
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

  deleteTemplate(scope) {
    const {dashboard, data} = this.state;
    const {campaign} = data || {};
    this.setState({loading: true});
    const updateData = {dashboard, campaign, scope};
    return post(`/api/restoreCampaignDashboard`, updateData).then(data => {
      this.setState({data: null, loading: false});
      // if(data.dashboard) {
      //   this.setState({
      //     dashboard: _.cloneDeep(data.dashboard),
      //   });
      // }
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }
})
