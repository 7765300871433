import React, {useState, useEffect, useRef} from 'react';
import _ from 'lodash';
import classnames from "classnames";
import {formatterMap} from "../utils/metadata";
import {Btn, CondirmDialog} from "../uikit";
import useOutsideClick from "../uikit/hooks/useOutsideClick";
import {defaultTooltip} from "../uikit/tooltip";
import FormulaParser from "../uikit/formulaParser";
import {CustomMetricDialogWrapper, DropdownToggle, DropdownToggleIcon} from './build.style';
import {FormWrapper, FormField, FormFooter, MetricsSelectorWrapper, FormulaBuilderWrapper, ErrorContainer, CustomMetricWrapper, DropdownToggler} from './clientData.style';
import * as d3 from "d3-format";

const getClientMetrics = metricsData => {
  if (!metricsData || metricsData.length === 0) {
    return [];
  }
  return metricsData.map(x => {
    if (x.key === "client_date") {
      return { eg: 10, key: x.key, label: x.label, formatter: formatterMap['integer'], supportPlatforms: ['overall', 'tv', 'digital'] };
    } else {
      const accumulatable = !['unique', 'reach'].some(k => x.key.toLowerCase().includes(k)) && x.formatter !== 'percentage';
      const obj = { eg: 10, key: x.key, label: x.label, formatter: formatterMap[x.formatter], supportPlatforms: ['overall', 'tv', 'digital'], accumulatable };
      if (x.formula) {
        obj.description = x.formula;
      }
      return obj;
    }
  });
}

const DropDownToggler = ({metrics, isOpen, handleMetricsChange, handleDeleteItem}) => {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useOutsideClick(dropdownRef, () => {
    if (dropdownRef) {
      setOpen(false)
    }
  });

  const handleItemClick = (item) => {
      handleMetricsChange(item);
      setOpen(false);
  }

  const handleItemDeleteClick = (item) => {
    handleDeleteItem(item);
    setOpen(false);
  }

  return <DropdownToggleIcon>
      <i class="fa fa-caret-down" aria-hidden="true" onClick={e => setOpen(true)}></i>
      {open && <>
          <div className='menu hidden' key={Date.now()} ref={dropdownRef}>
          {metrics && metrics.length > 0 && metrics.map(item => (
              <div className='menu-item' id={item.key} onClick={(e) => handleItemClick(item)}>
              {item.value} <i className="material-symbols-outlined" onClick={(e) => handleItemDeleteClick(item)}>delete</i>
              </div>
          ))}
          {metrics && metrics.length === 0 &&
              <div className='menu-item'>No Metric exists, create a metric to get started</div>
          }
          </div>
      </>
      }
  </DropdownToggleIcon>
};

const ColumnDropDownToggler = ({col, handleMetricsChange, dIndex}) => {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  let formatterSelected = 'integer';

  if (col.formatter) {
    formatterSelected = col.formatter;
  }

  const formatters = [
    {name: '***,***,***.** (Number with decimal points)', value: "float", selected: false},
    {name: '***,***,*** (Number with 0 points)', value: "integer", selected: false},
    {name: '--,--,--% (Number with percentage)', value: "percentage", selected: false},
    {name: '$--,--,--.__ (Number with $)', value: "money", selected: false}
  ];

  useOutsideClick(dropdownRef, () => {
    if (dropdownRef) {
      setOpen(false)
    }
  });

  const handleItemClick = (e, type) => {
    let event = {
      id: e.target.id,
      type: type
    }
    handleMetricsChange(event, col);
    setOpen(false);
  }

  return <DropdownToggler index={dIndex === 0}>
    <i className='fa fa-ellipsis-v' onClick={e => setOpen(true)}></i>
      {open && <>
        <div className='menu' key={Date.now()} ref={dropdownRef}>
          <div className='menu-title'>Formatter:</div>
          {formatters.map(item => (
            <div className={classnames('menu-item', {'menu-item-selected': formatterSelected === item.value})} id={item.value} onClick={(e) => handleItemClick(e, 'selectformatter')}>
              {item.name}
            </div>
          ))}
          <div className='menu-title'  onClick={(e) => handleItemClick(e, 'edit')}>Edit Metric</div>
          <div className='menu-title'  onClick={(e) => handleItemClick(e, 'delete')}>Delete Metric</div>
        </div>
      </>
    }
  </DropdownToggler>
};

const HandleAddClientData = ({clientKPIMetrics, onSave, onDelete, handleCancel}) => {
    const defaultSelected = {
        isSelected: false,
        data: {}
    }
    const metricSaveStates = {
        create: 'created',
        update: 'updated',
        delete: 'deleted',
    }
    const [metrics, setMetrics] = useState([]);
    const [metric, setMetric] = useState({label: '', state: ''});
    const [message, setMessage] = useState('');
    const [selected, setSelected] = useState(defaultSelected);
    const [metricsDropdownOpen, setMetricsDropdownOpen] = useState(false);

    useEffect(() => {
        if(metric.label !== "") {
        setMessage(`Metric <b>${metric.label}</b> ${metricSaveStates[metric.state]} successfully`);
        }
        setTimeout(() => {
        setMessage('')
        }, 1000)
    }, [metric])

    useEffect(() => {
      if(clientKPIMetrics) {
        setMetrics(clientKPIMetrics)
      }
  }, [clientKPIMetrics])

    const handleSave = (obj) => {
        let isItemExists = metrics.find(x => x.key === obj.key);
        let metricList = [...metrics];
        if(!isItemExists) {
        setMetric({label: obj.value, state: 'create'})
        obj.label = obj.value;
        metricList.push(obj);
        setMetrics(metricList);
        } else {
        setMetric({label: obj.value, state: 'update'})
        metricList.forEach(x => {
            if(x.key === obj.key) {
            x.description = obj.description;
            x.formatter = obj.formatter;
            x.value = obj.value;
            x.label = obj.value;
            }
            setMetrics(metricList);
        })
        }
        setSelected(defaultSelected);
        setTimeout(() => {
          onSave(metricList);
        }, 1000)
        // CondirmDialog.closeAll();
    }

    const handleDelete = (obj) => {
        let metricList = [...metrics];
        setMetric({label: obj.value, state: 'delete'})
        metricList.filter(x => x.key !== obj.key)
        setMetrics(metricList.filter(x => x.key !== obj.key));
        setSelected(defaultSelected);
        setTimeout(() => {
          onDelete(obj);
        }, 1000);
    }

    const handleMetricSelection = (obj) => {
      setSelected({isSelected: true, data: obj});
    }

    const dialogCustomMetrics = {
        type: 'form',
        backgroundClose: true,
        title: '',
        maxWidth: '500px',
        style: { maxWidth: '550px' },
        hideCancel: true,
        hideOK: true,
        onCancel: () => CondirmDialog.closeAll(),
        dialogBody: <CustomMetricDialogWrapper>
        {metrics && metrics.length > 0 ? <h3>Manage Metrics</h3> : <h3>Add Custom Metric</h3>}
        {message !== "" ? <div className='metrics-message'  dangerouslySetInnerHTML={ {__html: message} }></div> :  null}
        <div className='metrics-container'>
            <DropDownToggler metrics={metrics} isOpen={metricsDropdownOpen} handleMetricsChange={e => handleMetricSelection(e)} handleDeleteItem={(item) => handleDelete(item)}></DropDownToggler>
            <div className='create-metrics'>
              <CreateCustomMetricForm
                  data={null}
                  editObj={selected.isSelected ? selected.data : null}
                  isDeleteBtn={selected.isSelected ? true : false}
                  onCancel={e => handleCancel()}
                  onDelete={item => handleDelete(item)}
                  onSave={item => handleSave(item)}
                  handleMetricsDropdown={flag => setMetricsDropdownOpen(flag)}
              />
            </div>
          </div>
        </CustomMetricDialogWrapper>,
    };

    return CondirmDialog.defaultRoot.render(<CondirmDialog {...dialogCustomMetrics} />);
}

const CreateCustomMetricForm = ({data, editObj, isDeleteBtn=false, onCancel, onDelete, onSave, handleMetricsDropdown}) => {
    const errorRef = useRef(null);
    let defaultValues = {
      description: '',
      formatter: 'integer',
      formula: '',
      key: '',
      token: '',
      value: ''
    }
    const [form, setForm] = useState(defaultValues);
    useEffect(() => {
      if(!editObj) {
        setForm({...editObj, formatter: 'integer'})
      } else {
        setForm(editObj);
      }
    }, [editObj]);

    const isMetricEnabled = editObj ? editObj.isDefault: false;

    const getFormulaKeys = () => {
      let { formula } = form;
      if(data) {
        let {metrics} = data;
        let modifiedString = "";
        metrics.forEach(m => {
          if(m && formula && formula.includes(m.label) && modifiedString === "") {
            modifiedString = formula.replace(new RegExp(m.label, 'g'), `d.${m.key}`);
          } else if(modifiedString !== "") {
            modifiedString = modifiedString.replace(new RegExp(m.label, 'g'), `d.${m.key}`);
          }
        })
        return modifiedString;
      } else {
        return ""
      }
    };

    const getFormulaDeps = () => {
      let { formula } = form;
      if (data) {
        let {metrics} = data;
        let deps = [];
        metrics.forEach(m => {
          if(formula && formula.indexOf(m.label) !== -1) {
            deps.push(m.key)
          }
        })
        return deps;
      }
    }

    return <CustomMetricWrapper>
      <FormWrapper onSubmit={e => e.preventDefault()}>
        <FormField className='metricName'>
          <label>Metric Name</label>
            <input
              type="text"
              value={form.value}
              onChange={e => {
                let label = e.target.value;
                setForm({...form, value: label});
              }}
              onFocus={() => handleMetricsDropdown(true)}
              onBlur={e => {
                if(e.target.value !== "") {
                  let label = e.target.value;
                  let customKey = label.toString().replace(/[-()]/g, '');
                  customKey = `client_${customKey.toLowerCase().split(' ').join('_')}`;
                  if(!form.key) {
                    setForm({...form, key: customKey})
                  }
                }
              }}
            />
        </FormField>
        <FormField className='description'>
            <label>Description</label>
            <textarea value={form.description} rows={3} name="description" onChange={e => {
              setForm({...form, description: e.target.value})}
            }/>
        </FormField>
        <div className='formatters'>
          <label>Format</label>
          <FormField className='container'>
            <label className='form-radio'>
              <input type="radio" value="integer"
                checked={form.formatter === 'integer'}
                onChange={() => setForm({...form, formatter: 'integer'})}
              />
              <span>***,***,***.** (Number with decimal points)</span>
            </label>
            <label className='form-radio'>
              <input
                type="radio"
                value="float"
                checked={form.formatter === 'float'}
                onChange={() => {
                  setForm({...form, formatter: 'float'})
                }}
              />
              <span>***,***,*** (Number with 0 points)</span>
            </label>
            <label className='form-radio'>
              <input
                type="radio"
                value="percentage"
                checked={form.formatter === 'percentage'}
                onChange={() => {
                  setForm({...form, formatter: 'percentage'})}
                }
              />
              <span>--,--,--% (Number with percentage)</span>
            </label>
            <label className='form-radio'>
              <input
                type="radio"
                value="money"
                checked={form.formatter === 'money'}
                onChange={() => {
                  setForm({...form, formatter: 'money'})}
                }
              />
              <span>$--,--,--.__ (Number with $)</span>
            </label>
          </FormField>
        </div>
        {data && <FormulaGenerator disabled={isMetricEnabled} tableData={data} data={editObj} onForChange={(e, token) => {
          setForm({...form, formula: e, token: token})
        }}/>}
        <FormFooter>
          <FormField className='field-buttons'>
            <Btn className='cancel' onClick={() => {
              onCancel(defaultValues);
              setForm(defaultValues);
            }}>Cancel</Btn>
            <Btn disabled={!form.key ? true: false} type="primary" onClick={() => {
              form['formulaClient'] = getFormulaKeys(form.formula);
              form['formulaDeps'] = getFormulaDeps(form.formula);
              onSave(form);
              setForm(defaultValues);
            }}>Save</Btn>
            {/* {isDeleteBtn && <Btn type="danger" onClick={() => {
              onDelete(form);
              setForm(defaultValues);
            }}>Delete</Btn>} */}
          </FormField>
          <ErrorContainer ref={errorRef}></ErrorContainer>
        </FormFooter>
      </FormWrapper>
    </CustomMetricWrapper>
}

const FormulaGenerator = ({data, tableData, disabled, onForChange}) => {
    // let formula = '';
    const formulaTooltip = defaultTooltip;
    // if(data) {
    //     formula = data.formula
    // }
    const values = {
        "WA Business": 1,
        "Test Drives": 1
    };

    const renderFormulaTooltip = () => {
        return (
            `<div>
              <b>Rules for formula</b>
              <ul>
                <li>Click on the 'Insert Metric' dropdown to insert a metric.</li>
                <li>Use operators such as +-*/ and [] to include metric.</li>
              </ul>
            </div>`
        )
    }
    const [formula, setFormula] = useState(data?.formula)
    const [rawFormula = '', setRawFormula] = useState(data?.formula)
    const [cursorPosition, setCursorPosition] = useState({start: 0, end: 0});
    const updateFormula = (metric) => {
      let {start, end} = cursorPosition;
      // let newFormula = rawFormula + ` [${metric.value}]`;
      let newFormula = rawFormula.substring(0, start) + ` [${metric.value}] ` + rawFormula.substring(end, rawFormula.length - 1);

      values[metric.value] = 1;
      setFormula(newFormula);
    } ;
    return <FormulaBuilderWrapper>
        <div className='fBheader'>
        <label>
            Formula Builder
            <span>(`Round([Test Drives] + [Facebook])`)</span>
            <i className='material-symbols-outlined yellow'
            onMouseOver={formulaTooltip.onMouseOver({txt: renderFormulaTooltip()})}
            onMouseLeave={formulaTooltip.onMouseOut()}
            >info</i>
            </label>
        <MetricsSelector data={tableData} disabled={disabled} onItemSelect={metric => updateFormula(metric)}/>
        </div>
        <div className='formula-textarea'>
        <FormulaParser disabled={disabled}
                       dValue={formula}
                       input={values}
                       onCursorChange={cursorPosition => {
                         setCursorPosition(cursorPosition);
                       }}
                       onFormulaChange={(rawFormula, token) => {
            onForChange(rawFormula, token);
            setRawFormula(rawFormula);

        }}
        />
        {disabled && <div className='formulaDisabled'>Only Custom Metrics can create a formula</div>}
        </div>

    </FormulaBuilderWrapper>
}

const MetricsSelector = ({data, disabled, onItemSelect}) => {
    let {columns, dimension} = data;
    columns = [...columns].filter(x => !x.key.includes('client_date'));
    const dropdownRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [isTooltip, setTooltip] = useState({
        id: '',
        visible: false
    });

    useOutsideClick(dropdownRef, () => {
        if (dropdownRef) {
        setOpen(false)
        }
    });

    const handleClick = (col) => {
        onItemSelect(col);
        navigator.clipboard.writeText(col.value).then((e) =>  {
        setTooltip({id:  col.key, visible: true});

        setTimeout(() => {
            setTooltip({id: '', visible: true});
            setOpen(false);
        }, 1000);
        })
    }

    return <MetricsSelectorWrapper>
        <div className={classnames('container', {disabled: disabled === true})} onClick={e => !disabled && setOpen(true)}>
        Insert Metrics
        <i className="fa fa-caret-down" aria-hidden="true"></i>
        </div>
        {open && <>
            <div className='menu hidden' ref={dropdownRef}>
            {columns.map(col => {
                return !dimension.find(d => d.key === col.key) ? <div className='menu-item' key={col.key}  onClick={e => {
                handleClick(col);
                }}>
                {col.value}
                <i className="fa fa-clipboard" id={col.key} aria-hidden="true"></i>
                {isTooltip.visible && col.key === isTooltip.id && <div className="tooltip">Copied to clipboard!</div>}
                </div> : null}
            )}
            </div>
        </>
        }
    </MetricsSelectorWrapper>
};

const intFormatter = num => {
  if (num === null || num === undefined || isNaN(num)) {
    return ''
  }
  else if (!num) {
    return '0';
  }
  return parseInt(Number(num), 0);
}

const floatFormatter = num => {
  if (num === null || num === undefined || isNaN(num)) {
    return ''
  }
  else if (!num) {
    return '0';
  }
  return Number(num).toLocaleString();
}

// const pctFormatter = (num, digit = 1) => {
//   if (num === Infinity || num === null || num === undefined || isNaN(num)) {
//     return ''
//   }
//   else if (!num) {
//     return '0';
//   }
//   return (Number(num) * 100).toLocaleString(undefined, { maximumFractionDigits: digit || 1}) + '%';
// }
 const pctFormatter = (num, digit = 1) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '--';
  }
  return d3.format(`,.${digit || 0}%`)(num);
 }

const moneyFormatter = (num) => {
  return '$' + floatFormatter(num);
}

export {
    getClientMetrics,
    DropDownToggler,
    ColumnDropDownToggler,
    HandleAddClientData,
    CreateCustomMetricForm,
    FormulaGenerator,
    MetricsSelector,
    intFormatter,
    floatFormatter,
    pctFormatter,
    moneyFormatter
};