import styled from 'styled-components';
import {
  LightGray1,
  DarkGray1,
  Aqua,
  LightGray,
  SilverGray,
  MediumGray,
  DarkGray,
  LightBlue, Grass1
} from '../../app/StyleCommon';

export const StyledTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${LightGray1}
  border-collapse: collapse;
  border-spacing: 0;
  color: ${DarkGray1};
  background-color: rgba(255,255,255,.9);
  overflow: auto;
  position: relative;
  > thead {
    font-size: 14px;
    font-weight: 700;
    background: #fafbfc;
    background: linear-gradient(to right bottom,  ${SilverGray}, ${LightGray1});
    &:hover {
      background: linear-gradient(to right bottom,  ${SilverGray}, #e1e2e3);
    }
    &:hover .resizeHandler{
      display: block !important;
    }
    .sortable-item-hovering {
      position: relative;
      background-color: rgba(255,255,255, .5);
      &:before {
        content: '';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255, .5);
        z-index: 1;
        border: 1px dashed #ddd;
        border-radius: 5px;
      }
    }
    tr > th {
      margin: 3px 0;
      padding: 8px 10px;
      text-align: left;
      position: sticky;
      z-index: 1;
      top: 0px;
      .resizable {
          //overflow: hidden;
        .resizeHandler {
          display: none;
          cursor: col-resize;
          border-right: 1px dashed ${Grass1};
          right: -20px !important;
          &:hover {
            border-right: 2px dashed ${Grass1};
          }
        }
      }
      &.center {
        text-align: center;
      }
      &.top {
        vertical-align: top;
      }
      &.bottom {
        vertical-align: bottom;
      }
      &.right {
        text-align: right;
        padding-right: 10px;
      }
      &.sortable {
        cursor: pointer;
        padding-right: 20px;
        &.isSortColumn {
          .resizable {
            padding-right: 5px;
          }
        }
      }
      .sort-arrow {
        display: flex;
        flex-direction: column;
        position: absolute;
        background: white;
        padding-left: 5px;
        right: -10px;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        &.asc i:first-child{
          color: ${DarkGray}
        }
        &.desc i:last-child{
          color: ${DarkGray}
        }
        i {
          color: ${MediumGray};
          display: inline-block;
          height: 10px;
        }
      }
    }
  }
  > tbody {
    position: relative;
    > tr {
      //border-bottom: 1px solid ${LightGray1};
      //&:not(.placeholder):not(:nth-child(even)):hover {
      //  background-color: #f2f2f2;
      //}
      &:nth-child(even) {
        background-color: #fafbfc;
      }
      &.placeholder {
        user-select: none;
      }
      > td {
        padding: 10px 10px;
        color: ${DarkGray1};
        font-weight: 500;
        font-size: 12px;
        border-bottom: 1px solid ${LightGray};
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
        b {
          font-weight: bold;
        }

        i {
          font-weight: bold;
          text-decoration: none;
          font-style: normal;
        }
        .type {
          font-weight: bold;
        }
        .pretext {
          color: #7a7878;
          //text-decoration: line-through;
        }
        .update, .create, .delete, .type  {
          //color: #6EB3C4;
        }
        ul {
          list-style-type: disc;
          margin-left: 15px;
          li {
            margin: 2px 0
          }
        }
        label {
          font-weight: bold;
        }
      }
      &.no-data {
        color: ${MediumGray};
        td {
          text-align: center;
          min-height: 150px;
          &:hover {
            background: white;
          }
        }
      }
    }
  }
`;
