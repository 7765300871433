import styled, {css} from 'styled-components';
import {Btn} from '../uikit/index';
import {
  BitterSweet1,
  Grass1,
  BlueJeans,
  LightBlue,
  MediumGray,
  MediumGray1,
  LightGray1,
  Sunflower,
  DarkGray,
  Aladin_Orange_Light,
  Aladin_BG,
  Aladin_Purple_Light, Aladin_Blue_Dark, LightGray
} from "../app/StyleCommon";

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "FKGroteskNeue", "Open Sans", Helvetica, Arial, sans-serif;
  height: 100vh;
  width: 100vw;
  background-size: cover;

  &.morning {
    background-image: url(/img/login/morning.png);
    background-color: rgba(229, 86, 73);

    > form {
      background-color: rgba(224, 73, 60, 0.67);
    }

    .error-msg {
      color: #361329 !important;
    }
  }

  &.afternoon {
    background-color: rgba(110, 179, 196);
    background-image: url(/img/login/afternoon.png);

    > form {
      background-color: rgba(110, 179, 196, 0.5);
    }
  }

  &.evening {
    background-image: url(/img/login/evening.png);
    background-color: rgba(236, 104, 92);

    > form {
      background-color: rgba(236, 104, 92, 0.5);
    }

    .error-msg {
      color: #2f2a29 !important;
    }
  }

  //background: #f8f8fc;
  //background: linear-gradient(135deg, rgba(224,186,186,0.14) 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(225deg, rgba(235,235,235,0.61) 25%, transparent 25%) -10px 0/ 20px 20px, linear-gradient(315deg, rgba(255, 253, 253, 0.33) 25%, transparent 25%) 0px 0/ 20px 20px, linear-gradient(45deg, rgba(241, 239, 239, 0.24) 25%, #f4f4fd 25%) 0px 0/ 20px 20px;
  //background: linear-gradient(135deg, rgba(235,242,252,0.92) 25%, transparent 25%) -5px 0/ 10px 10px, linear-gradient(225deg, #eaf2fa 25%, transparent 25%) -5px 0/ 10px 10px, linear-gradient(315deg, #eef3f8 25%, transparent 25%) 0px 0/ 10px 10px, linear-gradient(45deg, #f8f6f5 25%, #f5f5f7 25%) 0px 0/ 10px 10px;
  > form {
    width: 40%;
    max-width: 420px;
    padding: 10px 30px 20px 30px;
    border-radius: 5px;
    text-align: left;
    background: rgba(255, 255, 255, .7);
    background: rgba(221, 155, 149, 0.75);
    border-radius: 15px;
    margin-top: -60px;
    @media screen and (max-width: 768px) {
      width: 90%;
      background: transparent !important;
      border: none;
    }

    .user-icon {
      font-size: 64px;
      color: ${MediumGray};
    }

    h3 {
      color: white;
    }

    > p {
      margin-bottom: 20px;
      color: white;
    }

    .form-title {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-size: 20px;
      color: ${Aladin_Orange_Light};
      font-weight: bold;

      img {
        width: 320px;
        display: block;
      }
    }

    .error-msg {
      color: ${BitterSweet1};
      margin-bottom: 30px;
      font-weight: bold;
    }

    input[type=text], input[type=password], input[type=email] {
      font-size: 14px;
      width: 100%;
      padding: 10px 10px;
      border-radius: 5px;
      box-sizing: border-box;
      transition: all .3s ease;
      vertical-align: middle;
      background: white;
      outline: none;
      border-radius: 5px;
      border: 1px solid transparent;

      &:focus {
        border-bottom: 1px solid ${Aladin_Blue_Dark};
      }
    }

    input[type=checkbox] {
      vertical-align: middle;
      //appearance: none;
      //background-color: white;
      //border: 1px solid blue;
      //width: 12px;
      //height: 12px;
      //[checked] {
      //  background-color: red;
      //}
    }

    .field {
      &:not(:first-child) {
        margin-bottom: 20px;
      }

      > label {
        display: block;
        width: auto;
        margin: 15px 0 5px 0;
        font-size: 14px;
        cursor: pointer;
        color: white;
      }
    }

    .bottom-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px auto 0 auto;
      color: white;

      a {
        color: white;
        text-decoration: none;
      }

      .sep {
        margin: 0 20px;
      }
    }
  }

  .site-footer {
    position: fixed;
    bottom: 10px;
    font-size: 12px;

    a {
      color: rgba(255,255,255,.7);
      margin: 0 10px;
      text-decoration: none;
      &:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }
`;

export const SubmitBtn = styled(Btn)`
  margin: 10px 0 10px;
  padding: 10px 10px !important;
  color: white;
  border: none;
  width: 100%;
  align-items: center;
  font-size: 20px !important;
  &:hover {
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    font-weight: bold;
  }

  &.morning {
    background-color: #361329 !important;
  }

  &.afternoon {
    background-color: #436265 !important;
  }

  &.evening {
    background-color: #361329 !important;
  }

  img {
    margin-right: 5px;
    vertical-align: middle;
  }
`;
