import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter, moneyFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Light, Aladin_Orange_Dark,
  Aladin_Orange_Light, Aladin_Purple_Dark, Aladin_Purple_Light, Aladin_Red_Light, Aqua, Aqua1,
  BitterSweet,
  BlueJeans, BlueJeans0, BlueJeans1, DarkGray,
  GrapeFruit,
  Grass,
  Grass1,
  MediumGray1,
  Mint, Sunflower
} from "../app/StyleCommon";
import {metricMetaData, platformLabelMap, siteLabelMap} from "../utils/metadata";
import {descendOtherAndUnknown} from "../utils/misc";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderChart();
  }

  render() {
    return (
      <ChartWrapper className="chart">
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data = []} = this.props;
    if(!data || !data.length) {
      return;
    }
    data = _.sortBy(data.filter(d => d.key !== 'overall'), d => ['tv', 'radio', 'digital', 'social', 'ooh', 'others'].indexOf(d.key));
    data = descendOtherAndUnknown(data);
    data.forEach(d => {
      d.name = platformLabelMap[d.key] || d.key;
    })
    const sumOfValue = _.sumBy(data, d => Number(d.value) || 0);
    const container = this.containerRef.current;
    let height = Math.max(this.containerRef.current.parentElement.offsetHeight || 0, 220);
    const myChart = echarts.init(container, null, {renderer: 'svg', height});
    let option = {
      // textStyle: {fontFamily: 'sans-serif, "Open Sans"'},
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      // color: ['#F66D44', '#FEAE65', '#E6F69D', '#AADEA7', '#64C2A6', '#2D87BB', '#3F9F9F', '#A2C3DB', "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      color: ['#f1a164', '#f4c168','#ec685c',  '#4f8f98', '#6eb3c4','#b7b8b9', '#2D6A4F', '#40916C','#532B88', '#9B72CF', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1],
      tooltip: {
        trigger: 'item',
        position: 'top',
        confine: true,
        valueFormatter: function (value) {
          return moneyFormatter(value);
          // let value = d.value;
          // if(value.length > 18){
          //   return value.substring(0, 15) + '...';
          // }
          // return value;
        }
      },
      grid: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        containLabel: true,
      },
      legend: {
        left: 0,
        top: 'center',
        orient: 'vertical',
        show: true,
        formatter: function (name) {//use formatter to check if length of string is greater than 20 and maintain its width.
          let item = data.find(d => d.key === name || platformLabelMap[d.key] === name) || {};
          name = item.label || name;
          // let str = (platformLabelMap[name] || name) + ` (${getMetricFormatter(metricMeta, currency)(item.value)})`;
          let str = (platformLabelMap[name] || name);
          if(str.length > 18){
            str =  str.substring(0, 15) + '...';
          }
          return str +  ' (' + (pctFormatter(item.value / sumOfValue)) + ')';
        }
      },
      series: [
        {
          type: 'pie',
          data: data,
          left: '100px',
          right: '0',
          label: {
            position: 'inner',
            fontSize: 12,
            color: '#605e5e',
            backgroundColor: 'rgba(255,255,255,.5)',
            padding: [1, 3, 1, 3],
            borderRadius: 5,
          },
        }
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
