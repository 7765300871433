import React from 'react';
import ReactDOM from "react-dom";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {ShareWrapper} from './datasource.style';
import {Btn} from "../uikit";
import {SelectStyled} from "../campaigns/select.style";
import {platformLabelMap, platformMeta} from "../utils/metadata";
import SearchBox from "../uikit/search/index";
import classnames from "classnames";
import {uniquesFormatter} from "../utils/formatter";

/**
 * becuase of report data limitation, currently we are not able to support multiple campaign datasource
 */
export default class extends React.Component {

  constructor(props) {
    super(props);
    const {report} = props;
    this.state = {
      datasource: _.cloneDeep(report.datasource || {campaigns: [], benchmark_campaigns: [], platforms: []}),
    }
    const {datasource} = this.state;
    datasource.metaMap = datasource.metaMap || {};
  }

  render() {
    const {report, onChange, template = {}} = this.props;
    const {datasource} = this.state;
    const {ta, platforms = []} = datasource || {};
    return (
      <ShareWrapper>
        {
          [
            {key: 'campaigns', label: 'Campaign'},
            {key: 'benchmark_campaigns', label: 'Benchmark Campaign:'},
          ].map(item => {
            if(item.key === 'benchmark_campaigns' && template.id !== 'campaign_benchmark_report') {
              return null;
            }
            let itemCampaigns = (datasource || {}) [item.key] || [];
            return (
              <React.Fragment key={item.key}>
                <div className="campaign-list-header">
                  <span>{item.label}:</span>
                </div>
                {
                  <ul className="campaign-list">
                    <li className="campaign-item">
                      <SearchBox
                        key={Date.now()}
                        className="searchBox campaigin-search-box"
                        api="/api/campaign_search"
                        placeholder="Search campaign..."
                        selected={(itemCampaigns[0] || {}).name}
                        searchWithoutKeyword={true}
                        closeOnItemClick={true}
                        selectedRaw={itemCampaigns[0]}
                        selectedItemRenderer={d => (
                          <span>
                            <div>
                              ({d.name} ({d.id})
                            </div>
                             <div className="desc">
                               <strong>{moment(d.start_date).format('MMM DD YYYY')}</strong>
                                &nbsp;-&nbsp;
                               <strong>{moment(d.end_date).format('MMM DD YYYY')}</strong>
                               &nbsp; ({(d.platforms || []).filter(p => p.key !== 'overall').map(p => platformLabelMap[p.key]).join(', ')}),
                               &nbsp; Advertiser: <strong>{d.advertiser}</strong>
                               &nbsp; Inflight Status: <strong>{d.inflight_status}</strong>
                             </div>
                          </span>
                        )}
                        itemRenderer={d => {
                          let option = d;
                          return (
                            <div className={classnames('item-option', {selected: false})} onClick={e => {
                              itemCampaigns[0] = d;
                              this.setState({datasource});
                            }}>
                              <div className="name">[{d.id}] {d.name}</div>
                              <div className="tag-list">
                                <span className="tag">Advertiser: {d.advertiser}</span>
                                <span className="tag">Period: {moment(d.start_date).format('DD MMM YYYY')} - {moment(d.end_date).format('DD MMM YYYY')} </span>
                                <span className="tag">Platforms: {(d.platforms || []).filter(p => p.key && p.key.toLowerCase() !== 'overall').map(p => {
                                  return (
                                    <span key={p.key} className='subTag'>{platformLabelMap[p.key]}</span>
                                  )
                                })}</span>
                              </div>
                            </div>
                          )
                        }}
                      />
                    </li>

                  </ul>
                }
              </React.Fragment>
            )
          })

        }

        {/*<p>Select platforms that you want to report.</p>*/}
        {/*<div className="platform-selection">*/}
        {/*  <div>Aggregate report for platforms: </div>*/}
        {/*  <div>*/}
        {/*    {*/}
        {/*      platformMeta.filter(d => d.key !== 'overall').map((platform, platformIndex) => {*/}
        {/*        return (*/}
        {/*          <label key={platform.key}>*/}
        {/*            <input*/}
        {/*              type="checkbox"*/}
        {/*               checked={platforms.indexOf(platform.key) >= 0}*/}
        {/*               onChange={e => {*/}
        {/*                if(e.target.checked) {*/}
        {/*                  platforms.push(platform.key);*/}
        {/*                } else {*/}
        {/*                  datasource.platforms = datasource.platforms.filter(k => k!== platform.key);*/}
        {/*                }*/}
        {/*                this.setState({datasource});*/}
        {/*              }}/>*/}
        {/*            {platform.label}*/}
        {/*          </label>*/}
        {/*        )*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="actions">
          {/*{!!report.datasource && }*/}
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="primary"
               disabled={!datasource.campaigns.length}
               onClick={e => {
                 this.props.onChange(datasource)
               }}>OK</Btn>
        </div>

      </ShareWrapper>
    )
  }
}