import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  MediacorpPrimary,
  Levander,
  Aladin_BG_1,
  Aladin_BG,
  Aladin_Orange_Light, Aladin_Orange_Dark
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  padding: 10px 20px;

  [contentEditable] {
    outline: none;
    min-width: 100px;

    &:focus {
      border-bottom: 1px solid ${MediacorpPrimary};
    }
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0px 5px 20px;
    border-radius: 5px;
    border: 1px solid #f1f1f8;
    background: ${Aladin_BG};
    //box-shadow: 1px 1px 2px rgba(0,0,0,.2);
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    .title {
      line-height: 1em;

      > div {
        line-height: 1.5em;
      }

      p {
        color: ${MediumGray1};
        font-size: 12px;
      }
      .campaign-info {
        color: ${MediumGray1};
        a {
          text-decoration: none;
          color: ${DarkGray};
          font-weight: normal;
          font-size: 12px;
          display: inline;
          margin-left: 10px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    a {
      margin-right: 15px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;

      i {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    .setting-container {
      text-align: right;
      width: 50%;
    }

    .setting-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .dropdown-toggler {
        display: flex;
        align-items: center;
        width: 40px;
        white-space: nowrap;
      }
      .dropdown-toggle {
        width: 30px;
      }

      .dropdown-toggler-inner {
        white-space: nowrap;
      }

      .dropdown {
        //margin-left: 30px;
      }

      a, button {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;

        i {
          font-size: 18px;
          font-weight: bold;
          vertical-align: middle;
        }

        &.active {
          color: ${Aladin_Orange_Light};
        }

        &:hover {
          background-color: ${Aladin_Orange_Light};
          color: white;
        }
      }
    }
  }

  .grid-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    .note {
      margin-bottom: 10px;
    }
    > div {
      height: calc(100vh - 200px);
    }

    > div:first-child {
      flex-grow: 1;
      padding-left: 10px;
      height: calc(100vh - 220px);
    }

    > div:last-child {
      margin-top: 0px;
      height: calc(100vh - 220px);
    }

    .chart-wrapper {
      .chart-container-menu {
        opacity: 0;
        z-index: 2;
      }

      &:hover .chart-container-menu {
        opacity: 1 !important;
      }
    }
  }
`
export const SaveReportDailogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  .subhead {
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .primary {
    padding: 5px 20px;
    min-width: 90px;
    background-color: ${Aladin_Orange_Dark};
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    color: white;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    cursor: pointer !important;
  }
`