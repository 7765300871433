export const PureWhite = '#fff';
export const Dark = '#000';

export const GrapeFruit = '#ED5565';
export const GrapeFruit1 = '#DA4453';

export const BitterSweet = '#FC6E51';
export const BitterSweet1 = '#E9573F';
export const BitterSweetLight = '#feebe7';

export const Sunflower = '#FFCE54';
export const Sunflower1 = '#F6BB42';
export const SunflowerLight = '#fff2d2';

export const Grass0 = '#d8f3ba';
export const Grass = '#A0D468';
export const Grass1 = '#8CC152';
export const GrassLight = '#effce1';

export const Mint = '#48CFAD';
export const Mint1 = '#37BC9B';

export const Aqua = '#4FC1E9';
export const Aqua1 = '#3BAFDA';

export const BlueJeans0 = '#e0eeff';
export const BlueJeans = '#5D9CEC';
export const BlueJeans1 = '#4A89DC';
export const LightBlue = '#e2efff';
export const LightBlue1 = '#cadffa';
export const DarkBlue = '#404854';
export const SunflowerBlue = "#8093F1";

export const PrimaryDarkBlue = '#0A5685';
export const PrimaryMediumBlue = '#587080';
export const SecondaryMediumBlue= '#63C3FF';
export const PrimaryLightBlue = '#8DB4CC';

export const Levander = '#AC92EC';
export const Levander1 = '#867ADC';

// export const MeOrangeLight = '#ffeed6';
export const MeOrange = '#f7a433';
export const MeOrange1 = '#df8203';
export const MeOrangeLight = '#fff0e0';

export const PinkRose = '#EC87C0';
export const PinkRose1 = '#D770AD';

export const LightGray = '#F5F7FA';
export const LightGray1 = '#E6E9ED';
export const LightGray2 = '#f7f7f7';

export const MediumGray = '#CCD1D9';
export const MediumGray1 = '#AAB2BD';
export const MediumGray2 = '#8c9cb0';

export const DarkGray = '#656D78';
export const DarkGray1 = '#434A54';

export const SilverGray = '#f6f8fa';
export const SilverDarkGray = '#2a2a36';
export const SilverSand = '#BBC8CA';

export const MenuBlue = "#2196F3";

export const RedSands = '#94020c';

export const FaceBook = '#325a9d';
export const Google = '#e33330';

export const Tan = '#dfa06e';
export const RosyBrown = '#B49286';
export const MediacorpPrimary = '#964fb0';
export const MediacorpPrimary1 = '#711f8f';

export const Aladin_BG = '#f7f7fc';
export const Aladin_BG_1 = '#ebe3fa';
export const Aladin_BG_2 = '#f3dcb5';

export const Aladin_BG_TV_1 = '#e6e7e8';
export const Aladin_BG_TV_2 = '#e1e2e3';

export const Aladin_Gray_Dark = 'rgb(66,62,63)';

export const Aladin_Orange_Lighter = '#f3c079';
export const Aladin_Orange_Light = '#f4c168';
export const Aladin_Orange_Dark = '#f1a164';


export const Aladin_Red_Lighter = '#f3acac';
export const Aladin_Red_Light = '#ef7b7b';
export const Aladin_Red_Dark = '#ff5858';

export const Aladin_Blue_Dark = '#8fd3f4';
export const Aladin_Blue_Dark1 = '#44bcf6';
export const Aladin_Blue_Light = '#bbe7fc';

export const Aladin_Purple_Lighter = '#d2b3f5';
export const Aladin_Purple_Light = '#c090f5';
export const Aladin_Purple_Dark = '#a85efa';

export const Aladdin_v2_red1 = '#ec685c';

export const Aladdin_v2_orange1 = '#f4c168';
export const Aladdin_v2_orange2 = '#f4c168';

export const Aladdin_v2_blue1 = '#436265';
export const Aladdin_v2_blue2 = '#4f8f98';
export const Aladdin_v2_blue3 = '#6eb3c4';

export const colorCategory1 = [
  SunflowerBlue, Grass, Levander, BitterSweet, Aqua, PinkRose, Mint, Tan, SilverSand, Google, RosyBrown
];

export const colorCategory2 = [
  BlueJeans, Grass, Sunflower, BitterSweet, MediumGray
];
export const colorCategory3 = [
  Grass, Sunflower, BitterSweet, Aqua, PinkRose, MediumGray
];
// export const colorCategory4 = ['#F66D44', '#FEAE65', '#E6F69D', '#AADEA7', '#64C2A6', '#2D87BB', '#3F9F9F', '#A2C3DB', "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"];
export const colorCategory4 = ['#f5d9a8', '#addeea', '#d3beee', '#96d3b7'];

export const colorsDaypart = ['#ec685c', '#6eb3c4', '#f1a164'];
export const colorsAladdin = ['#ec685c', '#f1a164', '#f4c168', '#436265', '#4f8f98', '#6eb3c4'];


/*
* global z-indexs
* */
export const LoadingZIndex = 15;
export const SideMenuZIndex = 10;
export const HeaderZIndex = 9;
export const BodyMaskZIndex = 9;
export const ElementMaskZIndex = 5;
export const DialogZIndex = 7;
export const DialogMaskZIndex = 11;
export const DropdownZIndex = 6;
export const SubHeaderZIndex = 8;

/**
 * global font size
 */
export const FontSizeSuperMini = '10px';
export const FontSizeMini = '11px';
export const FontSizeSmall = '12px';
export const FontSizeMedium = '14px';
export const FontSizeBig = '16px';
export const FontSizeBig1 = '18px';
export const FontSizeExtraBig = '22px';
export const FontSizeExtraBig2 = '26px';
export const FontSizeExtraBig3 = '32px';



