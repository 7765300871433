import styled, {css} from 'styled-components';
import {
  DropdownZIndex,
  FontSizeBig,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  BlueJeans, DarkGray, BlueJeans1, FontSizeSmall, DarkGray1, MediumGray1, BitterSweet, Grass, Grass1, Grass0, MeOrange1,
  Aqua1, Aqua, Mint, Mint1, Aladin_Orange_Light, Aladin_Orange_Dark, Aladin_BG_1, Aladin_BG, Aladin_Orange_Lighter
} from "../../app/StyleCommon";

export const SelectWrapper = styled.div.attrs({
  'data-rp': 'select-box'
})`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const SelectToggler = styled.div`
  position: relative;
  cursor: pointer;
  padding: 5px 10px;
  padding-right: 30px;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${FontSizeMedium};
  white-space: nowrap;
  background-color: transparent;
  img.icon {
    width: 16px;
    vertical-align: middle;
    margin-right: 10px;
  }
  ${props => props.unSelectable && css`
     &&&{
      padding-right: 60px;
     }
  `}
  ${props => props.disabled && css`
    cursor: not-allowed;
    background-color: #f1f2f3 !important;
    opacity: .5;
    > i.caret {
      display: none;
    }
  `}
  ${props => !props.disabled && css`
    &:hover {
      background: ${MediumGray};
    }
  `}
  &.has-clear-btn {
    position: relative;
    &:before {
      display: block;
      content: '';
      right: 0px;
      top: 0;
      bottom: 0;
      position: absolute;
      right: 0;
      background-color: white;
      width: 45px;
      opacity: .9;

    }
  }
  .default-label {
    font-size: 14px;
    color: ${MediumGray1};
    display: inline-block;
    transform: translate(3px, 0);
  }
  .search-input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    opacity: 0;
    padding: 0 10px;
    outline: none;
    font-size: 12px;
    color: black;
    &::placeholder {
      font-size: 14px;
      color: ${MediumGray1};
      line-height: 1.5em;
    }
    &:focus {
      opacity: 1;
    }
  }
  > i {
    margin-right: 5px;
    font-size: 12px;
  }
  > i.caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: ${FontSizeMedium};
  }
  > i.clear-all {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    font-weight: 300;
  }
`;

export const SelectMenu = styled.div`
  position: absolute;
  top: 25px;
  left: 0px;
  right: 0;
  overflow: hidden;
  //transition: all .3s ease;
  opacity: 0;
  z-index: ${DropdownZIndex};
  min-width: 200px;
  //box-shadow: 0px 1px 3px rgba(0,0,0,.1);
  background-color: white;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, .1));
  ${props => props.active && css`
     padding: 5px 0;
     opacity: 1;
  `}
  ${props => !props.active && css`
     pointer-events: none !important;
     display: none;
  `}
  .menu-header {
    margin-top: -5px;
    padding: 5px 20px 5px 20px;
    border-bottom: 1px solid ${LightGray1};
    background: ${LightGray};
    color: ${DarkGray1};
  }
  .close-menu-btn,
  .custom-menu-btn {
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${DarkGray};
    cursor: pointer;
    background-color: ${LightGray1};
    &:hover {
      color: ${MeOrange1};
    }
  }
`

export const MenuGroup = styled.div`
  h5 {
    padding: 10px 15px;
    margin: 0;
  }
  > ul {
     &.closed {
      display: none;
     }
  }
  .empty-group {
    color: ${MediumGray1};
    margin: -6px 0px 0 20px;
  }
`
export const MenuGroupHeader = styled.div`
  ${props => props.closable && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0 20px 0 10px;
    h5 {
      padding: 10px 0;
      margin: 0;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 12px;
      font-weight: normal;
    }
    &:hover {
      background-color: ${LightGray};
    }
  `}
  i {
    font-size: 12px;
  }
`;
export const MenuItem = styled.label`
  padding: 2px 10px;
  cursor: pointer;
  display: block;
  font-size: 12px;
  color: ${DarkGray1};
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  &:hover {
    background: ${Aladin_BG};
  }
  ${props => !props.multi && props.selected && css`
    background: ${Aladin_Orange_Lighter};
    color: white;
    &:hover {
      background: ${Aladin_Orange_Light};
    }
  `}
  input {
    margin-right: 10px;
    vertical-align: middle;
    height: 20px;
  }
  input[type=checkbox] {
    min-width: inherit;
  }
  input[type=radio] {
    visibility: hidden;
    width: 0;
    min-width: inherit;
    height: 25px;
  }

  ${props => props.disabled && css`
    opacity: .5;
    cursor: not-allowed;
    &.selected {
      opacity: 1;
      color: ${Aladin_Orange_Dark} !important;
    }
  `}
`;
