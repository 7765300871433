import styled, {css} from 'styled-components';
import {
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG, Aladin_BG_1, Sunflower, Aladin_BG_TV_1
} from "../app/StyleCommon";

export const Wrapper = styled.div`
  padding: 10px;
  position: relative;
  &.disabled {
    //pointer-events: none;
  }
  .campaign404 {
    position: fixed;
    top: 50px;
    height: 100vh;
    height: calc(100vh - 50px);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${BitterSweet1};
    z-index: 8;
    background-color: rgba(255,255,255,.9);
  }
  >.header {
    padding: 0px 0px;
    @media screen and (max-width: 768px) {
      padding: 0 30px !important;
      p {
        margin-left: 0 !important;
      }
    }
    @media screen and (min-width: 768px) {
      padding: 0 16px !important;
      p {
        margin-left: 0 !important;
      }
    }
    i {
      font-size: 16px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  >.header > .settings {
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    padding: 16px 85px 16px 0;
  }

  > h3 {
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
    }
  }

  > p {
    max-width: 90%;

    .desc {
      font-size: 12px;
      color: ${DarkGray};
    }
  }
  .alert-box {
    margin: 10px 50px;
    @media screen and (max-width: 768px) {
      margin: 10px 0px;
    }
  }

  .msq-search-box {
    // overflow: hidden;
  }

  .msq-search-box, .search-box {
    flex-grow: 1;
    position: relative;
    border-bottom: 1px solid ${MediumGray};

    i.fa-search {
      position: absolute;
      left: 10px;
      top: 18px;
      color: ${MediumGray1};
    }

    .msq-item-option, .dropdown-item > div {
      cursor: pointer;
      padding: 8px 10px;
      border-bottom: 1px solid ${Aladin_BG_1};

      &:nth-child(odd) {
        background: ${LightGray};
      }

      &.selected {
        background-color: #faeccb;
      }

      &:hover {
        background: #fcefd1;
      }
    }

    .msq-item-option,
    .item-option {

      &.disabled {
        * {
          color: ${MediumGray1} !important;
          cursor: not-allowed  !important;
          pointer-events: none  !important;
        }
      }



      .msq-name {
        font-weight: bold;
        font-size: 12px;
        color: ${DarkGray1};
        word-break: break-word;
        margin-right: 10px;
      }

      .msq-tag {
        padding: 1px 5px 1px 0;
        border-radius: 4px;
        margin: 0 10px 5px 0;
        font-size: 10px;
        //background-color: rgba(248, 242, 238, 0.38);
        color: ${DarkGray1};

      }
      .msq-platform-list {
        font-size: 12px;
        list-style: square;
        margin-left: 20px;
      }
    }

    input {
      padding: 10px 10px 10px 30px;
      font-size: 14px;
      border-radius: 0px;
      border: 1px solid ${MediumGray};
      border: none;
      background: transparent;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      color: ${Aladin_Orange_Dark};
      outline: none;
      &:not(.show) {
        opacity: 0;
      }

      ::placeholder {
        color: ${MediumGray1};
        opacity: .5;
        font-weight: bold;
      }

      &:focus {
        background: ${Aladin_BG};

        ::placeholder {
          color: ${Aladin_Orange_Dark};
        }

        & ~ i {
          color: ${Aladin_Orange_Dark};
        }
      }
    }
  }

  thead th {
    background: white;
    border: none;
    white-space: nowrap;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid ${MediumGray};
  }
`

export const StepWrapper = styled.div`
  margin: 40px 0 10px 4%;
  counter-reset: section;
  @media screen and (max-width: 768px) {
    margin-left: 15px;
  }
  .field-note {
    margin: -20px 10px 35px 10px;
    font-size: 12px;
    font-weight: bold;
    color: ${MediumGray1};
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    //background: linear-gradient(to bottom, rgba(255,255,255, .5), rgba(255,255,255, .7), rgba(255,255,255, .9), white, white);
    background: white;
    padding: 10px 10px 10px 10px;
    padding-right: calc(10% - 50px);
    border-top: 1px solid #f3b8d1;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 210px;
    background: ${Aladin_BG};
    z-index: 2;
    button {
      margin: auto 20px !important;
    }
  }

  .step {
    padding-bottom: 50px;
    padding-left: 40px;
    margin-left: 20px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding-left: 15px;
      margin-left: 10px;
    }
    >.step-mask {
      position: absolute;
      left: 30px;
      right: 0;
      top: 40px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255,255,255, .8);
      z-index: 1;
      font-size: 16px;
    }
    > h3 {
      font-size: 14px;
      color: ${DarkGray};
      background: ${LightGray};
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      margin-right: 100px;
      box-sizing: border-box;
      a {
        color: ${Grass1};
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    position: relative;
    min-height: 50px;

    &:not(:last-child) {
      border-left: 1px solid ${MediumGray};
    }

    &:before {
      counter-increment: section;
      content: counter(section);
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      font-size: 12px;
      font-weight: bold;
      transform: translate(-50%, -5px);
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${Aladin_Orange_Light};
      color: white;
      border-radius: 50%;
      border: 5px solid white;
    }
  }

  .field {
    margin-bottom: 25px;
    margin-right: 100px;
    position: relative;
    @media screen and (max-width: 768px) {
      margin-right: 24px;
      flex-wrap: wrap;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-right: 40px;
      flex-wrap: wrap;
    }
    .error-msg {
      position: absolute;
      text-align: right;
      right: 0px;
      bottom: 3px;
      opacity: .9;
      background-color: white;
      padding: 4px 5px;
      display: inline-block !important;
      width: auto !important;
      font-weight: bold;
      &.left {
        left: 0;
        right: auto;
      }
      &.static {
        position: static;
        text-align: left;
      }
    }

    .linkage-plus, .id-filters {
      cursor: pointer;
      margin-left: 10px;
      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }

    .linkage-list, .idfilter-list {
      width: 100%;
      .linkage-item, .filter-item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          .sep {
            margin: 10px 0 !important;
            width: 100%;
          }
        }
        .search-box {
          padding-right: 16px;
        }
        .sub-item-btn {
          cursor: pointer;
          i {
            font-size: 16px;
          }
        }

        .sub-item-label {
          width: 150px;
          margin-left: 0;
          font-size: 12px;
          i {
            font-size: 16px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }

        > div.select-box {
          width: 180px;
        }
        > span.sep {
          width: 60px;
          text-align: center;
        }
        >div.searchBox {
          width: calc(100% - 390px);
        }
        >a.sub-item-btn {
          width: 20px;
        }

        @media screen and (max-width: 768px) {

        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
        }
      }
    }

    .react-datepicker__aria-live {
      display: none;
    }

    &.changed > input,
    &.changed .react-datepicker__input-container input,
    &.changed .searchBox .select-value,
    &.changed .select-box .toggler {
      font-weight: bold;
      color: ${Aladin_Orange_Dark};
    }

    &.error > input,
    &.error .react-datepicker__input-container input,
    &.error .select-box .toggler {
      border-bottom: 1px dashed ${BitterSweet1}
    }

    display: flex;
    align-items: center;

    > label, .label {
      min-width: 100px;
      width: 100px;
      text-align: right;
      flex: none;
      margin-right: 24px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 150px;
        width: 150px;
      }
      @media screen and (min-width: 1024px) {
        min-width: 20%;
        // width: 300px;
      }
      .hint {
        position: absolute;
        top: 15px;
      }

      &.top {
        vertical-align: top;
        align-self: flex-start;
        margin-top: 25px;
      }
    }
    .fieldBox, .react-datepicker-wrapper {
      width: calc(100% - 130px);
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: calc(100% - 180px);
        width: calc(100% - 180px);
      }
      @media screen and (min-width: 1024px) {
        width: 80%;
        // width: calc(100% - 300px);
      }
    }

    > input {
      width: 100%;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      padding: 5px 10px 5px 10px;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-bottom: 1px solid ${MediumGray1};
      }
    }

    .select-box {
      &[disabled] .toggler {
        background: #fafafa !important;
        .default-label{
          font-size: 12px !important;
          color: ${DarkGray} !important;
        }
      }
      .toggler {
        width: 100%;
        background: white;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${MediumGray};
        opacity: 1;
        .default-label {
        }
      }
    }

    .error-msg {
      color: ${BitterSweet};
      display: block;
      width: 100%;
      font-size: 12px;
      margin-top: -6px;
    }
  }

  .react-datepicker-wrapper {
    display: block;
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
    }

    input[type=text] {
      padding: 10px 10px;
      width: 100%;
      border: none;
      box-sizing: border-box;
      border-bottom: 1px solid ${MediumGray};
    }

    .react-datepicker__close-icon::after {
      background-color: ${MediumGray};
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker {
    border: 1px solid ${MediumGray};

    .react-datepicker__time-container {
      border: 1px solid ${LightGray1};
    }

    &__header {
      background-color: white;
    }

    &__header--time {
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
    }

    &__time-list-item {
      height: 18px !important;
    }

    &__time-list {
      padding-top: 10px;
    }

    &__time-list-item--selected {
      background-color: ${Aladin_Orange_Light} !important;
    }

    &__day--selected {
      background-color: ${Aladin_Orange_Light} !important;
    }

    &__navigation--next {
      right: 90px;
    }

    &__day--keyboard-selected {
      background-color: ${MediumGray};
    }

    @media screen and (max-width: 667px) {
      &__day {
        margin: 0;
      }

      &__time-container {
        width: 60px;
      }

      &__time-list-item {
        padding: 5px 0px !important;
        width: 60px !important;
      }
    }
  }

  .add-platform-dropdown {
    width: 100px;
    .menu-item {
      padding: 10px;
      &.disabled {
        pointer-events: none;
        opacity: .5;
      }
    }
  }
  .platform-item {
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      >.label {
        width: 100%;
        text-align: left;
      }
    }
  }
  .platform-cfg {
    width: 100%;
      //background: ${LightGray};
    padding: 10px 5px;
    display: flex;
    align-items: center;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap !important;
      .ta, .budget, .goal {
        width: 100% !important;
        margin: 10px 0;
        padding: 0 !important;
      }
      .goal {
        .goal-item {
          flex-wrap: wrap;
          >a {
            margin: 10px 0;
          }
        }
        .sep {
          margin: 10px !important;
        }
      }
    }
    &.header {
      background: white;
      text-align: left;
      font-weight: bold;
      border-bottom: 1px solid ${MediumGray};
      @media screen and (max-width: 768px) {
        display: none;
      }
      .ta {
        width: 20%;
      }
      .budget, .ta {
        border-right: 1px solid ${MediumGray};
      }
      .desc {
        font-size: 12px;
        font-weight: normal;
        margin: 0 5px;
      }

      .goal {
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        i {
          font-size: 16px;
        }
      }
    }
    &.settings {
      margin-left: 50px;
      display: flex;
    }

    input.editor {
      border: none;
      border-bottom: 1px solid ${MediumGray1};
      padding: 8px 5px;
      text-align: right;
      outline: none;
      box-sizing: border-box;
      width: 100%;
      min-width: 40px;
    }

    > .top {
      align-self: flex-start;
      margin-top: 10px;
    }

    .budget {
      width: 23%;
      //min-width: 80px;
      flex: none;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      box-sizing: border-box;
      position: relative;
      &:hover .calculator {
        opacity: 1;
      }
      .calculator {
        position: absolute;
        opacity: 0;
        right: -5px;
        bottom: 3px;
        cursor: pointer;
        >span {font-size: 16px; color: #6EB3DE}
      }
      .error-msg {
        bottom: -10px;
        background: transparent;
        top: 110%;
        text-align: left;
        left: 0;
      }
      i {
        font-size: 16px;
        position: absolute;
        left: 10px;
        top: 6px;
      }

      .budget-editor {
        padding-left: 25px;
        &.changed {
          font-weight: bold;
          color: ${Aladin_Orange_Dark};
        }
        &.error {
          border-bottom: 1px dashed ${BitterSweet1};
        }
      }
    }

    .ta {
      width: 25%;
      box-sizing: border-box;
      flex: none;
      padding-left: 0px;
      padding-right: 10px;
      margin-right: 0px;
    }

    .goal {
      width: 60%;
      flex-grow: 1;
      //background: ${Aladin_BG};
      padding: 0px;
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }
      .goal-item {
        display: flex;
        align-items: center;
        padding: 5px 0;
        position: relative;
        .goal-value-desc {
          position: absolute;
          right: 52px;
          font-size: 10px;
        }
        &.changed input,
        &.changed .toggler {
          font-weight: bold;
          color: ${Aladin_Orange_Dark};
        }
        &.error {
          border: 1px dotted red;
          border-radius: 5px;
          margin-bottom: 20px;
        }
        .select-box {
          //min-width: 140px;
          .menu {
            right: auto;
            max-height: 400px;
            overflow-y: auto;
            * {
              white-space: nowrap;
            }
          }
          &.error .toggler{
            border-bottom: 1px dashed ${BitterSweet1};
          }
        }
        input.error {
          border-bottom: 1px dashed ${BitterSweet1};
        }
        .error-msg {
          top: -8px;
          left: 10px !important;
          bottom: auto !important;
          right: auto  !important;
          text-align: left;
          background: white;
        }
      }

      .sep {
        margin: 0 10px;
      }

      a {
        cursor: pointer;
        margin-left: 20px;
        &:last-child {
          margin-left: 8px;
        }
        i {
          font-size: 18px;
        }

        &:hover {
          color: ${Grass1};
        }
      }
    }
  }
  .platform-action {
    cursor: pointer;
    position: absolute;
    right: -30px;
    top: 20px;
    opacity: 0;
    transition: all .5s ease;
  }
  .platform-item:hover .platform-action {
    opacity: 1 !important;
  }

  .step-recipients {
    .share-box {
      .toggler {
        white-space: pre-wrap;
        //input {
        //  position: relative;
        //  width: 50px;
        //}
        .tag-value {
          font-size: 12px;
          padding: 3px 10px;
          background: ${LightBlue};
          border-radius: 5px;
          margin: 5px 10px 5px 0;
          display: inline-block;
        }
      }

      .menu {
        left: 0;
        //top: auto;
        //bottom: 30px;
      }
    }
  }
`;

export const AlertBox = styled.div`
  // background: #f8e8bf;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #f3e0ae;
  background: #f7eac9;

  ${props => props.type === 'info' && css`
    background: #fff7e4;
    font-weight: normal;
  `}
  ${props => props.type === 'note' && css`
    background: rgba(212, 229, 234, 0.49);
    border: none;
    margin-top: 20px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    i {
      font-size: 16px;
      color: ${MediumGray1};
      vertical-align: sub !important;
    }
  `}
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    color: #ec685c;
    span {
      font-size: 12px;
      color: #898787;
    }
  }

  .desc {
    font-size: 12px;

    b {
      font-weight: bold;
    }

    i {
      font-weight: bold;
      text-decoration: none;
      font-style: normal;
      font-size: 14px;
      vertical-align: inherit;
    }
    .type {
      font-weight: bold;
    }
    .pretext {
      color: #7a7878;
      //text-decoration: line-through;
    }
    .update, .create, .delete, .type  {
      //color: #6EB3C4;
    }
    ul {
      list-style-type: disc;
      margin-left: 15px;
      li {
        margin: 2px 0
      }
    }
    label {
      font-weight: bold;
    }
  }

  .actions {
    text-align: right;
    padding: 10px 10px;

    button {
    }
  }
`

export const ConfirmBox = styled.div`
  .title {
    font-weight: bold;
    margin-bottom: 16px;
    color: #f1a164;
    font-size: 16px;
  }
  .desc {
    font-size: 14px;
    margin-bottom: 16px;
  }
`

export const CustomMetricDialogWrapper =  styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  height: 370px;

  h3 {
    margin: 0 0 16px 0;
    z-index: 1;
  }

  .metrics-container {
    position: relative;
  }

  .metrics-message {
    color: ${Aladin_Orange_Dark};
    margin: 8px 0;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    bottom: 22px;
    z-index: 1000;
  }

  .dropDownBtn {
    position: absolute;
    right: 20px;
    z-index: 2;
    top: 30px;
    i {
      margin-right: 10px;
      &:hover {
        color: ${Aladin_Orange_Light};
      }
    }

  }

  .metrics-list {
    h4 {
      margin-top: 0;
    }
    ul {
      li {
          display: flex;
          padding: 4px 8px;
          border-bottom: 1px solid #ccc;
          label {
            width: 90%;
          }
          i {
            width: 5%;
            color: ${MediumGray1};
            cursor: pointer;
            &:hover {
              color: ${Aladin_Orange_Light}
            }
          }
          &:hover {
            background-color: ${LightGray}
          }
        }
    }
  }
  .create-metrics {
    position: absolute;
    top: -50px;
    width: 100%;
    background: white;
    z-index: 0;
    h4 {
      margin-top: 0;
    }
    form {
      background-color: white !important;
    }
    input {background: white;}
    > div {
      top: 80px;
      width: 100%;
      padding: 0;
      position: relative;
    }
  }
  .metrics-list {
    padding: 0 12px;
  }

  label {
    font-weight: 600;
  }

  .formatters {
    margin-bottom: 36px;
    > div {
      flex-wrap: wrap;
      > div {
        width: 45%;
      }
    }
  }
  .form {
    background: white;
  }
`

export const DropdownToggle =  styled.div`
  position: relative;
  z-index: 2;
  top: 35px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  i {
    cursor: pointer;
    &:hover {
      color:${Aladin_Orange_Light}
    }
  }
  .menu {
    top: 27px;
    width: 100%;
    right: 0;
    min-width: auto;
    position: absolute;
    // max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
  }
  .menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    padding: 10px 15px;
    font-weight: bold;
  }
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    cursor: pointer;
    font-weight: normal;
    &:hover {
      background: rgb(247, 247, 252);
      color: black;
    }
    >label {
      display: inline-block;
      width: 120px;
      margin-right: 10px;
      flex: none;
    }
    i {
      padding-left: 0;
      padding-right: 6px;
      font-size: 8px;
      font-weight: normal;
      visibility: hidden;
      margin-right: 4px;
      color: ${Aladin_Orange_Light};
      cursor: pointer;
    }
    i.visible {
      visibility: visible;
    }
  }
`

export const DropdownToggleIcon =  styled.div`
  position: relative;
  z-index: 2;
  top: 35px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  i {
    cursor: pointer;
    &:hover {
      color:${Aladin_Orange_Light}
    }
  }
  .menu {
    top: 27px;
    width: 100%;
    right: 0;
    min-width: auto;
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
  }
  .menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    padding: 10px 15px;
    font-weight: bold;
  }
  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    cursor: pointer;
    font-weight: normal;
    &:hover {
      background: rgb(247, 247, 252);
      color: black;
    }
    >label {
      display: inline-block;
      width: 120px;
      margin-right: 10px;
      flex: none;
    }
    i {
      padding-right: 0;
      font-size: 18px;
      font-weight: normal;
      color: #000;
      cursor: pointer;
    }
    i.visible {
      visibility: visible;
    }
  }
`
