import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  MediacorpPrimary,
  Levander,
  Aladin_BG_1,
  Aladin_BG,
  Aladin_Orange_Light, Aladin_Orange_Dark
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const ShareWrapper = styled.div`
  > p {
    font-weight: bold;
    font-size: 12px;
  }
  .share-list {
    background: ${Aladin_BG};
    padding: 10px 10px;
    border-radius: 20px;
    .share-item {
      list-style: circle;
      margin: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .tag {
        background: ${Aladin_BG_1};
        font-size: 12px;
        padding: 2px 5px;
        font-weight: bold;
        margin-right: 10px;
      }
      .shared-item-addedList {
        width: calc(100% - 150px);
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          flex-wrap: wrap;
        }
        > p:first-child {
          margin: 0;
          margin-bottom: 8px;
        }
        > p:last-child {
          margin: 0;
          margin-top: 8px;
        }
      }
    }

  }
  .share-create {
    display: flex;
    align-items: flex-start;
    padding: 20px 10px 40px 10px;
    min-height: 100px;
    border: 1px dashed ${MediumGray};
    margin-bottom: 20px;
    .share-box {
      width: calc(100% - 100px);
      .tag-list {
        width: calc(100% - 50px);
      }
      .clearAll {
        width: 50px
      }
      .msq-search-box {
        width: auto;
      }
    }
    .add-share {
      margin-top: 5px;
      cursor: pointer;
      color: ${Aladin_Orange_Dark};
      font-weight: bold;
      width: 100px;
    }
    .user-select {
      border: none;
      margin-right: 10px;
      background-color: ${Aladin_BG};
      background: transparent;
      input {
        background: transparent;
        color: black;
      }
      i {
        top: 12px;
      }
      .item-option {
        cursor: pointer;
        padding: 8px 10px;
        border-bottom: 1px solid ${Aladin_BG_1};
        background: white;
        &.selected {
          background-color: #faeccb;
        }

        &:hover {
          background: #fcefd1;
        }
      }
    }
  }
`
