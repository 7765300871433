import React, {Component} from 'react';
import moment from 'moment';
import {withRouter} from 'react-router';
import _ from 'lodash';
import {Link} from 'react-router-dom';

import {withConsumer} from '../app/ApplicationContext';
import {FaqWrapper, FaqToggler, FaqMenu, MenuItem} from './faq.style'

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      messages: []
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const {className} = this.props;

    return (
      <FaqWrapper className={className} ref={this.setWrapperRef}>
        <FaqToggler
          className="toggler"
          active={this.state.active}
          onClick={e => this.handleToggle(e)}>
          <i className="fa fa-question-circle" aria-hidden="true"></i>
        </FaqToggler>
        <FaqMenu className="menu" active={this.state.active}>
          <i className="close fa fa-times" onClick={e => this.handleToggle(e)}/>
          <h3>User Guide</h3>
          <p>Here's the help center for you to get familar with platform functions.</p>
          <ul>
            <li><MenuItem href="/user-guide/system-objectives">Campaign Management</MenuItem></li>
            <li><MenuItem href="/user-guide/roles-and-responsibilities">Reporting</MenuItem></li>
            <li>
              <ul>
                <li><MenuItem href="/user-guide/roles-and-responsibilities#rar-reviewer">Sharing</MenuItem></li>
                <li><MenuItem href="/user-guide/roles-and-responsibilities#rar-checker">Templates</MenuItem></li>
                <li><MenuItem href="/user-guide/roles-and-responsibilities#rar-approver">Customisation</MenuItem></li>
              </ul>
            </li>
            <li><MenuItem href="/user-guide/web-api-and-manual-upload">Metrics</MenuItem></li>
            <li><MenuItem href="/user-guide/ratecard">Admin Settings</MenuItem></li>
            <li><MenuItem href="/user-guide/commission-validation">User settings</MenuItem></li>
          </ul>
        </FaqMenu>
      </FaqWrapper>
    );
  }

  handleToggle(e) {
    const {active} = this.state;
    this.setState({active: !active});
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({active: false});
    }
  }
}