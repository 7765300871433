import styled, {css} from 'styled-components';
import {
  Aladin_BG,
  Aladin_BG_1, Aladin_Orange_Dark,
  BitterSweet, DarkGray1, DropdownZIndex, FontSizeMedium, LightGray, LightGray1,
  MediumGray, Sunflower, MediumGray1
} from "../../app/StyleCommon";

export const AutoCompleteWrapper = styled.div`
  position: relative;
  &.disabled {
    background: ${LightGray};
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const SearchBox = styled.div`
  position: relative;
  ${props => props.wide && css`
    @media (max-width: 667px) {
      width: 100%;
    }
  `}
  ${props => props.searching && css`
    .spinner {
      display: block !important;
    }
  `}

  > input{
    width: 100%;
    box-sizing: border-box;
    padding: 6px 30px 6px 35px;
    font-size: ${FontSizeMedium};
    border: none;
    background: #757a82;
    border-radius: 4px;
    outline: none;
    transition: width .3s ease;
    font-weight: bold;
    cursor: pointer;
    &[disabled] {
      background-color: ${LightGray} !important;
    }
    &:focus {

    }
    @media (max-width: 667px) {
      transition: none;
    }
    &.wideMode {
      border-color: rgba(255,255,255,.8);
      box-shadow: none !important;
    }
  }
  > .select-value1 {
    position: absolute;
    width: calc(100% - 20px);
    padding: 10px 10px 10px 0px;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    transition: all .2s ease;
    background-color: white;
    bottom: 0px;
    z-index: 1;
    margin-left: 30px;
    box-sizing: border-box;

    span {
      cursor: pointer
    }
    &.peep {
      right: 30px;
      left: auto;
      font-size: 10px;
    }
    .desc {
      font-size: 11px;
      color: #6b819f;
    }
  }
  i.fa-search {
    position: absolute;
    left: 10px;
    top: 18px;
    transform: translateY(-50%);
    font-size: 16px;
  }
  .spinner {
    display: block;
    position: absolute;
    left: 5px;
    top: 8px;
  }
  .hint-wrapper {
    position: absolute;
    right: 15px;
    font-size: 16px;
    i {
      color: ${BitterSweet} !important;
    }
  }
`;

export const SearchBoxDialog = styled.div`
  position: relative;
  border-color: rgba(255,255,255,.8);
  border-radius: 8px;
  margin-bottom: 16px;
  background: #fcfdfd;

  > input{
    width: 100%;
    color: ${BitterSweet} !important;
    font-weight: 600;
    box-sizing: border-box;
    padding: 10px 10px 10px 30px;
    border-radius: 8px;
    font-size: ${FontSizeMedium};
    outline: none;
    transition: width .3s ease;
    cursor: pointer;
    background: transparent;
    border: 0;

    @media (max-width: 667px) {
      transition: none;
    }
  }
  i.fa-search {
    position: absolute;
    left: 10px;
    top: 18px;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${BitterSweet} !important;
  }
`;

export const NoData = styled.div`
  color: ${DarkGray1};
  i {
    margin-right: 5px;
    color: ${Sunflower};
  }
`

export const Dropdown = styled.div.attrs({
  'data-rp': 'auto-complete-dropdown',
})`
  padding: 10px;
  position: absolute;
  top: 38px;
  left: -1px;
  right: -1px;
  overflow: auto;
  border: 1px solid ${MediumGray};
  border-top: 1px solid ${LightGray1};
  color: ${DarkGray1};
  background-color: rgba(255,255,255,1);
  z-index: ${DropdownZIndex};
  min-height: 50px;
  max-height: 500px;

  .footnote {
    text-align: left;
    border-top: 1px solid #f1f2f3;
    margin: 10px 0;
    padding-top: 10px;
  }
`;
export const DropdownContainer = styled.div`
  position: relative;
  mark {
    color: ${Aladin_Orange_Dark};
    background-color: transparent;
  }
`;
export const DropdownGroupTitle = styled.div`
  margin: 0;
  padding: 10px 10px;
  //text-transform: capitalize;
    background-color: ${Aladin_BG};
  border-bottom: 1px solid ${Aladin_BG_1}
  font-weight: bold;
`;
export const ShowMore = styled.div`
  margin: 5px 10px;
  margin-bottom: 15px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  i {
  }
`;
export const ShowMoreLink = styled.a`
  position: relative;
  margin: 5px 10px 5px 35px;
  width: 100% !important;
  opacity: 0.5;
  margin-bottom: 15px;
  color: #434A54 !important;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  i {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
    position: initial !important;
    color: #434A54 !important;
  }
  &:visited {
    color: #434A54 !important;
  }
  &:hover {
    color: #000 !important;
    text-decoration: none;
    opacity: 1;
    i {
      opacity: 1
    }
  }
`;
export const DropdownLoading = styled.div`
  padding: 5px 10px;
  // position: absolute;
  // bottom: -24px;
  font-size: 12px;
  width: calc(100% - 20px);
  font-weight: bold;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  // background: #f1a164;
  color: #000;
`;

export const DropdownItem = styled.div`
  cursor: pointer;
  white-space: normal;
  position: relative;
  padding: 4px 10px;
  padding-left: 35px;
  margin-bottom: 10px;
  &:nth-child(odd) {
    background-color: #fafbfc;
  }
  &:hover{
    background-color: ${Aladin_BG} !important;
  }
  img {
    position: absolute;
    left: 2px;
    top: 10px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }
  && a {
    font-size: 12px;
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
  }
  ${props => props.active && css`
    background-color: rgba(255,255,255,.3);
    &:hover {
      background-color: rgba(255,255,255,.5);
    }
    && a {
      color: ${DarkGray1};
    }
  `}
    .desc {
      .mr4 {
        margin-right: 4px;
      }
      .ml4 {
        margin-left: 4px;
      }
    }
  mark {
    color: inherit;
    font-weight: 700;
    background: transparent;
    color: #f1a164;
    
  }
    .msq-platform-list, .msq-item-option,
    .item-option {
      font-size: 12px;

      &.disabled {
        * {
          color: ${MediumGray1} !important;
          cursor: not-allowed  !important;
          pointer-events: none  !important;
        }
      }

      .msq-name {
        font-weight: bold;
        font-size: 12px;
        color: ${DarkGray1};
        word-break: break-word;
        margin-right: 10px;
      }

      .msq-tag {
        padding: 1px 5px 1px 0;
        border-radius: 4px;
        margin: 0 10px 5px 0;
        font-size: 10px;
        //background-color: rgba(248, 242, 238, 0.38);
        color: ${DarkGray1};
      }
    }
    .msq-platform-list {
      list-style: square;
      margin-left: 20px;
    }
`;

export const Pagination = styled.div`
    margin-top: 16px;
    text-align: right;
`;

export const PaginationLink = styled.button`
  color: #656D78;
  background-color: #fff;
  border: 0;
  font-size: 12px;
  text-decoration: underline;
  padding-left: 16px;
  cursor: pointer;
  opacity: .5;

  &:first-child {
    padding-right: 16px;
    border-right: 1px solid #656D78;
  }

  i {
    font-size: 18px;
    vertical-align: middle;
  }

  &.disabled {
    opacity: .5;
  }

  &:hover {
    opacity: 1;
  }
`
