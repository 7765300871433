import React from 'react';
import styled, {css} from 'styled-components';
import {
  Aladin_BG, Aladin_Orange_Dark,
  BitterSweet,
  DarkGray,
  DarkGray1,
  Grass,
  Grass1,
  LightGray,
  MediumGray
} from "../app/StyleCommon";
import SingleDropdown from '../uikit/dropdown/SingleDropdown';
import MutipleDropdown from '../uikit/dropdown/MutipleDropdown';

export const Wrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  >h3 {
    background: ${Aladin_BG};
    border-radius: 10px;
    padding: 10px 20px;
  }
  form {

  }
`;



export const FormWrapper = styled.form`
  padding: 10px 20px;
  border-radius: 5px;
  min-height: calc(100vh - 300px);
  max-width: 900px;
  input[type=text],
  input[type=password],
  input[type=email]{
    font-size: 14px;
    width: 100%;
    padding: 8px 6px;
    box-sizing: border-box;
    transition: all .3s ease;
    vertical-align: middle;
    background: none;
    outline: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #ddd;
    &:focus {
      border-bottom: 1px solid #aaa;
    }
  }
  input[type=submit] {
    margin-top: 20px;
    background-color: ${Grass};
    box-shadow: 1px 1px 5px rgba(255,255,255,.1), 1px 1px 15px rgba(255,255,255,.05);
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    vertical-align: middle;
    min-width: 150px;
    height: 30px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${Grass1};
    }
  }
`;

export const Sep = styled.hr`
  //background-color: ${LightGray};
  margin: 15px 0;
  border: none;
  border-bottom: 1px solid #ccc;
`

export const FormField = styled.div`
  position: relative;
  &:not(:first-child) {
    margin-bottom: 20px;
  }
  .err-msg {
    color ${BitterSweet};
  }
  > label {
    display: block;
    width: auto;
    margin: 40px 0 5px 0;
    color: ${DarkGray1};
    font-size: 14px;
    >span:first-child {
      display: inline-block;
      min-width: 150px;
    }
  }
  &.error > input {
    border-bottom: 1px dashed #E9573F;
  }
  .error-msg {
    // padding: 10px 5px;
    color: ${BitterSweet};
    // color: white;
    // border-radius: 5px;
    &.below {
      position: static;
      text-align: left;
    }
    position: absolute;
      text-align: right;
      right: 0px;
      bottom: 3px;
      opacity: .9;
      background-color: white;
      padding: 4px 5px;
      display: inline-block !important;
      width: auto !important;
      font-weight: bold;
  }
  .select-box {
    width: 100%;
    .toggler {
      border-bottom: 1px solid ${MediumGray};
    }
    .menu {
      .selected {
        background: white;
        strong {
          color: ${Aladin_Orange_Dark}
        }
      }
    }
  }
  button {
    margin-top: 20px;
  }
`;

export const Dropdown = styled(SingleDropdown)`
  width: 100%;
  .dropdown-toggle {
    border-bottom: 1px solid white !important;
    justify-content: space-between;
    padding: 5px 0 !important;
  }
  .menu {
    width: 100%;
    .search input {
      color: ${DarkGray};
    }
    .option {
      max-width: inherit !important;
    }
    label {
      padding: 8px 10px !important;
    }
  }
`

export const MultiSelectionDropdown = styled(MutipleDropdown)`
  width: 100%;
  .dropdown-toggle {
    border-bottom: 1px solid white !important;
    justify-content: space-between;
    padding: 5px 0 !important;
  }
  .menu {
    width: 100%;
    .search input {
      color: ${DarkGray};
    }
    .option {
      max-width: inherit !important;
    }
    label {
      padding: 8px 10px !important;
    }
  }
`

export const UserProfileDailogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  h3 {
    font-size: 16px;
    margin-top: -5px;
    padding-bottom: 4px;
  }
  .subhead {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .buttons {
    margin-top: 16px;
  }
  .primaryButton {
    padding: 5px 20px;
    min-width: 90px;
    background-color: ${Aladin_Orange_Dark};
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    color: white;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    cursor: pointer !important;
    display: flex;
  }
  .reset {
    padding: 5px 20px;
    min-width: 90px;
    background: rgb(230, 233, 237);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    color: rgb(67, 74, 84);
    cursor: pointer !important;
    margin-right: 10px;
  }
  .uploadIcon {
    margin-right: 6px;
    font-size: 16px;
  }
`
