import styled, {css} from 'styled-components';
import {
  Aladin_BG, Aladin_BG_1, Aladin_Blue_Dark,
  Aladin_Orange_Dark, Aladin_Orange_Light, Aladin_Orange_Lighter,
  BitterSweet1,
  BlueJeans, BlueJeans1,
  Dark,
  DarkGray, DarkGray1, Grass, Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray,
  MediumGray1, MediumGray2,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";

export const KeyMetric = styled.div`
  text-align: left;
  padding: 5px 10px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  align-self: flex-start;
  .chart-title {
    overflow: hidden;
    [contenteditable] {
      outline: none;
      &:focus {
        border-bottom: 1px solid ${Aladin_Orange_Dark};
      }
    }
  }
  .detail-chart {
    margin-top: 0px;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 0px !important;
    margin-left: 0px !important;
    font-weight: normal;
    white-space: nowrap;
    color: ${DarkGray};
    font-weight: bold;
  }
  .bottom {
    display: flex;
    align-items: center;
    color: #f1a164; //#668abb;
    font-weight: bold;
    font-size: 22px;
  }
  &.pacing .bottom {
    font-size: 18px;
  }
  .desc {
    font-size: 12px;
    color: ${DarkGray};
  }

  .no-data {
    font-size: 14px;
    margin-top: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .bottom, &.pacing .bottom {
      font-size: 14px;
    }
    .bar-item label, .desc span {
      font-size: 10px
    }
    .chart-title {
      font-size: 12px
    }
  }
  @media (min-width: 1024px) and (max-width: 1300px) {
    .bottom, &.pacing .bottom {
      font-size: 14px;
    }
    .bar-item label, .desc span {
      font-size: 10px
    }
    .chart-title {
      font-size: 12px
    }
  }
`;

export const PacingTooltip = styled.div`
  line-height: 1.8em;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  strong {
    color: ${Aladin_Orange_Dark};
  }
`
export const PacingWrapper = styled.div`
    // border-left: 1px solid ${MediumGray1};
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .bar-item {
    //display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    margin-right: 10px;
    &:hover {
      background: ${Aladin_BG_1};
    }
  }
  label {
    font-size: 11px;
    //margin-left: 5px;
    min-width: 50px;
    margin-right: 10px;
  }
  .pacing-bar {
    flex-grow: 1;
    height: 5px;
    border-radius: 0 5px 5px 0;
    background: ${MediumGray};
    border-radius: 2px;
    overflow: visible;
    font-size: 12px;
    padding-left: 0px;
    position: relative;
    &.good .actual {
      background: ${Aladin_Blue_Dark};
    }
    > div {
      height: 4px;
    }

    .actual {
      background: ${Sunflower};
    }

    .target {
      position: absolute;
      height: 6px;
      width: 1px;
      background: #ab8787;
      top: -2px;
      bottom: 0;
    }
  }

`