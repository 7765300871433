import React from 'react';
import ReactDOM from "react-dom";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {CreateWrapper} from './template.style';
import {Btn} from "../uikit";

export default class extends React.Component {

  constructor(props) {
    super(props);
    const {template = {}} = this.props;
    if(template.id !== 'empty') {
      this.state = {
        id: template.id,
        name: template.name,
        description: template.description,
      }
    } else {
      this.state = {
        name: 'New Template',
        description: 'Template description',
      }
    }
  }

  render() {
    const {report, template = {}, validate=false} = this.props;
    const {id, name, description, errorMap={}} = this.state;
    return (
      <CreateWrapper>
        <div className="field">
          <label>Name</label>
          <input type="text" value={name} onChange={e => this.setState({name: e.target.value})}/>
          {errorMap && errorMap.name ? <div className='error-msg'>Name is mandatory</div>: null}
        </div>
        <div className="field">
          <label>Description</label>
          <textarea type="text" value={description} onChange={e => this.setState({description: e.target.value})}/>
        </div>
        {/*<div className="check">*/}
        {/*  <div>*/}
        {/*    <label><input type="checkbox"/> Save for everybody.</label>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="primary"
               onClick={e => {
                if (validate && name === "") {
                  this.setState({errorMap: {name: true}})
                  return;
                } 
                 this.props.onChange({...template, name, description, config: _.pick(report, ['sections', 'header', 'name'])})
               }}>Save</Btn>
        </div>
      </CreateWrapper>
    )
  }
}