import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Aladin_Orange_Dark,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  Aladin_BG,
  Aladin_Purple_Dark,
  Aladin_Purple_Light,
  MediumGray2,
  Aladin_BG_1, Aladin_Orange_Light, Aladin_Blue_Light, Aladdin_v2_blue2, Aladin_Orange_Lighter
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  padding: 10px 20px;
  .campaign404 {
    position: fixed;
    top: 50px;
    height: 100vh;
    height: calc(100vh - 50px);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${BitterSweet1};
    z-index: 10000;
    background-color: rgba(255,255,255,.9);
  }
  .detail-header-wrapper {
    //background: ${Aladin_BG};
    //border-bottom: 1px dotted ${Aladin_Orange_Lighter};
    // padding: 0px 5px 0px 10px;
    //border-radius: 10px;
    //box-shadow: 0px 1px 3px rgba(0,0,0, .1);
    position: relative;
  }
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      width: 50%;
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
      @media screen and (min-width: 900px) and (max-width: 1024px) {
        width: 75%;
      }
      @media screen and (min-width: 1024px) {
        width: 100%;
      }
      .title-txt {
        font-weight: 600;
        //color: ${Aladin_Orange_Dark};
        font-size: 16px;
      }
      a {
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: auto;
        i {
          font-size: 14px;
          margin-right: 5px;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          width: 200px;
          margin-left: 8px;
          font-size: 11px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1250px) {
          width: 200px
        }
        @media screen and (min-width: 1024px) {
          margin-left: 16px;
          font-size: 14px
        }
      }

    }
    .setting-container {
      text-align: right;
      width: 50%;
    }
    .setting-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      div.dropdown {
        margin-right: 8px;
      }
      > button {
        margin-right: 8px !important;
      }
      a {
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
          font-size: 18px;
          font-weight: bold;
        }
        &.active {
          color: #f1a164;
        }
        &.undo {
          margin-right: 8px;
        }
        &:hover {
          color: #f1a164;
        }
      }
      .dropdown-toggle {
        height: 30px;
        width: auto;
      }
      .tune-dropdown {
        .menu {
          padding: 10px;
        }
      }
    }
  }
  .more-settings {
    background: white;
    padding: 10px 0px;
    position: relative;
  }
  .more-settings-link:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 0px solid #f1f1f1;
    border-bottom: 8px solid #f1f1f1;
    border-right: 8px solid transparent;
    left: 50%;
    top: 19px;
    border-left: 8px solid transparent;
  }
  .more-settings-overlay {
    background: white;
    padding: 10px 0px;
    // position: absolute;
    // z-index: 4;
    border: 1px solid #eee;
    // box-shadow: 0 6px 20px 0 rgba(0,0,0,0.16);
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    // height: 400px;
    overflow: auto;

    > div {
      padding : 0px
    }
  }
  .data-freshness {
    position: absolute;
    right: 10px;
    bottom: -35px;
    z-index: 2;
    font-size: 12px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      position: static;
    }
    .data-freshness-inner {
      display: flex;
      align-items: center;
    }
    i {font-size: 18px; margin-right: 5px}
    .toggler {
      .searchable-dropdown span {
        font-size: 12px;
      }
      .icon {
        font-size: 18px;
      }
    }
    .menu {
      z-index: 200;
      padding: 10px;
      right: 0;
      left: auto;
      min-width: 120px;
      .menu-header {
        padding: 10px 20px;
        white-space: nowrap;
        border-bottom: 1px solid ${MediumGray};
        background-color: transparent;
        font-weight: bold;
      }
    }
    .toggler {
      padding: 0;
      border: none;
      background-color: transparent;
      width: auto;
      margin-left: 20px;
      font-size: 18px;
      .caret {
        // display: none;
      }
      &.searchable {
        min-width: 20px;
        transition: min-width .3s ease;
        .searchable-dropdown {
          margin-right: 28px;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }
      &.searchable.active {
        min-width: 80px;
      }
    }
  }
  .dashboard-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 10px 0;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .tab-container-wrapper {
      flex-grow: 1;
      width: calc(80% - 320px);
    }
    .vis-panel {
      margin-left: 20px;
    }
    .campaign-dashboard-no-data {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding-top: calc(40vh - 100px);
      font-size: 18px;
      background: white;
      z-index: 1;
    }
  }
`
export const AttrOverview = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  .basic-info {
    display: flex;
    flex-wrap: wrap;
  }

  .attr {
    font-size: 11px;
    font-weight: bold;
    margin-right: 10px;
    color: ${MediumGray2};
    background: rgba(230, 233, 237, 0.5);
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    color: ${DarkGray};
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 5px 0;
      padding: 5px 10px;
    }

    &.more-settings {
      background: transparent;
      cursor: pointer;
      margin-left: 30px;
      font-size: 11px;

      i {
        font-size: 16px;
      }
    }
  }
  .healthStatus {
    font-size: 11px;
    label {
      font-weight: bold;
      color: #656D78;
      padding: 1px 5px 1px 5px;
    }
  }
`;


export const InFlightStatus = styled.span`
  display: inline-block;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: bold;
  &.DELIVERING {
    color: #4f8f98;
  }
  &.READY {
    color: #f1a164;
  }
  &.DONE {
    color: ${DarkGray};
  }
`
