import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  Aladin_Red_Dark,
  Aladin_BG,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG_1,
  Aladin_Orange_Lighter
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const FilterTagWrapper = styled.div`
  display: flex;
  //margin: 10px 0;
  align-items: center;
  position: relative;
  flex-grow: 1;

  > i {
    margin-right: 10px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .clearAll {
    align-self: center;
    cursor: pointer;
    position: absolute;
    right: 20px;
    @media screen and (max-width: 768px) {
      position: static;
      margin: 20px 0;
      padding: 5px 10px;
      text-align: center;
      border: 1px solid ${Aladin_BG_1};
      background-color: ${LightGray1} !important;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 50px;
    @media screen and (max-width: 768px) {
      padding-right: 0px;
      width: 100%;
    }
  }

  .tag {
    padding: 2px 8px;
    border: 1px solid rgba(255,255,255,.5);
    background: rgba(255,255,255,.9);
    margin: 5px 10px 5px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
      background-color: ${LightGray1};
    }

    .toggler {
      border: none;
      font-size: 10px;
    }

    .criteria {
      position: absolute;
      top: 30px;
      left: 0;
      right: auto;
      border: 1px solid ${MediumGray};
      z-index: 2;
    }

    strong {
      color: ${Aladin_Orange_Dark};
      margin: 0 5px;
    }

    > i {
      margin-left: 5px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${MediumGray};

      &:hover {
        color: ${BlueJeans1};
      }
    }
  }

  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .tag {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-right: 0;
    }
  }

  .criteria {
    padding: 20px;
    min-width: 120px;
    z-index: 1;
    background: white;

    .criteria-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      font-weight: bold;

      a {
        cursor: pointer;
      }
    }

    .criteria-op {
      margin: 10px 0;
    }

    .criteria-value {
      margin: 10px 0;

      > input {
        box-sizing: border-box;
        width: 100%;
        padding: 5px 10px;
        outline: none;
        border: none;
        border-bottom: 1px solid ${LightGray1};
      }

      .react-datepicker-wrapper {
        width: 100%;

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid ${MediumGray};
        }

        .react-datepicker__aria-live {
          display: none;
        }
      }
    }

    .apply-btn {
      text-align: right;
      padding: 10px 0;
    }
  }

`

export const FilterDropdown = styled(Dropdown)`
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    margin-right: 0px;
  }
  .dropdown-toggle {
    width: 100px;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    padding: 4px 10px;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    //color: ${Aladin_Orange_Light};
    @media screen and (max-width: 768px) {
      border: 1px solid ${Aladin_BG_1};
      background-color: ${Aladin_Orange_Dark} !important;
      width: 100%;
      box-sizing: border-box;
    }
    &:hover {
      color: ${Aladin_Orange_Dark};
    }
    i {
      font-size: 16px;
      margin-right: 10px;
    }
    &:hover i {
      color: ${BlueJeans1};
    }
  }
  .menu {
    top: 35px;
    right: auto;
    left: 0;
    min-width: 200px;
    max-height: 500px;
    padding: 0;
    @media screen and (max-width: 667px) {
      left: 0;
    }
    h3 {
      margin: 0;
      padding: 10px 10px;
      font-weight: normal;
      border-bottom: 1px solid ${LightGray1};
      background: ${LightGray};
    }
    .select-box {
      flex-grow: 1;
      margin: 0;
      min-width: 80px;
      .toggler {
        border: none;
        position: relative;
        i {
          z-index: 1;
          margin-right: 0;
        }
        &:after {
          background-color: #F5F7FA;
          position: absolute;
          content: '';
          bottom: 0;
          top: 0;
          right: 0;
          width: 30px;
        }
      }
      .menu {
        overflow: auto;
        label {
          white-space: normal !important;
          word-break: break-all;
        }
      }
    }
    .date-selector-dropdown {
      .toggler {
        display: flex;
        border: none;
        background: none;
        border-radius: 0;
        padding-left: 15px;
        padding-right: 40px;
        min-width: inherit;
          //border-bottom: 1px solid ${LightGray1};
        i {
          right: 10px;
        }
      }
      .dropdown-content {
        transform: translate(20px, -100%) translate(0px, -50px);
      }
    }
    .actions {
      padding: 10px;
    }
    .menu-top {
      padding: 6px 10px;
      border-bottom: 1px solid #f1f1f1;
      position: relative;
      i {
        position: absolute;
        top: 13px;
        left: 18px;  
        color: #f1a164;  
      }
      > input {
        width: calc(100% - 25px);
        border: 0;
        border-bottom: 1px solid #CCD1D9;
        line-height: 25px;
        border-radius: 6px;
        padding-left: 28px;
        outline: none;
        font-size: 12px;
        &:focus {
          border-color: #f1a164;
        }
      }
    }
    .menu-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      >label {
        display: inline-block;
        width: 120px;
        margin-right: 10px;
        flex: none;
      }
    }
  }
`;