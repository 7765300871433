import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {ComponentMenu, VisCanvas, VisPanel} from './dndMixin.style';
import { htmlToText } from 'html-to-text';
import {Loader, CondirmDialog} from "../uikit";
import ErrorBoundary from "./errorBoundary";
import Datatable from "./datatable";
import classnames from "classnames";
import {
  Aladin_BG,
  colorCategory4,
  MediumGray,
  MediumGray1
} from "../app/StyleCommon";
import {pctFormatter} from "../utils/formatter";
import {
  dimensionLabelMap, getFilterDimensions,
  metricMap,
  platformLabelMap
} from "../utils/metadata";
import {defaultTooltip, defaultWhiteTooltip} from "../uikit/tooltip";
import styled from "styled-components";
import {post} from "../utils/request";
import {saveAs} from 'file-saver';
import ContentEditable from "react-contenteditable";
import {SelectStyled} from "../uikit/selectbox/customized.style";
import {NoDataComponent, NotSupportedComponent} from "./noDataComponent";
import {AlertBox} from "../campaigns/build.style";
import DNDMixinChartDialog from './dndMixin.chartDialog';
import qs from "qs";
import { ChartDialogFooter } from './dndMixin.style';

const genChartQueryKey = (chart, filters) => {
  return _.compact([
    chart.key,
    chart.pkey,
    chart.cfg.platform,
    chart.cfg.platform2,
    chart.cfg.metric,
    chart.cfg.metric2,
    chart.cfg.metrics, //t handle metric list
    chart.cfg.dimension,
    chart.cfg.dimension2,
    chart.cfg.dimensions,
    chart.cfg.unit,
    chart.cfg.weekStyle,
    chart.cfg.date,
    chart.cfg.selected,
    chart.cfg.channels,
    (chart.cfg.period || {}).start,
    (chart.cfg.period || {}).end,
    (filters ? JSON.stringify(filters).replace(/[^a-zA-z0-9]/ig, '') : ''),
  ]).join('_');
}

export default class extends DNDMixinChartDialog{

  constructor(props) {
    super(props);
    this.tooltip = defaultTooltip;
    this.saveHistoryDebounced = _.debounce(this.saveHistory.bind(this), 500);
  }

  renderDashboard(platformKey, platformDashboard, extraSettings = {})   {
    const {report, dashboard, tv_ta, clientData, campaign = {}, reportData = [], context, digitalOptionMetadata, tvOptionMetadata, showDrop, mode, loadingMap = {}, errorMap = {}} = this.state;
    const {dataFreshness = {}} = this.state.data  || {};
    if(!context) {
      return null;
    }
    let {mixedVisualisations} = context;
    const customiseMode = mode === 'customize';
    let {historyStack = [], showChartExportBtn} = this.state;
    let {benchmark_campaigns = [], campaigns: datasource_campaigns = [] } = (report ||{}).datasource || {};
    let benchmarkCampaign = benchmark_campaigns[0];
    let datasource_campaign = datasource_campaigns[0];
    let compcontext = {...context, clientData};
    return (
    <VisCanvas className={classnames({editMode: customiseMode})}>
      {
        platformKey === 'overall' && ((datasource_campaign || campaign).platforms || []).find(d => d.key === 'social') &&
        <AlertBox type="note" className="note">
          <div className="desc">
            <i className="material-symbols-outlined">description</i> Note: Social is excluded from merging metrics across platforms due to the lack of a proxy solution at present.
          </div>
        </AlertBox>
      }
      {extraSettings.alertHeader}
      {
        !!extraSettings.reportHeader &&
        <div className="report-header">
          <div className="bg"></div>
          <img className="logo" src="/img/mediacorp-logo.png"/>
          <div className="title">
            <ContentEditable
              disabled={window.location.pathname === '/report/view'}
              html={report.title || 'Untitled'} // innerHTML of the editable div
              onChange={e => {
                this.reportAttrs = this.reportAttrs || {title: report.title};
                this.saveHistoryDebounced();
                report.title = htmlToText(e.target.value);
                this.setState({report, touched: true}, () => this.saveReportSilent && this.saveReportSilent());
              }} // handle innerHTML change
              tagName='span' // Use a custom HTML tag (uses a div by default)
            />
          </div>
        </div>
      }
      {
        platformDashboard.sections.map((section, sectionIndex) => {
          let platformKeysWithouOverall = (((datasource_campaign || campaign).platforms || []).map(d => d.key)).filter(p => p!== 'overall');
          let overallSupport = platformKeysWithouOverall.length > 1 && platformKeysWithouOverall.every(p => (dataFreshness[p] || ['radio', 'social', 'ooh', 'others'].includes(p)));
          const isDataChart = chart  => ['seperator', 'editor', 'campaign_information'].indexOf(chart.key) < 0;

          if((section.charts || []).some(chart => isDataChart(chart.key) && !dataFreshness[chart.cfg.platform])) { // some platform in section is not ready.
            // return null;
          }
          // if((section.charts || []).some(chart => isDataChart(chart) && chart.cfg.platform === 'overall' && !overallSupport) ||
          // (section.charts || []).some(chart => !isDataChart(chart) && (chart.cfg.platform === 'overall' || chart.cfg.platform === 'radio') && overallSupport)) {
          //   // return null;
          // }
          // if((section.charts || []).length === 1 && section.charts[0] && !dataFreshness[section.charts[0].cfg.platform]) {
          //   return null;
          // }
          if(!customiseMode && !section.charts.length) {
            return null;
          }
          let sectionKey = section.key || (platformKey + sectionIndex);
          return (
            <div
              key={'section_'+  sectionIndex}
              id={'section_'+  (section.id || sectionIndex)}
              // className="dashboard-section"
              className={classnames("dashboard-section", {'dashboard-nosource': platformDashboard.template_id && platformDashboard.datasource && Object.keys(platformDashboard.datasource).length === 0})}
              data-section-index={sectionIndex}
              data-index={section.charts.length}
              onDragEnter={e => this.visDragEnter(e)}
              onDragOver={e => this.visDragover(e)}
              onDragLeave={e => this.visDragleave(e)}
              onDrop={e => this.visDrop(e)}
            >
              <div className={classnames('section-name', {hasBG: !!section.bg})}
                   style={{backgroundColor: section.bg || 'transparent', textAlign: section.align || 'left'}}>
                <ContentEditable
                  className="section-name-editor"
                  disabled={!customiseMode}
                  html={section.title || ''} // innerHTML of the editable div
                  onChange={e => {
                    section.title = htmlToText(e.target.value);
                    this.setState({dashboard, touched: true}, () => this.saveReportSilent && this.saveReportSilent());
                  }} // handle innerHTML change
                  tagName='span' // Use a custom HTML tag (uses a div by default)
                />
                <div>
                  {
                    customiseMode &&
                    <span className="section-btn section-del-btn"
                          onMouseOver={this.tooltip.onMouseOver({txt: 'Insert a new section'})}
                          onMouseLeave={this.tooltip.onMouseOut()}
                          onClick={e => {
                            this.saveHistory();
                            platformDashboard.sections.splice(sectionIndex, 0, {title: 'Untitled Section', charts: []});
                            this.setState({dashboard, touched: true});
                          }}>
                      <i className="material-symbols-outlined">variables</i>
                    </span>
                  }
                  {
                    customiseMode &&
                    <span className="section-btn section-del-btn"
                          onMouseOver={this.tooltip.onMouseOver({txt: 'Delete this section'})}
                          onMouseLeave={this.tooltip.onMouseOut()}
                          onClick={e => {
                            this.saveHistory();
                            platformDashboard.sections.splice(sectionIndex, 1);
                            this.setState({dashboard, touched: true});
                          }}>
                      <i className="material-symbols-outlined">delete</i>
                    </span>
                  }
                  {
                    customiseMode &&
                    <span className="section-btn section-del-btn"
                          onMouseOver={this.tooltip.onMouseOver({txt: 'More section settings'})}
                          onMouseLeave={this.tooltip.onMouseOut()}
                          onClick={e => {
                            let sectionKey = section.key || platformKey + sectionIndex;
                            this.showSectionSettings(section, sectionKey);
                          }}>
                      <i className="material-symbols-outlined">settings</i>
                    </span>
                  }
                </div>
              </div>
              {
                !!section.filters && !section.disabled &&
                <div className="section-filters">
                  {
                    (getFilterDimensions(platformKey === 'overall' ? platformKeysWithouOverall : [platformKey]) || []).map(k => {
                      let optionData = (digitalOptionMetadata || []).concat(tvOptionMetadata || []).find(d => d.dimension === k);
                      let options = optionData? optionData.data : [];
                      return (
                        <div className="filter-item">
                          <label>
                            {dimensionLabelMap[k] || k}
                            {platformKey === 'overall' && !!optionData && !!optionData.platform &&
                              <span style={{color: MediumGray1}}> ({platformLabelMap[optionData.platform]})
                              </span>}
                          </label>
                          <SelectStyled
                            searchable={true}
                            plainStyle={true}
                            showGroupBatch={true}
                            defaultLabel={`Select ${dimensionLabelMap[k] || k}`}
                            toggleLableFunc={keys => {
                              if(keys.length === options.length) {
                                return 'All ' + dimensionLabelMap[k];
                              } else {
                                return dimensionLabelMap[k] + ` (${keys.length})`;
                              }
                            }}
                            multi={true}
                            useFixed={true}
                            selected={section.filters[k] || options.map(d => d.key)}
                            data={options}
                            onChange={v => {
                              let sectionKey = section.key || (platformKey + sectionIndex);
                              section.filters = section.filters || {};
                              section.filters[k] = v;
                              this.setState({dashboard}, () => this.loadDataForSection(section, sectionKey))
                            }}/>
                        </div>
                      )
                    })
                  }
                </div>
              }
              <div className={classnames('chart-list', {showDrop: showDrop, showMenu: customiseMode})}>
                {
                  !!loadingMap[platformKey + sectionIndex] &&
                  <div className="component-loading">
                    <Loader type="absolute" transparent={true} style={{top: '20px'}}/>
                  </div>
                }
                {section.charts.map((chart, chartIndex) => {
                  chart.cfg.platform = chart.cfg.platform || platformKey;
                  const queryKey = genChartQueryKey(chart, section.filters);
                  const visMeta = mixedVisualisations.find(d => d.key === chart.key) || {label: chart.key};
                  if(visMeta && !visMeta.hasOwnProperty('key')) {
                    return;
                  }
                  let {
                    Component = (visMeta.Component || NotSupportedComponent),
                    metricOptions,
                    metricOptionsPlatformLevel={},
                    dateUnitOptions,
                    secondaryMetricOptions,
                    demoData = {},
                    conditionalDemoData = {},
                    cfg = {}
                  } = visMeta;
                  let metricOptionMixed = [...(metricOptions || []), ...(metricOptionsPlatformLevel[platformKey] || [])];
                  const mixedCfg = {...visMeta.cfg, ...chart.cfg};
                  let {data: queryData, benchmark: benchmarkData, reloadKey} = reportData.find(d => d.key === queryKey) || {};
                  const {benchmarkMode} = mixedCfg;
                  if(benchmarkMode === 'current') {
                    benchmarkData = null;
                  } else if(benchmarkMode === 'benchmark') {
                    queryData = benchmarkData;
                    benchmarkData = null;
                  } else if (!benchmarkMode) {
                    benchmarkData = null;
                  }
                  if(!queryData && !chart.key.startsWith('keymetric_client')) {
                    Component = NoDataComponent;
                  }

                  if(campaign.inflight_status === 'READY') {
                    Component = visMeta.Component;
                    queryData = demoData;
                    if (!Array.isArray(demoData) && typeof demoData === 'object') {
                      queryData = {...demoData, ...(conditionalDemoData[platformKey] || {}), ...(chart.data || {})};
                    }
                    if(!!chart.data && (!chart.data || !chart.data.breakdown)) {
                      delete queryData.breakdown;
                    }
                  }
                  const isLoading = !!loadingMap[queryKey];
                  const loadFailedError = errorMap[queryKey];
                  return (
                    <div
                      className={classnames('chart-wrapper pdf_no_break', mixedCfg.size, chart.key)}
                      key={queryKey + reloadKey + sectionIndex + '_' + chartIndex + JSON.stringify(_.omit(chart.cfg, ['title', 'data']))}
                      data-size={mixedCfg.size}
                      data-section-index={sectionIndex}
                      data-index={chartIndex}
                      onDragEnter={e => this.visDragEnter(e)}
                      onDragOver={e => this.visDragover(e)}
                      onDragLeave={e => this.visDragleave(e)}
                      onDrop={e => this.visDrop(e, true)}

                      id={chart.key + '::' + sectionIndex + '::' + chartIndex}
                      style={{cursor: 'pointer'}}
                      onDragStart={e => this.visDragstart(e)}
                      onDragEnd={e => this.visDragend(e)}>
                      {
                        customiseMode && chartIndex === 0 &&
                        <div
                          className={classnames('sep', 'pre', 'droparea', {vertical: true, showDrop})}
                          data-section-index={sectionIndex}
                          data-index={0}
                          onDragEnter={e => this.visDragEnter(e)}
                          onDragOver={e => this.visDragover(e)}
                          onDragLeave={e => this.visDragleave(e)}
                          onDrop={e => this.visDrop(e)}
                        />
                      }
                      {
                        customiseMode && !visMeta.hideConfig &&
                        <div
                          data-size={mixedCfg.size}
                          data-section-index={sectionIndex}
                          data-index={chartIndex}
                          draggable={customiseMode}
                          id={chart.key + '::' + sectionIndex + '::' + chartIndex}
                          onDragStart={e => this.visDragstart(e)}
                          onDragEnd={e => this.visDragend(e)}
                          className="drag-indicator">
                          <span className="material-symbols-outlined">drag_indicator</span>
                        </div>
                      }
                      {
                        !visMeta.hideConfig &&
                        <a className="chart-container-menu"
                           onClick={e => this.showChartOptionDialog(chart, {mixedCfg, queryData, benchmarkCampaign, platformKey, section, sectionIndex, chartIndex, benchmarkData})}>
                          {/*<i className="material-symbols-outlined">more_vert</i>*/}
                          <i className="material-symbols-outlined">menu</i>
                          {/*<i className="material-symbols-outlined">settings</i>*/}
                        </a>
                      }
                      {/*{*/}
                      {/*  !customiseMode && !!queryData && showChartExportBtn &&*/}
                      {/*  <a className="chart-container-menu"*/}
                      {/*     onClick={e => this.showComponentData(chart, queryData)}>*/}
                      {/*    /!*<i className="material-symbols-outlined">more_vert</i>*!/*/}
                      {/*    <i className="material-symbols-outlined">table</i>*/}
                      {/*  </a>*/}
                      {/*}*/}
                      <ErrorBoundary>
                        {
                          (visMeta.showLabel || !!loadFailedError) &&
                          <ContentEditable
                              className={'chart-title'}
                              disabled = {!customiseMode}
                              html={mixedCfg.title ? mixedCfg.title : (visMeta.labelFunc ? visMeta.labelFunc(chart, context) : visMeta.label)}
                              onChange={e => {
                                chart.cfg.title = htmlToText(e.target.value);
                                this.setState({dashboard, touched: true});
                              }}
                              tagName='div'
                          />
                        }

                        <Component
                          key={chart.key + chart.pkey + '_' + sectionIndex + '_' + chartIndex}
                          cfg={chart.cfg}
                          chart={chart}
                          mode={mode}
                          hideTitle={true}
                          context={compcontext}
                          title={visMeta.label}
                          data={queryData}
                          benchmark={benchmarkData}
                          currency={campaign.currency || 'SGD'}
                          isLoading={isLoading}
                          error={loadFailedError}
                          displayMode={!customiseMode}
                          onChange={d => {
                            chart.data = d;
                            chart.cfg.data = d;
                            this.setState({dashboard, touched: true});
                          }}
                          chartStateChanged={(newState, noReload) => {
                            chart.cfg = {...chart.cfg, ...newState};
                            this.setState({dashboard, touched: true}, () => !noReload && this.loadDataForChart(chart, section.filters));
                          }}/>
                        {
                          isLoading &&
                          <div className="component-loading">
                            <Loader type="absolute" transparent={true} style={{top: '20px'}}/>
                          </div>
                        }
                      </ErrorBoundary>
                      {
                        customiseMode  &&
                        <div
                          className={classnames('sep', 'tail', 'droparea', {vertical: true, showDrop})}
                          key={'droparea' + chart.key + chartIndex}
                          data-section-index={sectionIndex}
                          data-index={chartIndex + 1}
                          onDragEnter={e => this.visDragEnter(e)}
                          onDragOver={e => this.visDragover(e)}
                          onDragLeave={e => this.visDragleave(e)}
                          onDrop={e => this.visDrop(e)}
                        />
                      }
                    </div>
                  )
                })}
                {
                  customiseMode && !section.charts.length &&
                  <div className="chart-wrapper" key="placeholder">
                    <div
                      className={classnames('square', 'droparea', {highlight: true, showDrop})}
                      data-section-index={sectionIndex}
                      data-index={section.charts.length}
                      onDragEnter={e => this.visDragEnter(e)}
                      onDragOver={e => this.visDragover(e)}
                      onDragLeave={e => this.visDragleave(e)}
                      onDrop={e => this.visDrop(e)}
                    />
                  </div>
                }
              </div>
            </div>
          )
        })
      }
      {
        customiseMode &&
        <div className="new-section-btn" onClick={e => {
          let id = Date.now();
          platformDashboard.sections.push({
            title: 'New Section', id,  charts: []
          })
          this.setState({dashboard: dashboard, touched: true});
          this.scrollToElement(id)
        }}>
          <i className="material-symbols-outlined">add</i> Create new section
        </div>
      }

    </VisCanvas>
    )
  }

  generateUniqueId = () => {
    const id = Date.now();
    return id;
  }

  scrollToElement = id => {
    setTimeout(() => {
      document.getElementById('section_'+id).scrollIntoView({
        behavior: 'smooth'
      });
    }, 100)
  }

  renderVisPanel(cfg) {
    const {groupByPlatform, campaignType} = cfg || {};
    const {report, dashboard, visPanelClose, platformKeys, context, tabIndex, mode, visKeyword, reportData = []} = this.state;
    if(!context) {
      return null;
    }
    let {mixedVisualisations, mixedMetaData} = context;
    const {dataFreshness = {}} = this.state.data  || {};
    let {campaigns = []} = (report ||{}).datasource || {};
    let datasourcePlatformsKeys = (campaigns[0] || {}).platform_keys ||[];
    let showOverall = datasourcePlatformsKeys.length > 1;
    const showVisGroup = k => {
      if(platformLabelMap[k] && !dataFreshness[k]) {   // disable the vis panels in ase of no data freshness
        return false;
      }
      return Object.keys(platformLabelMap).indexOf(k) < 0 || (k === 'overall' ? showOverall : datasourcePlatformsKeys.indexOf(k) >= 0);
    };
    if(visKeyword) {
      const visKeywordLowered = visKeyword.toLowerCase();
      mixedVisualisations = mixedVisualisations.filter(d => {
        return d.searchKeywords.indexOf(visKeywordLowered) >= 0;
      });
    }
    const customiseMode = mode === 'customize';
    const platformKey = platformKeys[tabIndex] || {};
    let visualisationGroups = [], groupOpenMap = this.state.groupOpenMap;
    if(groupByPlatform) {
      visualisationGroups = ['overall', 'tv', 'digital', 'radio', 'social', 'common'].map(platform => {
        let label = platform === 'overall' ? 'Cross Platforms' : (platformLabelMap[platform] + ' Platform');
        if(platform === 'common') {
          label = 'Others';
        }
        return {
          key: platform,
          label: (
            <span
              onMouseOver={platform == 'common' ? undefined : defaultWhiteTooltip.onMouseOver({tipContent: (
                  <div>Data as of {moment(dataFreshness[platform]).format('MMM DD YYYY')}</div>
                )})}
              onMouseLeave={defaultWhiteTooltip.onMouseOut()}>
              {label}
            </span>
          ),
          chartList: mixedVisualisations.filter(d => {
            if(platform !== 'common' && (d.supportPlatforms || []).indexOf('common') >= 0) {
              return false;
            }
            return (d.supportPlatforms || []).indexOf(platform) >= 0;
          })
        }
      });
      if(!showOverall) {
        groupOpenMap = groupOpenMap || {[datasourcePlatformsKeys[0]]: true};
      } else {
        groupOpenMap = groupOpenMap || {'overall': true};
      }
    } else {
      let paltformCharts = mixedVisualisations.filter(d => (d.supportPlatforms || []).indexOf(platformKey) >= 0);
      let cats = _.compact(_.uniq(paltformCharts.map(d => d.category)));
      visualisationGroups = cats.map(cat => {
        return {key: cat, label: cat, chartList: paltformCharts.filter(d => d.category === cat)};
      })
      groupOpenMap = groupOpenMap || cats.reduce((ret, next) => ({...ret, [next]: true}), {});
    }
    visualisationGroups.map(v => {
      let keymetrics = v.chartList.filter(c => c.key.startsWith('keymetric_') && !c.key.startsWith('keymetric_client'));
      let clientMetrics = v.chartList.filter(c => c.key.startsWith('keymetric_client'));
      let charts = v.chartList.filter(c => !c.key.startsWith('keymetric_') && !c.key.startsWith('keymetric_client'));
      v.chartList = [...keymetrics, ...clientMetrics, ...charts];
    });

    const allCharts = _.flattenDeep(_.values(dashboard).map(d => (d.sections || []).map(s => s.charts)));
    return (
      <VisPanel className={classnames('vis-panel', {closed: visPanelClose})} onClick={e => {
        if(visPanelClose) {
          this.setState({visPanelClose: false});
        }
      }}>
        <h3 className="vispanel-header">
          <span>Visualisations</span>
        </h3>
        <div className="vispanel-actions">
          <div className="vispanel-expand">
            {!visPanelClose && <a onClick={e => this.setState({visPanelClose: true}, () => {
              window.dispatchEvent(new Event('resize'))
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
              }, 50);
            })}>
              <i className="material-symbols-outlined">switch_left</i>
            </a>}
            {!!visPanelClose && <a onClick={e => this.setState({visPanelClose: false}, () => {
              window.dispatchEvent(new Event('resize'))
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
              }, 50);

            })}>
              <i className="material-symbols-outlined">switch_right</i>
            </a>}
          </div>
        </div>
        <div className="vispanel-body"
             onDragEnter={e => this.visDragEnter(e)}
             onDragLeave={e => this.visDragleave(e)}
             onDragOver={e => this.visDragover(e)}
             onDrop={e => this.visDragRemove(e)}>
          <div className="searchBox">
            <i className="fa fa-search"/>
            <input
              type="text"
              placeholder="Search visualisation..."
              value={visKeyword}
              onChange={e => this.setState({visKeyword: e.target.value})}/>
          </div>
          {
            visualisationGroups.map((group, groupIndex) => {
              let {key: groupKey, label: groupLabel, chartList: visualisationInGroup} = group;
              // if(!visualisationInGroup.length || !showVisGroup(groupKey)) return null;
              return (
                <div className="vis-group" key={groupKey}>
                  <h4 className="vis-group-title" onClick={e => {
                    let element = e.currentTarget;
                    this.setState({groupOpenMap: {...groupOpenMap, [groupKey]: !groupOpenMap[groupKey]}}, () => {
                      (groupIndex === visualisationGroups.length -1) && element.scrollIntoView();
                    });
                  }}>
                    <span>{groupLabel}</span>
                    {!!groupOpenMap[groupKey] && <i className="material-symbols-outlined">keyboard_double_arrow_down</i>}
                    {!groupOpenMap[groupKey] && <i className="material-symbols-outlined">keyboard_double_arrow_right</i>}
                  </h4>
                  {
                    !!groupOpenMap[groupKey] &&
                    <div className="vis-list">
                      {visualisationInGroup.map(d => {
                        let currentPlatform = groupByPlatform ? groupKey : platformKey;
                        let {Component, demoData = {}, defaultByPlatformLevel, conditionalDemoData = {}, cfg, extraStyle, disabled, visSize} = d;
                        if(defaultByPlatformLevel && defaultByPlatformLevel[cfg.platform || currentPlatform]) {
                          let platformLevelMeta = defaultByPlatformLevel[cfg.platform || currentPlatform] || {};
                          cfg = {...cfg, ...(platformLevelMeta.cfg || {})};
                          demoData = platformLevelMeta.demoData || demoData;
                        }
                        let mixedDemoData = demoData;
                        if(Array.isArray(demoData)) {
                          mixedDemoData = [...demoData, ...(conditionalDemoData[currentPlatform] || [])];
                        }
                        else if (typeof demoData === 'object') {
                          mixedDemoData = {...demoData, ...(conditionalDemoData[currentPlatform] || {})};
                        }
                        let existingChart = allCharts.find(c => c.key === d.key && c.cfg.platform === platformKey);
                        let disabledOnUsing = !d.allowMultiple && !!existingChart && !showVisGroup[groupKey];
                        let inUsed = d.key.startsWith('keymetric') && existingChart;
                        return (
                          <div key={'vis_' + d.key}
                               className={classnames('vis', {inUse: inUsed, disabled: disabled || disabledOnUsing}, cfg.size || visSize)}
                               draggable={customiseMode}
                               id={d.key+ '$$' + currentPlatform}
                               onDragStart={e => this.visDragstart(e)}
                               onDragEnd={e => this.visDragend(e)}>

                            {
                              !!d.label && !d.key.startsWith('keymetric_') &&
                              <div className="vis-name">
                                <i className="fa-solid fa-circle-info"
                                   style={{fontSize: '10px', color: MediumGray, marginLeft: '5px'}}/>
                                {d.label}
                              </div>
                            }
                            <div className='vis-wrapper'>
                              {!!d.thumbnail && <div className="thumbnail" style={{background: `url(${d.thumbnail})`}}/> }
                              {!d.thumbnail && <Component key={'vis_' + d.key + '_component'} context={context} data={mixedDemoData} cfg={cfg} style={extraStyle}/>}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              )
            })
          }
        </div>
      </VisPanel>
    )
  }

  showSectionSettings(section, sectionKey) {
    const {dashboard, alwaysShowSectionFilters = true} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Section Options</strong>,
      width: '600px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeDealog(),
      onOK: () => CondirmDialog.closeDealog(),
      dialogBody: (
        <ComponentMenu key={Date.now()}>
          <div className={`menu-option action flex`} onClick={e => {
            CondirmDialog.closeDealog();
          }}>
            <div className="menu-option">
              <div className="label">Section Header Color</div>
              <div className="metric-selector">
                {
                  ['white', Aladin_BG, ...colorCategory4.slice(0, 13)].map(color => {
                    return (
                      <div
                        style={{
                          width: '12px', height: '12px',
                          background: color,
                          display: 'inline-block',
                          marginRight: '10px',
                          border: '1px solid #ddd',
                      }}
                        key={color}
                        onClick={e => {
                          CondirmDialog.closeDealog();
                          this.saveHistory();
                          section.bg = color;
                          this.setState({dashboard, touched: true});
                        }}
                        className={classnames('select-option', {selected: color === section.bg})}>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className={`menu-option action flex`} onClick={e => {
            CondirmDialog.closeDealog();
          }}>
            <div className="menu-option">
              <div className="label">Text Align</div>
              <div className="metric-selector">
                {
                  ['left', 'center'].map(align => {
                    return (
                      <div
                        key={align}
                        onClick={e => {
                          CondirmDialog.closeDealog();
                          this.saveHistory();
                          section.align = align;
                          this.setState({dashboard, touched: true});
                        }}
                        className={classnames('select-option', {selected: align === section.align})}>
                        {_.capitalize(align)}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {/*{*/}
          {/*  (!!alwaysShowSectionFilters || (!!sectionKey && ['overall', 'digital','tv', 'radio'].some(p => sectionKey.indexOf(p) >= 0) )) && //only digital platform support drill down.*/}
          {/*  <div className={`menu-option action flex`} onClick={e => {*/}
          {/*    if(!section.disabled && !!section.filters) {*/}
          {/*      section.disabled = true;*/}
          {/*      delete section.filters;*/}
          {/*    } else {*/}
          {/*      section.disabled = false;*/}
          {/*      section.filters = section.filters || {}*/}
          {/*    }*/}
          {/*    this.setState({dashboard, touched: true}, () => this.loadDataForSection(section, sectionKey));*/}
          {/*    CondirmDialog.closeDealog();*/}
          {/*  }}>*/}
          {/*    <i className="material-symbols-outlined">filter_alt</i> {(!section.disabled && section.filters) ? 'Disable Filters' : 'Enable Section Scope Filters' }*/}
          {/*  </div>*/}
          {/*}*/}
        </ComponentMenu>
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />);
  }

  visDragstart(ev) {
    if(!ev.target.id) {
      return;
    }
    ev.dataTransfer.setData("id", ev.target.id);
    if(this.state.mode !== 'customize') return;
    ev.dataTransfer.dropEffect = "copy";
    ev.currentTarget.classList.add("dragging");
    this.setState({showDrop: true});
  }

  visDragend(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "copy";
    ev.currentTarget.classList.remove("dragging");
    this.setState({showDrop: false});
  }

  visDragEnter(ev) {
    ev.preventDefault();
  }

  visDragover(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "copy";
    ev.currentTarget.classList.add('hover');
  }

  visDragleave(ev) {
    ev.preventDefault();
    ev.currentTarget.classList.remove('hover');
    ev.currentTarget.classList.remove("dragging");
  }

  visDrop(ev, replace) {
    ev.preventDefault();
    ev.stopPropagation(); // Prevent populate to parent dom element cause duplicate event handling.
    const {campaign, dashboard, tabIndex, platformKeys, context, autoSave} = this.state;
    let {mixedVisualisations} = context
    this.setState({showDrop: false});
    const visKey = ev.dataTransfer.getData("id");
    if (!visKey) {
      return;
    }
    this.saveHistory();

    let targetSectionIndex = Number(ev.currentTarget.dataset.sectionIndex) || 0;
    let targetChartIndex = Number(ev.currentTarget.dataset.index) || 0;

    const platformKey = platformKeys[tabIndex] || {};
    const platformCanvas = dashboard[platformKey];
    const sectionCanvas = platformCanvas.sections[targetSectionIndex];
    const targetSectionCharts = sectionCanvas.charts;
    const [visKeyBasic, visKeyPlatform] = visKey.split('$$');
    let chartMeta = mixedVisualisations.find(d => d.key === visKeyBasic);

    if(chartMeta) { // drag from drawer
      const {cfg: platformLevelCfg = {}} = (chartMeta.defaultByPlatformLevel || {})[visKeyPlatform || platformKey] || {};
      const chart = {pkey: Date.now(), key: chartMeta.key, cfg: {...chartMeta.cfg, ...platformLevelCfg, platform: visKeyPlatform || platformKey}};
      const leadingChart = targetSectionCharts[targetChartIndex] || targetSectionCharts[targetChartIndex - 1];
      if(chartMeta.cfg.size !== 'size100' && leadingChart && ['seperator', 'editor'].indexOf(chartMeta.key) < 0) {
        chart.cfg.size = leadingChart.cfg.size;
      }
      targetSectionCharts.splice(targetChartIndex, replace ? 1 : 0, chart);
      this.loadDataForChart(chart, sectionCanvas.filters);
    } else {
      let [dropChartKey, sourceSectionIndex, sourceChartIndex] = visKey.split('::');
      sourceSectionIndex = Number(sourceSectionIndex);
      sourceChartIndex = Number(sourceChartIndex);
      if(sourceSectionIndex == targetSectionIndex) {
        targetChartIndex = Math.min(targetChartIndex, targetSectionCharts.length - 1);
        // targetSectionCharts[targetChartIndex] = targetSectionCharts.splice(sourceChartIndex, 1, targetSectionCharts[targetChartIndex])[0]; //swap position
        let [chart] = targetSectionCharts.splice(sourceChartIndex, 1);
        if(targetChartIndex > sourceChartIndex) {
          targetChartIndex -= 1;
        }
        targetSectionCharts.splice(targetChartIndex, 0, chart)
      } else {
        let sourceSectionCharts =  platformCanvas.sections[sourceSectionIndex].charts;
        //remove from source section
        let [chart] = sourceSectionCharts.splice(sourceChartIndex, 1);
        targetSectionCharts.splice(targetChartIndex, 0, chart)
        //insert to target section
      }
    }

    this.setState({dashboard, touched: true}, () => {
      if(autoSave && this.saveReportSilent) {
        this.saveReportSilent();
      }
    });

  }

  removeVis(key, sectionIndex, index) {
    const {loadingMap, dashboard, tabIndex, platformKeys, autoSave} = this.state;

    this.saveHistory();

    let platformKey = platformKeys[tabIndex] || {};
    const platformCanvas = dashboard[platformKey];
    const sectionCanvas = platformCanvas.sections[sectionIndex];
    sectionCanvas.charts.splice(index, 1);

    this.setState({dashboard, touched: true}, () => {
      if(autoSave && this.saveReportSilent) {
        this.saveReportSilent();
        this.setState({dashboard});
      }
    });
  }

  visDragRemove(e) {
    e.preventDefault();
    e.stopPropagation(); // Prevent populate to parent dom element cause duplicate event handling.
    const visKey = e.dataTransfer.getData("id");
    if (!visKey) {
      return;
    }
    let [dropChartKey, sourceSectionIndex, sourceChartIndex] = visKey.split('::');
    if(!dropChartKey || !sourceSectionIndex || !sourceChartIndex){
      return;
    }

    this.setState({showDrop: false});
    this.removeVis(dropChartKey, Number(sourceSectionIndex), Number(sourceChartIndex));
  }

  saveHistory() {
    let {dashboard, historyStack = []} = this.state;
    let snapshot = _.cloneDeep(dashboard);
    if(this.reportAttrs) {
      snapshot = {...snapshot, reportAttrs: this.reportAttrs};
      delete this.reportAttrs;
    }
    historyStack = _.takeRight(historyStack.concat([snapshot]), 10);
    this.setState({historyStack});
  }

  rollback() {
    let {autoSave, historyStack = [], report} = this.state;
    let previousState = historyStack.pop();
    if(previousState.reportAttrs) {
      report = {...report, ...previousState.reportAttrs};
    }
    this.setState({dashboard: _.cloneDeep(previousState), report, historyStack, touched: false}, () => {
      if(autoSave && this.saveReportSilent) {
        this.saveReportSilent();
      }
    });
  }

  loadDataForChart(chart, sectionFilters) {
    const {tv_ta, report = {}, clientData = [], campaign = {}, reportData = [], loadingMap = {}, errorMap = {}} = this.state;
    const queryKey = genChartQueryKey(chart, sectionFilters);
    this.setState({loadingMap: {...loadingMap, [queryKey]: true}, errorMap: {...errorMap, [queryKey]: null}});
    const urlParams = qs.parse(window.location.search.slice(1));
    const params = {
      tv_ta,
      chart,
      filters: sectionFilters,
      datasource: report.datasource || {campaigns: [campaign]},
      report_id: report.id,
      share_code: urlParams.code,
    }
    post(`/api/loadDataForChart`, params).then(chartData => {
      let idx = reportData.findIndex(d => d.key === queryKey);
      if(idx >=0){
        reportData.splice(idx, 1, chartData);
      } else {
        reportData.push(chartData);
      }
      this.setState({reportData, loadingMap: {...loadingMap, [queryKey]: false}, errorMap: {...errorMap, [queryKey]: null}});
    }).catch(e => {
      this.setState({loadingMap: {...loadingMap, [queryKey]: false}, errorMap: {...errorMap, [queryKey]: e.message}});
    });
  }

  loadDataForSection(section, sectionKey) {
    const {tv_ta, report = {}, campaign = {}, reportData = [], loadingMap = {}, errorMap = {}} = this.state;
    const chartsMap = section.charts.reduce((ret, next) => {
      const queryKey = genChartQueryKey(next, section.filters);
      return ({...ret, [queryKey] : true});
    }, {});
    const chartsMapReverse = Object.keys(chartsMap).reduce((ret, next) => ({...ret, [next]: false}), {});
    this.setState({loadingMap: {...loadingMap, [sectionKey]: true, ...chartsMap}, errorMap: {...errorMap, [sectionKey]: null}});
    const params = {
      tv_ta,
      section,
      datasource: report.datasource || {campaigns: [campaign]}
    }
    post(`/api/loadDataForSection`, params).then(chartDataList => {
      for(let chartData of (chartDataList || [])) {
        let idx = reportData.findIndex(d => d.key === chartData.key);
        if(idx >=0){
          reportData.splice(idx, 1, chartData);
        } else {
          reportData.push(chartData);
        }
      }
      this.setState({reportData, loadingMap: {...loadingMap, [sectionKey]: false, ...chartsMapReverse}});
    }).catch(e => {
      this.setState({error: e.message, loadingMap: {...loadingMap, [sectionKey]: false, ...chartsMapReverse}, errorMap: {...errorMap, [sectionKey]: e.message}});
    });
  }

  downloadPDF(code) {
    let {report} = this.state;
    this.setState({loading: true});
    post(`/api/downloadReportPDF`, {id: report.id, code, webDomain: window.location.host}, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'blob',
      }
    }).then((data) => {
      this.setState({loading: false});
      var blob = new Blob([data], {type: 'application/pdf'});
      saveAs(blob, `Aladdin Report - ${report.name}.pdf`);
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  downloadExcel(code) {
    let {report, context} = this.state;
    let {mixedVisualisations, allMixedLabelMap} = context;
    let titleMap = _.flattenDeep(report.sections.map(s => {
      return s.charts.map(c => ({...c, filters: s.filters}));
    })).reduce((ret, chart) => {
      const queryKey = genChartQueryKey(chart, chart.filters);
      const chartMeta = mixedVisualisations.find(d => d.key === chart.key) || {};
      let chatTitle = '';
      if(chartMeta.key.startsWith('keymetric_client_')) {
        chatTitle = allMixedLabelMap[chartMeta.key.substring(10, chartMeta.key.length)]; //this will remove keymetric_ to find label
      } else {
        chatTitle = chart.cfg.title || (chartMeta.labelFunc ? chartMeta.labelFunc(chart, context) : chartMeta.label);
      }
      if(chart.key.indexOf('keymetric') >= 0){
        chatTitle = chatTitle || metricMap[chart.cfg.metric || '']
      }
      return {
        ...ret,
        [queryKey]: chatTitle
      }
    }, {})
    this.setState({loading: true});
    post(`/api/downloadReportExcel`, {id: report.id, code, titleMap, allMixedLabelMap}).then(excelData => {
      this.setState({loading: false});
      const blob = new Blob([this.s2ab(excelData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      let filename = `Aladdin Report - ${report.name}.xlsx`;
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

  showComponentData(chart, queryData, benchmarkData, context) {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Chart Data</strong>,
      width: '750px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeDealog(),
      onOK: () => CondirmDialog.closeDealog(),
      dialogBody: (
        <div>
          <Datatable
            data = {queryData}
            benchmark = {benchmarkData}
            chart = {chart}
            context = {context}
          />
          {['tv_schedule'].indexOf(chart.key) >= 0 && this.showTvScheduleSpots(queryData)}
        </div>
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />);
  }

  showTvScheduleSpots(queryData) {
    return (
      <ChartDialogFooter>
        <div className='labelText'><b>Txed Spots</b>: {queryData.data && _.sum(queryData.data.map(d => {if(!d.future) {return d.spots}})) || 0}</div>
        <div className='labelText'><b>Future Spots</b>: {queryData.data && _.sum(queryData.data.map(d => {if(d.future) {return d.spots}})) || 0}</div>
      </ChartDialogFooter>
    )
  }

  adjust() {
    if(window.innerWidth < 768) {
      return;
    }
    let sizeMap = {
      'size100': 1,
      'size1_2': 0.5,
      'size1_3': 1/3,
      'size2_3': 2/3,
      'size1_4': 1/4,
      'size3_4': 3/4,
      'size1_5': 1/5,
      'size2_5': 2/5,
      'size3_5': 3/5,
      'size4_5': 4/5,
    };
    let list = document.querySelectorAll('.chart-list');
    let listWidth = (document.querySelectorAll('.chart-list')[0] || {}).offsetWidth;
    for(let cont of list) {
      let charts = cont.querySelectorAll('.chart-wrapper');
      let stack = 0; let queue = []
      for(let node of charts) {
        // node.querySelector('.droparea').style.width = '20px';
        node.classList.remove(['grid-line-head', 'grid-line-tail']);
        ((node.querySelector('.droparea') || {}).style || {}).width = '20px';
        if(stack === 0) {
          node.classList.add('grid-line-head');
          ((node.querySelector('.droparea.pre') || {}).style || {}).width = listWidth + 'px';
        }
        let size = node.dataset['size'];
        if(!size || !sizeMap[size]) break;
        stack += sizeMap[size];
        queue.push(node);
        if(stack === 1) {
          // for(let n of queue) {
          //   n.style.marginRight = (20 + 20 /(queue.length - 1)) + 'px';
          // }
          if(queue.length < 10) {
            node.classList.add('grid-line-tail');
            ((node.querySelector('.droparea.tail') || {}).style || {}).width = listWidth + 'px';
          }
          node.style.marginRight = 0;
          node.style.width = pctFormatter(sizeMap[size]);
          stack = 0;
          queue = [];
        } else if(stack > 1) {
          stack = sizeMap[size];
          queue = [node];

          node.classList.add('grid-line-head'); // new line
          ((node.querySelector('.droparea.pre') || {}).style || {}).width = listWidth + 'px';
        }
      }
    }
    window.dispatchEvent(new Event('resize'));
  }

  componentDidMount() {
    this.adjust();
  }

  componentDidUpdate() {
    this.adjust();
  }
}
