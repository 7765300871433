import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {TableController, TableController1, Wrapper} from './index.style';
import {CondirmDialog, ProgressBar, Table, TableScrollWrapper} from "../uikit";
import {StyledDropdown} from "../uikit/dropdown/styled";
import {MediumGray1} from "../app/StyleCommon";
import {Link} from "react-router-dom";
import { supportedColumns } from './columns';
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import classNames from "classnames";
import {cacheResource, getFromCache} from "../utils/cache";
import {paginationValues} from '../utils/metadata';
import FilterBox from "./FilterBox";
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';

const REPORT_LIST_CACHE_KEY = 'REPORT_LIST_CACHE_KEY';
export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(REPORT_LIST_CACHE_KEY) || {};
    const queryParams = new URLSearchParams(window.location.search);
    const searchterm = queryParams.get("search");
    const filters = [];
    this.state = {
      showMore: false,
      page: 1,
      PAGE_SIZE: 5,
      sortBy: 'create_time',
      sortDirection: 'desc',
      ...cachedSettings
    }
    if (searchterm && searchterm !== "") {
      this.state.keyword = searchterm;
    }
    this.queryAgainDebounce = _.debounce(this.queryAgain.bind(this), 500);
    
  }

  cacheParams() {
    cacheResource(REPORT_LIST_CACHE_KEY, _.pick(this.state, ['page', 'PAGE_SIZE', 'sortBy', 'sortDirection', 'keyword']))
  }

  render() {
    const {data, error, loading, keyword, page, PAGE_SIZE, sortBy, sortDirection, showMore} = this.state;
    let {templates = [], reports, total} = data || {};
    const filters = [];
    const filterMetadata= [];
    // templates = [...templates, {name: 'test', discription: 'test', priority: 100}];
    const {session = {}} = this.props.appState || {};
    let canViewList = session.rights.indexOf('View Reports List') >= 0;
    let canEdit = session.rights.indexOf('Build Report') >= 0;
    let canView = session.rights.indexOf('View Report') >= 0;
    let canDelete = session.rights.indexOf('Delete Report') >= 0;
    let canBuildReport = session.rights.indexOf('Build Report') >= 0;
    const filterColumns = _.cloneDeep(supportedColumns).map(s => {
        if (s.key === "creator") {
            return { ...s, label: 'Creator' };
        }
        return { ...s };
    });
    return (
      <MainLayout activeItem="reporting" breadcrumb={[{path: '/reporting', label: 'Reporting'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryReportListPage`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        <Wrapper>
          {
            canEdit &&
            <div className="template-list">
              <div className="template-list-left">
                <h4>
                  <div>Report Templates</div>
                  {/*<a>Show More >></a>*/}
                </h4>
                <p>Click the template item below to create a report for your campaign.</p>
                <div className="items">
                  {templates.slice(0, showMore ? templates.length : 4).map((d, templateIndex) => {
                    let iconSet = ['note', 'feed', 'breaking_news_alt_1', 'devices'];
                    let disabled = session.rights.indexOf('Build Report') < 0;
                    return (
                      <Link
                        className={classNames('item', {disabled: disabled})}
                        key={d.name}
                        to={{
                          pathname: disabled ? '/reporting' : "/report/builder",
                          search: `?fr=tmp_${d.id}`,
                          hash: `#${d.name}`,
                          state: {template: d, dashboard: d.config}
                        }}>
                        <div className="item-inner">
                          <div className="icon">
                            <span className="material-symbols-outlined">{iconSet[templateIndex] || iconSet[0]}</span>
                          </div>
                          <div>
                            <strong>{d.name}</strong>
                            <p>{d.description}</p>
                            {
                              d.priority > 10 &&
                              <a className="delete-link" onClick={e => this.deleteTemplate(d, e)}><i className="fa fa-trash"/></a>
                            }
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
              {/*<div className="report_intr">*/}
              {/*  <img  src="/img/report/report_intr.png" />*/}
              {/*</div>*/}
              {
                templates.length > 4 &&
                <div className="more-less-trigger" onClick={e => this.setState({showMore: !showMore})}>
                  {!!showMore && <div>Show Less <i className="material-symbols-outlined">keyboard_double_arrow_up</i></div>}
                  {!showMore && <div>Show More <i className="material-symbols-outlined">keyboard_double_arrow_down</i></div>}
                </div>
              }
            </div>
          }
          {/* <TableController>
            1211111
            <h4 style={{marginLeft: '30px'}}>My Reports</h4>
            <div className="searchBox">
              <i className="fa fa-search"/>
              <input
                type="text"
                placeholder="Enter report name id to search..."
                value={keyword}
                onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.queryAgainDebounce())}/>
            </div>
          </TableController> */}

            <TableController1 style={{background: 'white', marginBottom: '0', borderBottom: '1px solid #efefef'}}>
              <h4 style={{margin: '0 40px 0 0'}}>My Reports</h4>
              <FilterBox
                key={JSON.stringify(filters)}
                filters={filters}
                supportedColumns={filterColumns}
                filterMetadata={filterMetadata}
                onChange={newFilters => this.setState({filters: newFilters, page: 1}, () => this.queryAgain())} />
              <div className="searchBox">
                <i className="fa fa-search"/>
                <input
                  type="text"
                  placeholder="Search report..."
                  value={keyword}
                  onChange={e => this.setState({keyword: e.target.value, page: 1}, () => this.queryAgainDebounce())}/>
                {this.state.keyword && this.state.keyword !== "" ? <i class="fa fa-times" aria-hidden="true" onClick={() => this.setState({keyword: '', page: 1}, () => this.queryAgainDebounce())}></i>: null}
              </div>
            </TableController1>
          <TableScrollWrapper className="report-table">
            <Table
              context={{session}}
              columns={[
                {
                  key: 'index',
                  label: 'No.',
                  align: 'center',
                  style: {verticalAlign: 'top', color: MediumGray1, paddingTop: '12px', maxWidth: '25px'},
                  renderer: (d, i) => i + 1
                },
                ...supportedColumns,
                {
                  key: 'actions',
                  sortable: false,
                  label: 'Actions',
                  hidden: !canEdit,
                  align: 'center top',
                  renderer: (d, dIndex) => {
                    let noAction = !canEdit &&  !canView && !canDelete;
                    if(noAction) {
                      return '--';
                    }
                    let hasWriteAccess = (d.creator === session.userId || (d.shares ||[]).some(s => s.users.indexOf(session.userId) >= 0));
                    return (
                      <div className="actions" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                          canEdit && hasWriteAccess &&
                          <Link to={`/report/builder?id=${d.id}`}><i className="fa fa-edit"/></Link>
                        }
                        {
                          canEdit &&
                          <StyledDropdown
                            useFixed={true}
                            fixedLeft={-50}
                            fixedTop={(dIndex > 3 || reports.length < 2) ? -130 : 10}
                            closeOnClick={true}
                            toggler={<i className="fa fa-ellipsis-v"/>}>
                            {
                              canView &&
                              <div className={`menu-item`}>
                                {
                                  hasWriteAccess &&
                                  <Link to={`/report/builder?id=${d.id}`}><i className="fa fa-edit"/>Open</Link>
                                }
                                {
                                  !hasWriteAccess &&
                                  <Link to={`/report/readonly?id=${d.id}`}><i className="fa fa-edit"/>View</Link>
                                }
                              </div>
                            }
                            {
                              canEdit &&
                              <div className={`menu-item`}>
                                <a onClick={e => this.duplicateReport(d)}><i className="fa fa-copy"/>Clone</a>
                              </div>
                            }

                            {
                              canDelete && hasWriteAccess &&
                              <div className={`menu-item`}>
                                <a onClick={e => this.deleteReport(d)}><i className="fa fa-trash"/>Delete</a>
                              </div>
                            }

                          </StyledDropdown>
                        }
                      </div>
                    )
                  }
                },
              ]}
              rows={canViewList ? (reports || []) : []}
              sort={{sortColumn: sortBy, direction: sortDirection}}
              sortChange={sortBy => {
                this.setState({sortBy: sortBy, page: 1}, () => {
                  this.cacheParams();
                  this.queryAgain();
                });
              }}
              directionChange={direction => {
                this.setState({sortDirection: direction}, () => {
                  this.cacheParams();
                  this.queryAgain();
                });
              }}
              noDataText={<div style={{height: '110px', marginTop: '50px'}}>{canViewList ? 'No Data Found' : 'You are not allowed to view report list'}</div>}
            />
          </TableScrollWrapper>
          {
            canViewList &&
            <ListFooter>
              <span className="info">
                <strong>{total}</strong> reports found.
                Show
                <select
                  value={PAGE_SIZE}
                  onChange={e => this.setState({
                    PAGE_SIZE: Number(e.target.value),
                    page: 1,
                  }, () => {
                    this.cacheParams();
                    this.queryAgain();
                  })}>
                {paginationValues.map(d => {
                  return (
                    <option key={d} value={d}>{d}</option>
                  )
                })}
                </select>
                per page
            </span>
              <PaginationStyled
                onChange={p => this.setState({page: p}, () => {
                  this.cacheParams();
                  this.queryAgain();
                })}
                current={page}
                pageSize={PAGE_SIZE}
                total={total}
                hideOnSinglePage={false}/>
            </ListFooter>
          }

        {/* {this.renderTableComp()} */}
        {/* {this.renderTableGrid()} */}

        {/* <ReactTable/> */}
        </Wrapper>
      </MainLayout>
    )
  }

  renderDropdown({row, onRowChange}){
    const titles = ['Dr.', 'Mr.', 'Mrs.', 'Miss', 'Ms.'];
    return (
      <select
        value={row.title}
        onChange={(event) => onRowChange({ ...row, title: event.target.value }, true)}
        autoFocus
      >
        {titles.map((title) => (
          <option key={title} value={title}>
            {title}
          </option>
        ))}
      </select>
    )
  }

  buildQuery() {
    const {page, PAGE_SIZE, filters, keyword, sortBy, sortDirection} = this.state;
    const query = {
      keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      filters,
      sortBy,
      sortDirection
    };
    return query;
  }

  deleteReport(report) {
    CondirmDialog.showConfirm(`Do you really want to delete this report ?`, () => {
      this.setState({loading: true});
      post('/api/deleteReport', {id: report.id}).then(result => {
        if (result.success) {
          this.setState({loading: false, data: null})
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }

  deleteTemplate(template, e) {
    e.preventDefault();
    e.stopPropagation();
    CondirmDialog.showConfirm(`Do you really want to delete this template ?`, () => {
      this.setState({loading: true});
      post('/api/deleteTemplate', {id: template.id}).then(result => {
        if (result.success) {
          this.setState({loading: false, data: null})
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }

  duplicateReport(d) {
    this.setState({loading: true})
    return post('/api/duplicateReport', {id: d.id}).then(report => {
      this.setState({data: null, loading: false});
      this.props.history.push(`/report/builder?id=${report.id}&clone=y`);
    }).catch(e => {
      this.setState({loading: false});
      console.error(e);
    })
  }

  queryAgain() {
    this.cacheParams();
    this.setState({loading: true});
    return post(`/api/queryReportListPage`, this.buildQuery()).then(data => {
      this.setState({data: data, loading: false});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }
})