import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default class EditorConvertToHTML extends Component {
  static defaultProps = {
    onChange: _ => undefined, // change handler
  };

  constructor(props) {
    super(props);
    let editorState = EditorState.createEmpty();
    if(props.data) {
      const blocksFromHtml = htmlToDraft(props.data);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      editorState = EditorState.createWithContent(contentState);
    }

    this.state = {
      editorState: editorState
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  };

  render() {
    const { editorState } = this.state;
    const { disabled, mode} = this.props;
    return (
      <div draggable={false}>
        <Editor
          readOnly={disabled || mode === 'display'}
          editorState={editorState}
          placeholder={'Input text here'}
          wrapperClassName="demo-wrapper"
          editorClassName= {`demo-editor`}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ['inline', 'colorPicker', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          // toolbarCustomButtons={[<Personalizer />]}
        />
      </div>
    );
  }
}