import React from 'react';
import {
  Aladin_BG,
  Aladin_Orange_Light,
  colorCategory4,
  DarkGray,
  LightGray,
  MediumGray,
  MediumGray1
} from "../app/StyleCommon";
import {intFormatter, pctFormatter, periodFormat} from "../utils/formatter";
import {metricMap} from "../utils/metadata";
import styled, {css} from "styled-components";
import {visualisations} from "./index";

export const NotSupportedComponent = (props) => {
  const Wrapper = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${MediumGray1};
    i {
      font-size: 28px;
      color: ${MediumGray};
      vertical-align: middle;
    }
  `
  return (
    <Wrapper>
      <div>
        <div><i className="material-symbols-outlined">broken_image</i> {props.title || ''}</div>
        <div>Visualization Component not found!</div>
      </div>
    </Wrapper>
  )
}
export const NoDataComponent = (props) => {

  const {context} = props;
  const {mixedVisualisations, mixedLabelMap} = context;
  let {thumbnail} = mixedVisualisations.find(d => d.key === props.chart.key) || {};
  const Wrapper = styled.div`
    padding: 20px;
    display: block;
    text-align: center;
    color: ${DarkGray};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: -40px;
    ${thumbnail && css`
      min-height: 220px;
    `}
    .bg {
      position: absolute;
      width: 80%;
      height: 80%;
      opacity: .15;
      left: 10%;
      top: 10%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    i {
      font-size: 18px;
      vertical-align: middle;
    }
    >div:nth-child(1) {
      height: 100%;
    }
  `
  let title = props.title;
  if(props.chart.key.indexOf('keymetric_') === 0) {
    title = mixedLabelMap[props.cfg.metric] || props.cfg.metric;
  }
  return (
    <Wrapper className={"no-data"} thumbnail = {thumbnail}>
      <div>
        {/*{!props.isLoading && <div><i className="material-symbols-outlined">broken_image</i></div>}*/}
        {!props.isLoading && !props.error  && <div title={props.chart.key}>Data not available.</div>}
        {!!props.isLoading && <div title={props.chart.key}>Loading...</div>}
        {!!props.error && <div title={props.chart.key}>Loading Failed, {props.error}</div>}
      </div>
      <div className="bg" style={{backgroundImage: `url(${thumbnail})`}}></div>
    </Wrapper>
  )
}