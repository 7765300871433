import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  GrapeFruit,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG,
  Aladin_BG_1,
  Aladin_BG_2, Aladin_Blue_Dark, Aladin_Orange_Lighter
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const DashboardWrapper = styled.div`
  padding: 20px 20px 20px 30px;

  .live-campaigns {
    .items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .item {
        background: ${LightGray};
        padding: 15px;
        border-radius: 10px;
        margin-right: 35px;
        width: 25%;
        box-sizing: border-box;
        align-self: stretch;
        text-decoration: none;
        color: ${DarkGray};
        font-weight: bold;
        position: relative;
        padding-bottom: 50px;
        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 15px auto;
        }

        &:hover {
          box-shadow: 3px 5px 13px rgba(0, 0, 0, .1);
          cursor: pointer;
        }

        &:nth-child(1) {
          background: linear-gradient(120deg, rgba(238, 243, 250, 0.73) 0%, rgba(229, 239, 252, 0.78) 100%);
        }

        &:nth-child(2) {
          background: linear-gradient(120deg, rgba(238, 243, 250, 0.73) 0%, rgba(229, 239, 252, 0.78) 100%);
        }

        &:nth-child(3) {
          background: linear-gradient(120deg, rgba(238, 243, 250, 0.73) 0%, rgba(229, 239, 252, 0.78) 100%);
        }

        &:nth-child(4) {
          background: linear-gradient(120deg, rgba(238, 243, 250, 0.73) 0%, rgba(229, 239, 252, 0.78) 100%);
          margin-right: 0;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }

        .advertiser {
          font-size: 16px;
          font-weight: bold;
          color: ${DarkGray};
        }

        .name {
          margin: 10px 0;
          font-size: 14px;
          color: ${DarkGray};
        }

        .metric {
          justify-content: space-between;
          color: ${DarkGray};
          background: rgba(245, 233, 233, 0.18);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 40px;
          z-index: 1;
          border-radius: 0 0 10px 10px;
          padding: 0 10px;

          .inflight_status {
            padding: 5px 10px 5px 0;
            font-size: 12px;
            font-weight: bold;
          }

          .status {
            border-radius: 5px;
            padding: 2px 5px;
            font-weight: bold;
            font-size: 12px;
            white-space: nowrap;

            &.ON_CHECK {
              background: ${Grass1};
              color: white;
            }

            &.OFF_CHECK {
              background: ${BitterSweet1};
              color: white;
            }

            &.WARNING {
              background: ${Sunflower};
              color: white;
            }
          }
        }

        > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .revenue-chart,
  .key-breakdowns {
    margin-top: 35px;
    background: white;
    background: ${Aladin_BG} !important;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgba(186, 181, 243, 0.51);

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select-box {
        width: auto;
        font-size: 14px;

        .toggler {
          min-width: inherit;
          border: none;
          background: ${Aladin_BG_1};
          font-size: 12px;
          border-radius: 10px;
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          width: auto;
          min-width: auto;

          i {
            position: static;
            margin-left: 10px;
            vertical-align: middle;
            transform: none;
          }
        }
      }
    }
  }

  .key-breakdowns {
    .items {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      .item {
        flex-grow: 1;
        width: 100%;
        //background: white;
        padding: 20px;
        border-radius: 15px;
        margin-right: 20px;
          //border-right: 1px solid ${Aladin_BG_2};
        background: rgba(255, 255, 255, .4);

        &:last-child {
          margin-right: 0px;
          border-right: none;
        }
      }
    }
  }

  .feeds {
    margin-top: 20px;
    height: 840px;
    overflow-y: hidden;
    position: relative;
    transform: translateX(10px);

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 80px;
      width: 100%;
      left: 0;
      background: linear-gradient(to bottom, transparent, white);
    }

    .feeds-header {
      margin: 10px 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-right: 30px;
      border-bottom: 1px solid ${Aladin_Orange_Lighter};

      .header-tab {
        padding: 3px 5px;
        borderRadius: 5px;
        marginRight: 15px;
        cursor: pointer;
        margin-right: 10px;

        &:hover {
          text-decoration: underline;
        }

        &.selected {
          background: ${Aladin_Orange_Light};
          color: white;
        }

      }
    }

    .item {
      border-left: 1px dashed ${Aladin_Orange_Light};
      padding: 0px 20px 10px 20px;
      margin: 0;
      margin-left: 15px;

      .title {
        transform: translateX(-28px);

        i {
          margin-right: 10px;

          &.ok {
            color: #1fc41f;
          }

          &.bad {
            color: red;
          }
        }
      }

      .desc {
        color: ${DarkGray};
        font-size: 12px;
      }
    }
  }

`