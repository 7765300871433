import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {post, get} from '../../utils/request';
import {Table} from "../../uikit/index";
import {Pagination, PaginationLink, SearchBoxDialog} from "./index.style";
import {MSQListWrapper, SelectedRow, List, DropdownItem} from "./msqTable.style";
import {moneyFormatter} from "../../utils/formatter";
import {platformLabelMap} from "../../utils/metadata";

const MsqTable = ({
  id, 
  type,
  keyword, 
  confirmInfo
}) => {
  const [tableState, setTableState] = useState({
    error: false,
    loading: false,
    page: 0, 
    pageSize: 10, 
    sortBy: '', 
    sortDirection: ''
  });
  const [tableData, setTableData] = useState([]);
  const apiUrl = '/api/msq_search';
  const [inputKey, setInputKey] = useState('');

  useEffect(() => {
    setInputKey(!id ? keyword : id);
  }, []);

  useEffect(() => {
    if(inputKey !== "") {
      newHandler(inputKey);      
    }
  }, [inputKey])

  useEffect(() => {
    if(inputKey !== "") {
      searchData(inputKey);      
    }
  }, [tableState.page, tableState.sortBy, tableState.sortDirection])


  const searchData = (val) => {
    let query = {
      keyword: val, 
      limit: 10, 
      offset: tableState.page,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection
    }
    if (keyword) {
      setTableState({...tableState, loading: true})
      post(apiUrl, query)
        .then(res => {
          const {result}= res;
          let data = Object.keys(result).map(key => {
            return result[key]
          })
          setTableState({...tableState, loading: false});
          setTableData(data[0])
        })
        .catch(e => {
          setTableData([]);
          setTableState({...tableState, error: e.message, loading: false});
        });
    } else {
      setTableState({...tableState, data: null, loading: false});
    }
  }

  const handleRowClick = (row) => {
    confirmInfo && confirmInfo.showNextScreen(
      <>
        <SelectedRow>Selected MSQ ID: <span>{row.id}</span></SelectedRow>
        {renderMsqItem(row)}
      </>
      , ['1'])
  }

  const newHandler = useCallback(_.debounce(searchData, 500), []);

  const handlePagination = type => {
    if(type === "next") {
      setTableState({...tableState, page: tableState.page+10})
    } else if (type === "prev") {
      setTableState({...tableState, page: tableState.page > 10 ? tableState.page-10: 0})
    }
  }

  const renderSearchBox = () => {
    return (
      <SearchBoxDialog>
        <i className="fa fa-search"/>
        <input
          type="text"
          placeholder="Search..."
          value={inputKey}
          onChange={e => {
            setInputKey(e.target.value);                
            newHandler(e.target.value)
          }}/>
      </SearchBoxDialog>
    )
  }

  const renderTable = () => {
    return (
      <>
        <div className="data-table">
              <Table
                context={{page: tableState.page, PAGE_SIZE: tableState.pageSize}}
                columns={[
                  {
                    key: 'id',
                    label: 'MSQ Id',
                    align: 'left',
                    sortable: true,
                    style: {maxWidth: '50px'}                  
                  },
                  {
                    key: 'name',
                    label: 'Campaign Name',
                    align: 'left',
                    sortable: true,
                    renderer: (d, i) => `<b>${d.name}</b>`
                  },
                  {
                    key: 'budget',
                    label: 'Net',
                    align: 'left',
                    sortable: true,
                    
                  },
                  {
                    key: 'advertiser',
                    label: 'Advertiser',
                    align: 'left',
                    sortable: true,
                  },
                  {
                    key: 'start_date',
                    label: 'Period',
                    align: 'left',
                    sortable: true,
                    renderer: (d, i) => {
                      return `${moment(d.start_date).format('DD MMM YYYY')} - ${moment(d.end_date).format('DD MMM YYYY')}`
                    }
                  },
                  {
                    key: 'update_time',
                    label: 'Updated',
                    align: 'left',
                    sortable: true,
                    renderer: (d, i) => moment().format('DD MMM YYYY')
                  }
                ]}
                rows={tableData || []}
                sort={{sortColumn: tableState.sortBy, direction: tableState.sortDirection}}
                sortChange={sortBy => {
                  setTableState({...tableState, sortBy: sortBy});
                }}
                directionChange={direction => {
                  setTableState({...tableState, sortDirection: direction});
                }}
                noDataText={<div style={{height: '110px', marginTop: '50px'}}>No data</div>}
                isDangeouslyBinded={true}
                // rowClicker={row => handleRowClick(row)}
              />
        </div>
        {!id && tableData && tableData.length > 9 &&  <Pagination>
          <PaginationLink  onClick={(e) => handlePagination('prev')}>
            <i className="material-symbols-outlined">keyboard_double_arrow_left</i> Prev
          </PaginationLink>
          <PaginationLink onClick={(e) => handlePagination('next')}>
            Next <i className="material-symbols-outlined">keyboard_double_arrow_right</i>
          </PaginationLink>
        </Pagination>}
    </>
    )
  }


  const renderMsqItem = (d, keyword) => {
    let platformsGrouped = _.values(_.mapValues(_.groupBy(d.platforms || [], item => {
      return item.platform + item.platform_campaign_id + item.name;
    }), (arr, key) => {
      return arr.reduce((ret, next) => {
        if(ret) {
          return {
            ...ret,
            net: (ret.net || 0) + (next.net || 0),
            start_date: _.min([ret.start_date, next.start_date]),
            end_date: _.max([ret.end_date, next.end_date]),
          }
        } else {
          return next;
        }
        return ret;
      }, null)
    }));
    return (
      <DropdownItem key={d.id} onClick={() => {}} className='list'>
        <div className='msq-item-option 121'>
          <div className="msq-name">
            {d.name}, MSQ ID: ${d.id}
          </div>
          <div className="msq-tag-list">
            <div className="msq-tag">
              <b>Period:</b> {moment(d.start_date).format('DD MMM YYYY')} - {moment(d.end_date).format('DD MMM YYYY')}
            </div>
            <div className="msq-tag">
              <b>Advertiser:</b> {d.advertiser}
            </div>
            <span className="msq-tag">
              <b>Net:</b> {moneyFormatter(d.budget, d.currency)} {d.currency}
            </span>
          </div>
        </div>
        <ul className="msq-platform-list">
          {_.sortBy(platformsGrouped, _.property('platform')).filter(p => p.platform !== 'overall').map(p => {
            return (
              <li key={p.platform_campaign_id}>
                <div className="msq-tag-list">
                  <span className="msq-tag">
                    <b>{platformLabelMap[p.platform]}</b> - {p.platform_campaign_id} - {p.name} - {p.product}
                  </span>
                  <span className="msq-tag">
                    <b>Period:</b> {moment(p.start_date).format('DD MMM YYYY')} - {moment(p.end_date).format('DD MMM YYYY')}
                  </span>
                  <span className="msq-tag">
                    <b>Net:</b> {moneyFormatter(p.net, p.currency)} {p.currency}
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
      </DropdownItem>
    )
  }

  const renderDialogContents = () => {
    return (
      <MSQListWrapper>
        {type === "single" ? <>
          {id && tableData && tableData.length > 0 && <List>{tableData.map(d => renderMsqItem(d))}</List>}
        </>: <>
          {renderSearchBox()}
          {!id && renderTable()}
        </>}
      </MSQListWrapper>
    )
  }
  
  return renderDialogContents()
}

export default MsqTable;
