import ReactDOMServer from 'react-dom/server';

let timer, trigger;

if ('ontouchstart' in document.documentElement) {
  document.querySelector('body').style.cursor = 'pointer'
}

let tooltip = document.querySelector('.ripple-tip');
if (!tooltip) {
  tooltip = document.createElement('div');
  tooltip.setAttribute('class', 'ripple-tip dark');
  document.body.append(tooltip);
}

export default function createTooltip  (config = {}) {
  const {targetSelector, tipCreator, directionFunc, positionFunc, minWidth, maxWidth, fixedWidth, delay, extraClass, tipSensitive = true} = config;
  var cleanTimerAndRun = function (action, timeout, desc) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      tooltip.style.display = action === 'show' ? 'block' : 'none';
    }, timeout || 0);
  }
  if(tipSensitive) {
    tooltip.addEventListener('mouseover', () => {
      cleanTimerAndRun('show', delay || 0, 'mouseover tooltip');
    });
    tooltip.addEventListener('mouseout', () => {
      cleanTimerAndRun('hide', 100, 'mouseleave tooltip')
    });
  }

  const show = function (d) {
    trigger = this;
    tooltip.classList.add('dark');
    tooltip.classList.remove('bright');
    if(!!extraClass) {
      tooltip.classList.add(extraClass);
    }
    let direction = directionFunc || 'top';
    if (typeof directionFunc === 'function') {
      direction = directionFunc(d);
    }
    let tipContent = tipCreator;
    if (typeof tipCreator === 'function') {
      tipContent = tipCreator(d);
    }
    if(!tipContent) {
      return;
    }
    if (typeof tipContent === 'string') {
      tipContent = {body: tipContent};
    }
    if (minWidth) {
      tooltip.style.minWidth = minWidth;
    } else {
      tooltip.style.minWidth = 'auto';
    }
    if (maxWidth) {
      tooltip.style.maxWidth = maxWidth;
    } else {
      tooltip.style.maxWidth = 'auto';
    }
    if (fixedWidth) {
      tooltip.style.minWidth = 'auto';
      tooltip.style.maxWidth = 'auto';
      tooltip.style.width = fixedWidth;
    }
    tooltip.innerHTML = `
      <div class="tip-inner">
        ${tipContent.title ? `<div class="tip-header">${tipContent.title}</div>` : ''}
        <div class="tip-body">${tipContent.body}</div>
      </div>
      <svg class="tip-arrow" width="14" height="14" viewBox="0 0 10 10"><rect x="1.5" y="1.5" transform="matrix(0.7071 0.7071 -0.7071 0.7071 5 -2.0711)" width="7.1" height="7.1"></rect></svg>
    `;

    if (typeof positionFunc === 'function') {
      let position = positionFunc(d);
      tooltip.style.left = position[0] + "px";
      tooltip.style.top = position[1] + "px";
    } else {
      let rect = this.getBoundingClientRect();
      let rX = window.pageXOffset + rect.left;
      let rY = window.pageYOffset + rect.top;
      if (direction === 'rightstart') {
        tooltip.style.left = (rX + rect.width) + "px";
        tooltip.style.top = rY + "px";
      }
      if (direction === 'lefttop') {
        tooltip.style.left = (rX - rect.width / 2) + "px";
        tooltip.style.top = rY + "px";
      }
      if (direction === 'left') {
        tooltip.style.left = rX + "px";
        tooltip.style.top = (rY + rect.height / 2) + "px";
      } else if (direction === 'right') {
        tooltip.style.left = (rX + rect.width) + "px";
        tooltip.style.top = (rY + rect.height / 2) + "px";
      } else if (direction === 'top') {
        tooltip.style.left = (rX + rect.width / 2) + "px";
        tooltip.style.top = rY + "px";
      } else if (direction === 'bottom') {
        tooltip.style.left = (rX + rect.width / 2) + "px";
        tooltip.style.top = (rY + rect.height) + "px";
      }
    }
    tooltip.classList.remove('left');
    tooltip.classList.remove('right');
    tooltip.classList.remove('top');
    tooltip.classList.remove('bottom');
    if(!tipContent.title) {
      tooltip.classList.add('no-title');
    } else {
      tooltip.classList.remove('no-title');
    }
    tooltip.classList.add(direction);
    cleanTimerAndRun('show', 0, 'mouseon trigger');
  };
  const hide = function () {
    cleanTimerAndRun('hide', 200, 'mouseleave trigger');
  }
  if (targetSelector !== 'dynamic' && targetSelector !== 'dynamic_responsive') {
    document.querySelector(targetSelector).addEventListener('mouseover', show);
    document.querySelector(targetSelector).addEventListener('mouseout', hide);
  }
  if (targetSelector === 'dynamic_responsive') {
    tooltip.classList.add('fit-for-mobile')
  } else {
    tooltip.classList.remove('fit-for-mobile')
  }

  // document.querySelector('body').addEventListener('touchstart', (e) => {
  document.querySelector('body').addEventListener('click', (e) => {
    let target = e.currentTarget;
    setTimeout(() => {
      if (trigger && !trigger.contains(target) && !tooltip.contains(target)) {
        cleanTimerAndRun('hide', 0, 'body click');
      }
    }, 50);
  });
  return {
    show,
    hide,
    onMouseOver(d) {
      return function (e) {
        show.call(e.currentTarget || e.target, d);
      }
    },

    onMouseOut(d) {
      return function (e) {
        hide.call(e.currentTarget || e.target, d);
      }
    }
  };
}

export const defaultTooltip = createTooltip({
  targetSelector: 'dynamic',
  tipCreator: d => d.tipContent ? ReactDOMServer.renderToString(d.tipContent) : `<div>${d.txt}</div>`,
  // minWidth: '250px',
  extraClass: 'bright',
  directionFunc: 'bottom'
});
export const defaultWhiteTooltip = createTooltip({
  targetSelector: 'dynamic',
  tipCreator: d => d.tipContent ? ReactDOMServer.renderToString(d.tipContent) : `<div>${d.txt}</div>`,
  extraClass: 'bright',
  directionFunc: 'left'
});
export const defaultWhiteTooltipTop = createTooltip({
  targetSelector: 'dynamic',
  tipCreator: d => d.tipContent ? ReactDOMServer.renderToString(d.tipContent) : `<div>${d.txt}</div>`,
  extraClass: 'bright',
  directionFunc: 'top'
});
export const defaultWhiteTooltipRight = createTooltip({
  targetSelector: 'dynamic',
  tipCreator: d => d.tipContent ? ReactDOMServer.renderToString(d.tipContent) : `<div>${d.txt}</div>`,
  extraClass: 'bright',
  directionFunc: 'right'
});

export const defaultWhiteTooltipLeft = createTooltip({
  targetSelector: 'dynamic',
  tipCreator: d => d.tipContent ? ReactDOMServer.renderToString(d.tipContent) : `<div>${d.txt}</div>`,
  extraClass: 'bright',
  minWidth: '250px',
  directionFunc: 'left'
});