import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans, BlueJeans1,
  Dark,
  DarkGray, DarkGray1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1, Grass1, BitterSweet1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const VirtualMsqListWrapper = styled.div`
  padding: 20px;
  .control-bar {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      flex-grow: 1;
      margin-right: 30px;
      padding: 4px 10px;
      border: none;
      border-bottom: 1px solid ${LightGray1};
      outline: none;
    }
  }
  table.virtual-link-table {
    margin-top: 20px;
    thead tr th {
      background: ${LightGray};
      border-bottom: 1px solid ${LightGray1};
    }
    .name-link {
      text-decoration: underline;
      cursor: pointer;
    }
    .actions {
      a {
        cursor: pointer;
        margin: 0 10px;
        opacity: .9;
        font-weight: normal;
        i {
          font-size: 18px;
          cursor: pointer;
        }
        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
        &.del {
          color: ${BitterSweet1};
        }
      }
    }
  }
`
export const EditingLinkForm = styled.div`
  min-width: 600px;
  .field-wrapper {
    .error-msg {
      color: ${BitterSweet1};
      margin-left: 170px;
      margin-top: -10px;
      font-size: 12px;
    }
  }
  .field {
    display: flex;
    align-items: center;
    margin: 10px;
    > label {
      display: inline-block;
      min-width: 150px;
      margin-right: 10px;
    }
    >input {
      width: 100%;
      border: 1px solid ${MediumGray};
      padding: 4px 10px;
      border-radius: 5px;
    }
    .react-datepicker-wrapper {
      flex-grow: 1;
      .react-datepicker__aria-live {
        display: none;
      }
      input {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid ${MediumGray};
        padding: 4px 10px;
        border-radius: 5px;
      }
    }
    &.error input{
      border-color: ${BitterSweet1};
    }
    &.error .select-box .toggler{
      border-color: ${BitterSweet1};
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`
