import React from 'react';
import ReactServer from 'react-dom/server';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {
  Aladin_Orange_Lighter,
  BitterSweet,
  BlueJeans,
  DarkGray,
  LightGray1,
  MediumGray,
  MediumGray1
} from "../app/StyleCommon";
import {
  intFormatter,
  moneyFormatter, moneyFormatterGenerator,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import {
  campaignStatusMap,
  campaignTypeMap,
  CampaignTypeOptions,
  inflightStatusMap, metricMap, metricMetaData,
  platformLabelMap, releaseStatusMap
} from "../utils/metadata";
import {HealthStatus, InflightStatus, Pacing} from "./index.style";
import createTooltip, {defaultTooltip, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import {ProgressBar} from "../uikit";
import styled from "styled-components";

let BrandWrapper = styled.div`
      >div {
        border-bottom: 1px dashed ${MediumGray};
        margin: 5px 0;
        //white-space: nowrap;
        //max-width: 100px;
        //text-overflow: ellipsis;
        //overflow: hidden;
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0;
        }
        &:nth-child(even) {
          //background-color: white;
        }
      }
  `

export const columnTooltip = defaultWhiteTooltip;
export const supportedColumns = [
  {
    hideDefault: true,
    key: 'index',
    label: 'No.',
    align: 'center',
    sortable: true,
    style: {paddingTop: '12px', maxWidth: '20px'},
    renderer: (d, i, context) => ((context.page - 1) * context.PAGE_SIZE) + i + 1
  },
  {
    key: 'id',
    label: 'ID',
    align: 'left',
    sortable: true,
    style: {paddingTop: '12px', maxWidth: '200px'},
    filterOps: ['$match', '$nmatch'],
    renderer: (d, i) => d.id.startsWith('AC') ? d.id : ('AC' + d.id)
  },
  {
    key: 'msq_id',
    label: 'MSQ_ID',
    hideDefault: true,
    align: 'left',
    sortable: true,
    style: {paddingTop: '12px', maxWidth: '200px'},
    filterOps: ['$match', '$nmatch'],
    renderer: (d, i) => {
      let additionalMsqs = (d.id_links || []).filter(idl => idl.key === 'overall').map(idl => idl.value)
      return _.uniq(_.compact([d.msq_id, ...additionalMsqs])).join(', ');
    }
  },
  {
    key: 'platform_campaign_ids',
    label: 'Platform IDs',
    hideDefault: true,
    align: 'left',
    sortable: false,
    style: {paddingTop: '12px', maxWidth: '200px'},
    filterOps: ['$match', '$nmatch'],
    renderer: (d, i) => {
      return (
        <div>
          {
            Object.keys(d.platform_campaign_ids || {}).map(k => {
              if(!d.platform_campaign_ids[k] || !d.platform_campaign_ids[k].length) {
                return null;
              }
              return <div>{platformLabelMap[k] + ': ' + (d.platform_campaign_ids[k] || []).filter(id => id !== 'undefined').join(', ')}</div>
            })
          }
        </div>
      )
    }
  },
  {
    key: 'name',
    label: 'Campaign Name',
    align: 'left',
    sortable: true,
    style: {paddingTop: '12px', width: '300px',wordBreak: 'break-word'},
    filterOps: ['$match', '$nmatch'],
    renderer: (d, i, context) => {
      const {session} = context;
      let canViewCampaign = session.rights.indexOf('View Campaign Dashboard') >= 0;
      if(!canViewCampaign) {
        return d.name;
      }
      return (
        <div>
          <Link to={`/campaign/details?id=${d.id}`}>
            {d.name}
          </Link>
          {d.release_status === 'DRAFT' && <span className="desc tag">Draft</span>}
        </div>
      )
    }
  },
  {
    key: 'type',
    label: 'Type',
    align: 'left',
    sortable: true,
    style: {paddingTop: '12px', width: '300px',wordBreak: 'break-word'},
    filterOps: ['$in', '$nin'],
    options2: CampaignTypeOptions,
    renderer: d => {
      return <span style={{wordBreak: 'normal'}}>{campaignTypeMap[d.type] || d.type}</span>;
    }
  },
  {
    key: 'platform_keys',
    sortable: true,
    label: 'Platform',
    align: 'left',
    filterOps: ['$contain', '$ncontain'],
    options: ['digital', 'tv', 'radio', 'social'],
    renderer: (d, index) => {
      return <div>
        {(d.platform_keys || []).map(k => platformLabelMap[k] || k).join(', ')}
      </div>
    }
  },
  {
    key: 'industry',
    sortable: true,
    hideDefault: true,
    label: 'Industry',
    align: 'left',
    filterOps: ['$in', '$nin'],
    renderer: d => {
      return d.industry
    }
  },
  {
    key: 'advertiser',
    sortable: true,
    hideDefault: true,
    label: 'Advertiser',
    align: 'left',
    filterOps: ['$in', '$nin'],
    renderer: d => {
      return d.advertiser
    }
  },
  {
    key: 'brand',
    hideDefault: true,
    sortable: true,
    label: 'Brand',
    align: 'left',
    filterOps: ['$in', '$nin'],
    renderer: d => {
      return (
        <BrandWrapper>
          {_.uniq((d.brand || '').split(',')).map(b =>
            <div key={Date.now() + b}>
              {b}
            </div>
          )}
        </BrandWrapper>
      )
    }
  },
  {
    key: 'start_date',
    sortable: true,
    label: 'Start Date',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    datatype: 'datetime',
    renderer: d => {
      return moment(d.start_date).format('MMM DD YYYY')
    }
  },
  {
    key: 'end_date',
    sortable: true,
    label: 'End Date',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    datatype: 'datetime',
    renderer: d => {
      return moment(d.end_date).format('MMM DD YYYY')
    }
  },
  {
    key: 'budget',
    sortable: true,
    hideDefault: false,
    label: 'Budget',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    renderer: d => {
      return (
        <div>
          {moneyFormatterGenerator(d.currency)(d.budget)}
        </div>
      )
    }
  },
  {
    key: 'create_time',
    sortable: true,
    hideDefault: false,
    label: 'Created Time',
    align: 'left',
    filterOps: ['$lt', '$gt'],
    datatype: 'datetime',
    renderer: d => {
      return (
        <div>
          <div>{moment(d.create_time).format('YYYY-MM-DD HH:mm')}</div>
          <div className="desc">{moment(d.create_time).fromNow()}</div>
        </div>
      )
    }
  },
  {
    key: 'update_time',
    sortable: true,
    hideDefault: true,
    label: 'Updated Time',
    align: 'left',
    filterOps: ['$lt', '$gt'],
    datatype: 'datetime',
    renderer: d => {
      return moment(d.update_time).format('MMM DD YYYY hh:mm A')
    }
  },
  {
    key: 'creator',
    sortable: true,
    hideDefault: true,
    label: 'Creator',
    align: 'left',
    filterOps: ['$match', '$nmatch'],
    renderer: (d, index, context) => {
      let users = context.users;
      let getUser = users.find(y => y.id === d.creator) ? users.find(y => y.id === d.creator).name : '';
      let tooltip = createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => d.creator,
        directionFunc: 'top'
      });
      return <div
        onMouseOver={tooltip.onMouseOver(d)}
        onMouseLeave={tooltip.onMouseOut()}>
          {(getUser || '')}
        </div>
    }
  },
  {
    key: 'editors',
    sortable: true,
    hideDefault: true,
    label: 'Planners',
    align: 'left',
    filterOps: ['$match', '$nmatch'],
    renderer: (d, index, context) => {
      let users = context.users;
      let getUser = d.editors && d.editors.map(x => x ? users.find(y => y.id === x) ? users.find(y => y.id === x).name : '' : '');
      let getUserId = x => users.find(y => y.name == x);
      let tooltip = createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => getUserId(d).id,
        directionFunc: 'top'
      });

      return <div>
          {getUser && getUser.length > 0 && getUser?.map(x => {
            return <div onMouseOver={tooltip.onMouseOver(x)}
            onMouseLeave={tooltip.onMouseOut()}>
                {x} {getUser.length > 1 && getUser.indexOf(x) !== getUser.length - 1 ? ',' : ''}
            </div>
          })}
        {/* {(getUser || []).join(', ')} */}
      </div>
    }
  },
  {
    key: 'viewers',
    sortable: true,
    hideDefault: true,
    label: 'Viewers',
    align: 'left',
    filterOps: ['$match', '$nmatch'],
    renderer: (d, index, context) => {
      let users = context.users;
      let getUser = d.viewers && d.viewers.map(x => x ? users.find(y => y.id === x) ? users.find(y => y.id === x).name : '' : '');
      let getUserId = x => users.find(y => y.name == x);
      let tooltip = createTooltip({
        targetSelector: 'dynamic',
        tipCreator: d => getUserId(d).id,
        directionFunc: 'top'
      });
      return <div>
        {getUser && getUser.length > 0 && getUser?.map(x => {
            return <div onMouseOver={tooltip.onMouseOver(x)}
            onMouseLeave={tooltip.onMouseOut()}>
                {x} {getUser.length > 1 && getUser.indexOf(x) !== getUser.length - 1 ? ',' : ''}
            </div>
          })}
        {/* {(getUser || []).join(', ')} */}
      </div>
    }
  },
  {
    key: 'metrics_overall.impressions',
    sortable: true,
    hideDefault: true,
    label: 'Impressions',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    renderer: d => {
      return intFormatter((d.metrics_overall ||{}).impressions);
    }
  },
  {
    key: 'metrics_overall.cpm',
    sortable: true,
    hideDefault: true,
    label: 'CPM',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    renderer: d => {
      return moneyFormatter((d.metrics_overall ||{}).cpm, d.currency);
    }
  },
  {
    key: 'metrics_overall.reach',
    sortable: true,
    hideDefault: true,
    label: 'Reach',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    renderer: d => {
      return intFormatter((d.metrics_overall ||{}).reach);
    }
  },
  {
    key: 'metrics_overall.cpv',
    sortable: true,
    hideDefault: true,
    label: 'Cost Per Reach',
    align: 'center',
    filterOps: ['$lt', '$gt'],
    renderer: d => {
      return moneyFormatter((d.metrics_overall ||{}).cpv, d.currency);
    }
  },
  {
    key: 'inflight_status',
    sortable: true,
    label: 'Flight Status',
    align: 'center',
    filterOps: ['$in', '$nin'],
    options: ['READY', 'DELIVERING', 'DONE'],
    renderer: d => {
      return (
        <InflightStatus className={d.inflight_status}
          // onMouseOver={tooltipProgess.onMouseOver(d)}
          // onMouseLeave={tooltipProgess.onMouseOut()}
        >
          {inflightStatusMap[d.inflight_status]}</InflightStatus>
      )
    }
  },
  {
    key: 'release_status',
    sortable: true,
    label: 'Publish Status',
    hideDefault: true,
    align: 'center',
    filterOps: ['$in', '$nin'],
    options: ['DRAFT', 'RELEASED'],
    renderer: d => {
      return releaseStatusMap[d.release_status] || d.release_status;
    }
  },
  {
    key: 'health_status',
    sortable: true,
    label: 'Health Status',
    align: 'center',
    filterOps: ['$in', '$nin'],
    options: ['ON_TRACK', 'OFF_TRACK'],
    renderer: (d, i) => {
      if(!d.health_status || d.health_status === '-' || !d.health_status_details  || !d.health_status_details.length) {
        return '--';
      }
      d.health_status = d.health_status;
      d.health_status_details = d.health_status_details || [];
      let health = d.health_status === 'ON_TRACK' ? 'green' : 'red';
      d.txt = `<div style="font-size: 12px; min-width: 250px">
        <h3>${d.health_status}</h3>
        ${d.health_status_details.map(item => {
          let meta = metricMetaData.find(m => m.key === item.metric) || {key: item.metric, label: item.metric, formatter: v => v};
          return `<p>${platformLabelMap[item.platform]} KPI:  ${meta.formatter(item.total)} ${meta.label},
                  ${campaignStatusMap[item.status]}.
                  <strong style="color: ${item.status === 'ON_TRACK' ? '#4f8f98' : '#ec685c'}">
                    <br/>
                    expected: ${meta.formatter(item.target)},
                    actual: ${meta.formatter(item.actual)}
                    (
                      ${pctFormatter(Math.abs(item.actual  - item.target) / item.target)}
                      ${item.actual  > item.target ? 'over-deliver' : 'under-deliver'}
                    )
                  </strong>
                  </p>`
        }).join('')}
    </div>`
      return (
        <HealthStatus
          className={health}
          onMouseOver={defaultWhiteTooltip.onMouseOver(d)}
          onMouseLeave={defaultWhiteTooltip.onMouseOut()}>
          {campaignStatusMap[d.health_status]}
        </HealthStatus>
      )
    }
  },
  // {
  //   key: 'sales_person',
  //   sortable: true,
  //   hideDefault: true,
  //   label: 'Sales Person',
  //   align: 'left',
  //   filterOps: ['$in', '$nin'],
  //   renderer: d => {
  //     return d.sales_person || '--';
  //   }
  // },
]
