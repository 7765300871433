import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import classnames from 'classnames';
import moment from 'moment';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Dark1, Aladin_Blue_Light,
  Aladin_Orange_Dark,
  Aladin_BG_1, Aladin_Purple_Light,
  BlueJeans1,
  DarkGray,
  Grass1,
  MediumGray,
  MediumGray1
} from "../app/StyleCommon";
import _ from "lodash";
import {getContextChartScale} from "./util";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      unit: 'daily'
    }
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    const {unit} = this.state;
    return (
      <ChartWrapper className="chart">
        <div className="chart-header">
          <div className="chart-legent">
          </div>
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data, cfg, context, currency} = this.props;
    const {campaign = {}} = context || {};
    let {metric, metric2, type : visType = 'bar', unit, type1: visType1 = 'line', yAxisStyle} = cfg;
    let universe = campaign.universe || 4552.9 * 1000;
    const container = this.containerRef.current;
    let metricMeta = context.mixedMetaData.find(d => d.key === metric) || {key: metric, label: metric.replace('client_', '')};
    let metricMeta2 = context.mixedMetaData.find(d => metric2 && d.key === metric2) || {key: metric, label: metric.replace('client_', '')};
    let metricMap = context.mixedLabelMap;
    const {formatter} = metricMeta;
    data = _.cloneDeep(data);
    data = _.sortBy(data, d => d.date || d.key);
    if(cfg.cumulative && metricMeta.accumulatable) {
      for(let i = 1; i< data.length ; i++) {
        data[i][metric] = (data[i-1][metric] || 0) + (data[i][metric] || 0);
        if(metric2 && metricMeta2.accumulatable) {
          data[i][metric2] = (data[i-1][metric2] || 0) + (data[i][metric2] || 0);
        }
      }
    }
    // else if (metric === 'reach' || metric === 'reach_pct') {
    //   for(let i = 1; i< data.length ; i++) {
    //     data[i][metric] = Math.max(data[i-1][metric], data[i][metric]);
    //   }
    // }
    if(metric.startsWith('reach') || (cfg.cumulative && metricMeta.accumulatable)) {
      for(let i = data.length - 1; i> 0; i--) {
        if(data[i][metric] === data[i-1][metric]) {
          data[i][metric] = undefined
        }
      }
    }

    const maxTargetValue = _.max(data.map(d => d[`target_${metric}`] || 0));
    const height = 250;
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    let tooltipIndex = -1;
    const colors = ['#4f8f98', '#FEAE65'];
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value) => {
          if(metricMeta2) {
            return (tooltipIndex++ % 2) ? getMetricFormatter(metricMeta)(value) : getMetricFormatter(metricMeta2)(value);
          } else {
            return getMetricFormatter(metricMeta)(value);
          }
        },
      },
      grid: {
        bottom: '40',
        top: '40',
        left: '60px',
        right: '60px'
      },
      legend: {
        left: 10,
        top: 0,
        show: (maxTargetValue || metricMeta2) ? true : false,
        itemWidth: 16,
        itemHeight: 10,
      },
      xAxis: {
        type: 'category',
        data: data.map(d => (d.key || d.date)),
        axisLine: {
          onZero: false,
          lineStyle: {
            fontWeight: 'bold'
          }
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          formatter: value => {
            if(!value) return '';
            if(unit === 'daily') {
              return moment(value).format('DD, MMM');
            } else {
              return value;
            }
          }
        }
      },
      yAxis: _.compact([
        {
          type: 'value',
          position: 'left',
          splitNumber: 2,
          // name: metricMap[metric],
          nameLocation: 'start',
          nameGap: 20,
          alignTicks: true,
          nameTextStyle: {
          },
          axisTick: {
            inside: false, length: 5
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            inside: true,
            margin: -40,
            fontWeight: 'bold',
            color: colors[0],
            formatter: value => {
              if(!value) return '';
              return (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(value);
            }
          }
        },
        ((metric2 && yAxisStyle !== 'combine') ? {
          type: 'value',
          position: 'right',
          splitNumber: 2,
          // name: metricMap[metric2],
          nameLocation: 'start',
          alignTicks: true,
          nameGap: 20,
          nameTextStyle: {
          },
          axisTick: {
            inside: false, length: 5
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            inside: true,
            margin: -50,
            fontWeight: 'bold',
            color: colors[1],
            formatter: value => {
              if(!value) return '';
              return getMetricFormatter(metricMeta2, currency)(value);
            }
          }
        } : undefined),
      ]),
      series: [
        {
          name: metricMap[metric],
          type: visType || 'bar',
          emphasis: {
            focus: 'series'
          },
          data: data.map(d => d[metric]),
          barMaxWidth: 200,
          barWidth: (metric2 && metric2 !== metric) ? '30%' : '70%',
          label: {
            show: (data.length >= 10) ? false : true,
            position: 'top',
            formatter: (d,i) => {
              return (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(d.value)
            }
          },
          itemStyle: {
            color: '#6eb3c4'
            // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //   { offset: 0, color: '#a0d3da' },
            //   { offset: 1, color: '#6eb3c4' }
            // ])
          },
          areaStyle: {opacity: 0.08},
        },
        ((metric2 && metric2 !== metric) ? {
          name: metricMap[metric2],
          type: visType1 || 'line',
          yAxisIndex: yAxisStyle === 'combine' ? 0 : 1,
          barMaxWidth: 200,
          barWidth: (metric2 && metric2 !== metric) ? '30%' : '70%',
          lineStyle: {
            color: Aladin_Orange_Dark
          },
          itemStyle: {
            color: Aladin_Orange_Dark
          },
          emphasis: {
            focus: 'series'
          },
          data: data.map(d => d[metric2]),
          label: {
            formatter: d => (metricMeta2.formatter2 || getMetricFormatter(metricMeta2, currency))(d[metric2])
          },
        } : undefined),
        // ((maxTargetValue && !metric2) ? {
        //   name: `Target ${metricMap[metric]}`,
        //   type: 'line',
        //   data: data.map(d => d[`target_${metric}`]),
        //   symbolSize: 6,
        //   // symbol: 'diamond',
        //   symbol: 'none',
        //   lineStyle: {
        //     opacity: .6,
        //   },
        //   itemStyle: {
        //     color: '#F29727', //Aladin_Orange_Dark
        //   },
        //   label: {
        //     // show: cfg.unit === 'daily' ? false : true,
        //     show: data.length < 6 ? true : false,
        //     position: 'right',
        //     formatter: d => (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(d.value)
        //   },
        // }: undefined)
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
  .chart-actions {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 1;
    .toggler {
      border: none;
      background: ${Aladin_BG_1};
      padding: 2px 40px 2px 10px;
      width: auto;
    }
  }
`;

export const UnitItem = styled.span`
  padding: 0 5px;
  cursor: pointer;
  color: ${DarkGray};
  &:after {
    content: '';
    display: inline-block;
    height: 10px;
    width: 2px;
    margin-left: 8px;
    background: ${MediumGray};
  }
  &:last-child:after {
    display: none;
  }
  &.selected {
    color: ${BlueJeans1};
    font-weight: bold;
  }
`;
