import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {Provider, withConsumer} from './ApplicationContext';
import PrivateRoute from './PrivateRoute';
import Login from '../user/login';
import Dashboard from '../dashboard/index';
import Campaigns from '../campaigns/index';
import CampaignDetails from '../campaigns/details';
import ExtraSettings from '../campaigns/extraSettings';
import CampaignEditor from '../campaigns/build';
import ClientData from '../campaigns/clientData';
import CampaignHistory from '../campaigns/history';
import Reporting from '../reporting/index';
import ReportBuilder from '../reporting/builder';
import ReportInteractiveView from '../reporting/interactive';
import ReportReadonlyView from '../reporting/viewer';
import VirtualMsqList from '../campaigns/virtualMsqList';

import Profile from '../user/profile';
import UserEditor from '../settings/user_edit';
import Settings from '../settings/index';
import Login2FA from '../user/verify2fa';
import ForgotPw from '../user/forgot_pw';
import ResetPw from '../user/reset_pw';

import {GlobalStyle} from './App.style';

export default class extends React.Component {

  render() {
    return (
      <Provider>
        <GlobalStyle/>
        <Router>
          <Switch>
            <Route path="/login" component={Login}/>
            <PrivateRoute path="/login_2fa" component={Login2FA}/>
            <Route path="/forget_password" exact component={ForgotPw}/>
            <Route path="/reset_password" exact component={ResetPw}/>

            <PrivateRoute path="/" exact component={withConsumer((props) => {
              const {session} = props.appState;
              let canViewDashboard = session.rights.indexOf('View Home Dashboard') >= 0;
              let canViewCampaignList = session.rights.indexOf('View Campaign List Page') >= 0;
              if(!canViewDashboard) {
                if(canViewCampaignList) {
                  return <Campaigns/>
                } else {
                  return <Profile/>
                }
              } else {
                return <Dashboard/>
              }

            })}/>
            {/*<Route path="/" exact component={Campaigns}/>*/}
            <PrivateRoute path="/campaigns" exact component={Campaigns}/>
            <PrivateRoute path="/campaigns/:id" exact component={Campaigns}/>
            <PrivateRoute path="/campaign/details" exact component={CampaignDetails}/>
            <PrivateRoute path="/campaign/builder" exact component={CampaignEditor}/>
            <PrivateRoute path="/campaign/setup" exact component={CampaignEditor}/>
            <PrivateRoute path="/campaign/settings" exact component={CampaignEditor}/>
            <PrivateRoute path="/campaign/history" exact component={CampaignHistory}/>
            <PrivateRoute path="/campaign/ext-settings" exact component={ExtraSettings}/>
            <PrivateRoute path="/campaign/client-data" exact component={ClientData}/>
            <PrivateRoute path="/virtual-links" exact component={VirtualMsqList}/>
            <PrivateRoute path="/reporting" exact component={Reporting}/>
            <PrivateRoute path="/report/builder" exact component={ReportBuilder}/>
            <PrivateRoute path="/report/share" exact component={ReportInteractiveView}/>
            <PrivateRoute path="/report/readonly" exact component={ReportReadonlyView}/>
            <Route path="/report/view" exact component={ReportInteractiveView}/>
            <PrivateRoute path="/profile" exact component={Profile}/>

            <PrivateRoute path="/admin" exact component={Settings}/>
            <PrivateRoute path="/admin/users" exact component={Settings}/>
            <PrivateRoute path="/admin/audit" exact component={Settings}/>
            <PrivateRoute path="/admin/roles" exact component={Settings}/>
            <PrivateRoute path="/admin/user/create" exact component={UserEditor}/>
            <PrivateRoute path="/admin/user/settings" exact component={UserEditor}/>
            <PrivateRoute path="*" render={() => (<Redirect to="/"/>)}/>
          </Switch>
        </Router>
      </Provider>
    );
  }
}
