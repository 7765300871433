import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import Dropzone from 'react-dropzone'
import {PageWrapper, DropzoneWrapper} from './extraSettings.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, TabContainer, Loader, ProgressBar} from "../uikit";
import qs from "qs";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import Table from "../uikit/table";
import {dimensionLabelMap, metricMap, metricMetaData} from "../utils/metadata";
import {getMetricFormatter} from "../utils/formatter";
import {TableWrapper} from "../charts/index.style";
import {BlueJeans} from "../app/StyleCommon";
import {post} from "../utils/request";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      detailMap: {}
    }
  }

  buildQuery() {
    const urlParams = qs.parse(window.location.search.slice(1));
    return {
      id: urlParams.id,
    };
  }


  render() {
    const {data, error, loading, file} = this.state;
    let {modelResult, campaign} = data || {};
    return (
      <MainLayout activeItem="campaign" breadcrumb={[{path: '/campaigns', label: 'Campaigns'}, {path: '/campaign/ext-settings', label: 'Blend CPV settings'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/campaignExtSettings`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({data: data});
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        <PageWrapper>
          <div>
            <h3>
              <span>Blend CPV settings</span>
            </h3>
            <p>The setting page is for Blended CPV campaign admin to update campaign modeled target data, tv/digital reach factor parameters.</p>
            {
              !!error && <ErrorMessage message={error}/>
            }
            {
              !!data && !modelResult &&
              <div>
                <h4>You did not upload factor and daily target reach data yet, please follow the following Excel format to upload.</h4>
                <p>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{fontSize: '20px', verticalAlign: 'center'}} className="material-symbols-outlined">download</span> &nbsp;
                    <a href="/doc/blend_reach_input.xlsx" target="_blank">
                      Example File
                    </a></div>
                  <img src="/img/assets/blended_cpv_excel_example.png" style={{maxWidth: '500px'}}/>
                </p>

              </div>
            }
            {
              !!data && !!modelResult &&
              <div>
                <div><strong>Factor:</strong> {modelResult.factor}</div>
                <div><strong>Target Reach:</strong></div>
                <TableWrapper className="data-table">
                  <Table
                    columns={[
                      {
                        key: 'index',
                        label: '',
                        align: 'center',
                        sortable: true,
                        style: {maxWidth: '20px'},
                        renderer: (d, i) => (i + 1)
                      },
                      {
                        key: 'date',
                        label: 'Date',
                        align: 'left',
                        sortable: true,
                      },
                      {
                        key: 'overall',
                        label: 'Overall',
                        align: 'left',
                        sortable: true,
                      },
                      {
                        key: 'tv',
                        label: 'TV',
                        align: 'left',
                        sortable: true,
                      },
                      {
                        key: 'digital',
                        label: 'Digital',
                        align: 'left',
                        sortable: true,
                      },
                    ]}
                    rows={modelResult.targets || []}
                    noDataText={'No target reach data found'}
                  />
                </TableWrapper>
              </div>
            }
          </div>
          <div>
            <h4>Upload Blended CPV factor and reach targets:</h4>
            <p>Please select an Excel file that matching to the left side format to upload.  Note that the first row to put the blended cpv factor and the daily reach targets starting from second rows</p>
            <DropzoneWrapper className="dropzone-wrapper">
              <Dropzone
                onDrop={files => this.setState({
                  file: files[0]
                })}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                {
                  !file &&
                  <div>
                    <p className="desktop-upload">
                      Drag campaign settings here to upload. <br/>
                      or <a style={{color: BlueJeans, textDecoration: 'underline'}}>browse</a>
                    </p>
                  </div>
                }
                {
                  !!file &&
                  <div className="droped-file">
                    <div>
                      <i className="fa fa-file"></i>
                      <span>{file.name}</span>
                    </div>
                  </div>
                }
              </Dropzone>
            </DropzoneWrapper>
            <div className="actions">
              <Btn type="primary" onClick={e => this.uploadFile()} disabled={!file}>
                <i style={{fontSize: '18px', verticalAlign: 'middle'}} className="material-symbols-outlined">upload</i>
                Upload
              </Btn>
            </div>
          </div>
        </PageWrapper>

      </MainLayout>
    )
  }

  uploadFile() {
    const {file, data} = this.state;
    let {modelResult, campaign} = data || {};
    this.setState({loading: true});
    const formData = new FormData();
    formData.append('id', campaign.id);
    formData.append('file', file);
    return post(`/api/uploadBlendedCPVSettings`, formData,{
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(resp => {
      this.setState({loading: false, data: resp});
    }).catch(e => {
      this.setState({error: e, loading: false});
    })
  }
})