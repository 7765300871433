import { ExecuteFunction, ExecuteOperator } from "./Functions";
import { CreateNodesFromTokens } from "./NodeGenerator";

export function EvaluateTokens(tokens, input) {
  if(tokens && tokens.length > 0) {
    const nodes = CreateNodesFromTokens(tokens);
    let result = "";
    for (const node of nodes) {
      result += EvaluateNode(node, input);
    }
    return result;
  }
}

function EvaluateNode(node, input) {
  if (!node) {
    return "";
  }
  if (node.type === "operator") {
    const parameters = node.innerNodes.map((x) => EvaluateNode(x, input));
    return ExecuteOperator(node.value, parameters);
  } else if (node.type === "function") {
    if(node && node.innerNodes) {
      const parameters = node.innerNodes.map((x) => EvaluateNode(x, input));
      return ExecuteFunction(node.value, parameters);
    }
  } else if (node.type === "reference") {
    return input[node.value] || "";
  } else if (node.type === "string") {
    return node.value;
  } else if (node.type === "number") {
    return node.value;
  }
  return "";
}

export function GetReferencesFromTokens(tokens) {
  if(tokens && tokens.length>0) {
    return tokens
      .filter((x) => x.type === "reference-name")
      .map((x) => x.value)
      .filter((v, i, a) => a.indexOf(v) === i); // remove duplicates
  }
}

export function GetKeysRefFromTokens(references) {
  let operators = /[+\-*/]/;
  if(references && references.length > 0 && references !== "") {
    let keys = references[0].split(operators);
    return keys;
  }
}
