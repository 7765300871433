import React from 'react'
import {ErrorBoxWrapper} from './index.style';

export default class extends React.Component {
  render() {
    return (
      <ErrorBoxWrapper {...this.props}>
        <i className="material-symbols-outlined">error</i>
        <span>{this.props.message}</span>
      </ErrorBoxWrapper>
    );
  }
}