import React from 'react';
import {LoaderWrapper, Spinner} from "./index.style";
import classNames from "classnames";

//https://loading.io/css/

export default (props) => (
  <LoaderWrapper className="loader" {...props}>
    {/*<Spinner type={props.size}/>*/}
    {/*<div className={classNames('lds-roller', props.size)}>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*  <div></div>*/}
    {/*</div>*/}
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </LoaderWrapper>
);
