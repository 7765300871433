import React, {useState, useEffect, useDeferredValue, useRef, useCallback, Suspense} from 'react';
import _ from 'lodash';
import {VirtualMsqListWrapper, EditingLinkForm} from './virtualMsqList.style';
import {Table, Btn, ProgressBar, CondirmDialog} from '../uikit/index';
import MainLayout from '../layout/index';
import {SelectStyled} from "./select.style";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {paginationValues, platformLabelMap} from "../utils/metadata";
import {post} from "../utils/request";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {DialogWrapper} from "../uikit/dialog/index.style";
import {ListFooter} from "./index.style";
import Loader from "../uikit/Loader";
import classnames from "classnames";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import {withConsumer} from "../app/ApplicationContext";

export default withConsumer(function(props) {
  const [state, setState] = useState({keyword: '', page: 1, PAGE_SIZE: 10, sortBy: 'create_date', sortDirection: 'desc'});
  const {keyword, page, PAGE_SIZE, sortBy, sortDirection} = state;
  const [editingLink, setEditingLink] = useState(null);
  const [loading, setLoading] = useState(true); //initial loading
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const {session = {}} = props.appState || {};
  let hasRight = session.rights.indexOf('Setup/Update Campaign') >= 0;

  const deleteItemHandler = useCallback(item => {
    CondirmDialog.showConfirm('Do you want to delete this virtual link?', () => {
      post('/api/deleteVirtualLink', {virtualLink: item}).then(() => {
        CondirmDialog.showAlert('Deleted');
        setState({...state, ts: Date.now()}); //reload
      }).catch(e => {
        CondirmDialog.showAlert(`Failed, ${e.message}`);
        setError(e.message);
      })
    });
  });

  const debounceLoadData = useCallback(_.debounce((keyword, page, PAGE_SIZE, sortBy, sortDirection) => {
    setError(false);
    setLoading(true);
    post(`/api/queryVirtualLinks`, {
      keyword: keyword,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      sortBy, sortDirection
    }).then(data => {
      setData(data);
      setLoading(false);
    }).catch(e => {
      setError(e.message);
      setLoading(false);
    });
  }, 500), []);

  useEffect(() => {
    debounceLoadData(keyword, page, PAGE_SIZE, sortBy, sortDirection);
  }, [state])

  return (
    <MainLayout activeItem="campaign" loading={loading} breadcrumb={[
      {path: '/campaigns', label: 'Campaigns'},
      {path: '/campaigns/custom-msq-links', label: 'Virtual MSQ'}
    ]}>
      <VirtualMsqListWrapper>
        <h3>Virtual MSQ Registry</h3>
        {!!error && <ErrorMessage message={error}/> }
        {!hasRight && <ErrorMessage message={'Not Allowed'}/> }
        {/*{!!loading && <Loader /> }*/}
        {
          !!editingLink &&
          <ItemDetailRenderer
            editingLink={editingLink}
            metadata = {data?.virtualLinkMetadata}
            onChange={item => {
              setState({...state, ts: Date.now()}) //reload
            }}
            onClose={() => {
              setEditingLink(null);
            }}/>
        }
        <div className="control-bar">
          <input
            type="text"
            value={keyword}
            placeholder="Search Record..."
            onChange={e => {
              setState({...state, keyword: e.target.value})
          }}/>
          <Btn type="primary" onClick={e => {
            setEditingLink({});
          }}>
              Add Record
          </Btn>
        </div>
        {
          !!hasRight &&
          <Table
            className="virtual-link-table"
            columns={[
              {key: '_no', label: 'NO.', align: 'center', renderer: (d, i) => i + 1 + (page -1) * PAGE_SIZE},
              {key: 'virtual_msq_id', label: 'Virtual MSQ ID', sortable: true},
              {key: 'campaign_name', sortable: true, label: 'Campaign Name'},
              // {key: 'msq_id', label: 'Real MSQ ID', sortable: true},
              {key: 'platform', label: 'Platform', sortable: true, renderer: d => platformLabelMap[d.platform]},
              {key: 'platform_campaign_id', label: 'Platform Campaign ID', sortable: true, renderer: d => {
                  return <a className={"name-link"} onClick={e => setEditingLink(d)}>{d.platform_campaign_id}</a>
                }},
              // {key: 'advertiser', label: 'Advertiser', sortable: true},
              // {key: 'industry', label: 'Industry', sortable: true},
              {key: 'creator', label: 'Creator', sortable: true},
              {key: 'create_date', label: 'Created Date', sortable: true},
              // {key: 'update_date', label: 'Updated Date', sortable: true},
              {key: 'actions', label: 'Actions', renderer: d => {
                  return (
                    <div className="actions">
                      <a className="del" onClick={e => deleteItemHandler(d)}  title="Delete">
                        <i className="material-symbols-outlined">delete</i>
                      </a>
                      <a onClick={e => setEditingLink(d)} title="Update">
                        <i className="material-symbols-outlined">edit</i>
                      </a>
                      <a onClick={e => setEditingLink(_.cloneDeep(_.omit(d, ['id', 'platform_campaign_id', 'create_date', 'update_date'])))}  title="Clone">
                        <i className="material-symbols-outlined">content_copy</i>
                      </a>
                    </div>
                  )
                }},
            ]}
            rows={data?.rows || []}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={sortBy => {
              setState({...state, sortBy: sortBy});
            }}
            directionChange={direction => {
              setState({...state, sortDirection: direction});
            }}
          />
        }
        {
          !!data && !error && !!hasRight &&
          <ListFooter>
          <span className="info 123333">
              <strong>{data?.total}</strong> items found.
              Show
              <select
                value={PAGE_SIZE}
                onChange={e => setState({
                  ...state,
                  PAGE_SIZE: Number(e.target.value),
                  page: 1,
                })}>
              {paginationValues.map(d => {
                return (
                  <option key={d} value={d}>{d}</option>
                )
              })}
              </select>
              per page
          </span>
            <PaginationStyled
              onChange={p => setState({...state, page: p})}
              current={page}
              pageSize={PAGE_SIZE}
              total={data?.total}
              hideOnSinglePage={false}/>
          </ListFooter>
        }
      </VirtualMsqListWrapper>
    </MainLayout>

  )
});

function ItemDetailRenderer(props) {
  const {editingLink, metadata} = props;
  const {industries = [], advertisers = []} = metadata || {};
  const [link, setLink] = useState(_.cloneDeep(editingLink));
  const [active, setActive] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMap, setErrorMap] = useState({});
  const dialogRef = useRef(null)

  function handleOutsideClick(e) {
    if (dialogRef.current && !dialogRef.current.contains(e.target)) {
      setActive(false);
      props.onClose();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true); //use capture
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    }
  }, [active]);

  const validate = () => {
    const errorMap = {};
    ['platform', 'campaign_name', 'platform_campaign_id', 'virtual_msq_id', 'start_date', 'end_date', 'advertiser'].forEach(k => {
      if (!link[k]) {
        errorMap[k] = `${k} should not be empty`;
      }
    });
    return errorMap;
  }

  const createOrUpdateItemHandler = useCallback(item => {
    setLoading(true);
    post(`/api/${item.id ? 'update' : 'create'}VirtualLink`, {virtualLink: item}).then(() => {
      setLoading(false);
      CondirmDialog.showAlert('Success');
      props.onChange(link);
    }).catch(e => {
      setError(e.message);
      setLoading(false);
    })
  });

  return !active ? null : (
    <DialogWrapper>
      <div className="dialog" ref={dialogRef}>
        {<div className="close" onClick={e => props.onClose()}><i className="fa fa-times"/></div>}
        <div className="title" style={{textAlign: 'left'}}>
          <h3>{link.id ? 'Update' : 'Create'} Virtual Link</h3>
        </div>
        <div className="main-screen">
          <EditingLinkForm>
            {!!error && <ErrorMessage message={error}/>}
            {!!loading && <Loader type="absolute"/>}
            {
              [
                {
                  key: 'virtual_msq_id',
                  label: 'Virtual MSQ ID',
                  type: 'string',
                  placeholder: 'e.g. Virtual_MSQ_KFC_Campaign_2024_Sep'
                },
                {
                  key: 'msq_id', label: 'MSQ ID (Optional)', type: 'string',
                  note: 'Optional, '
                },
                {
                  key: 'platform',
                  label: 'Platform',
                  type: 'select',
                  data: ['tv', 'radio', 'digital'],
                  labelMap: platformLabelMap
                },
                {key: 'platform_campaign_id', label: 'Platform Campaign ID', type: 'string'},
                {key: 'campaign_name', label: 'Campaign Name', type: 'string'},
                {key: 'advertiser', label: 'Advertiser', type: 'select', data: advertisers},
                {key: 'industry', label: 'Industry', type: 'select', data: industries},
                {key: 'start_date', label: 'Start Date', type: 'date'},
                {key: 'end_date', label: 'End Date', type: 'date'},
              ].map(d => {
                return (
                  <div className={"field-wrapper"}>
                    <div className={classnames('field', {error: !!errorMap[d.key]})}>
                      <label>{d.label}</label>
                      {
                        d.type === 'string' &&
                        <input type="text" value={link[d.key]} placeholder={d.placeholder}
                               onChange={e => {
                                 setLink({...link, [d.key]: e.target.value})
                               }}/>
                      }
                      {
                        d.type === 'select' &&
                        <SelectStyled
                          searchable={true}
                          selected={link[d.key]}
                          data={d.data.map(k => ({key: k, label: d.labelMap ? (d.labelMap[k] || k) : k}))}
                          onChange={k => {
                            setLink({...link, [d.key]: k});
                          }}/>
                      }
                      {
                        d.type === 'date' &&
                        <DatePicker
                          selected={link[d.key] ? moment(link[d.key]).toDate() : null}
                          onChange={date => {
                            let newDate = date ? moment(date).format('YYYY-MM-DD') : null;
                            setLink({...link, [d.key]: newDate});
                          }}
                          showTimeSelect={false}
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          placeholderText={'Select date'}
                          isClearable={false}
                          dateFormat="yyyy-MM-dd"/>
                      }
                    </div>
                    {
                      !!errorMap[d.key] &&
                      <div className="error-msg">{errorMap[d.key]}</div>
                    }
                  </div>
                );
              })
            }
            <p style={{margin: '10px'}}>
              <strong>Note:</strong> the updated virtual link will take about 10 minute to be indexed by search engine. you can use it
              to setup campaign after it's indexed by indexer.
            </p>
            <div className="actions">
              <Btn type="reset" onClick={e => props.onClose()}>Cancel</Btn>
              <Btn
                type="primary"
                onClick={e => {
                  let errorMap = validate();
                  setErrorMap(errorMap);
                  if (!Object.keys(errorMap).length) {
                    createOrUpdateItemHandler(link);
                  }
                }}>
                {link.id ? 'Update' : 'OK'}
              </Btn>
            </div>
          </EditingLinkForm>
        </div>
      </div>
    </DialogWrapper>
  )
}
