import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  Aladdin_v2_blue1, Aladdin_v2_blue3, Aladdin_v2_orange1,
  BlueJeans, BlueJeans0,
  BlueJeans1,
  DarkGray,
  Grass, Grass1,
  LightBlue, LightGray,
  MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  intFormatter
} from "../utils/formatter";
import styled from "styled-components";
import Selectbox from "../uikit/selectbox";
import {dimensionLabelMap, dimensionValueMap} from "../utils/metadata";
import {SelectInlineStyled, SelectStyled} from "../uikit/selectbox/customized.style";
import DateSelectorDropdown from "../uikit/DateSelector/DateSelectorDropdown";
import {getContextChartScale} from "./util";

const ChartWrapper = styled.div`
  min-height: 100px;
  legend {
    font-size: 14px;
    font-weight: bold;
    color: ${DarkGray};
  }
  .filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 20px;
    .filter-item {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
  .select-box {
    .toggler {
      background-color: transparent;
    }
  }
  .date-selector-dropdown {
    display: inline;
    margin-left: 5px;
    .toggler {
      border: none;
      background: white;
      padding: 5px 20px 5px 5px;
      height: auto;
      background-color: transparent;
      .fa {
        right: 0px;
        top: 6px;
      }
    }
  }
`

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  render() {
    let {data: daypartReport, metric = 'spots', cfg} = this.props;
    let today = moment().format('YYYY-MM-DD');
    let channelLabel = {
      radio: 'Station',
      tv: 'Channel',
    }[cfg.platform] || 'Channel';
    const channelNameMap = {
      'CH5': 'Channel 5',
      'CH8': 'Channel 8',
      'CHU': 'Channel U',
      'SUR': 'Suria',
      'CEN': 'Vasantham',
      'CNAS': 'CNA',
    };
    return (
      <ChartWrapper>
        <div className="filters">
          <div className="filter-item">
            <label>{channelLabel}s: </label>
            <SelectInlineStyled
              searchable={true}
              plainStyle={true}
              defaultLabel={`Select ${channelLabel}`}
              multi={true}
              allSelectedLabel={`All ${channelLabel}s`}
              // useFixed={true}
              showGroupBatch={true}
              selected={cfg.channels || daypartReport.channels || []}
              data={(daypartReport.channels || []).map(k => ({key: k, label: dimensionValueMap[k] || channelNameMap[k] || k}))}
              onChange={channels => {
                this.props.chartStateChanged({channels: channels});
              }}/>
          </div>
          <div  className="filter-item">
            <label>Period:</label>
            <DateSelectorDropdown
              mode={'range'}
              placeholder={'placeholder'}
              period={cfg.period || daypartReport.period || {start: today, end: today}}
              onDateRangeUpdated={period => {
                this.props.chartStateChanged({period: period});
              }}/>
          </div>
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data: daypartReport, metric = 'spots'} = this.props;
    const container = this.containerRef.current;
    const height = 350;
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    const myChart = echarts.init(container, null, {renderer: 'svg', height, width});
    const {items} = daypartReport;
    const hours = _.range(0, 24).map(d => (d < 10 ? '0': '') + d);
// prettier-ignore
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const data = items.map(d => {
      return [Number(d.datehour), Number(d.weekday), d.value];
      // return [Number(d.datehour), (Number(d.weekday) || 7) - 1, d.value];
    });
    const min = _.min(data.map(d => d[2]))
    const max = _.max(data.map(d => d[2]))
    let option = {
      colors: ['red', 'green'],
      tooltip: {
        position: 'top',
        valueFormatter: v => {
          return intFormatter(v);
        }
      },
      grid: {
        top: '10',
        bottom: '30',
        left: '140',
        right: '0',
      },
      xAxis: {
        type: 'category',
        data: hours.map((value, i) => {
          return moment(value, 'HH').format('h A');
        }),
        formatter: (value, i) => {
          return moment(value, 'HH').format('h A');
        },
        splitArea: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: Aladdin_v2_blue3,
          fontWeight: 'bold'
        },
      },
      yAxis: {
        type: 'category',
        data: days,
        splitArea: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          fontWeight: 'bold',
          color: v => {
            return (v === 'Saturday' || v === 'Sunday') ? Aladdin_v2_orange1 : Aladdin_v2_blue3;
          }
        },
      },
      visualMap: {
        min: min,
        max: max,
        calculable: true,
        orient: 'vertical',
        left: 'left',
        bottom: 'center',
        inRange : {
          color: ['white', Aladdin_v2_blue3], //From smaller to bigger value ->
        },
        formatter: function (value) {
          return intFormatter(value);
        }
      },
      series: [
        {
          name: '',
          type: 'heatmap',
          data: data,
          label: {
            show: true,
            fontSize: '10px',
            color: 'inherit',
            // borderColor: 'white',
            // borderWidth: 5,
            formatter: function (d) {
              return intFormatter(d.value[2]);
            }
          },
          itemStyle: {
            borderColor: 'rgba(255,255,255,.5)',
            borderWidth: 5,
            orderRadius: 5
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 11,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };;

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}
