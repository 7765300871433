import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  MediacorpPrimary,
  Levander,
  Aladin_BG_1,
  Aladin_BG,
  Aladin_Orange_Dark,
  Aladin_Orange_Light
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const ShareWrapper = styled.div`
  > p {
    font-weight: bold;
    font-size: 12px;
  }
  .campaign-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    pading: 10px 20px;
    margin-bottom: 10px;
  }
  .campaign-list {
    background: ${Aladin_BG};
    padding: 10px 10px;
    border-radius: 20px;
    margin-bottom: 30px;
    .campaign-item {
      padding-left: 10px;
      margin: 5px 0 5px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .campaigin-search-box {
        background-color: transparent;
        width: 100%;
        cursor: pointer;
        input {
          background-color: transparent;
        }
      }
      .campaigin-search-box {
        flex-grow: 1;
        position: relative;
        min-height: 20px;

        > div:nth-child(1) {
          display: flex;
          .select-value {
            margin-left: 25px;
          }
        }
        i {
          position: absolute;
          left: 0px;
          top: 10px;
          color: ${MediumGray1};
        }
        .spinner {
          position: absolute;
          top: 0px !important;
          left: 0px;
        }

        .item-option {
          cursor: pointer;
          padding: 8px 10px;
          border-bottom: 1px solid ${Aladin_BG_1};

          &.selected {
            background-color: #faeccb;
          }

          &:nth-child(even) {
            background: ${LightBlue};
          }

          &:hover {
            background: #fcefd1;
          }

          .name {
            font-weight: bold;
            font-size: 12px;
            color: ${DarkGray};
          }

          .tag {
            padding: 1px 5px;
            border-radius: 4px;
            margin: 0 10px 5px 0;
            font-size: 10px;
            background-color: rgba(248, 242, 238, 0.38);
            color: #f3d1bc ${DarkGray};
          }
          .subTag {
            margin-right: 4px;
            ::after {
              content: ", ";
           }
            &:last-child {
              border-right: 0;
              ::after {
                content: " ";
             }
            }
          }
        }

        input {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          padding: 10px 10px 10px 20px;
          font-size: 14px;
          border-radius: 0px;
          border: 1px solid ${MediumGray};
          border: none;
          background: transparent;
          width: 100%;
          box-sizing: border-box;
          outline: none;
          color: ${Aladin_Orange_Dark};

          &:not(.show) {
            opacity: 0;
          }

          ::placeholder {
            color: ${MediumGray1};
            opacity: .5;
            font-weight: bold;
          }
          &:focus {
            background: ${Aladin_BG};

            ::placeholder {
              color: ${Aladin_Orange_Dark};
            }

            & ~ i {
              color: ${Aladin_Orange_Dark};
              left: 0px;
              top: 10px;
            }
          }
        }
      }
      a {
        cursor: pointer;
        i {
          font-size: 16px;
        }
      }
    }

  }
  .platform-selection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    >div:first-child {
      margin-right: 10px;
    }
    label {
      margin-right: 10px;
    }
  }

  .actions {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
