import styled, {css} from 'styled-components';
import {
  DropdownZIndex,
  FontSizeBig,
  FontSizeMedium,
  LightGray,
  LightGray1,
  MediumGray,
  BlueJeans,
  DarkGray,
  BlueJeans1,
  FontSizeSmall,
  DarkGray1,
  Grass1,
  BitterSweet1,
  Aladdin_v2_red1,
  LightBlue,
  Aladin_Orange_Light,
  Aladin_Blue_Dark, Aladin_BG, Aladin_BG_1, Sunflower, Aladin_BG_TV_1, Aladin_BG_TV_2
} from "../app/StyleCommon";

export const NotificationWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const NotificationToggler = styled.div`
  position: relative;
  cursor: pointer;
  background-color: white;
  box-sizing: border-box;
  height: 50px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FontSizeMedium};
  background-color: transparent;
  border-left: 1px solid rgba(255,255,255, .1);
  border-right: 1px solid rgba(255,255,255, .1);
  font-size: 18px;
  font-weight: bold;
  &:hover {
    background-color: ${LightGray1} !important;
  }
  .count {
    position: absolute;
    top: 15%;
    left: 45%;
    display: inline-block;
    padding: 0 4px;
    min-width: 8px;
    max-width: 19px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 22px;
    text-align: center;
    font-family: "Roboto","Arial",sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    background-color: #4f8f98;
    color: #fff;
  }
  ${props => props.active && css`
    //background: white !important;
    color: ${DarkGray1};
    i {
      filter: drop-shadow(0px 1px 3px gray);
    }
    .count {
      border: 1px solid ${MediumGray};
    }
  `}
`;

export const NotificationMenu = styled.div`
  position: fixed;
  top: 50px;
  left: auto;
  right: 0px;
  bottom: 0;
  overflow: auto;
  width: 465px;
  transition: all .3s ease;
  opacity: 0;
  z-index: ${DropdownZIndex};
  min-width: 250px;
  border: 1px solid ${LightGray1};
  background: ${Aladin_BG};
  overflow-y: auto;
  @media (max-width: 667px) {
    left: 35px;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: auto;
    max-height: inherit;
  }

  ${props => props.active && css`
    padding: 10px;
    opacity: 1;
  `}
  ${props => !props.active && css`
    pointer-events: none !important;
  `}
  > h3 {
    padding: 0px 10px;
    color: ${DarkGray1};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .load-more {
    display: flex;
    align-items: center;
    margin: 0 0 20px 10px;
    cursor: pointer;
    span {
      text-decoration: underline;
    }
    i {
      font-size: 18px;

    }
  }
`
export const MenuItem = styled.label`
  padding: 10px 10px;
  display: block;
  font-size: 12px;
  color: ${DarkGray1};
  border-top: 1px solid ${MediumGray};
  position: relative;

  a {
    width: auto !important;
    text-decoration: none;
  }

  ${props => props.isNew && css`
    background-color: ${Aladin_BG_1};
  `}
  .noti-header {
    color: #f1a164;
    font-size: 16px;
    font-weight: bold;
    i {
      margin-right: 10px;
      font-size: 11px;
      vertical-align: middle;

      &.fa-check {
        color: ${Grass1};
      }

      &.fa-close {
        color: ${BitterSweet1};
      }
    }
  }

  .noti-body {
    font-size: 12px;
    margin: 10px 0;
    // &:hover {
      //   text: ${MediumGray};
    // }
    .tag {
      padding: 0px 5px;
      background-color: ${LightGray1};
      font-weight: bold;
      border-radius: 5px;
      margin: 5px 5px 5px 0;
      &.yellow {
        color: ${BlueJeans};
      }
    }
    b {
      font-weight: bold;
    }

    i {
      font-weight: bold;
      text-decoration: none;
      font-style: normal;
    }
    .type {
      font-weight: bold;
    }
    .pretext {
      color: #7a7878;
      //text-decoration: line-through;
    }
    .update, .create, .delete, .type  {
      //color: #6EB3C4;
    }
    ul {
      list-style-type: disc;
      margin-left: 15px;
      li {
        margin: 2px 0
      }
    }
    label {
      font-weight: bold;
    }
  }

  .noti-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${DarkGray}
  }

  .del {
    position: absolute;
    display: inline-block;
    padding: 5px;
    right: 15px;
    top: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const EmptyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  min-height: calc(100vh -150px);
  text-align: center;
  img {
    width: 200px;
    height: auto;
  }
  p {
    font-size: 14px;
  }
`;

