import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter, periodFormat,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Dark,
  Aladin_Blue_Dark1,
  Aladin_Blue_Light,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_Purple_Dark,
  Aladin_Purple_Light,
  Aladin_Red_Dark,
  DarkGray,
  Grass1,
  MediumGray1,
  MediumGray,
  Aladin_Orange_Lighter,
  LightGray1,
  Aladdin_v2_blue1,
  Aladdin_v2_blue2,
  Aladdin_v2_blue3,
  Aladin_BG_2,
  Aladin_BG_1, Aladin_BG,
} from "../app/StyleCommon";
import Table from "../uikit/table";
import {BarWrapper, TableWrapper} from "./index.style";
import {dimensionLabelMap, metricMap, metricMetaData, platformLabelMap, siteLabelMap} from "../utils/metadata";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";
import classNames from "classnames";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      closeMap: {}
    };
  }

  render() {
    const {data, cfg} = this.props;
    const {metric1, metric2} = cfg;
    const {dimensionValues = [], rows = []} = data;
    try{ //patching, don't want to break existing production.
      rows.forEach(d => {
        Object.keys(d).filter(k => k !== 'type').forEach(k => {
          d[k].column = k;
          d[k].key = d.type;
        })
      })
    } catch (e){
      console.error(e);
    }

    let sorted = _.sortBy(_.flatten(rows.map(d => dimensionValues.map(k => d[k] || {}))), d => d.cor || 0).reverse();
    let sorted7 = _.sortBy(_.flatten(rows.map(d => dimensionValues.map(k => d[k] || {}))), d => d.cor7 || 0).reverse();
    sorted.filter(d => d.key !== 'Whole Day' && d.column !== 'Grand Total' && d.cor > 0).slice(0, 5).forEach(d => {
      d.isTop5 = true;
    })
    sorted7.slice(0, 7).forEach(d => {
      d.isTop7 = true;
    })
    return (
      <ChartWrapper className="chart">
        <TableWrapper className="data-table">
          <Table
            columns={[
              {
                key: 'type',
                label: '',
                align: 'left',
              },
              ...(
                dimensionValues.map(dimValue => {
                  return {
                    key: dimValue,
                    label: dimValue,
                    align: 'right',
                    renderer: d => {
                      console.log("d", dimValue, "d[dimValue]", d[dimValue]);
                      let {isTop5, isTop7, cor} = d[dimValue] || {}
                      if(cor === null) {
                        isTop5 = false;
                        isTop7 = false;
                      }
                      return (
                        <div style={{
                          padding: '8px 5px',
                          color: (isTop5 ? Aladdin_v2_blue3 : DarkGray),
                          fontWeight: (isTop5 ? 'bold' : 'normal'),
                          background: (isTop7 ? '#d7eaf3': 'transparent')
                        }}>
                          {pct2Formatter(cor || 0)}
                        </div>
                      )
                    }
                  };
                })
              )
            ]}
            rows={rows || []}
            noDataText={'No data found'}
          />
        </TableWrapper>
        <div style={{margin: '20px 0 10px 0', fontSize: '11px'}}>
          <span><span style={{color: Aladdin_v2_blue3, fontWeight: 'bold'}}>Blue Text</span> 	- Top 5 belts in correlation with sales</span>,
          <span><span style={{background: '#d7eaf3', padding: '1px 5px',  marginLeft: '10px'}}>Blue box</span>	- Correlation is highest within 7 days</span>
        </div>
      </ChartWrapper>
    )
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  padding: 5px 5px;
  box-sizing: border-box;

  .data-table {
    width: 100%;
    margin-bottom: 10px;
    max-height: 400px;
    overflow: auto;
    position: relative;

    td, th {
      padding: 5px 0px;
      margin: 10px 0;

      &:first-child {
        border-right: 1px solid rgba(141, 203, 218, 0.48);

      }
    }

    td.right {
      padding-right: 5px;
    }

    .table-nav-column {
      white-space: normal;
      word-break: break-word;
      font-weight: normal !important;
      padding-left: 10px;
      //color: #436265;
      vertical-align: middle;
    }

    .material-symbols-outlined {
      position: absolute;
      left: -6px;
      color: ${MediumGray};
      cursor: pointer;
      padding: 2px;

      &:hover {
        color: ${Aladin_Orange_Dark};
      }
    }
  }
`;
