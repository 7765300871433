import React, {Component, useEffect} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Aladdin_v2_blue3,
  Aladin_BG, Aladin_BG_1, Aladin_BG_2, Aladin_Orange_Dark, Aladin_Orange_Lighter, BlueJeans, DarkGray,
  DarkGray1, Grass1, LightGray1,
  MediumGray1,
} from "../app/StyleCommon";
import Table from "../uikit/table";
import classnames from "classnames";
import styled, {css} from 'styled-components';
import MonthPanel from '../uikit/DateSelector/MonthPanel';
import createTooltip, {defaultTooltip, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import {TableWrapper} from "./index.style";
import {dimensionValueMap, platformLabelMap} from "../utils/metadata";
import {pct2Formatter} from "../utils/formatter";

const Wrapper = styled.div`
  .download {
    display: block;
    margin-bottom: 10px;
    text-align: right;
    cursor: pointer;
    &:hover {
      color: ${Aladin_Orange_Dark};
    }
  }
  .data-table {
    height: 400px;
    max-height: 80vh;
    overflow: auto;
  }
  table {
    td {
      word-break: break-all;
    }
  }
`;

export default class extends Component {

  convertToTable() {
    let {data, benchmark, context, chart} = this.props;
    let {mixedDimensionsLabelMap, mixedLabelMap}= context;
    let metricMap = mixedLabelMap;
    let dimensionLabelMap = mixedDimensionsLabelMap;

    if(/^keymetric_/ig.test(chart.key)) {
      return {
        columns: [
          {
            key: 'metric',
            label: 'Metric',
            renderer: (d, i) => metricMap[chart.cfg.metric]
          },
          {
            key: 'value',
            label: 'Value',
            renderer: (d, i) => d.value
          },
          !!benchmark ?
            {
              key: 'benchmark',
              label: 'Benchmark',
              renderer: (d, i) => benchmark.value || '--'
            }
            :
            {
            key: 'target',
            label: 'Target',
            renderer: (d, i) => {
              if(chart.key === 'keymetric_pacing') {
                return '--';
              }
              return d.target || '--'
            }
          }
        ],
        data: [data]
      }
    }
    else if(/^timeseries_stack/ig.test(chart.key)) {
      let {metric, dimension} = chart.cfg;
      return {
        columns: [
          {
            key: dimension,
            label: dimensionLabelMap[dimension],
            renderer: (d, i) => d[dimension]
          },
          {
            key: 'date',
            label: 'Period',
            renderer: (d, i) => d.key || d.date
          },
          {
            key: metric,
            label: metricMap[metric],
            renderer: (d, i) => d[metric]
          }
        ],
        data: _.flattenDeep(Object.keys(data.groupedData).map((dimensionValue, key) => {
          return data.groupedData[dimensionValue].map(d => ({...d, [dimension]: dimensionValue}));
        }))
      }
    }
    else if(/^timeseries/ig.test(chart.key)) {
      let {metric, metric2} = chart.cfg;
      let metrics = _.compact([metric, ...(metric2 ? [metric2] : [])]);
      return {
        columns: [
          {
            key: 'date',
            label: 'Period',
            renderer: (d, i) => d.key || d.date
          },
          ...metrics.map(metric => {
            return {
              key: metric,
              label: metricMap[metric],
              renderer: (d, i) => d[metric]
            };
          })
        ],
        data: data
      }
    }
    else if(/^heatmap/ig.test(chart.key)) {
      let {metric, dimensions} = chart.cfg;
      // let groupByDatehour = _.groupby(data.items, d => d.datehour);
      return {
        columns: [
          {
            key: 'datehour',
            label: 'Date Hour',
          },
          {
            key: 'weekday',
            label: 'Weekday',
          },
          {
            key: 'value',
            label: 'spots',
          }
        ],
        data: data.items
      }
    }
    else if(/two_level_breakdown/ig.test(chart.key)) {
      let {main, secondary, selected} = data || {}
      let {dimension, dimension2, metric, metrics} = chart.cfg || {}
      let metricMixed = _.compact([metric, ...(metrics || [])]);
      return {
        columns: [
          {
            key: 'dimension',
            label: 'Dimension',
            renderer: (d, i) => d.dimension
          },
          {
            key: 'dimensionValue',
            label: 'Dimension Value',
            renderer: (d, i) => d.dimensionValue
          },
          ...metricMixed.map(metric => {
            return {
              key: metric,
              label: metricMap[metric],
              renderer: (d, i) => d[metric]
            };
          })
        ],
        data: [
          ...(main.map(d => ({dimension: dimension, dimensionValue: d[dimension] || d.label || d.key, ...d}))),
          ...(secondary.map(d => ({dimension: dimension2, dimensionValue: d[dimension2] || d.label || d.key, ...d})))
        ]
      }
    }

    else if(/breakdown_bar_horizontal/ig.test(chart.key)) {
      let {metric, metrics, dimension, dimension1} = chart.cfg;
      metrics = _.compact([metric, ...(metrics || [])]);
      return {
        columns: [
          {
            key: 'dimension',
            label: dimensionLabelMap[dimension],
            renderer: (d, i) => {
              let key = d.key || d[dimension];
              return dimensionValueMap[key] || key;
            }
          },
          ...metrics.map(metric => {
            return {
              key: metric,
              label: metricMap[metric],
              renderer: (d, i) => d[metric]
            };
          })
        ],
        data: data
      }
    }
    else if(/dimension_breakdown_table/ig.test(chart.key)) {
      let {metrics, dimension, dimensions, dimension1} = chart.cfg;
      let extraDimensions = [];
      dimensions = dimensions ? dimensions : [dimension];
      if(dimensions.length === 1 && dimensions[0] === 'lineitem') {
        extraDimensions = ['start_date', 'end_date']
      }
      return {
        columns: [
          ...dimensions.map(dim => {
            return {
              key: 'dimension',
              label: dimensionLabelMap[dim],
              renderer: (d, i) => d[dim + '_label'] ? `${d[dim + '_label']} (${d[dim]})` :d[dim]
            };
          }),
          ...extraDimensions.map(dim => {
            return {
              key: dim,
              label: dimensionLabelMap[dim] || dim,
              // renderer: (d, i) => d[dim] || (d.details || {})[dim]
              renderer: (d, i) => d[dim] || (d.details || {})[dim]
            };
          }),
          ...metrics.map(metric => {
            return {
              key: metric,
              label: metricMap[metric],
              renderer: (d, i) => d[metric]
            };
          })
        ],
        data: data
      }
    }
    else if(/breakdown_platform/ig.test(chart.key)) {
      let {metric, metric2, dimension} = chart.cfg;
      let metrics = _.compact([metric, ...(metric2 ? [metric2] : [])]);
      return {
        columns: [
          {
            key: 'dimension',
            label: dimensionLabelMap[dimension],
            renderer: (d, i) => platformLabelMap[d.key || d[dimension]]
          },
          ...metrics.map(metric => {
            return {
              key: metric,
              label: 'Actual ' + metricMap[metric],
              renderer: (d, i) => d.actual
            };
          }),
          ...metrics.map(metric => {
            return {
              key: metric,
              label: 'Target ' + metricMap[metric],
              renderer: (d, i) => d.target
            };
          })
        ],
        data: data
      }
    }
    else if(/breakdown/ig.test(chart.key)) {
      let {metric, metric2, dimension, dimension1} = chart.cfg;
      let metrics = _.compact([metric, ...(metric2 ? [metric2] : [])]);
      return {
        columns: [
          {
            key: 'dimension',
            label: dimensionLabelMap[dimension],
            renderer: (d, i) => d.key || d[dimension]
          },
          ...metrics.map(metric => {
            return {
              key: metric,
              label: metricMap[metric],
              renderer: (d, i) => d[metric]
            };
          })
        ],
        data: data
      }
    }
    else if(/correlation/ig.test(chart.key)) {
      let {metric, metric2} = chart.cfg;
      let metrics = _.compact([metric, ...(metric2 ? [metric2] : [])]);
      const {dimensionValues = [], rows = []} = data;
      return {
        columns: [
          {
            key: 'dimension',
            label: 'type',
            renderer: (d, i) => d.type
          },
          ...dimensionValues.map(dimValue => {
            return {
              key: dimValue,
              label: dimValue,
              renderer: d => {
                let {cor} = d[dimValue] || {};
                return pct2Formatter(cor || 0)
              }
            };
          })
        ],
        data: rows
      }
    }
    else if(/^tv_schedule/ig.test(chart.key)) {
      const {period, data: dailyData = []} = data || {};
      const unwindedData = _.flattenDeep(dailyData.map(d => {
        return (d.programs || []).map(item => {
          item.spots = item.spots || (item.pt || 0) + (item.opt || 0) + (item.vc|| 0);
          return item;
        });
      }))
      let columns = [{
          key: 'date',
          label: 'Date',
        },
        {
          key: 'channel',
          label: chart.cfg.platform === 'radio' ? 'Station' : 'Channel',
        }];
      return {
        columns: chart.cfg.platform === 'radio' ? columns : [...columns, {
          key: 'title',
          label: 'Title',
        }],
        // [
        //   {
        //     key: 'spot_type',
        //     label: 'Spot Type',
        //   },
        //   {
        //     key: 'marketing_scheme_type',
        //     label: 'Marketing Scheme Type',
        //   },
        //   {
        //     key: 'marketing_scheme_sub_type',
        //     label: 'Marketing Scheme  Sub Type',
        //   },
        //   {
        //     key: 'original_nett',
        //     label: 'Original Net',
        //   }
        // ],
        data: unwindedData
      }
    }
    else if(/^tv_program_lookup/ig.test(chart.key)) {
      const {period, data: programData = [], date} = data || {};
      return {
        columns: [
          {
            key: 'date',
            label: 'Time',
          },
          {
            key: 'channel',
            label: 'Channel',
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'ratings',
            label: 'Ratings',
          },
          {
            key: 'spot_type',
            label: 'Spot Type',
          },
          {
            key: 'marketing_scheme_type',
            label: 'Marketing Scheme Type',
          },
          {
            key: 'bought',
            label: 'Booked',
            renderer: d => {
              return d['bought'] ? 'Yes' : 'No'
            }
          },
          {
            key: 'booked_number',
            label: 'Booked Campaigns',
          },
          {
            key: 'campaigns',
            label: 'Campaigns',
            renderer: d => {
              return (d.campaigns || []).map(d => `${d.id}: ${d.name}`).join(', ')
            }
          },
        ],
        data: programData
      }
    }
    // else if(/^campaign_information$/ig.test(chart.key)) {
    //   return queryCampaignInfo(chart, context).catch(retNullAndLog);
    // }
    // else if(/^campaign_attribute$/ig.test(chart.key)) {
    //   return queryKeyAttribute(chart, context).catch(retNullAndLog);
    // }
    // else if(/^keymetric_budget/ig.test(chart.key)) {
    //   return queryBudget(chart, context).catch(retNullAndLog);
    // }
    // else if(/^keymetric_pacing/ig.test(chart.key)) {
    //   return queryPacing(chart, context).catch(retNullAndLog);
    // }
    // else if(/^keymetric_/ig.test(chart.key)) {
    //   return queryKeyMetrics(chart, context).catch(retNullAndLog);
    // }
    // else if(/^breakdown_platform/ig.test(chart.key)) {
    //   return queryPlatformBreakdown(chart, context).catch(retNullAndLog);
    // }
    //
    // else if(/^tv_schedule/ig.test(chart.key)) {
    //   return queryTVSchedule(chart, context).catch(retNullAndLog);
    // }
    // else if(/^tv_program_lookup/ig.test(chart.key)) {
    //   return queryTVProgramLookup(chart, context).catch(retNullAndLog);
    // }
    // else if(/^two_level_breakdown/ig.test(chart.key)) {
    //   return queryTwoLevelBreakdown(chart, context).catch(retNullAndLog);
    // }
    // else if(/^breakdown_/ig.test(chart.key) || /_breakdown/ig.test(chart.key)) {
    //   return queryBreakdown(chart, context).catch(retNullAndLog);
    // }
    else {
      return {
        columns: [
          {
            key: 'data',
            label: 'Data',
            renderer: d => JSON.stringify(d)
          }
        ],
        data: [data]
      }
    }

  }

  render() {
    let {columns, data} = this.convertToTable();
    let {chart} = this.props;
    return (
      <Wrapper>
        <a className="download" onClick={e => {
          this.downloadReport(chart);
        }}>
          <i className="fa fa-download" aria-hidden="true"></i>
          &nbsp;Download
        </a>
        <TableWrapper className="data-table">
          <Table
            columns={[
              {
                key: 'index',
                label: '',
                align: 'left',
                sortable: true,
                style: {maxWidth: '20px'},
                renderer: (d, i) => (i + 1)
              },
              ...columns
            ]}
            rows={data}
            noDataText={'No data found'}
          />
        </TableWrapper>
      </Wrapper>
    )
  }

  displayValues(chart, d, c) {
    if(chart.key === "tv_program_lookup") {
       if(typeof d[c.key] === 'boolean') {
          return d[c.key] ? 'Yes' : 'No'
       } else {
        return d[c.key]
       }
    } else if (chart.key === "heatmap") {
      if(!d[c.key]) {
        return String(d[c.key])
      }
    }
    return d[c.key];
  }


  downloadReport(chart) {
    let {columns, data} = this.convertToTable();
    const rows = [
      columns.map(d => d.label),
      ...(data.map(d => {
        return columns.map(c => (c.renderer ? c.renderer(d) : this.displayValues(chart, d, c) || ''));
      }))
    ];
    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = String(row[j]);
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, 'data.csv');
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
