import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {
  BitterSweet1,
  BlueJeans,
  DarkBlue,
  DarkGray,
  DarkGray1,
  FontSizeBig,
  LightGray,
  LightGray1,
  MediumGray1,
  Mint,
  SilverDarkGray,
  SilverGray,
  SecondaryMediumBlue,
  BlueJeans1,
  Levander,
  Mint1,
  Grass1,
  MeOrange,
  MeOrange1,
  MeOrangeLight,
  Aqua1,
  MediumGray,
  Aladin_Orange_Light,
  Aladin_Orange_Dark,
  Aladin_Blue_Dark,
  LightBlue,
  Aladin_Gray_Dark,
  Aladin_Red_Light,
  Aladin_Red_Dark,
  Aladin_BG,
  Aladin_Blue_Dark1, Aladin_Blue_Light, colorsAladdin, colorsDaypart, Aladin_BG_TV_1
} from "../app/StyleCommon";
import {SideMenuZIndex, BodyMaskZIndex, HeaderZIndex, DialogMaskZIndex} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";

const HeaderHeight = '0';
const HeaderHeightNumber = 50;
const SideMenuWidth = '210px';
const SideMenuWidthNumber = 210;

export const MainWrapper = styled.div`
  display: block;
  ${props => !!props.withChat && css`
    position: fixed;
    right: 320px;
    left: 0;
  `}
  //overflow: hidden;
  // ${props => props.tvDemoTheme && css`
  //   div {
  //
  //   }
  // `}
  ${props => !props.open && css`
    ${LogoWrapper}{
      overflow: hidden;
      border-bottom: 1px solid rgba(255,255,255,.1);
      background: ${Aladin_Gray_Dark};
      .logo {
        display: none;
      }
      i {
        color: white;
      }
      span {
        opacity: 0;
      }
    }
    ${SideMenuWrapper} {
      width: 48px !important;
      box-shadow: 1px 0 5px rgba(0,0,0,.1);
      height: auto;
      overflow: hidden;
      bottom: 0;
      min-height: auto;
      padding: 0;
      box-shadow: none;
      background: ${Aladin_Gray_Dark};
      border-right: none;
      .logout {
        background: ${Aladin_Gray_Dark};
      }
      .menu-lead {
        height: 0;
        width: 60%;
        margin-left: 20%;
        overflow: hidden;
        border-bottom: 1px solid rgba(255,255,255,.2);
        margin-bottom: 20px;
        padding: 0;
        &:first-child {
          display: none;
          margin-top: 35px;
        }
      }
    }
    ${SideMenuItem} {
      padding: 0 10px;
      text-align: center;
      min-width: inherit;
      display: flex;
      align-items: center;
      border-radius: 10px 0 0 10px;
      i {
        margin-right: 0;
        text-align: center;
        margin-left: 5px;
        font-size: 20px;
        color: white;
      }
      span {
        opacity: 0;
      }
    }
    ${HeaderSection} {
      padding-left: 45px !important;
    }
    ${BodySection} {
      margin-left: 50px;
    }
    .fixedNexttoMenu {
      left: 48px !important;
    }
  `}

  ${props => !props.open && css`
     @media screen and (max-width: 768px) {
     ${LogoWrapper}{
       padding-left: 10px;
     }
      ${SideMenuWrapper} {
        width: 35px !important;
        height: 100vh !important;
        overflow: hidden;
        //max-height: 50px;
        bottom: auto;
        min-height: auto;
        padding-bottom: 0;
        box-shadow: none;
      }
      ${SideMenuItem} {
        padding: 0 5px;
      }
      ${HeaderSection} {
        //padding-left: 60px;
      }
      ${BodySection} {
        margin-left: 20px;
      }
      .fixedNexttoMenu {
        left: 0px;
      }
    }
  `}
`;


export const SideMenuWrapper = styled.div`
  z-index: ${SideMenuZIndex};
  background-color: #fde4e4;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-height: 100%;
  padding-bottom: 250px;
  width: ${SideMenuWidth};
  transition: width .2s ease-in;
  //background-image: linear-gradient(30deg,#ff9757,#7d5e3c);
  overflow: hidden;
    // border-right: 1px solid ${LightGray1};
  background: ${Aladin_BG};
    //background-image: linear-gradient(30deg, ${Aladin_Orange_Dark}, ${Aladin_Red_Dark}, ${Aladin_Orange_Light});
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  a,
  a:active,
  a:visited {
    color: white;
    color: ${DarkGray1};
  }

  @media print {
    display: none;
  }

  .copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 14px 10px;
    text-align: center;
    border-top: 1px solid ${LightGray1};
    background: ${LightGray};
  }
`;

export const SideMenuList = styled.div`
  padding-top: 0;
  margin-top: 35px;
  //box-shadow: 1px 0 5px rgba(0,0,0,.2), 2px 0 15px rgba(0,0,0,.1);
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
  .menu-lead {
    padding: 10px 10px 10px 35px;
    margin-top: 20px;
    font-size: 12px;
  }
`;

export const SideMenuItem = styled(Link)`
  width: 100%;
  padding: 15px 10px 15px 35px;
  text-align: left;
  box-sizing: border-box;
  font-size: 13px;
  color: ${Aladin_Red_Dark};
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
  position: relative;
  min-height: 45px;
  white-space: nowrap;
  opacity: 1;
  ${props => props.ischild && css`
    padding: 12px 10px 12px 70px;
    min-height: auto;
    color: white;
    * {
      font-size: 12px !important;
      color: ${Aladin_Red_Dark};
    }
  `}
  ${props => props.isparent && css`
  `}
  ${props => props.hide && css`
      opacity: 0;
      padding: 0 10px 0 35px;
      height: 0;
      overflow: hidden;
  `}
  &.logout {
    position: absolute;
    bottom: 0;
    color: ${Aladin_Blue_Dark};
  }
  i {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 16px;
    text-align: left;
    flex: none;
  }
  i.fa-minus {
    font-size: 8px;
    margin-right: 0px;
    margin-top: -1px;
  }
  i.caret {
    position: absolute;
    right: -15px;
    top: 20px;
    width: 20px;
    font-size: 14px;
  }
  span {
    text-decoration: none;
    vertical-align: middle;
    font-weight: 600;
  }
  &&:hover {
    //background: #0F5985;
    //background: ${LightGray1};
  }
  ${props => props.selected && css`
    border-radius: 20px 0 0 20px;
    position: relative;
    background: white;
    &:before, &:after {
      position: absolute;
      content: '';
      right: 0px;
      display: block;
      width: 20px;
      height: 20px;
      background: white;
    }
    &:before {
      position: absolute;
      top: -20px;
      clip-path: path("M20,20 L20,0 C20,12 15,20 0,20 Z");
    }
    &:after {
      position: absolute;
      bottom: -20px;
      clip-path: path("M20,0 L0,0 C15,0 20,12 20,20 Z");
    }
    && {
      //background: rgb(32,40,51);
      span, i {
        font-weight: bold;
        letter-spacing: -0.2px;
        color: ${Aladin_Red_Dark};
      }
    }
    &&.morning {
      span, i {
        color: ${colorsDaypart[0]};
      }
    }
    &&.afternoon {
      span, i {
        color: ${colorsDaypart[1]};
      }
    }
    &&.evening {
      span, i {
        color: ${colorsDaypart[2]};
      }
    }
  `}

  ${props => !props.selected && css`
    &:hover {
      text-shadow: 0px 0px rgba(255,255,255, .8);
    }
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  //color: white;
  height: 40px;
  padding: 5px 0px 5px 15px;
  overflow: visible;
  //border-right: 1px solid rgba(0,0,0,.05);
  //border-bottom: 1px solid rgba(34, 53, 105, 0.1);
  color: ${DarkGray1};
  //background-image: linear-gradient(0deg, #fafbfc, #eeeded00);
  //box-shadow: 0 1px 3px 0 rgba(60,64,67,.1);
  color: white;
  white-space: nowrap;
  cursor: pointer;
  * {
    color: ${DarkGray1};
  }
  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    img {
      width: 120px;
      margin-right: 4px;
      vertical-align: middle;
      margin-right: 5px;
      filter: opacity(0.9);
    }
  }
  i {
    font-size: 18px;
    //color: white;
  }
  a {
    cursor: pointer;
    display: inline-block;
    width: 32px;
    flex: none;
  }
  > span {
    transition: all .2s ease;
    opacity: 1;
    font-weight: bold;
  }
`;
export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: ${DarkGray1};
  height: 60px;
  padding: 5px 15px 5px 10px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: ${HeaderHeightNumber}px;
  padding-left: ${SideMenuWidthNumber}px;
  transition: all .2s ease-in-out;
  z-index: ${HeaderZIndex};
  background-image: linear-gradient(0deg, #fafbfc, #eeeded00);
  //box-shadow: 0 1px 2px 0 rgba(60,64,67,.1), 0 1px 3px 1px rgba(60,64,67,.1);
  @media screen and (max-width: 768px) {
    padding-left: 40px !important;
  }
  a {
    cursor: pointer;
    display: inline-block;
    width: 32px;
  }
  .right-side {
    display: flex;
    align-items: center;
    flex-grow: 0;
    justify-content: flex-end;
  }

  .searchBox {
    flex-grow: 1;
    margin: 10px 20px;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 18px;
      color: ${MediumGray1};
    }
    input {
      padding: 10px 10px 10px 30px;
      font-size: 14px;
      border-radius: 0px;
      border: 1px solid ${MediumGray};
      border: none;
      background: transparent;
      width: 100%;
      box-sizing: border-box;
      outline: none;
      color: ${Aladin_Orange_Dark};
      ::placeholder {
        color: ${MediumGray1};
        opacity: .5;
        font-weight: bold;
      }
      &:focus {
        background: ${Aladin_BG};
        ::placeholder {
          color: ${Aladin_Orange_Dark};
        }
        & ~ i {
          color: ${Aladin_Orange_Dark};
        }
      }
    }
  }
`;

export const UserMenuDropdown = styled(Dropdown)`
  .dropdown-toggle {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .toggler-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      text-align: right;

      .name {
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .initial {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        font-size: 12px;
        font-weight: bold;
          // background-color: ${Aladin_Orange_Light};
        background-color: rgb(248, 231, 209);
        color: #b5780a;
        //background-image: url(/img/user.png);
        //background-size: contain;
      }
    }

    img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: 5px;
    }

    i {
      position: absolute;
      top: 15px;
      right: 10px;
      color: #aaa;
      font-size: 16px;
    }

    &:hover {
      background-color: ${LightGray1};
    }
  }

  .menu {
    top: 50px;
    right: 0;
    left: auto;
    min-width: 200px;

    .user-info {
      padding: 15px 20px;
      border-bottom: 1px solid ${LightGray1};

      .name {
        display: block;
      }

      .email {
        font-size: 11px;
      }
    }

    .menu-item {
      a {
        text-decoration: none;
      }

      &:hover {
        a {
          color: ${Aqua1};
        }
      }
    }
  }
`;

export const BodySection = styled.div`
  min-height: 500px;
  min-width: 400px;
  // min-height: calc(100vh - ${HeaderHeightNumber}px);
  transition: margin-left .2s ease-in-out;
  position: relative;
  box-sizing: border-box;
  margin-top: ${HeaderHeightNumber}px;
  margin-left: ${SideMenuWidthNumber}px;
  .body-footer {
    height: 50px;
    padding: 10px 20px;
  }
  @media screen and (max-width: 768px) {
    min-width: auto;
    margin-left: 0px;
  }
`;

export const BodyMask = styled.div`
  //background-color: rgba(0,0,0, 0);
  position: fixed;
  top: ${HeaderHeight};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: all .2s ease;
  ${props => props.visible && css`
    z-index: ${BodyMaskZIndex};
    background-color: rgba(0,0,0, .3);
  `}
`;

export const CommonErrorMsg = styled.div`
  padding: 10px 0;
  color: ${BitterSweet1};
  font-weight: bold;
`;

export const Breadcrumb = styled.ul`
  margin: 0;
  margin-left: 25px;
  // height: ${HeaderHeightNumber}px;
  margin-top: 60px;
  padding: 5px 0 5px 0;
  // background: ${Aladin_BG};
  color:  white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  >li {
    white-space: nowrap;
    color: ${DarkGray1};
    &.seperator {
      margin: auto 14px;
      color: ${DarkGray1};
    }
    >a {
      // color: ${Aladin_Orange_Dark};
      color: ${DarkGray1};
      text-decoration: none;
      width: auto;
      //font-weight: bold;
      &:hover {
        color: ${DarkGray1};
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;
