import React from 'react';
import _ from 'lodash';
import { ComponentMenu } from './dndMixin.style';
import { CondirmDialog } from "../uikit";
import classnames from "classnames";
import { dimensionLabelMap, metricMetaData, SizeOptions } from "../utils/metadata";

export default class extends React.Component {

    constructor(props) {
      super(props);
    }

    showChartOptionDialog(chart, chartContext) {
        const {dashboard, context, clientData} = this.state;
        const {mixedCfg, platformKey, section, sectionIndex, chartIndex, queryData, benchmarkCampaign, benchmarkData} = chartContext;
        let {mixedVisualisations, allMixedLabelMap, mixedMetaData} = context;
        const realPlatformKey = mixedCfg.platform || platformKey;
        let visMeta;
        let labelMap = allMixedLabelMap;
        visMeta = mixedVisualisations.find(d => d.key === chart.key) || {label: chart.key};

        let {
          bgColorOptions,
          dateUnitOptions,
          metricOptions,
          metricOptionsSortable,
          metricOptionsPlatformLevel={},
          metricOptionField = 'metric', //default = 'metric', can be 'metrics' if multiples
          metricMulti,
          metricOptionCumulativeOption,
          chartTypeOptions,
          chartTypeOptionsDefault,
          dimensionOptions,
          dimensionOptionsPlatformLevel={},
          dimensionOptionField = 'dimension', //default = 'dimension'
          secondaryMetricMulti,
          secondaryMetricOptions,
          secondaryMetricOptionsPlatformLevel={},
          secondaryMetricOptionField,
          secondaryChartTypeOptions,
          secondaryDimensionOptions,
          secondaryChartTypeOptionsDefault,
          secondaryDimensionOptionField, //default = 'dimension2
          secondaryDimensionOptionsPlatformLevel={},
          secondaryMetricOptionMutuallyExclusive,
          paginationTypeOptions,
          menuLabelMap = {},
          pageSizeOptions,
          detailOptions,
          noMixedBenchmark,
          cfg
        } = visMeta;
        let metricOptionMixed = _.uniq([...(metricOptions || []), ...(metricOptionsPlatformLevel[realPlatformKey] || [])]);
        let dimensionOptionsMixed = _.uniq([...(dimensionOptions || []), ...(dimensionOptionsPlatformLevel[realPlatformKey] || [])]);
        let secondaryMetricOptionMixed = _.uniq([...(secondaryMetricOptions || []), ...(secondaryMetricOptionsPlatformLevel[realPlatformKey] || [])]);
        let secondaryDimensionOptionsMixed = _.uniq([...(secondaryDimensionOptions || []), ...(secondaryDimensionOptionsPlatformLevel[realPlatformKey] || [])]);
        let benchmarkOptions = [
          {key: 'current', label: 'Show current campaign data'},
          {key: 'benchmark', label: 'Show benchmark campaign data'},
        ];
        if(chart.key.indexOf('keymetric')>=0) {
          benchmarkOptions.push({key: 'mixed', label: 'Show both current & benchamrk campaign data'})
        }
        const confirmInfo = {
          type: 'form',
          backgroundClose: true,
          title: <strong>Chart Options</strong>,
          width: '700px',
          hideCancel: true,
          hideOK: true,
          onCancel: () => CondirmDialog.closeDealog(),
          onOK: () => CondirmDialog.closeDealog(),
          dialogBody: (
            <ComponentMenu key={Date.now()}>
              {
                [
                  {options: bgColorOptions, label: 'Background Color', field: 'bg', hideInDisplayMode: true, isColor: true},
                  {options: SizeOptions, label: 'Width', field: 'size', hideInDisplayMode: true},
                  {options: dateUnitOptions, label: visMeta.dateUnitOptionsLabel || 'Period Type', field: 'unit', defaultValue: 'daily'},
                  {options: chartTypeOptions, label: visMeta.chartTypeOptionsLabel || 'Metric Visualisation Type', field: 'type', defaultValue: visMeta.chartTypeOptionsDefault},
                  {options: dimensionOptionsMixed, label: visMeta.dimensionOptionsLabel || 'Dimension', field: dimensionOptionField},
                  {options: metricOptionMixed, label: visMeta.metricOptionsLabel || 'Metric', field: metricOptionField, multi: metricMulti, sortable: metricOptionsSortable, cumulativeSelector: metricOptionCumulativeOption},
                  {options: secondaryChartTypeOptions, isSecondary: true, label: visMeta.secondaryChartTypeOptionsLabel || 'Secondary Metric Visualisation Type', field: 'type1', defaultValue: visMeta.secondaryChartTypeOptionsDefault},
                  {options: secondaryDimensionOptionsMixed, isSecondary: true, label: visMeta.secondaryDimensionOptionsLabel || 'Secondary Dimension', field: secondaryDimensionOptionField},
                  {options: secondaryMetricOptionMixed, isSecondary: true, label: visMeta.secondaryMetricOptionsLabel || 'Secondary Metric', field: secondaryMetricOptionField, multi: secondaryMetricMulti, mutuallyExclusive: secondaryMetricOptionMutuallyExclusive},
                  {options: paginationTypeOptions, label: visMeta.paginationTypeOptionsLabel || 'Pagination Type', field: 'pagination_type', defaultValue: 'scroll'},
                  {options: pageSizeOptions, label: visMeta.pageSizeOptionsLabel || 'Page Size', field: 'pageSize', defaultValue: 10},
                  {options: detailOptions, label: 'Extra Description', field: 'descriptionVisible', defaultValue: 'show'},
                  {options: benchmarkCampaign ? benchmarkOptions : null, label: 'Benchmark Options', field: 'benchmarkMode'},
                ].map(menuItem => {
                  if(!menuItem.options || !menuItem.options.length) return null;
                  if(menuItem.hideInDisplayMode && this.state.mode !== 'customize') return null;
                  // let SorterComponent = menuItem.sortable ? Sortable : styled.div;
                  if(['metric', 'metric2', 'metrics', 'dimension', 'dimensions'].includes(menuItem.field)) {
                    let metrics = menuItem.options.filter(key => !key.startsWith('client_'));
                    let clientMetrics = menuItem.options.filter(key => key.startsWith('client_'));
                    menuItem.options = [...metrics, ...clientMetrics];
                  }
                  let {accumulatable, isAccumulative} =  mixedMetaData.find(d => d.key === chart.cfg.metric) || {};
                  return (
                    <div className="menu-option">
                      <div className="label">{menuItem.label}</div>
                      <div
                        onReplacePosition={(x, y) => {
                          let arr = chart.cfg[menuItem.field] || [];
                          arr[x] = arr.splice(y, 1, arr[x])[0]; // swap position.
                          this.setState({dashboard, touched: true}, () => this.loadDataForChart(chart, section.filters));
                        }}
                        className="metric-selector">
                        {
                          menuItem.options.map(option => {
                            const metricDisabled = ['tv'].indexOf(chart.cfg.platform) >= 0 && chart.cfg.dimension && ['channel', 'date'].indexOf(chart.cfg.dimension) < 0 && ['reach', 'reach_pct', 'cpv', 'reach_3', 'reach_3_pct'].indexOf(option) >= 0;
                            if(typeof option !== 'object') {
                              option = {key: option, label: labelMap[option] || dimensionLabelMap[option] || menuLabelMap[option] ||_.capitalize(option)};
                            }
                            let selected  = false;
                            let optionKey = option.key;
                            let selectedValue = chart.cfg[menuItem.field] || menuItem.defaultValue;
                            if(!selected) {
                              selected = chart.cfg[menuItem.field || 'metric'] === optionKey || (!selectedValue && optionKey === menuItem.defaultValue);
                            }
                            selected = Array.isArray(selectedValue) ? selectedValue.indexOf(optionKey) >= 0 : selectedValue === optionKey;
                            let deSelectable = ['metrics', 'dimensions'].indexOf(menuItem.field) >= 0 &&  selectedValue && selectedValue.length > 1;
                            deSelectable = selected && (deSelectable || menuItem.isSecondary) && chart.key !== 'correlation_table';
                            return (
                              <div
                                className={classnames("select-option", {disabled: metricDisabled, selected: selected, isClient: String(option.key).indexOf('client_')>= 0})}
                                style={menuItem.isColor ? {width: '12px', height: '12px', background: option.key, display: 'inline-block', marginRight: '10px'}: {}}
                                key={optionKey}
                                onClick={e => {
                                  CondirmDialog.closeDealog();
                                  this.saveHistory();
                                  let chartData = this.handleChartDialogClick(chart, menuItem, optionKey, dimensionOptionsMixed, secondaryDimensionOptionsMixed, metricOptionMixed, secondaryMetricOptionMixed);
                                  this.setState({dashboard, touched: true}, () => this.loadDataForChart(chartData, section.filters));
                                }}>
                                {deSelectable && <i className="material-symbols-outlined close-btn">cancel</i>}
                                {menuItem.isColor ? '' : option.label}
                              </div>
                            )
                          })
                        }
                      </div>
                      {
                        menuItem.cumulativeSelector && (accumulatable || isAccumulative) &&
                        <label style={{display: 'flex', alignItems: 'center'}}>
                          <input
                            disabled={!accumulatable}
                            type={"checkbox"}
                            checked={chart.cfg.cumulative || isAccumulative}
                            onChange={e => {
                            chart.cfg.cumulative = e.currentTarget.checked;
                            this.setState({dashboard, touched: true});
                            CondirmDialog.closeDealog();
                          }}/> Show cumulative metric value
                        </label>
                      }
                      {
                        menuItem.field === 'unit' && chart.cfg.unit === 'weekly' &&
                        <label style={{display: 'flex', alignItems: 'center'}}>
                          <input
                            type={"checkbox"}
                            checked={chart.cfg.weekStyle === 'campaign'}
                            onChange={e => {
                              chart.cfg.weekStyle = e.currentTarget.checked ? 'campaign' : undefined;
                              this.setState({dashboard, touched: true}, () => this.loadDataForChart(chart, section.filters));
                              CondirmDialog.closeDealog();
                            }}/> Use campaign start day as week start day.
                        </label>
                      }
                      {
                        ['timeseries', 'breakdown_bar_horizontal'].includes(chart.key) && ['metrics', 'metric2'].includes(menuItem.field) &&
                        <label style={{display: 'flex', alignItems: 'center'}}>
                          <input
                            type={"checkbox"}
                            checked={chart.cfg.yAxisStyle === 'combine'}
                            onChange={e => {
                              chart.cfg.yAxisStyle = e.currentTarget.checked ? 'combine' : 'separate';
                              this.setState({dashboard, touched: true});
                              CondirmDialog.closeDealog();
                            }}/> Combine Left and Right Y-Axis
                        </label>
                      }
                    </div>
                  )
                })
              }
              {
                ['seperator', 'editor', 'campaign_information'].indexOf(chart.key) < 0 &&
                <div className={`menu-option action flex`} onClick={e => {
                  CondirmDialog.closeDealog();
                  this.showComponentData(chart, queryData, benchmarkData, context);
                }}>
                  <i className="material-symbols-outlined">table</i> Show Chart data
                </div>
              }
              {
                this.state.mode === 'customize' &&
                <div className={`menu-option action flex danger`} onClick={e => {
                  CondirmDialog.closeDealog();
                  this.removeVis(chart.key, sectionIndex, chartIndex);
                }}>
                  <i className="material-symbols-outlined">close</i> Remove Chart
                </div>
              }

            </ComponentMenu>
          ),
        }
        CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />);
    }

    handleChartDialogClick(chart, menuItem, optionKey, dimensionOptionsMixed, secondaryDimensionOptionsMixed, metricOptionMixed, secondaryMetricOptionMixed) {
      let currentValue = chart.cfg[menuItem.field] || menuItem.defaultValue;
      if(menuItem.field === 'metrics' || menuItem.field === 'dimensions') { //array.
        if(menuItem.field === 'dimensions' && !currentValue && chart.cfg.dimension) { // migrate single dimension to multiple dimensions.
          currentValue = [chart.cfg.dimension];
        }
        currentValue = currentValue || [];
        if(currentValue.indexOf(optionKey) >= 0 && (currentValue.length > 1 || menuItem.isSecondary)) {
          chart.cfg[menuItem.field] = currentValue.filter(k => k !== optionKey);
        }
        else {
          let selected = _.uniq([...(currentValue || []), optionKey]);
          if(menuItem.mutuallyExclusive && (currentValue || []).find(m => menuItem.mutuallyExclusive(m, optionKey))) {
            selected = [optionKey];
          }
          if(menuItem.field === 'dimensions') {
            chart.cfg[menuItem.field] = selected.reverse().slice(0, 2).reverse(); //max support only 2 dimensions.
            if(chart.cfg[menuItem.field].filter(k => ['meid_seg', 'age', 'gender', 'interest'].includes(k)).length > 1) {
              chart.cfg[menuItem.field] = chart.cfg[menuItem.field].slice(1, 2);
            }
          } else {
            chart.cfg[menuItem.field] = menuItem.options.map(d => d.key || d).filter(k => selected.indexOf(k) >= 0); // to keep the order and remove not supported ones
          }
        }
      } else { //single value
        if(currentValue === optionKey && ['metric', 'dimension'].indexOf(menuItem.field) < 0) {
          if(chart.key !== 'correlation_table') { // correlation chart always need two metrics.
            chart.cfg[menuItem.field] = null;
          }
        } else {
          chart.cfg[menuItem.field] = optionKey;
          if(/^keymetric_/g.test(chart.key) && menuItem.field === 'metric') {
            delete chart.cfg.title;
            chart.key = `keymetric_${optionKey}`;
          }
        }
      }

      if(['timeseries', 'correlation_table'].indexOf(chart.key) < 0 && ['dimensions', 'dimension', 'dimension2', 'metric', 'metric1', 'metric2', 'metrics'].indexOf(menuItem.field) >= 0) {
        let isDisabledMetric = metric => ['tv'].indexOf(chart.cfg.platform) >= 0 && chart.cfg.dimension && ['channel', 'date'].indexOf(chart.cfg.dimension) < 0 && ['reach', 'reach_pct', 'cpv', 'reach_3', 'reach_3_pct'].indexOf(metric) >= 0;
        let firstClientMetric = metricOptionMixed.find(x => !isDisabledMetric(x) && x.startsWith('client_'));
        let firstNonClientMetric = metricOptionMixed.find(x => !isDisabledMetric(x) && !x.startsWith('client_'));

        let first5ClientMetric = metricOptionMixed.filter(x => x.startsWith('client_')).slice(0, 5);
        let first5NonClientMetric = metricOptionMixed.filter(x => !isDisabledMetric(x)  && !x.startsWith('client_')).slice(0, 5);

        let firstClientDimension = dimensionOptionsMixed.find(x => x.startsWith('client_'));
        let firstNonClientDimension = dimensionOptionsMixed.find(x => !x.startsWith('client_'));

        let testAndSet = (fields, value) => {
          fields.forEach(field => {
            if(chart.cfg[field]) {
              chart.cfg[field] = Array.isArray(chart.cfg[field]) ? (Array.isArray(value) ? value : [value]) : value;
            }
          })
        }
        let existingIsClient = String(JSON.stringify(currentValue)).indexOf('client_') >= 0;
        let selectingClient = optionKey.indexOf('client_') >= 0;
        if((existingIsClient && !selectingClient) || (!existingIsClient && selectingClient)) {
          if(menuItem.field.indexOf('dimension') >= 0) {
            testAndSet(['dimensions', 'dimension'], optionKey);
            testAndSet(['metric', 'metric1','metric2', 'metrics'], selectingClient ? firstClientMetric : firstNonClientMetric);
            if(chart.key === 'dimension_breakdown_table') {
              testAndSet(['metrics'], selectingClient ? first5ClientMetric : first5NonClientMetric);
            }
          } else if(menuItem.field.indexOf('metric') >= 0) {
            testAndSet(['metrics', 'metric','metric1', 'metric2'], optionKey);
            testAndSet(['dimension', 'dimensions', 'dimension1', 'dimension2'], selectingClient ? firstClientDimension : firstNonClientDimension);
          }
        }
      }
      if(['size', 'bg'].indexOf(menuItem.field) < 0) {
        delete chart.cfg.title; // once config changed, custom title will be removed
      }
      if(menuItem.field === 'pagination_type' && optionKey === 'pagination' && currentValue !== 'pagination') {
        chart.cfg.pageSize = 5;
      }
      else if(menuItem.field === 'pageSize') {
        chart.cfg.pagination_type = 'pagination';
      }

      return chart;
    }

}

