export const roles = [
    {role: 'Administrator', privileges: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]},
    {role: 'Pricing Management', privileges: [1, 2, 3, 4, 5, 6, 8, 9, 10]},
    {role: 'Commercial Insights & GTM Solutioning, Digital Solutions', privileges: [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 13, 15]},
    {role: 'Commercial Ops', privileges: [1, 2, 3, 4, 5, 6, 8, 9, 10]},
    {role: 'Sales Admin / Digital Sales / IAM Sales', privileges: [0, 1, 2, 3, 6, 8, 9, 10]},
    {role: 'Agency Partnerships', privileges: [6, 8, 9, 10]},
    {role: 'Commercial Planning / Other Super Users (X-Functional)', privileges: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]},
  ];
export const roleMatrix = [
  {
    cat: 'Basic', data: [
      {id: 1, priv: 'Create Campaign'},
      {id: 2, priv: 'Edit Campaign parameters'},
      {id: 3, priv: 'Edit Campaign Goals'},
      {id: 4, priv: 'Create Campaign Dashboard'},
      {id: 5, priv: 'Edit Campaign Dashboard'},
      {id: 6, priv: 'View Campaign Dashboard'},
      {id: 7, priv: 'Global view of all campaign'},
      {id: 8, priv: 'Export Campaign Report/ Share Report Link'},
      {id: 9, priv: 'Receive Campaign Alerts'},
      {id: 10, priv: 'Assign User Access to Campaign Dashboard'},
      {id: 0, priv: 'Access Home page dashboard'},

    ]
  },
  {
    cat: 'User Management', data: [
      {id: 11, priv: 'Add/Remove User'},
      {id: 12, priv: 'Change User Role'},
    ]
  },
  {
    cat: 'Platform Mgmt', data: [
      {id: 13, priv: 'Add/ Edit / Remove Pre-Configured Dashboards'},
      {id: 14, priv: 'Audit Trail on campaign creation, campaign editing'},
      {id: 15, priv: 'Create Dashboard for All Campaigns'},
      {id: 16, priv: 'System Alert Settings'},
    ]
  },
]

export const demoUserList = [
  {userId: 'Mike.Lin@mediacorp.com.sg', name: 'Mike Lin', register_date: '2023-05-09', roles: ['Administrator']},
  {userId: 'wuyue@mediacorp.com.sg', name: 'Wu Yue',  register_date: '2023-05-09', roles: ['Pricing Management', 'Commercial Ops']},
]
export const auditList = [
  {userId: 'user1@mediacorp.com.sg', name: 'User1', date: '2023-05-09 11:34:56', action: 'Login', details: `kpi from 'CPM' to 'Reach', campaign budget from $25k to $56k`, ip: '212.34.23.124', country: 'Singapore', ua: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36'},
  {userId: 'user1@mediacorp.com.sg', name: 'User1', date: '2023-05-09 12:34:56', action: 'Change Campaign settings', details: `kpi from 'CPM' to 'Reach', campaign budget from $25k to $56k`, ip: '212.34.23.124', country: 'Singapore', ua: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36'},
  {userId: 'user1@mediacorp.com.sg', name: 'User1', date: '2023-05-09 13:34:56', action: 'Logout', details: `kpi from 'CPM' to 'Reach', campaign budget from $25k to $56k`, ip: '212.34.23.124', country: 'Singapore', ua: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36'},
  {userId: 'user2@mediacorp.com.sg', name: 'User 2', date: '2023-05-09 13:34:56', action: 'Share Report to client', details: `kpi from 'CPM' to 'Reach', campaign budget from $25k to $56k`, ip: '212.34.23.124', country: 'Singapore', ua: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/112.0.0.0 Safari/537.36'},
]