import React from 'react'
import _ from 'lodash'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './index.style';
import {Table, TableScrollWrapper, TabContainer, Select, Btn} from "../uikit/index";
import {roleMatrix, roles, demoUserList} from "./metadata";
import {TableController} from "./index.style";
import RoleManagement from "./roles";
import UserManagement from "./users";
import AuditManagement from "./audit";
import {getFromCache,cacheResource} from "../utils/cache";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";

const ADMIN_SETTINGS_CACHE_KEY = 'ADMIN_SETTINGS_CACHE_KEY';
const pathMap = {1: '/admin/roles', 0: '/admin/users', 2: '/admin/audit'};
const pathMapReversed = _.invert(pathMap);
export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    const cachedSettings = getFromCache(ADMIN_SETTINGS_CACHE_KEY) || {};

    this.state = {
      activeIndex: Number(pathMapReversed[window.location.pathname]) || 0,
      ...cachedSettings
    }
  }
  render() {
    const {activeIndex, roleKeyword} = this.state;
    const tableRows =  _.flatten(roleMatrix.map(d => ([d, ...d.data])));
    const {session} = this.props.appState;
    let canAdminSettings = session.rights.indexOf('View Admin Settings') >= 0;
    let canViewUser = session.rights.indexOf('View System User List') >= 0;
    let canViewRoles = session.rights.indexOf('View System Roles and priviledges') >= 0;
    let canAuditLogs = session.rights.indexOf('View System Audit Logs') >= 0;
    let noViewRights = !canViewUser && !canViewRoles && !canAuditLogs;
    return (
      <MainLayout activeItem="admin" breadcrumb={[{path: '/settings', label: 'Admin Settings'}]}>
        <Wrapper>
          <div>
            <h4>Admin Settings</h4>
            {
              (!canAdminSettings || noViewRights) && <ErrorMessage message={'Not Allowed'}/>
            }
            {
              canAdminSettings &&
              <TabContainer
                key={`tabcontainer_${activeIndex}`}
                tabTitles={
                  _.compact([
                    canViewUser ? 'User Management' : undefined,
                    canViewRoles ? 'System Roles': undefined,
                    canAuditLogs ? 'Audit Trail': undefined
                  ])
                }
                activeIndex={activeIndex}
                onChange={i => {
                  this.setState({activeIndex: i}, () => this.cacheParams());
                  this.props.history.push(pathMap[i]);
                }}>
                {canViewUser && <UserManagement />}
                {canViewRoles && <RoleManagement />}
                <AuditManagement />
              </TabContainer>
            }
          </div>
        </Wrapper>
      </MainLayout>
    )
  }

  cacheParams() {
    cacheResource(ADMIN_SETTINGS_CACHE_KEY, _.pick(this.state, ['activeIndex']))
  }
})