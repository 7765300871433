import React from 'react';
import classNames from 'classnames';
import Dropdown from './index';
import {DropdownStyledWrapper} from './index.style';

export default class SingleSelectionDropdown extends Dropdown {
  static get defaultProps() {
    return {
      data: [],
      onChange: () => null
    };
  }

  constructor(props) {
    super(props);
    this.radioGroupId = props.radioGroupId || Math.round(Date.now() * Math.random());
    this.state = {
      active: props.active,
      selected: this.props.selected,
      keyword: null,
      height: 0,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      selected: props.selected
    });
  }

  componentDidUpdate(previousProps, previousState) {
    if (!previousState.active && this.state.active) {
      this.searchInput && this.searchInput.focus();
    }
  }

  render() {
    let styles = {};
    if (this.props.displayOnTop) {
      styles = {top: `-${this.state.height}px`, left: '-50%'};
    }
    return (
      <DropdownStyledWrapper
        className={classNames('dropdown selectbox', this.props.className, {active: this.state.active})}>
        {this.renderToggler()}
        <div
          ref="menu"
          className={classNames('menu', {hidden: !this.state.active})}
          style={styles}>
          <div className="menu-header">
            <div className="name">{this.props.label}</div>
          </div>
          {this.renderSearchBar()}
          {this.renderOptionList()}
        </div>
      </DropdownStyledWrapper>
    );
  }

  renderToggler() {
    const {icon} = this.props;
    let toggleText = this.renderToggleLabel();
    if (this.props.toggler) {
      return (
        <div ref="toggler" className="dropdown-toggle" onClick={e => this.toggleMenu(e)}>
          {this.props.toggler}
        </div>
      );
    } else {
      return (
        <div ref="toggler" className="dropdown-toggle" onClick={e => this.toggleMenu(e)}>
          {icon} <span>{toggleText}</span> {this.props.caret ? <i className="fa fa-angle-down"/> : null}
        </div>
      );
    }
  }

  renderToggleLabel() {
    const selected = this.state.selected;
    let toggleText = this.props.label;
    if (!this.props.fixedToggle) {
      if (!selected || !this.props.data) {
        toggleText = toggleText || 'Select Option';
      } else {
        const item = this.props.data.find(item => String(item.key) === String(selected));
        toggleText = item ? item.label : (toggleText || 'Select Option');
      }
    }
    return toggleText;
  }

  renderOptionList() {
    let sorted = this.props.data;
    if (this.props.sort) {
      sorted = this.props.data.sort((a, b) => a.label > b.label ? 1 : -1);
    }
    if (this.props.dataGroups) {
      return this.renderOptionGroupList();
    } else if (this.props.data.length > 0) {
      return this.renderOptionGroup(sorted);
    } else {
      return <div className="options empty">no options available</div>;
    }
  }

  renderOptionGroupList() {
    return (
      <div className="options-group-list">
        {
          this.props.dataGroups.map((group) => this.renderOptionGroup(group.items, group.groupName))
        }
      </div>
    );
  }

  renderOptionGroup(items, title) {
    const titleElement = title ? (<div>{title}</div>) : null;
    return (
      <div className="options-group-item" key={title}>
        <div className="options-group-item-title">{titleElement}</div>
        <ul className="options">
          {this.filterItems(items).map(item => this.renderOptionItem(item))}
        </ul>
      </div>
    );
  }

  renderOptionItem(item) {
    const checked = String(this.state.selected) === String(item.key);
    return (
      <li className={classNames('option', {checked: checked, disabled: item.disabled})}
          key={item.key} data-key={`key_${item.key}`}>
        <label>
          <input
            type="radio"
            className="filter-radio"
            name={this.radioGroupId}
            checked={checked}
            onClick={e => this.handleClicked(e, item.key)}
            onChange={e => this.handleChange(e, item.key)}
          />
          <span>{item.label}</span>
        </label>
      </li>
    );
  }

  renderSearchBar() {
    if (this.props.data.length < 8) {
      return null;
    }
    return (
      <div className="search">
        <input
          ref={(input) => {
            this.searchInput = input;
          }}
          type="text"
          placeholder="Search"
          autoFocus="true"
          onChange={e => this.updateSearch(e)}/>
      </div>
    );
  }

  filterItems(items = []) {
    if (!this.state.keyword) {
      return items.filter(item => !item.hidden);
    }
    const keyword = String(this.state.keyword).toLowerCase();
    return items.filter(item => !item.hidden).filter(item => {
      const lkey = item.key.toLowerCase();
      const llabel = item.label.toLowerCase();
      return lkey.indexOf(keyword) >= 0 || llabel.indexOf(keyword) >= 0;
    });
  }

  updateSearch(e) {
    this.setState({keyword: String(e.target.value).toLowerCase()});
  }

  handleChange(e, value) {
    e.stopPropagation();
    this.setState({selected: value, active: false});
    this.props.onChange(value || this.state.selected);
  }

  handleClicked(e, key) {
    const {selected} = this.props;
    const {active} = this.state;
    if (selected === key) {
      if (key === 'All') {
        this.setState({selected: undefined});
        this.props.onChange(undefined);
      }
      this.setState({active: false})
    }
  }
}