import React from 'react';
import {GridContainer} from "./index.style";

export default class extends React.Component {
  render() {
    return (
      <GridContainer {...this.props}>
        {this.props.children}
      </GridContainer>
    )
  }
}