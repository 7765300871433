import moment from 'moment';
import _ from 'lodash';

export const isValidUrl = function(string) {
  try {
    new URL(string);
  } catch (e) {
    return false;
  }
  return true;
}


export const getQuarter =  function(d) {
  d = d ? moment(d) : moment();
  var q = [4, 1, 2, 3];
  return q[Math.floor(d.month() / 3)];
}

export const descendOtherAndUnknown = function (data, keyAttr = 'key') {
  ['Others', 'others', 'UNKNOWN', 'Unknown', 'unknown'].forEach(k => {
    const index = _.findIndex(data, d => d[keyAttr] === k || d === k);
    index >= 0 && data.push(data.splice(index, 1)[0]);
  });
  return data;
}