import styled, {css} from 'styled-components';
import {
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG, Aladin_BG_1, Sunflower, Aladin_BG_TV_1
} from "../../app/StyleCommon";

export const Wrapper = styled.div`
  width: 100%;
  .error {
    color: red;
    font-size: 12px;
  }
`

export const Editor = styled.div`
  position: relative;
  margin-bottom: 6px;

  .formula-editor-textarea {
    resize: none;
    width: 100%;
    color: transparent;
    caret-color: #000;
    border: 1px solid #ddd;
    padding: 8px 4px;
    font-family: monospace;
    letter-spacing: normal;
    height: 60px !important;
    font-size: 14px;
    font-weight: 600;

    &:disabled {
      border: 1px solid #eee;
    }

    &::selection {
      color: transparent;
      background: #eee;
    }
    
  }
  .formula-editor-formatted {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 8px;
    border: 1px solid transparent;
    padding: 8px 4px;
    font-family: monospace;
    letter-spacing: normal;
    white-space: pre-wrap;
    pointer-events: none;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 600;

    span {
      font-size: 14px;
      font-weight: 600;
    }

    .string,
    .quote {
      color: #a11;
    }

    .number {
      color: #164;
    }

    .function-name {
      color: #07a;
    }

    .reference-name {
      color: #05a;
    }

    .operator,
    .comma {
      color: #838c90;
    }

    .bracket {
      color: #997;
    }

    .error,
    .unfinished-formula {
      color: red;
    }
  }
`

  