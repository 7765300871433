import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter, periodFormat,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Dark,
  Aladin_Blue_Dark1,
  Aladin_Blue_Light, Aladin_Orange_Dark,
  Aladin_Orange_Light, Aladin_Purple_Dark, Aladin_Purple_Light, Aladin_Red_Dark, DarkGray,
  Grass1,
  MediumGray1,
  MediumGray, Aladin_Orange_Lighter, LightGray1,
} from "../app/StyleCommon";
import Table from "../uikit/table";
import {BarWrapper, TableWrapper} from "./index.style";
import {dimensionValueMap, siteLabelMap} from "../utils/metadata";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";
import classNames from "classnames";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      closeMap: {}
    };
  }

  render() {
    const {closeMap} = this.state;
    console.log("tttt", this.state, "ppppp", this.props)
    const {data, cfg, currency, context} = this.props;
    let {mixedMetaData, mixedDimensionsLabelMap, mixedLabelMap}= context;
    const {dimension, dimensions, metrics = [], pagination_type = 'scroll', customSortedColumns = []} = cfg;
    let {page = 1, pageSize = 10, sortBy, sortDirection} = cfg;
    const selectedDimensions = _.sortBy(dimensions || [dimension] || [], k => customSortedColumns.indexOf(k));
    let sorted = data;
    if(selectedDimensions.includes('interest')) {
      sorted = sorted.filter(d => d.key !== 'Others' && d.key !== 'age-13-17');
    }
    if(!sortBy) {
      sortBy = selectedDimensions[0];
      sortDirection = sortDirection || 'asc';
    }
    if(sortBy && sortDirection) {
      sorted = _.sortBy(sorted, d => {
        return d[`${sortBy}_label`] || d[sortBy];
      });
      if(sortDirection === 'desc') {
        sorted = sorted.reverse();
      }
    }
    let pageData = sorted;
    if(pagination_type !== 'scroll' && pagination_type !== 'all') {
      pageData = sorted.slice((page-1) * pageSize, page * pageSize)
    }
    pageData.forEach(d => {
      d.key = selectedDimensions.map(dim => dim + (d[dim] || '-')).join(',')
    })
    if(selectedDimensions.length > 1 && sortBy === selectedDimensions[0]) {
      let groupKeys = _.uniq(pageData.map(d => d[selectedDimensions[0]]));
      pageData = _.cloneDeep(pageData);
      let grouped = _.groupBy(pageData, d => d[selectedDimensions[0]]);
      Object.keys(grouped).forEach(k => {
        let items = grouped[k];
        items.forEach((item, i) => {
          if(i === 0) {
            item.uiClassName = 'first-row';
          } else if(closeMap[k]) {
            item.uiClassName = 'hide-row';
          }
        })
      })
    }
    const toggleHideMap = (k) => {
      this.setState({closeMap: {...closeMap, [k]: !closeMap[k]}})
    }
    const columns = [
      // {
      //   key: 'index',
      //   label: '',
      //   align: 'center',
      //   sortable: true,
      //   hidden: selectedDimensions.length > 1,
      //   style: {maxWidth: '20px'},
      //   renderer: (d, i) => (i + 1)
      // },
      ...(selectedDimensions).map((dimension, dimensionIndex) => {
        return {
          key: dimension,
          label: mixedDimensionsLabelMap[dimension] || dimension,
          align: 'left dimension',
          sortable: true,
          renderer: (d, rowIndex)=> {
            let isParentRow = false;
            if(sortBy === dimension && dimensionIndex === 0 && selectedDimensions.length > 1) {
              let firstRowIndex = pageData.findIndex(r => r[dimension] === d[dimension]);
              let lastRowIndex = _.findLastIndex(pageData, r => r[dimension] === d[dimension]);
              if(rowIndex > firstRowIndex && firstRowIndex>= 0) {
                return <div title={d[dimension]}></div>;
              } else if(lastRowIndex > firstRowIndex) {
                isParentRow = true;
              }
            }
            let labelMap = {sites: siteLabelMap}[dimension] || dimensionValueMap || {};
            let labelKey = dimension + '_label';
            let isFirstRow = sortBy === dimension && dimensionIndex === 0 && selectedDimensions.length > 1;
            let parentIndicator = (
              <span
                onClick={e => toggleHideMap(d[dimension])}
                className="material-symbols-outlined" style={{verticalAlign: 'middle', fontSize: '16px'}}>
                {!closeMap[d[dimension]] ? 'expand_more' : 'keyboard_arrow_right'}
              </span>
            )
            return (
              <div className={classNames('table-nav-column')} style={{fontWeight: 'bold'}}>
                {!d[labelKey] && <div>{isParentRow && parentIndicator} {labelMap[d[dimension]] || d[dimension] || ' -- '}</div>}
                {!!d[labelKey] && <div>{isParentRow && parentIndicator}{labelMap[d[labelKey]] || d[labelKey] || ' -- '} (<strong>{d[dimension]}</strong>)</div>}
              </div>
            )
          }
        }
      }),
      {
        key: 'creativeType',
        label: 'Type',
        align: 'center',
        hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'creative',
        sortable: true,
        renderer: d => {
          return (d.details || {}).type || '--';
        }
      },
      {
        key: 'creativeSize',
        label: 'Size',
        align: 'center',
        hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'creative',
        sortable: true,
        renderer: d => {
          return (d.details || {}).size || '--';
        }
      },
      {
        key: 'period',
        label: 'Period',
        align: 'center',
        hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'lineitem',
        sortable: true,
        renderer: d => {
          if(!(d.details || {}).start_date || !d.details.end_date) {
            return '--';
          }
          return periodFormat({start: d.details.start_date, end: d.details.end_date}, 'short');
        }
      },
      {
        key: 'status',
        label: 'Status',
        align: 'center',
        hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'lineitem',
        sortable: true,
        renderer: d => {
          return (d.details || {}).status || '--';
        }
      },
      // {
      //   key: 'pacing',
      //   label: 'Pacing',
      //   align: 'right',
      //   hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'lineitem',
      //   sortable: true,
      //   renderer: d => {
      //     // return pctFormatter(Math.min((d.details || {}).pacing, 1), 0);
      //     return pctFormatter(d.details?.pacing);
      //   }
      // },
      // {
      //   key: 'goal',
      //   label: 'Goal',
      //   align: 'right',
      //   hidden: selectedDimensions.length !== 1 || selectedDimensions[0] !== 'lineitem',
      //   sortable: true,
      //   renderer: d => {
      //     // return pctFormatter(Math.min((d.details || {}).pacing, 1), 0);
      //     return d.details.goal ? Object.keys(d.details.goal).map(k => `${k}: ${intFormatter(d.details.goal[k])}`).join(', ') : '-';
      //   }
      // },
      ...(metrics.map(metric => {
        let isDemoReport = selectedDimensions.some(dim => ['age', 'gender', 'interest'].includes(dim));
        let metricMeta = mixedMetaData.find(d => d.key === metric);
        return {
          key: metric,
          label: mixedLabelMap[metric],
          align: 'right',
          sortable: true,
          renderer: d => {
            return (
              <div key={metric}>
                {(isDemoReport && d[`total_${metric}`]) && <div>{pct2Formatter(d[metric] / d[`total_${metric}`])}</div>}
                {(!isDemoReport || !d[`total_${metric}`]) && getMetricFormatter(metricMeta, currency)(d[metric])}
                {
                  !isDemoReport && !!d[`total_${metric}`] &&
                  <div className="desc">{pct2Formatter(d[metric] / d[`total_${metric}`])}</div>
                }
              </div>
            )
          }
        }
      }))
    ];
    const columnsSorted = _.sortBy(columns, c => {
      let index = (customSortedColumns || []).indexOf(c.key);
      return index >= 0 ? index : (selectedDimensions.indexOf(c.key) >= 0 ? 0 : Infinity);
    })
    const visibleColumns = columns.filter(d => !d.hidden);
    const visibleColumnKeys = visibleColumns.map(d => d.key);
    return (
      <ChartWrapper className="chart">
        <TableWrapper className={classNames('data-table', pagination_type === "all" ? 'data-table-expand' : null)}>
          <Table
            rowSortable={true}
            onReplacePosition={(colum1, colum2, type) => {
              let newCustomSortedColumns = customSortedColumns || _.cloneDeep(visibleColumnKeys);
              newCustomSortedColumns = _.sortBy(visibleColumnKeys, k => newCustomSortedColumns.indexOf(k));
              let x = newCustomSortedColumns.indexOf(colum1);
              let y = newCustomSortedColumns.indexOf(colum2);
              newCustomSortedColumns[x] = null;
              newCustomSortedColumns.splice(y + (x < y ? 1 : 0), 0, colum1); // insert position.
              newCustomSortedColumns = _.compact(newCustomSortedColumns);
              this.props.chartStateChanged({customSortedColumns: newCustomSortedColumns}, true);
            }}
            columns={columnsSorted.filter(d => !d.hidden)}
            rows={pageData}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={sortBy => {
              this.props.chartStateChanged({sortBy: sortBy}, true);
            }}
            directionChange={direction => {
              this.props.chartStateChanged({sortDirection: direction}, true);
            }}
            noDataText={'No data found'}
          />
        </TableWrapper>
        {
          pagination_type !== 'scroll' && pagination_type !== 'all' &&
          <ListFooter>
            <span className="info">
                {/*{data.length} items.*/}
            </span>
            <PaginationStyled
              onChange={p => this.props.chartStateChanged({page: p}, true)}
              current={page}
              pageSize={pageSize}
              total={data.length}
              hideOnSinglePage={true}/>

          </ListFooter>
        }
      </ChartWrapper>
    )
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  padding: 5px 5px;
  box-sizing: border-box;

  .data-table {
    width: 100%;
    margin-bottom: 10px;
    max-height: 400px;
    overflow: auto;
    position: relative;

    td {
      padding: 8px 5px
    }

    td.right {
      padding-right: 20px
    }

    .table-nav-column {
      white-space: normal;
      word-break: break-word;
      font-weight: normal !important;
      padding-left: 10px;
      //color: #436265;
      vertical-align: middle;
    }

    .material-symbols-outlined {
      position: absolute;
      left: -6px;
      color: ${MediumGray};
      cursor: pointer;
      padding: 2px;

      &:hover {
        color: ${Aladin_Orange_Dark};
      }
    }

    .first-row {
      td {
        //background-color: #f8fbfc;
      }

      &:not(:first-child) td {
        border-top: 1px dashed #d4e4e8;
      }
    }

    .hide-row {
      display: none;
    }

    .child-row {
      position: relative;
    }
  }

  .data-table-expand {
    height: auto;
    max-height: 100%;
  }
`;