import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {AttrOverview, VisCanvas, DropArea, VisPanel, Wrapper} from './details.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, TabContainer, Loader, ProgressBar} from "../uikit";
import {WidgetHistoryWrapper, RevisionList, ShowMore, NoData, DetailView, CampaignDetails} from "./history.style";
import qs from "qs";
import {platformLabelMap, platformMeta} from "../utils/metadata";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {post} from "../utils/request";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      detailMap: {},
      historyLoaded: []
    }
  }

  buildQuery() {
    const {page, pageSize} = this.state;
    const urlParams = qs.parse(window.location.search.slice(1));
    return {
      id: urlParams.id,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    };
  }


  render() {
    const {data, error, loading, page, pageSize, detailMap, noMore, historyLoaded} = this.state;
    const historyGroupByDay = _.groupBy(historyLoaded, d => moment(d.change_time).format('YYYY-MM-DD'));

    const dates = Object.keys(historyGroupByDay).sort().reverse();
    return (
      <MainLayout activeItem="campaign" breadcrumb={[{path: '/campaigns', label: 'Campaigns'}, {path: '/campaign/history', label: 'Campaign History'}]} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/campaignHistoryQuery`}
            params={this.buildQuery()}
            successHandler={data => {
              if(data.history && data.history.length) {
                this.setState({historyLoaded: historyLoaded.concat(data.history || [])});
              }
              this.setState({data: data})
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !!data &&
          <WidgetHistoryWrapper>
            <h3>
              <span>Campaign History</span>
              {/*<Btn type="link"><i className="material-symbols-outlined">download</i> Export</Btn>*/}
            </h3>
            <RevisionList>
                {dates.map(date => {
                  let items = historyGroupByDay[date];
                  items = _.sortBy(items, _.property('change_time')).reverse();
                  return (
                    <ul key={date}>
                      {items.map((d, i) => {
                        const showDetails = true; //detailMap[d.change_time];
                        const detailsView = this.renderChange(d);
                        if(!detailsView) {
                          return null;
                        }
                        return (
                          <li key={d.change_time} className={i === 0 ? 'day-break' : ''}>
                            {
                              i === 0 &&
                              <div className="time day-break-time">
                                <div className="icon-wrapper"><i className="icon fa fa-calendar"/></div>
                                <div>{moment(d.change_time).format('DD-MMM-YYYY')}</div>
                                <div className="time1">{moment(d.change_time).format('hh:mm A')}</div>
                              </div>
                            }
                            {
                              i !== 0 &&
                              <div className="time successive">
                                <div><i className="fa fa-clock-o"/> {moment(d.change_time).format('hh:mm A')}</div>
                              </div>
                            }
                            <div className="info">
                              <div className="basic" onClick={e => this.setState({
                                detailMap: {
                                  ...detailMap,
                                  [d.change_time]: !showDetails
                                }
                              })}>
                              <span>
                                {d.action || (d.old_value ? 'Updated' : 'Created')} by <strong>{d.userId}</strong>.
                              </span>
                                {/*<Btn type="link" size="small">*/}
                                {/*  <i className={`fa fa-angle-double-${showDetails ? 'up' : 'down'}`}/>*/}
                                {/*</Btn>*/}
                              </div>
                              {
                                !!showDetails && <DetailView>{detailsView}</DetailView>
                              }
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  )
                })}
              </RevisionList>
            {
              !noMore && historyLoaded.length === page * pageSize &&
              <ShowMore>
                <a onClick={e => {
                  this.setState({page: page + 1}, () => this.queryNextPage());
                }}>Load More <i className="fa fa-angle-double-down"/></a>
              </ShowMore>
            }
            {
              !historyLoaded.length &&
              <NoData>
                No Revision History Found.
              </NoData>
            }
          </WidgetHistoryWrapper>
        }
      </MainLayout>
    )
  }

  queryNextPage() {
    this.setState({loading: true});
    return post('/api/campaignHistoryQuery', this.buildQuery()).then(data => {
      if(data.history && data.history.length) {
        const {historyLoaded} = this.state;
        this.setState({historyLoaded: historyLoaded.concat(data.history || [])});
      }
      this.setState({loading: false, data: data})
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }

   getDiff(oldData, newData) {
    let message = "";
    if(newData.columns.length > oldData.columns.length) {
      const newMetric = newData.columns.filter(newObj => !oldData.columns.some(oldObj => _.isEqual(newObj, oldObj)));
      message = `New Metric [${newMetric[0].value}] added to metrics.`
    } else if (newData.columns.length < oldData.columns.length) {
      const deletedMetric = oldData.columns.filter(oldObj => !newData.columns.some(newObj => _.isEqual(oldObj, newObj)));
      message = `Metric <span class="old">[${deletedMetric[0].value}]</span> deleted from metrics.`
    }
    return <span dangerouslySetInnerHTML={{__html: message}}></span>
  };

  renderClientDataChangeHistory(history) {
    let {campaign_id, old_client_value, new_client_value} = history;
    let field = "Client Data";
    let isCreate = Object.keys(old_client_value).length === 0 && Object.keys(new_client_value).length > 0;
    let isDelete = Object.keys(old_client_value).length > 0 && Object.keys(new_client_value).length === 0;
    let isUpdate = Object.keys(old_client_value).length > 0 && Object.keys(new_client_value).length > 0;

    let fileName = null;
    if(isCreate) {
      fileName = new_client_value.file;
    } else if (isDelete) {
      fileName = old_client_value.file;
    }
    return <div className="attr">
        <label>{_.capitalize(field).replace(/[-_]/g, ' ')} {isUpdate && <span> -&gt; Metrics</span>}</label>
        <div className="attr-value">
          {(isCreate || isDelete) ? <div className='client-attr'>
            {fileName && fileName !== "" &&<span>File <b>[{fileName}]</b></span>}
            {isCreate && <span>Uploaded to</span>}
            {isDelete && <span>Deleted from</span>}
            <span>
              Campaign : {campaign_id}
            </span>
          </div>: this.getDiff(old_client_value, new_client_value)
          }
        </div>
      </div>
  }

  renderChange(history) {
    let {campaign_id, old_value, new_value, old_client_value, new_client_value} = history;
    old_value = old_value || {};
    new_value = new_value || {};
    if(typeof old_value === 'string') {
      old_value = old_value ? JSON.parse(old_value) : {};
    }
    if(typeof new_value === 'string') {
      new_value = new_value ? JSON.parse(new_value) : {};
    }
    let fields = ['msq_id', 'name', 'start_date', 'end_date', 'type', 'platforms', 'viewers', 'editors'];
    fields = fields.filter(field => {
      return JSON.stringify(old_value[field]) !== JSON.stringify(new_value[field]);
    })
    if(old_client_value || new_client_value) {
      fields.push('client');
    }
    if(!fields.length) {
      return null;
    }
    let isCreate = !history.old_value;

    return (
      <CampaignDetails>
        {fields.map(field => {
          if(field === 'platforms') {
            let platformKeys = platformMeta.map(p => p.key);
            return platformKeys.map(platformKey => {
              let oldPlatform = (old_value.platforms || []).find(d => d.key === platformKey) || {};
              let newPlatform = (new_value.platforms || []).find(d => d.key === platformKey) || {};
              if(JSON.stringify(oldPlatform) !== JSON.stringify(newPlatform)) {
                let platformFields = ['budget', 'goals'];
                return platformFields.map(platformField => {
                  let oldPlatformFieldValue = oldPlatform[platformField] || {};
                  let newPlatformFieldValue = newPlatform[platformField] || {};
                  if(JSON.stringify(oldPlatformFieldValue) !== JSON.stringify(newPlatformFieldValue)) {
                    return (
                      <div className="attr">
                        <label>{_.capitalize(field).replace(/[_-]/g, ' ')} -> {platformLabelMap[platformKey]} -> {_.capitalize(platformField)}</label>
                        <div className="attr-value">
                          {isCreate && <span>Set &nbsp;</span>}
                          {!isCreate && <span style={{wordBreak: 'initial'}}>Change from </span>}
                          {!isCreate &&
                            <div className="old">
                              {isNaN(oldPlatformFieldValue) ? JSON.stringify(oldPlatformFieldValue) : oldPlatformFieldValue}
                            </div>
                          }
                          <span>to</span>
                          <div className="new">
                            {isNaN(newPlatformFieldValue) ? JSON.stringify(newPlatformFieldValue) : newPlatformFieldValue}
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return null;
                  }
                })
              } else {
                return null;
              }
            })
          } else if (field === 'client') {
            return this.renderClientDataChangeHistory(history);
          }else {
            return (
              <div className="attr">
                <label>{_.capitalize(field).replace(/[-_]/g, ' ')}</label>
                <div className="attr-value">
                  {isCreate && <span>Set &nbsp;</span>}
                  {!isCreate && <span  style={{wordBreak: 'initial'}}>Change from </span>}
                  {!isCreate &&
                  <div className="old">
                    {old_value[field] || 'empty'}
                  </div>
                  }
                  <span>to</span>
                  <div className="new">
                    {JSON.stringify(new_value[field])}
                  </div>
                </div>
              </div>
            )
          }

        })}
      </CampaignDetails>
    )
  }
})
