import React, {Component} from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import DateSelector from './index';
import {DateSelectorDropdownWrapper} from './index.style';
import {FixedHook} from './hooks';
import Btn from "../btn/index";

export default class extends Component {
  static defaultProps = {};

  constructor(props) {
    super(props)
    this.state = {active: false};
    this.menuRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  render() {
    let {mode, period, tabIndex, shortcuts = [], up, togglerLabel, menuHeader, useFixed, clearable} = this.props;
    const InternalFormat = 'YYYY-MM-DD';
    let calculatedPeriod = period;
    if(!period) {
      period = undefined;
    }
    if (period === 'last7days') {
      calculatedPeriod = {start: moment().add(-6, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last30days') {
      calculatedPeriod = {start: moment().add(-29, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last60days') {
      calculatedPeriod = {start: moment().add(-59, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last90days') {
      calculatedPeriod = {start: moment().add(-89, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period === 'last180days') {
      calculatedPeriod = {start: moment().add(-179, 'day').format(InternalFormat), end: moment().format(InternalFormat)}
    }  else if (period === 'last1year') {
      calculatedPeriod = {start: moment().add(-1, 'year').format(InternalFormat), end: moment().format(InternalFormat)}
    } else if (period && typeof period === 'object') {
      calculatedPeriod = {
        start: moment(period.start).format(InternalFormat),
        end: moment(period.end).format(InternalFormat),
      }
    }
    let selectedShortcut = shortcuts.find(d => d.key === period);
    const {active} = this.state;
    return (
      <DateSelectorDropdownWrapper
        ref={this.wrapperRef} up={up} tabIndex={tabIndex}
        className={classnames('date-selector-dropdown', this.props.className, {active: active})}>
        <a className='toggler' onClick={e => this.handleToggle(e)}>
          {togglerLabel}
          {
            !togglerLabel && typeof period === 'object' &&
            <React.Fragment>
              <span className="highlight">{moment(period.start).format('MMM DD, YYYY')}</span>
              {mode !== 'single' && <span>&nbsp;-&nbsp;</span>}
              {mode !== 'single' && <span className="highlight">{moment(period.end).format('MMM DD, YYYY')}</span>}
            </React.Fragment>
          }
          {
            !togglerLabel && !!selectedShortcut &&
            <span className="highlight">{selectedShortcut.label}</span>
          }
          {
            !togglerLabel && !period && <span>{togglerLabel || 'Select'}</span>
          }
          <i className="fa fa-calendar"/>
        </a>
        {
          active &&
          <div ref={this.menuRef} className="dropdown-content">
            {menuHeader}
            <DateSelector
              mode={mode}
              period={calculatedPeriod}
              className={`date-selector ${mode}`}
              futureDateAllowed={this.props.futureDateAllowed}
              boundryPeriod={this.props.boundryPeriod}
              onDateRangeUpdated={newPeriod => {
                this.setState({active: false});
                this.notifyDateRangeUpdated(newPeriod);
              }}/>
            {
              !!shortcuts.length &&
              <label className="sep">
                <span>OR Predefined Period</span>
              </label>
            }
            {
              !!shortcuts.length &&
              <ul className="shortcuts">
                {shortcuts.map(d => {
                  return (
                    <li key={d.key} className={period === d.key ? 'selected' : ''}>
                      <a onClick={e => {
                        this.setState({active: false});
                        this.notifyDateRangeUpdated(d.key)
                      }
                      }>{d.label}</a>
                    </li>
                  );
                })}
              </ul>
            }
            {
              !!clearable && period &&
              <div className="periodSer">
                Date range: &nbsp;
                <span>{moment(period.start).format('YYYY-MM-DD')} </span>
                &nbsp;to&nbsp;
                <span>{period.end ? moment(period.end).format('YYYY-MM-DD') : 'Unlimited'} </span>
              </div>
            }
            {
              !!clearable && period &&
              <div className="actions">
                <Btn type="reset" onClick={e => {
                  this.setState({active: false});
                  this.props.onDateRangeUpdated(null);
                }}>Clear</Btn>
                <Btn type="reset" onClick={e => {
                  this.setState({active: false});
                  this.props.onDateRangeUpdated({...period, end: null});
                }}>Clear End Date</Btn>
              </div>
            }
          </div>
        }
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.menuRef} offset={{top: 35}}/>}
      </DateSelectorDropdownWrapper>
    );
  }

  notifyDateRangeUpdated(period) {
    if (typeof period === 'string') {
      return this.props.onDateRangeUpdated(period);
    } else {
      let calculatedPeriod = {
        start: moment(period.start).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end: moment(period.end).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      }
      return this.props.onDateRangeUpdated(calculatedPeriod);
    }
  }

  handleToggle(e) {
    const {disabled} = this.props;
    if (!disabled) {
      this.setState({active: !this.state.active});
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
}