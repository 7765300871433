import styled, {css} from 'styled-components';
import Select from '../uikit/selectbox/index';
import {
  Aladin_Blue_Dark, Aladin_Blue_Light,
  Aladin_Orange_Dark,
  BlueJeans,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray
} from "../app/StyleCommon";

export const SelectStyled = styled(Select)`
  .toggler {
    background-color: ${LightGray};
    border: 1px solid ${MediumGray};
    color: ${DarkGray1};
    filter: none;
    border-radius: 4px;
    padding: 7px 10px;
    padding-right: 30px;
    border-radius: 5px;
    font-size: 14px;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all .5s ease;
    ${props => props.togglerTheme && css`
      background-color: ${props.togglerTheme} !important;
      color: white  !important;;
      span {
        color: white  !important;;
      }
    `}
    ${props => props.togglerMinWidth && css`
      min-width: ${props.togglerMinWidth}px;
    `}
    &:hover {
      background-color: ${LightGray1};
    }
    .default-label {
      color: ${DarkGray}
    }
    .prefix-label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
  &.segment-option-list .menu {
    max-height: 450px;
    overflow: auto;
  }
  .menu {
    top: 35px;
    left: 0;
    right: 0;
    min-width: auto;
    border: 1px solid ${MediumGray};
    ${props => props.up && css`
      transform: translate(0, -100%) translate(0, -30px);
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    `}
    label {
      white-space: normal;
      &.selected {
         background: white;
        color: ${DarkGray};
        font-weight: bold;
      }
      &:hover {
        background: ${LightGray1};
      }
      font-weight: normal;
      input[type=radio] {
        margin: 0 5px;
      }
    }
    .menuHeader {
      padding: 10px 20px;
      background: ${LightGray};
      border-bottom: 1px solid #f1f2f3;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i.add-metric {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
`

export const SelectInlineStyled = styled(SelectStyled)`
  .toggler {
    border: none;
    display: inline;
    background: none;
    padding: 0 30px 0 5px;
    margin-left: 5px;
    border-radius: 0;
    // color: ${BlueJeans};
    //border-bottom: 1px dotted ${MediumGray};
    &:hover {
      background: none;
    }
    img {
      display: none;
    }
  }
  .menu {
    top: 25px;
    text-align: left;
    right: auto !important;
    max-height: 550px;
    overflow: auto;
    ${props => props.mobileAdjust && css`
      @media screen and (max-width: 667px) {
        left: 50px !important;
        right: 10px !important;
        width: calc(100vw - 60px) !important;
      }
    `}
    .menu-group {

    }
    .dimension-item {
        display: flex;
        align-items: flex-start;
        padding: 5px 20px;
        white-space: normal;
        padding: 5px 0;
        width: 300px;
        img {
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .description {
           font-size: 11px;
           color: ${DarkGray1};
           font-weight: 300;
        }
    }
  }
`