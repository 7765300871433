import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  moneyFormatterGenerator,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {Grass1, MediumGray1} from "../app/StyleCommon";
import {metricMetaData, platformLabelMap, platformSorted} from "../utils/metadata";
import {getContextChartScale} from "./util";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    return (
      <ChartWrapper className="chart">
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data, cfg, context, currency} = this.props;
    const container = this.containerRef.current;
    const {metric} = cfg;
    const metricMeta = context.mixedMetaData.find(d => d.key === metric);
    let formatter = getMetricFormatter(metricMeta, currency);
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    data = _.sortBy(data, d => platformSorted.indexOf(d.platform));
    data = data.filter(d => ['social', 'ooh', 'others'].indexOf(d.key) < 0); //filter platforms not yet supported
    const height = 250;
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    const hasTarget = data.some(d => d.target);
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      tooltip: {
        trigger: 'axis',
        position: 'top',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: function (value) {
          return getMetricFormatter(metricMeta, currency)(value);
        }
      },
      grid: {
        bottom: '20',
        top: '80',
        left: '65px',
        right: '20px'
      },
      legend: {
        top: 30,
        show: hasTarget
      },
      xAxis: {
        type: 'category',
        data: data.map(d => platformLabelMap[d.key]),
        axisLabel: {
          show: true,
          interval: 0,
          // rotate: 35,
        },
      },
      yAxis: {
        type: 'value',
        position: 'left',
        splitNumber: 2,
        axisTick: {
          inside: false, length: 5
        },
        splitLine: {
          show: true
        },
        axisLabel: {
          formatter: value => {
            if(!value) return '';
            return (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(value);
          }
        }
      },
      series: [
        {
          name: 'Actual',
          data: data.map(d => d.actual),
          type: 'bar',
          itemStyle: {
            color: '#6eb3c4'
          },
          label: {
            show: cfg.unit === 'daily' ? false : true,
            position: 'top',
            formatter: d => (metricMeta.formatter2 || formatter)(d.value)
          },
          areaStyle: {opacity: 0.08},
        },
        hasTarget ? {
          name: 'Projected',
          data: data.map(d => d.target),
          type: 'bar',
          itemStyle: {
            color: '#f1a164'
          },
          label: {
            show: cfg.unit === 'daily' ? false : true,
            position: 'top',
            formatter: d => (metricMeta.formatter2 || formatter)(d.value)
          },
          areaStyle: {opacity: 0.08},
        }: undefined,
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
