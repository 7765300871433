import React from 'react';
import styled, {css} from 'styled-components';
import {
  MediumGray,
  FontSizeMedium,
  Mint1,
  BitterSweet1,
  Mint,
  BitterSweet,
  LightGray,
  LightGray1,
  DarkGray1,
  BlueJeans,
  BlueJeans1,
  MediumGray1,
  MediumGray2,
  DarkGray,
  Aqua1,
  Grass1,
  Aladin_Red_Dark,
  Aladin_Orange_Light,
  Aladin_Orange_Lighter, Aladin_Orange_Dark, Aladin_Blue_Dark, Aladin_Blue_Dark1, Aladin_BG, Aladin_BG_2, Aladin_BG_TV_1
} from "../app/StyleCommon";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  background-color: white;
  padding: 10px 20px;
  position: relative;
  padding-right: 200px;
  width: calc(100% - 40px);
  h3 {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 400px;
    // padding-left: 10px;
  }
  h4 {
    // padding-left: 10px;
    background: ${Aladin_BG};
    padding: 10px;
  }
  .navigation {
    position: fixed;
    top: 80px;
    right: 80px;
    width: 270px;
    padding-left: 40px;
    padding-bottom: 50px;
    margin-left: 100px;
    margin-top: 60px;
    list-style: none;
    min-height: calc(100vh - 300px);
    border-left: 1px solid #e6ecf1;
    @media screen and (max-width: 768px) {
      display: none;
    }
    li {
      padding: 10px 0;
      a {
        text-decoration: none;
        color: ${DarkGray1};
        &:hover {
          color: ${Grass1};
          text-decoration: underline;
        }
      }
      i {
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }
  .setting-wrapper {
    // padding-left: 10px;
    white-space: normal;
    overflow: hidden;
    width: calc(100% - 400px);
    @media screen and (max-width: 768px) {
      width: 95%;
    }
    h3 {
      display: flex;
      align-items: center;
      color: ${DarkGray1};
      margin-bottom: 30px;
      img {
        margin-right: 20px;
        border-radius: 50%;
        height: 48px;
        width: 48px;
      }
    }
    p {
      white-space: normal;
      width: 90%;
    }
    .avatar-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      .avatar {
          width: 128px;
        margin-right: 30px;
      }
    }
    .field-wrapper {
      margin: 20px 0 30px 0;
      white-space: nowrap;
      .field {
        display: flex;
        margin: 20px 0;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          box-sizing: border-box;
        }
        .success-msg {
          color: ${Grass1};
        }
        .error-msg {
          color: ${BitterSweet1};
        }
        >label {
          @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            text-align: left;
          }
          flex: none;
          display: inline-block;
          vertical-align: middle;
          width: 180px;
          max-width: 300px;
          text-align: right;
          margin-right: 30px;
          white-space: normal;
          font-weight: bold;
          font-size: 12px;
          color: ${DarkGray};
        }
        >input {
          @media screen and (max-width: 768px) {
            width: 90%;
            box-sizing: border-box;
            margin: 5px 10px;
          }
          color: #535353;
          font-size: ${FontSizeMedium};
          padding: 5px 10px;
          width: 60%;
          max-width: 700px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid ${MediumGray};
          outline: none;
          &[type=password] {
            font-size: 16px;
            padding: 5px 15px;
          }
          &:focus {
            border-bottom: 1px solid ${MediumGray1}
          }
          &:disabled {
            background: ${LightGray};
          }
        }
        .password-strength-wrapper {
          width: 60%;
          max-width: 700px;
          @media screen and (max-width: 768px) {
            width: 90%;
          }
          input {
            border-bottom: 1px solid ${MediumGray1};
            width: 100%;
            font-size: 16px;
          }
        }
      }
    }
  }
`;