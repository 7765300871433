import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG, Aladin_BG_1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  .field {
    display: flex;
    align-items: center;
    > label, >.label {
      width: 20%;
    }
    // border-bottom: 1px dotted ${Aladin_Orange_Light};
  }
  .select-box {
    width: auto;
    background-color: transparent;
    .toggler {
      width: 100%;
      border: none;
      background-color: transparent !important;
      //background: transparent !important;
      opacity: 1;
    }
  }
  input {
    background: transparent;
  }
  .linkage-plus, .id-filters {
    cursor: pointer;
    margin-left: 10px;
  }

  .linkage-list, .idfilter-list {
    width: 100%;

    .linkage-item, .filter-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .sub-item-btn {
        cursor: pointer;

        i {
          font-size: 16px;
        }
      }

      .sub-item-label {
        margin-left: 0px;
        min-width: 120px;
        font-size: 12px;

        i {
          font-size: 16px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }

  .moreInfo {
    .filter-item {
      > span , .sub-item-label {
        padding-right: 12px;
        width: calc(15% - 12px);
        margin-right: 0;
      }
      .select-box {
        padding-right: 12px;
        width: calc(35% - 12px);
        margin-right: 0;

        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }
    .toggler {
      padding: 0
      cursor: auto;
    }
  }
  .linkage-item {
    > .sub-item-label {
      padding-right: 12px;
      width: calc(15% - 12px);
      margin-right: 0;
    }
    >.select-box {
      padding-right: 12px;
      width: calc(35% - 12px);
      margin-right: 0;
      cursor: auto;
    }
    .equal {
      width: 15%;
    }
    > .last-item {
      width: 35%;
      padding-right: 0;
      text-align: right;
      cursor: auto;
    }
    .toggler {
      padding: 0;
      cursor: auto;
    }
  }

  .campaignList {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    > div {
      display: flex;
    }
    label {
      margin-bottom: 4px;
      margin-right: 12px;
      display: flex;
      width: 200px;
      i {
        font-size: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
      .toggler {
        padding: 0;
        font-size: 12px;
        margin-left: 4px;
        cursor: auto;
      }
    }
    ul {
      list-style-type: disc;
      margin-left: 38px;
      li {
        font-size: 12px;
        b {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;