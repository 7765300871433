import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const PageWrapper = styled.div`
  margin: 20px 50px;
  display: flex;
  >div {
    &:first-child {
      width: 60%;
      width: calc(60% - 50px);
      margin-right: 50px;
    }
    &:last-child {
      width: 40%;
    }

    flex-wrap: nowrap;
  }
  .data-table {
    max-height: 500px;
    overflow: auto;
  }
  .dropzone-wrapper {
  }
  .actions {
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: flex-start;
  }
`;

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  >div {
    overflow: hidden;
    cursor: pointer;
    min-height: 450px !important;
    height: auto !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #818181;
    background-color: rgba(0,0,0,.01);
    border-color: rgb(229, 222, 222) !important;
    position: relative;
    &:hover {
      background-color: ${LightGray1};
    }
    p {
      max-width: 280px;
      text-align: center;
    }
    .droped-file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-all;
      i {
        margin-right: 10px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
      }
    }
    .uploadIcon {
      margin-right: 10px;
    }
  }
`;