import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {AttrOverview, VisCanvas, Wrapper} from './interactive.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, TabContainer, Loader, ProgressBar} from "../uikit";
import qs from "qs";
import DndMixin from "../charts/dndMixin";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {generateDashboardContext} from "../charts/dndMixin.client";
import {AlertBox} from "../campaigns/build.style";
import {Link} from "react-router-dom";

export default withConsumer(class extends DndMixin {

  constructor(props) {
    super(props);
    const urlParams = qs.parse(window.location.search.slice(1));
    this.state = {
      mode: 'display',
      loadingMap: {},
      showChartExportBtn: true,
      code: urlParams.code
    }
  }

  componentDidMount() {
    super.componentDidMount();
  }
  componentDidUpdate() {
    super.componentDidUpdate();
  }

  buildQuery() {
    const urlParams = qs.parse(window.location.search.slice(1));
    return {id: urlParams.id, code: urlParams.code};
  }

  render() {
    const {report, code, data, alerts, error, loading} = this.state;
    const {session = {}} = this.props.appState || {};
    const share = (report?.shares || []).find(s => s.code == code) || {};
    const reportQuery = this.buildQuery();

    return (
      <MainLayout activeItem="reporting" breadcrumb={_.compact([
        {path: '/reporting', label: 'My Reports'},
        {path: '/reporting/readonly', label: 'View Report'}
      ])} loading={loading}>
      <Wrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/reportView`}
            params={reportQuery}
            successHandler={data => {
              let clientData = data.metadata.clientData || [];
              this.setState({
                data: data,
                clientData: clientData,
                alerts: data.alerts,
                context: generateDashboardContext(data)
              });
              if(data.report) {
                this.setState({
                  report: data.report,
                  reportData: data.reportData,
                  dashboard: {overall: data.report}
                }, () => {
                  window.exportReady = true;
                });
              }
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!loading && <Loader/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          (data && data.notAllowed) && <ErrorMessage message={'Not Allowed'}/>
        }
        {
          !!report &&
          <>
            <div className="page-header no-print">
              <strong className="title">
                <div>{report.name}</div>
                <p>Shared by {share.shared_by || report.creator} {moment().add(-34, 'minute').fromNow()}</p>
              </strong>
              <div className="setting-controls no-print">
                <StyledDropdown
                  closeOnClick={true}
                  toggler={<span className="dropdown-toggler"><span className="material-symbols-outlined">download</span></span>}>
                  <div className={`menu-item`}>
                    {/*<a href={`/api/downloadReportExcel?id=${report.id}`}>*/}
                    <a onClick={e => this.downloadExcel(reportQuery.code)}>
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download as Excel
                    </a>
                  </div>
                  <div className={`menu-item`}>
                    <a onClick={e => this.downloadPDF(reportQuery.code)}>
                      <i className="material-symbols-outlined">picture_as_pdf</i> Download as PDF Report
                    </a>
                  </div>
                </StyledDropdown>
              </div>
            </div>
            <div className="grid-wrapper">
              {this.renderDashboard('overall', report, {
                reportHeader: report.name,
                alertHeader: (alerts || []).map(alert => {
                  return (
                    <AlertBox key={alert.id}>
                      <div className="title" dangerouslySetInnerHTML={{__html: alert.title}}></div>
                      <div className="desc" dangerouslySetInnerHTML={{__html: alert.details.replace('Please change campaign settings according to the change.', '').replace('You can change the MSQ If there is a new MSQ is created for this campaign', '')}}></div>
                    </AlertBox>
                  )
                })
              })}
            </div>
          </>
        }
      </Wrapper>
      </MainLayout>
    )
  }
})
