import React from 'react';
import {withConsumer} from '../app/ApplicationContext';
import {Link} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper} from './login.style';
import {SubmitBtn} from './login.style';
import classNames from "classnames";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      password: '',
      rememberMe: localStorage && localStorage.getItem('rememberMe') === 'YES'
    };
  }

  login = () => {
    let {userId, password, rememberMe} = this.state;
    userId = userId || this.refs.userInput.value;
    password = password || this.refs.passwordInput.value;
    if (!userId || !password) {
      return this.setState({error: 'Please input your account and password'});
    }
    this.setState({error: null});
    this.props.login(userId, password, rememberMe);
    // window.location.href = '/';
  }

  changeRememberMe(rememberMe) {
    this.setState({rememberMe: rememberMe});
    localStorage && localStorage.setItem('rememberMe', rememberMe ? 'YES' : 'NO');
  }

  render() {
    const {error: appError, loading, session} = this.props.appState;
    const {error, userId, password, rememberMe, formID} = this.state;
    if (session) {
      if(window.logout) {
        delete window.logout;
        return <Redirect to={{pathname: '/'}}/>;
      } else {
        const {from} = this.props?.history?.location?.state || {from: {pathname: '/'}}
        return <Redirect to={from}/>;
      }
    }
    let hour = new Date().getHours();
    let daypart = (hour >= 5 && hour < 11) ? 'morning' : ((hour >= 11 && hour < 18) ? 'afternoon' : 'evening')
    return (
      <LoginWrapper className={classNames(daypart)}>
        <form
          formID={formID}
          onSubmit={e => {
            e.preventDefault();
            this.login(e)
          }}>
          <div className="form-title">
            <img src="/img/login/logo_white.png" />
            {/*Login to Aladdin Analytics Dashboard*/}
          </div>
          {/*<div className="user-icon material-symbols-outlined">person</div>*/}
          {/*<h3>Sign In</h3>*/}
          {
            !!error && <div className="error-msg">{error}</div>
          }
          {
            !!appError &&
            <div className="error-msg">{appError || 'Incorrect User ID and/or Password'}</div>
          }
          {
            userId === 'demo' &&
            <div className="error-msg">{'Please use your company email as user name to login (password same as demo)'}</div>
          }
          <div className="field">
            <input ref="userInput"
                   value={userId}
                   type="text"
                   placeholder="Email"
                   onChange={e => {
                     this.setState({userId: e.currentTarget.value});
                   }}/>
          </div>
          <div className="field">
            <input ref="passwordInput"
                   value={password}
                   type="password"
                   placeholder="Password"
                   onChange={e => this.setState({password: e.currentTarget.value})}/>
          </div>
          <div className="field">
            <label>
              {/*<input type="checkbox"*/}
              {/*       checked={rememberMe}*/}
              {/*       onChange={e => this.changeRememberMe(e.target.checked)}/> Stay Signed In*/}
            </label>
          </div>

          <SubmitBtn className={classNames(daypart)}>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Sign In
          </SubmitBtn>
          <div className="bottom-links">
            {/*<Link to={'/forget_password'}>Forgot Password</Link>*/}
            {/*<span className="sep">or</span>*/}
            <Link to={'/forget_password'}>Forgot your password?</Link>
          </div>
        </form>

        <div className={"site-footer"}>
          <a href="https://www.mediacorp.sg/terms-conditions" target="_blank">Terms & Conditions</a>
          <a href="https://www.mediacorp.sg/privacy-policy" target="_blank">Privacy Policy</a>
          <a href="https://mediacorp.vulnerability-disclosure.com" target="_blank">Report Vulnerability</a>
        </div>
      </LoginWrapper>
    );
  }
})
