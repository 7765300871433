import React, {PureComponent} from 'react';
import {Spinner1, SplashWrapper} from './index.style';

export default class extends PureComponent {
  render() {
    return (
      <SplashWrapper>
        <Spinner1>
          <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Spinner1>
      </SplashWrapper>
    );
  }
}