import React, {Component} from 'react';
import moment from 'moment';
import {findDOMNode} from 'react-dom';
import {withConsumer} from '../app/ApplicationContext';
import {NotificationWrapper, NotificationMenu, NotificationToggler, MenuItem, EmptyBlock} from './notification.style'
import Btn from "../uikit/btn/index";
import {post} from "../utils/request";
import {BitterSweet, DarkGray1} from "../app/StyleCommon";
import {CondirmDialog} from "../uikit";
import StreamTable from "../dashboard/stream";

let syncingMessage = false, syncTimer;
export default withConsumer(class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      messages: []
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
    this.syncNotification();
    if(syncTimer) {
      clearInterval(syncTimer);
    }
    syncTimer = setInterval(() => {
      this.syncNotification();
    }, 10000);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }

  render() {
    const {session = {}} = this.props.appState || {};
    const {className} = this.props;
    let {messages = [], error, loading} = this.state;
    const newMessages = (messages || []).filter(m => m.isNew);
    return (
      <NotificationWrapper className={className}>
        <NotificationToggler
          className="toggler"
          active={this.state.active}
          onClick={e => this.handleToggle(e)}>
          {/*<i className="fa fa-bell-o" aria-hidden="true"></i>*/}
          <i className="material-symbols-outlined">notifications</i>
          {
            !!newMessages.length &&
            <span className="count">{newMessages.length >= 10 ? '10+' : newMessages.length}</span>
          }
        </NotificationToggler>
        <NotificationMenu className="menu" active={this.state.active}>
          <h3>
            Notifications
            {
              !!messages.length &&
              <Btn type="primary" onClick={e => this.cleanNotifications()}>Clear</Btn>
            }

          </h3>
          {!!error &&<div className="error">{error}</div>}
          {
            !messages.length &&
            <EmptyBlock>
              <div>
                <span className="material-symbols-outlined">notifications</span>
                <br/>
                <br/>
                <span style={{color: DarkGray1}}>All caught up!</span>
              </div>
            </EmptyBlock>
          }

          {messages.map(m => (
            <MenuItem isNew={m.isNew === 'YES'} key={m.id}>
              <div className="noti-header">
                {
                  m.level === 'warning' &&
                  <i className="fa fa-solid fa-circle" style={{color: BitterSweet}}></i>
                }
                {
                  m.level === 'info' &&
                  <i className="fa fa-solid fa-circle"></i>
                }
                {m.type.replace(/[-_]/g, ' ')}
              </div>
              <div className="noti-body">
                <a href={m.click_through_link} onClick={e => {
                  if (m.title) {
                    this.beforeClose();
                  }
                }}>
                  <span dangerouslySetInnerHTML={{__html: m.details}}/>
                  {m.status === 'NEED_RESOLVE' && <span className="tag yellow">Need Resolve</span>}
                  {m.status === 'RESOLVED' && <span className="tag">Resolved</span>}
                </a>
              </div>
              <div className="noti-footer">
                {/*<span>by <strong>{m.userName || m.userId}</strong></span> */}
                <span></span>
                <span>{moment().from(moment(m.date))}</span>
              </div>
              <a className="del" onClick={e => this.deleteNotification(m.id)}>x</a>
            </MenuItem>
          ))}
          {
            messages.length >= 5 &&
            <div className="load-more" onClick={e => this.openStream()}>
              <span>Load More</span> <i className="material-symbols-outlined">keyboard_double_arrow_right</i>
            </div>
          }
        </NotificationMenu>
      </NotificationWrapper>
    );
  }

  openStream() {
    const {data} = this.state;
    const {alerts = []} = data || {};
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: 'All Notifications',
      width: '900px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <StreamTable />
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  handleToggle(e) {
    const {active} = this.state;
    //when switch from active to hidden, clear 'isNew' message mark;
    if (active) {
      this.beforeClose();
    }
    this.setState({active: !active});
  }

  beforeClose() {
    let {messages = []} = this.state;
    const newMessages = (messages || []).filter(m => m.isNew);
    if (newMessages.length) {
      this.readNotifications(newMessages.map(m => m.id))
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.beforeClose();
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        console.error(e);
      }
    }
  }

  syncNotification() {
    if(syncingMessage) {
      return;
    } else {
      syncingMessage = true;
    }
    return post('/api/queryNotifications', {}).then(data => {
      this.setState({messages: data.alerts});
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      syncingMessage = false;
    })
  }

  cleanNotifications() {
    this.setState({active: false})
    return post('/api/clearNotifications', {}).then(data => {
      this.setState({messages: []});
    }).catch(e => {
      console.error(e);
    })
  }

  readNotifications(ids) {
    return post('/api/readNotifications', {ids}).then(data => {
      this.setState({messages: []});
    }).catch(e => {
      console.error(e);
    })
  }

  deleteNotification(id) {
    const {messages} = this.state;
    return post('/api/deleteNotification', {id}).then(data => {
      this.setState({messages: messages.filter(m => m.id !== id)});
    }).catch(e => {
      console.error(e);
    })
  }

})
