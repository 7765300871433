import styled, {css} from 'styled-components';

export const SortableWrapper = tag => styled[tag]`
  .sortable-item-hovering {
    position: relative;
    background-color: rgba(255,255,255, .5);
    &:before {
      content: 'Drop here';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255, .5);
      z-index: 1;
      border: 2px dashed #818181;
      border-radius: 5px;
    }
   }
`;