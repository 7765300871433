import React from 'react';
import styled, {css} from 'styled-components';

export const ErrorMessageWrapper = styled.div`
  color: #E9573F;
  font-weight: 600;
  margin-left: 15px;
  margin: 10px 0;
  padding: 15px 20px;
  background: #fefaf9;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f1f2f3;
  i {
    margin-right: 10px;
  }
`
export const ErrorMessage = props => {
  let errorMessage = props.message;
  if(errorMessage === 'Internal Server Error') {
    errorMessage = 'Internal Server Error, Please try again later.'
  }
  return (
    <ErrorMessageWrapper className="error-msg" {...props}>
      {/*<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>*/}
      {errorMessage}
    </ErrorMessageWrapper>
  )

}
