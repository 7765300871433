import React from 'react';

export default [
  {
    leadingText: 'Manage',
    key: 'home',
    label: 'Dashboard',
    icon: <i className="material-symbols-outlined">dashboard</i>,
    href: '/',
    hiddenFunc: session => (session.rights || []).indexOf('View Home Dashboard') < 0,
  },
  {
    leadingTextFunc: session => (session.rights || []).indexOf('View Home Dashboard') < 0 ? 'Manage' : undefined,
    key: 'campaign',
    label: 'Campaigns',
    icon: <i className="material-symbols-outlined">campaign</i>,
    // icon: <i className="material-symbols-outlined">inbox_customize</i>,
    href: '/campaigns',
    hiddenFunc: session => (session.rights|| []).indexOf('View Campaign List Page') < 0,
  },
  {
    key: 'reporting',
    label: 'Reporting',
    icon: <i className="material-symbols-outlined">insights</i>,
    href: '/reporting',
    hiddenFunc: session => (session.rights|| []).indexOf('View Reports List') < 0,
  },
  {
    leadingText: 'Settings',
    key: 'profile',
    label: 'My Profile',
    icon: <i className="material-symbols-outlined">settings_account_box</i>,
    hiddenFunc: session => (session.rights|| []).indexOf('View My Profile') < 0,
    href: '/profile',
  },
  {
    key: 'admin',
    label: 'Admin Settings',
    icon: <i className="material-symbols-outlined">settings_suggest</i>,
    href: '/admin',
    hiddenFunc: session => (session.rights|| []).indexOf('View Admin Settings') < 0,
  },
  {
    key: 'logout',
    label: 'Sign Out',
    icon: <i className="fa fa-sign-out" aria-hidden="true"></i>,
    href: '/login'
  },
]