import {injectGlobal, createGlobalStyle, css} from 'styled-components';
import {DarkGray1} from "./StyleCommon";
import {paginationStyles} from "./externalStyles/rc-pagination-css.style";
import {tooltipStyles} from "./externalStyles/tooltip-css.style";
import {datepickerStyles} from "./externalStyles/react-datepicker-css.style";
import {editorStyles} from "../uikit/editor/editor-css.style";

// let isDemoLaptop = window.localStorage.getItem('laptopUserId') === 'Mike.Lin@mediacorp.com.sg';
// isDemoLaptop = isDemoLaptop || (window.innerWidth > 1700);
// ${isDemoLaptop && css`filter: brightness(95.5%);`}
let isDemoLaptop = false; //window.innerWidth === 1920; //BOARD ROOM tv
export const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: white;
    margin: 0;
    overflow-x: hidden;
    min-height: 100vh;
  }
  html {
    ${isDemoLaptop && css`filter: brightness(91%);`}
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "FKGroteskNeue", "Open Sans", "Lato", "Comfortaa", "Montserrat", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    background-color: white;
    color: ${DarkGray1};
  }

  .material-symbols-outlined {
    transform: translateX(-10000px);
  }
  input, button {
    font-family: "FKGroteskNeue", "Open Sans", Helvetica, Arial, sans-serif;
  }

  ul, ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  a,
  a:active,
  a:visited {
    color: ${DarkGray1};
    font-style: normal;
  }

  .material-symbols-outlined {
    //font-size: 18px;
    //vertical-align: center;
  }

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: all 300ms ease-in;
  }

  .fade-appear {
    opacity: 0.01;
    filter: blur(3px);
  }

  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: all .5s ease-in;
    filter: blur(0);
  }

  .row-fade-appear {
    opacity: 0.01;
  }

  .row-fade-appear.row-fade-appear-active {
    opacity: 1;
    transition: all .5s ease-in;
  }

  //@media print {
  //  .pdf_no_break {
  //    //page-break-before: always;
  //    page-break-inside: avoid
  //  }
  //
  //  .pdf_page {
  //    page-break-after: always;
  //  }
  //
  //  .pdf_no_break_after {
  //    page-break-after: avoid;
  //  }
  //
  //  .no-print {
  //    display: none !important;
  //  }
  //}
  //* {
  //  :hover::-webkit-scrollbar-thumb {
  //    background-color: #f6ce68;
  //  }
  //
  //  ::-webkit-scrollbar {
  //    width: 12px;
  //    height: 15px;
  //    z-index: 10000000;
  //  }
  //
  //  ::-webkit-scrollbar-thumb {
  //    background-color: #fafbfc;
  //  }
  //}

  ${paginationStyles}
  ${tooltipStyles}
  ${datepickerStyles}
  ${editorStyles}
`;
