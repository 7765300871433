import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_BG,
  Aladin_Blue_Dark,
  Aladin_Blue_Dark1,
  Aladin_Blue_Light, Aladin_Orange_Dark,
  Aladin_Orange_Light, Aladin_Purple_Dark, Aladin_Purple_Light, Aladin_Red_Dark, DarkGray,
  Grass1,
  MediumGray1
} from "../app/StyleCommon";
import Table from "../uikit/table";
import {BarWrapper, TableWrapper} from "./index.style";
import {
  dimensionLabelMap,
  dimensionValueMap,
  metricMap,
  metricMetaData,
  platformLabelMap,
  siteLabelMap
} from "../utils/metadata";
import {PaginationStyled} from "../uikit/pagination/index.style";
import {ListFooter} from "../campaigns/index.style";
import {getContextChartScale} from "./util";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef(); // main chart
    this.containerRef1 = React.createRef(); //second chart / table
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  renderChart() {
    this.renderPie();
  }
  renderPie() {
    let {data, cfg, chartStateChanged, currency} = this.props;
    const {dimension, metric, type = 'bar'} = cfg;
    let metricMeta = metricMetaData.find(m => m.key === metric) || {};
    let {main, secondary, selected} = data || {}
    main = _.cloneDeep(main);
    main.forEach(d => {
      if(d.label) {
        d.label = d.label + ` (${d.key})`;
      } else {
        d.label = siteLabelMap[d.key] || d.key;
      }
    })

    main = _.sortBy(main, _.property('value')).reverse();
    const container = this.containerRef.current;
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    let height = Math.max(this.containerRef.current.parentElement.offsetHeight || 0, 260);
    if(main.length < 10) {
      height = main.length * 30 + 35;
    } else {
      height = 10 * 30 + 35;
    }
    height = Math.max(height, 260);
    if(main.length === 1) {
      height =  150;
    }
    echarts.dispose(container);
    var myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    var sorted = type === 'bar' ? main.reverse() : main;
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      color: ['#f4c168', '#F66D44', '#E6F69D', '#AADEA7', '#64C2A6', '#2D87BB', '#3F9F9F', '#A2C3DB', "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      title: {
        top: -3,
        text: `${metricMap[metric]} by ${dimensionLabelMap[dimension]}`, textStyle: {
          fontSize: '12px',
        }},
      tooltip: {
        trigger: 'axis',
        position: 'top',
        confine: true,
        extraCssText: 'max-width:auto; white-space:pre-wrap;',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value, i) => {
          return getMetricFormatter(metricMeta, currency)(value)
        },
      },
      legend: {
        left: 0,
        top: 30,
        orient: 'vertical',
        type: 'scroll',
        pageIconSize: '8',
        pageIconColor: Aladin_Purple_Dark,
        pageTextStyle: {
          lineHeight: 56,
          color: Aladin_Purple_Dark
        },
        itemStyle: {
          fontSize: 10,
        },
        tooltip: {
          show: true,
          position: function (point, params, dom, rect, size) {
            // fixed at top
            return ['50%', point[1]];
          }
        },
        color: DarkGray,
        overflow: 'ellipsis',
        fontSize: 12,
        formatter: function (name) {//use formatter to check if length of string is greater than 20 and maintain its width.
          let item = main.find(d => d.key === name) || {};
          let str = (item.label || (platformLabelMap[name] || name)) + ` (${getMetricFormatter(metricMeta, currency)(item.value)})`;
          if(str.length > 18){
            return str.substring(0, 18) + '...';
          }
          return str;
        }
      },
      grid: {
        top: '30',
        left: main.length > 12 ? 35 : 15,
        right: 50,
        bottom: '10',
        containLabel: true
      },
      dataZoom: type === 'bar' ? [
        {
          show: main.length > 9,
          id: 'dataZoomY',
          type: 'slider',
          filterMode: 'none',
          left: 0,
          yAxisIndex: [0],
          start: Math.min(100 * (1 - 9 / main.length), 100),
          end: 100,
          zoomLock: false,
          brushSelect: false,
          borderColor: Aladin_BG,
          backgroundColor: Aladin_BG,
          fillerColor: 'rgba(114,223,243,0.16)',
          textStyle: { color: 'transparent'},
          handleStyle: {
            // color: '#f3d4a9',
            color: 'transparent',
            borderColor : 'transparent',
          },
          moveHandleStyle: {
            color: 'transparent',
            borderColor : 'white',
          },
          brushStyle: {
          },
          dataBackground : {
            lineStyle: {
              color: '#8fd3f4',
            },
            areaStyle: {
              color: '#8fd3f4',
            }
          },
          selectedDataBackground : {
            lineStyle: {
              color: '#4f8f98',
            },
            areaStyle: {
              color: '#4f8f98',
            }
          }
        }
      ]: undefined,
      xAxis: type === 'bar' ?  {
        type: 'value',
        show: false,
        boundaryGap: [0, 0.01],
        splitNumber: 2,
        offset: 2,
        axisLabel: {
          formatter: metricMeta.formatter2 || metricMeta.formatter
        },
      }: undefined,
      yAxis: type === 'bar' ? {
        type: 'category',
        data: main.map(d => d.label || d.key),
        axisLine: {
          onZero: false,
          lineStyle: {
            color: '#6eb3c4',
            fontWeight: 'bold'
          }
        },
        axisLabel: {
          color: DarkGray,
          overflow: 'ellipsis',
          fontSize: 12,
          formatter: function (value) {
            let line = main.find(d => d.key === value) || {};
            value = line.label || value;
            if(value.length > 18){
              return value.substring(0, 15) + '...';
            }
            return value;
          }
        },
      } : undefined,
      series: [
        {
          type: type || 'bar',
          data: sorted.map(d => ({
            ...d,
            name: d.label || d.key,
            value: d.value,
            itemStyle: (data.selected && data.selected !== d.key) ? {
              color: '#6eb3c4',
              // borderColor: 'gray',
              opacity: 0.6,
              // color: 'gray'
            } : undefined
          })),
          radius: '90%',
          left: '50%',
          top: '-50%',
          selectedMode: 'single',
          selectedOffset: 10,
          animation: false,
          barWidth: 16,
          barGap: 12,
          itemStyle: {
            borderRadius: type === 'bar' ? 0 : 0,
            color: type === 'bar' ? '#6eb3c4' : undefined
          },
          label: type === 'bar' ? {
            show: true,
            position: 'right',
            formatter: d => (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(d.value),
          } : {
            position: 'inner',
            fontSize: 10,
            formatter: d => {
              let line = main.find(d => d.key === d.name || d.label === d.name) || {};
              let str = line.label || platformLabelMap[d.name] || d.name || '';

              if(str.length > 12){
                return str.substring(0, 12) + '...';
              }
              return str;
            }
          },
          labelLine: {
            show: false
          },
        }
      ]
    };

    myChart.setOption(option);
    if(selected) {
      let dataIndex = main.findIndex(d => d.key === selected);
      if(dataIndex >= 0) {
        myChart.dispatchAction({type:'select',seriesIndex: 0,dataIndex: dataIndex})
      }
    }

    myChart.on('legendselectchanged', (e) => {
      myChart.dispatchAction({
        type: 'legendSelect',
        name: e.name
      });
      let dataIndex = main.findIndex(d => d.key ===e.name);
      myChart.dispatchAction({type:'select',seriesIndex: 0,dataIndex: dataIndex})
    })
    myChart.on('selectchanged', (e) => {
      let newSelected = null;
      if(e.selected && e.selected[0]) {
        let dataIndex = e.selected[0].dataIndex[0];
        newSelected = (main[dataIndex] || {}).key
        if(newSelected && newSelected !== data.selected) {
          data.selected = newSelected;
          chartStateChanged({selected: newSelected});
        }
      } else {
        data.selected = newSelected;
        chartStateChanged({selected: newSelected});
      }

      return true;
    });
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }

  render() {
    const {data, cfg, currency} = this.props;
    let {main = [], secondary = [], selected} = data || {}
    const {metric, dimension, dimension2, metrics = [], customSortedColumns} = cfg;
    const {page = 1, pageSize = 8, sortBy = dimension2, sortDirection = 'asc'} = cfg;
    let sorted = secondary;
    if(sortBy && sortDirection) {
      if(sortBy === 'timebelt') {
        sorted = _.sortBy(sorted, d => {
          let [hour, range]  = (d.key || '').split(' ');
          if(hour && range) {
            return Number(hour) + {AM: 0, PM: 12}[range];
          } else {
            return -1;
          }
        });
      } else {
        sorted = _.sortBy(sorted, _.property(sortBy));
      }

      if(sortDirection === 'desc') {
        sorted = sorted.reverse();
      }
    }
    const columns = [
      // {
      //   key: 'index',
      //   label: '',
      //   align: 'center',
      //   sortable: true,
      //   style: {maxWidth: '20px'},
      //   renderer: (d, i) => (i + 1)
      // },
      {
        key: dimension2,
        label: (dimensionLabelMap[dimension2] || dimension2) + ` ${selected ? ` (${selected})` : `(all ${dimensionLabelMap[dimension]}s)`}`,
        align: 'left',
        sortable: true,
        renderer: d => {
          let labelMap = dimensionValueMap || {};
          return <div className="table-nav-column">{d.label || labelMap[d.key] || d.key}</div>
        }
      },
      ...(metrics.map(metric => {
        let metricMeta = metricMetaData.find(d => d.key === metric);
        return {
          key: metric,
          label: metricMap[metric],
          align: 'right',
          sortable: true,
          renderer: d => {
            return (
              <div key={metric}>
                {getMetricFormatter(metricMeta, currency)(d[metric])}
              </div>
            )
          }
        }
      }))
    ];
    const visibleColumnKeys = columns.map(d => d.key);
    const columnsSorted = _.sortBy(columns, c => {
      return (customSortedColumns || []).indexOf(c.key);
    });
    return (
      <ChartWrapper className="chart">
        <div>
          <div ref={this.containerRef}/>
        </div>
        <div>
          <TableWrapper className="data-table">
            <Table
              rowSortable={true}
              onReplacePosition={(colum1, colum2, type) => {
                let newCustomSortedColumns = customSortedColumns || _.cloneDeep(visibleColumnKeys);
                newCustomSortedColumns = _.sortBy(visibleColumnKeys, k => newCustomSortedColumns.indexOf(k));
                let x = newCustomSortedColumns.indexOf(colum1);
                let y = newCustomSortedColumns.indexOf(colum2);
                newCustomSortedColumns[x] = null;
                newCustomSortedColumns.splice(y + (x < y ? 1 : 0), 0, colum1); // insert position.
                newCustomSortedColumns = _.compact(newCustomSortedColumns);
                this.props.chartStateChanged({customSortedColumns: newCustomSortedColumns}, true);
              }}
              columns={columnsSorted}
              rows={sorted.slice((page-1) * pageSize, page * pageSize)}
              sort={{sortColumn: sortBy, direction: sortDirection}}
              sortChange={sortBy => {
                this.props.chartStateChanged({sortBy: sortBy}, true);
              }}
              directionChange={direction => {
                this.props.chartStateChanged({sortDirection: direction}, true);
              }}
              noDataText={'No data found'}
            />
          </TableWrapper>
          <ListFooter>
            <span className="info">
                {/*{secondary.length} items.*/}
            </span>
            <PaginationStyled
              onChange={p => this.props.chartStateChanged({page: p}, true)}
              current={page}
              pageSize={pageSize}
              total={secondary.length}
              hideOnSinglePage={false}/>

          </ListFooter>
        </div>
      </ChartWrapper>
    )
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px;
  box-sizing: border-box;
  >div:nth-child(1) {
    width: 35%;
    width: calc(35% - 10px);
    min-width: 200px;
    flex-grow: 1;
    margin-top: 10px;
    align-self: stretch;
    margin-right: 10px;
  }
  >div:nth-child(2) {
    width: 65%;
    min-width: 200px;
    flex-grow: 1;
  }
  .data-table {
    border-left: 1px dotted #6eb3c4;
    td {padding: 8px 5px}
    td.right {padding-right: 20px}
    .table-nav-column {
      white-space: normal;
      word-break: break-word;
      max-width: 400px;
    }
  }
`;
