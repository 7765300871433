import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {Aqua1, DarkGray} from "../../app/StyleCommon";

export const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  >li {
    outline: none;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border: none;
    background: none;
    &:hover {
      text-decoration: underline;
      a {
        color: #4f8f98;
      }
    }
    a {
      font-size: 14px;
      line-height: 24px;
      color: ${DarkGray};
    }
    &.rc-pagination-item {
      width: auto
    }
    &.rc-pagination-item-active {
      a {
        color: #4f8f98;
      }
    }
  }
`;
