import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  margin-top: 20px;
  background: transparent;
  border-radius: 10px;
  padding: 10px;

  .field {
    display: flex;
    align-items: center;

    > label, > .label {
      width: 20%;
    }
  }

  .select-box {
    .toggler {
      border: none;
      background: transparent !important;
      opacity: 1;
    }
  }

  input {
    background: transparent;
  }

  .platform-cfg {
    width: 100%;
      //background: ${LightGray};
    padding: 10px 5px;
    display: flex;
    align-items: center;

    &.header {
      text-align: left;
      font-weight: bold;
      border-bottom: 1px solid ${MediumGray};

      .ta {
        width: 22%;
      }

      .budget, .ta {
        border-right: 1px solid ${MediumGray};
      }

      .goal {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          font-size: 16px;
        }
      }
    }

    input.editor {
      border: none;
      border-bottom: 1px solid ${MediumGray1};
      padding: 5px 5px;
      text-align: right;
      outline: none;
      box-sizing: border-box;
      width: 100%;
    }

    > .top {
      align-self: flex-start;
    }

    .budget {
      width: 25%;
      //min-width: 80px;
      flex: none;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
      box-sizing: border-box;
      position: relative;

      i {
        font-size: 16px;
        position: absolute;
        left: 10px;
        top: 3px;
      }

      .budget-editor {
      }
    }

    .ta {
      width: 30%;
      box-sizing: border-box;
      flex: none;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 10px;
    }

    .goal {
      width: 40%;
      flex-grow: 1;
        // background: ${Aladin_BG};
      padding: 0 10px;

      .goal-item {
        display: flex;
        align-items: center;
        padding: 5px 0;
      }

      .sep {
        margin: 0 10px;
      }

      a {
        cursor: pointer;
        margin-left: 10px;

        i {
          font-size: 18px;
        }

        &:hover {
          color: ${Grass1};
        }
      }
    }
  }
`;