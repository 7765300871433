import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  MediacorpPrimary,
  Levander,
  Aladin_BG_1,
  Aladin_BG,
  Aladin_Orange_Light
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const CreateWrapper = styled.div`
  padding: 20px;
  .field {
    margin-bottom: 30px;
    label {
      display: block;
    }
    input, textarea {
      box-sizing: border-box;
      width: 100%;
      padding: 3px 10px;
      border: none;
      border-bottom: 1px solid ${MediumGray};
      outline: none;
      &:focus {
        border-color: ${Aladin_Orange_Light};
      }
    }
    textarea {
      height: 90px;
      border: 1px solid ${MediumGray};
    }
    .error-msg {
      color: #FC6E51;
      font-size: 12px;
      font-weight: bold;
      margin-top: 4px;
    }
  }
  .actions {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
