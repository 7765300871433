import React, { useEffect, useState } from "react";
import { FormulaEditor } from "./FormulaEditor";
// import "./Example.scss";
import { EvaluateTokens, GetKeysRefFromTokens, GetReferencesFromTokens } from "./Evaluator";
import { useForm } from "./useForm";
// import "./styles.css";
import {Wrapper} from './formulaParser.style';

const FormulaParser = ({input, disabled, dValue, onFormulaChange, onTokensChange, onCursorChange}) => {
  // console.log("dValue", dValue)
  // const [formula, setFormula] = useState(
  //   `"Hello " & [First Name] & "! " & Round(1.2 + [Some Number])`
  // );
  const [formula, setFormula] = useState('');
  const [errors, setErrors] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [inputValues, setInputValue] = useForm(input);

  const references = GetReferencesFromTokens(tokens);
  const colkeys = GetKeysRefFromTokens(references);
  const result = EvaluateTokens(tokens, inputValues);

  useEffect(() => {
    if(dValue === "") {
      setFormula('')
    }
    setFormula(dValue)
  }, [dValue])

  useEffect(() => {
    onFormulaChange(formula, tokens);
  }, [formula, tokens])

  return (
    <Wrapper>
      <FormulaEditor
        disabled={disabled}
        value={formula}
        onChange={e => setFormula(e)}
        onErrorsChanged={setErrors}
        onTokensChanged={setTokens}
        onCursorChange={onCursorChange}
      />
      {errors.map((error, i) => (
        <div key={i} className="error">
          {error.error}
        </div>
      ))}
    </Wrapper>
  );
}


export default FormulaParser;