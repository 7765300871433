import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  Mint,
  GrapeFruit,
  Aladin_Red_Dark,
  Aladin_Purple_Dark,
  Aladin_Orange_Light,
  Aladin_BG,
  Aladin_Orange_Dark,
  Aladin_Blue_Dark,
  Aladin_Gray_Dark,
  Aladin_Orange_Lighter,
  Aladin_BG_1,
  Aladin_BG_2,
  Aladin_BG_TV_1
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';
import {SelectStyled} from "./select.style";

export const Wrapper = styled.div`
  padding: 10px 20px;
  .campaign-table {
    border: none;
    tbody td {
      vertical-align: top;
    }
    a.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: .5;
    }

    .tag {
      margin: 0 10px;
      padding: 0 5px;
      text-decoration: none !important;
      background-color: #f5e5c9;
      border: 1px solid #f8e0b7;
      border-radius: 5px;
      font-size: 10px;

    }
    .desc {
      color: ${MediumGray1};
    }
  }

  thead th {
    background: white;
    border: none;
    white-space: nowrap;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid ${MediumGray};
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 20px 0;
  .setting-controls {
      vertical-align: center;
      .action-item {
        cursor: pointer;
        margin-left: 10px;
        display: inline-flex;
        align-items: center;
        &:hover {
          color: ${Grass1};
        }
      }
  }
  button {
    background-image: linear-gradient(20deg,gold,#ff875f,gold) !important;
    box-shadow: 0px 1px 2px sandybrown;
  }
`
export const KeyMetrics = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 10px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    margin-right: 0;
  }

  .metric-item {
    margin: 10px 20px 15px 0;
    padding: 4px 40px 3px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-width: 120px;
    height: 18px;
    cursor: pointer;
    //background: linear-gradient(120deg, #f6c57e 0%, #f6d37d 100%);
    background: none;
    //border: 1px solid #e6f1ff;
    //border-bottom: 1px solid #f1cb6d;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .1), 1px 1px 3px rgba(0, 0, 0, .05);
    background-color: rgba(241, 242, 243, 0.77);
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      background-color: rgba(244, 193, 104, 0.2);
    }

    &:nth-child(1) .value {
        //background: linear-gradient(120deg, #fe5196 0%, ${Aladin_Orange_Light} 100%);
      background: #6eb3c4;
      color: white;
    }

    &:nth-child(2) .value {
      //background: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
      background: #40916C;
      color: white;
    }

    &:nth-child(3) .value {
      //background: linear-gradient(120deg, #fd8bd9 0%, #a85efa 100%);
      background: #9B72CF;
      color: white;
    }

    &:nth-child(4) .value {
      //background: linear-gradient(120deg, #fd8bd9 0%, #f09819 100%);
      background: #ec685c;
      color: white;
    }

    &:nth-child(5) .value {
      background: linear-gradient(120deg, #f09819 0%, #ff5858 100%);
    }

    // &:before {
    //   display: block;
    //   content: '';
    //   position: absolute;
      //   background-color: ${Aladin_Orange_Lighter};
    //   width: 5px;
    //   height: 3px;
    //   bottom: 0;
    //   left: 0;
    // }
    &:hover .label {
      color: ${DarkGray1};
    }

    .label {
      font-weight: bold;
      //color: white;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-shadow: 0 0px 1px rgba(0, 0, 0, .2);

      i {
        font-size: 14px;
        color: white;
        margin-right: 5px;
      }
    }

    .value {
      color: ${DarkGray1};
      font-size: 12px;
      font-weight: bold;
      border-radius: 5px;
      padding: 0 5px;
      position: absolute;
      background-color: rgba(244, 193, 104, 0.46);
      bottom: 2px;
      right: 3px;
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
`;

export const TableController1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Aladin_BG};
  border-radius: 5px;
  padding: 2px 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  ${props => props.whiteBG && css`
    background: white;
  `}
  >a {
    cursor: pointer;
    &:hover {
      color: ${Aladin_Orange_Dark} !important;
    }
  }
  .searchBox {
    margin-right: 20px;
    position: relative;
    min-width: 250px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 10px 0;
    }
    i.fa-search {
      position: absolute;
      left: 10px;
      top: 6px;
      color: ${Aladin_Orange_Dark};
    }
    i.fa-times {
      position: absolute;
      right: 10px;
      top: 6px;
      color: ${Aladin_Gray_Dark};
      cursor: pointer;
    }
    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border: none;
      outline: none;
      border-bottom: 1px solid ${MediumGray};
        //background: ${LightGray};
      box-sizing: border-box;
      outline: none;
      width: 100%;
      border-radius: 5px;
      background: rgba(255,255,255, .5);
      &:focus {
        border-radius: 0px;
        border-color: ${Aladin_Orange_Dark};
      }
    }
  }
  >.select-box {
    .toggler {
      border: none;
      i {
        display: none;
      }
    }
  }
  .dropdown-toggle {
    background: transparent !important;
  }
`
export const TableController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 15px 0;
  position: relative;
`;

export const ListFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  &.right {
    justify-content: flex-end;
  }
  strong {
    font-weight: bold;
    padding: 0px 10px;
    border-radius: 5px;
    background: ${Aladin_BG};
  }
  @media screen and (max-width: 667px) {
    flex-wrap: wrap;
    .info {
      display: block
      margin: 10px 0;
    }
    .rc-pagination {
    }
  }
  select {
    padding: 2px 5px;
    font-size: 14px;
    min-width: 50px;
    margin: auto 5px;
    border: none;
    outline: none;
  }
`;

export const HealthStatus = styled.div`
  display: inline-block;
  //width: 24px;
  border-radius: 5px;
  padding: 2px 6px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  &.green {
    background: #6eb3c4;
    color: white;
  }

  &.red {
    background: #ec685c;
    color: white;
  }

  &.greenHealthStatus {
    color: #6eb3c4;
    font-size: 11px;
  }

  &.redHealthStatus {
    color: #ec685c;
    font-size: 11px;
  }

  &.yellow {
    background: ${Sunflower};
    color: ${GrapeFruit};
  }
`

export const InflightStatus = styled.div`
  display: inline-block;
  //width: 24px;
  border-radius: 5px;
  padding: 4px 6px;
  font-weight: bold;

  &.DONE {
    color: ${DarkGray1}
  }

  &.DELIVERING {
    color: #6eb3c4;
  }

  &.READY {
    color: #f4c168;
  }
`

export const Pacing = styled.div`
  min-width: 50px;
  height: 16px;
  background: ${LightGray1};
  border-radius: 5px;
  overflow: visible;
  font-size: 12px;
  padding-left: 2px;
  position: relative;
  >div {
    height: 16px;
  }
  &.green .actual{
    background: ${Grass1};
  }
  &.red .actual{
    background: ${BitterSweet1};
  }
  &.yellow .actual{
    background: ${Sunflower};
  }
  .target {
    position: absolute;
    height: 12px;
    width: 1px;
    background: gray;
    top: 0;
    bottom: 0;
  }
`

export const ColumnSelector = styled(SelectStyled)`
  width: auto !important;
  margin-right: 10px;
  .toggler {
    width: 30px;
    background: transparent;
    &:hover .material-symbols-outlined {
      color: ${Aladin_Orange_Dark} !important;
    }
    &.searchable {
      min-width: 20px;
      transition: min-width .3s ease;
    }
    &.searchable.active {
      min-width: 80px;
    }
  }
  .menu {
    max-height: inherit !important;
    left: auto !important;
    //right: 0;
    min-width: 200px !important;
    @media screen and (max-width: 768px) {
      left: 0 !important;
      right: 0 !important;
    }
    h3 {
      font-weight: normal;
      font-size: 14px;
      margin: 10px 10px;
    }
    .menu-group {
      max-height: 380px;
      overflow: auto;
      margin-bottom: 10px;
    }
  }

`