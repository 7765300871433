import React from 'react';
import ReactDOM from "react-dom";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {ShareWrapper} from './share.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, TabContainer, Loader, CondirmDialog, TableScrollWrapper, ProgressBar} from "../uikit";
import ShareBox from "../campaigns/ShareBox";

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      shares: props.report.shares || [],
      touched: false,
    }
  }

  render() {
    const {report} = this.props;
    const {users, shares, touched, usersMap} = this.state;
    return (
      <ShareWrapper>
        {
          !!shares && !!shares.length &&
          <>
            <p>Share list</p>
            <ul className="share-list">
              {shares.map(share => {
                return (
                  <li className="share-item">
                    {share.isNew && <div className='shared-item-addedList'>
                      <p>Adding share to</p> 
                      <div>{share.users.map(email => <div className="tag">{email}</div>)}</div>
                      <p>Click 'Save Share Settings' button to share.</p>
                    </div>}
                    {!share.isNew && <div className='shared-item-addedList'>
                      <p>Shared to</p>
                      <div>{share.users.map(email => <div className="tag">{email}</div>)}</div>
                      <p>{moment(share.create_date).fromNow()}.</p>
                    </div>}
                    <Btn type="reset" size="mini" onClick={e => this.revoke(share)}>Revoke</Btn>
                  </li>
                )
              })}
            </ul>
          </>
        }
        <p>&nbsp;</p>
        {!!shares && !!shares.length && <p>Add New Share:</p>}
        <div style={{fontSize: '12px', margin: '-10px 0 10px 0'}}>User(s) will receive an email with the shared report link.. </div>
        {/*<label style={{display: 'block', margin: '10px 0 10px 0'}}>*/}
        {/*  <input type="checkbox"/> Generate an access code for the report link*/}
        {/*</label>*/}
        <div className="share-create">
          <ShareBox
            className="user-select share-box"
            key={Date.now()}
            users={users}
            usersMap={usersMap || []}
            data={[]}
            allowExternal={true}
            onChange={(users, usersMap) => {
              this.setState({users, usersMap});
            }}/>

          {
            !!users.length &&
            <a className="add-share" onClick={e => this.addShare()}>
              <i className="material-symbols-outlined" style={{verticalAlign: 'middle', fontSize: '18px'}}>add</i>
              Add share
            </a>
          }
        </div>
        <div className="actions">
          <Btn type="reset" onClick={e => this.props.onCancel()}>Cancel</Btn>
          <Btn type="primary"
               disabled={!touched}
               onClick={e => {
                 shares.forEach(d => {
                   delete d.isNew;
                 })
                 this.props.onChange(shares)
               }}>Save Share Settings</Btn>
        </div>
      </ShareWrapper>
    )
  }

  addShare() {
    const {operator} = this.props;
    const {shares, users} = this.state;
    if(users.length) {
      let newShare = {
        code: _.random(10000, 100000),
        users: users,
        create_time: moment().format('YYYY-MM-DD HH:mm:ss'),
        shared_by: operator,
        isNew: true
      }
      shares.push(newShare);
      this.setState({shares, users: [], touched: true})
    }
  }
  revoke(share) {
    const {shares, users} = this.state;
    this.setState({shares: shares.filter(s => s.code !== share.code), touched: true});
  }
}