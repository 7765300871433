import React from 'react'
import classNames from 'classnames';
import {Redirect, Link} from 'react-router-dom';
import {post} from '../utils/request';
import queryString from 'qs';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper, SubmitBtn} from './login.style';

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.urlParams = queryString.parse(this.props.location.search.slice(1));
    this.state = {loading: false, error: null, ...this.urlParams}
  }

  resetPassword(e) {
    e.preventDefault();
    const {userId: userIdRef, oldPassword: oldPasswordRef, newPassword: newPasswordRef} = this.refs;
    const userId = this.urlParams.userId || userIdRef.value;
    const oldPassword = oldPasswordRef ? oldPasswordRef.value : null;
    const newPassword = this.refs.newPassword.value;

    this.setState({success: false, loading: true, error: null});
    post('/api/resetPW', {userId, oldPassword, newPassword})
      .then(result => {
        this.setState({loading: false, success: true});
      }).catch(e => {
      console.error(e);
      this.setState({loading: false, error: e.message});
    });
  }

  render() {
    const {userId, oldPassword, newPassword, error, loading} = this.state;
    let hour = new Date().getHours();
    let daypart = (hour >= 5 && hour < 11) ? 'morning' : ((hour >= 11 && hour < 17) ? 'afternoon' : 'evening')
    return (
      <LoginWrapper className={daypart}>
        <form>
          <div className="form-title">
            <img src="/img/login/logo_white.png" />
          </div>
          <h3>Reset Password</h3>
          {
            !!error && <div className="error-msg">{error}</div>
          }
          <div>
            {this.state.success && <p style={{color: 'green'}}>Your password has been changed.</p>}
          </div>
          <div className="field">
            <input ref="userInput"
                   value={userId}
                   type="text"
                   placeholder="Email"
                   onChange={e => this.setState({email: e.currentTarget.value})}/>
          </div>
          <div className="field">
            <input ref="oldPassword"
                   value={oldPassword}
                   type={this.urlParams.oldPassword ? 'hidden' : 'password'}
                   placeholder="Old Password"
                   onChange={e => this.setState({oldPassword: e.currentTarget.value})}/>
          </div>
          <div className="field">
            <input ref="newPassword"
                   value={newPassword}
                   type="password"
                   placeholder="New Password"
                   onChange={e => this.setState({newPassword: e.currentTarget.value})}/>
          </div>

          <SubmitBtn  className={daypart} onClick={e => this.resetPassword(e)}>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Update Password
          </SubmitBtn>
          <div className="bottom-links">
            <Link to={'/login'} style={{marginLeft: '20px'}}>Back</Link>
          </div>
        </form>
      </LoginWrapper>
    )
  }
}