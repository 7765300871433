import moment from "moment";
import _ from "lodash";
import {
  intFormatter,
  floatFormatter,
  pctFormatter,
  uniquesFormatter,
  moneyFormatter,
  pctFormatterWithoutSign,
  pct2Formatter
} from './formatter';
export const TargetAudienceOptions = [
  {key: 'P15+', label: 'P15+'},
  {key: 'PMEB', label: 'PMEB'},
  {key: 'Male', label: 'Male'},
  {key: 'Female', label: 'Female'},
]
export const KPIOptions = [
  {key: 'impressions', label: 'Impressions'},
  {key: 'grp_pct', label: 'GRP (%)'},
  {key: 'reach', label: 'Reach'},
  {key: 'reach_pct', label: 'Reach (%)'},
  // {key: 'reach_3', label: 'Reach 3+'},
  // {key: 'reach_3_pct', label: 'Reach % 3+'},
]

export const platformKPIOptions = {
  tv: [
    {key: 'impressions', label: 'Impressions'},
    {key: 'grp_pct', label: 'GRP (%)'},
    {key: 'reach', label: 'Reach'},
    {key: 'reach_pct', label: 'Reach (%)'},
    {key: 'reach_3', label: 'Reach 3+'},
    {key: 'reach_3_pct', label: 'Reach (%) 3+'},
  ],
  radio: [
    {key: 'impressions', label: 'Impressions'},
    {key: 'grp_pct', label: 'GRP (%)'},
    {key: 'reach', label: 'Reach'},
    {key: 'reach_pct', label: 'Reach (%)'},
    {key: 'reach_3', label: 'Reach 3+'},
    {key: 'reach_3_pct', label: 'Reach (%) 3+'},
  ],
  digital: [
    {key: 'impressions', label: 'Impressions'},
    {key: 'grp_pct', label: 'GRP (%)'},
    {key: 'reach', label: 'Reach'},
    {key: 'reach_pct', label: 'Reach (%)'},
    {key: 'reach_3', label: 'Reach 3+'},
    {key: 'reach_3_pct', label: 'Reach (%) 3+'},
    {key: 'impressions_display', label: 'Banner Impressions'},
    {key: 'clicks', label: 'Clicks'},
    {key: 'display_clicks', label: 'Banner Clicks'},
    {key: 'video_starts', label: 'Video Starts'},
    {key: 'video_engaged_views', label: 'Video Engaged Views'},
    {key: 'video_completions', label: 'Video Completion'},
    {key: 'pv', label: 'Page Views'},
  ],
  social: [
    {key: 'impressions', label: 'Impressions'},
    {key: 'grp_pct', label: 'GRP (%)'},
    {key: 'reach', label: 'Reach'},
    {key: 'reach_pct', label: 'Reach (%)'},
    {key: 'comments', label: 'Comments'},
    {key: 'likes', label: 'Likes'},
    {key: 'engagement', label: 'Engagement'},
  ],
  ooh: [
    {key: 'impressions', label: 'Impressions'},
    {key: 'grp_pct', label: 'GRP (%)'},
    {key: 'reach', label: 'Reach'},
    {key: 'reach_pct', label: 'Reach (%)'},
    {key: 'comments', label: 'Comments'},
    {key: 'likes', label: 'Likes'},
    {key: 'engagement', label: 'Engagement'},
  ]
}

export const KPI_CATS = {
  PCT_KPIS: ['grp_pct', 'reach_pct', 'reach_3_pct']
}
export const CampaignTypeOptions = [
  {key: 'guaranteed_impressions', label: 'Guaranteed Impressions', kpi_metrics: ['impressions', 'grp_pct'], kpi_metrics_summary: 'Impressions or GRP (%)'},
  {key: 'guaranteed_reach', label: 'Guaranteed Reach', kpi_metrics: ['reach_pct', 'reach_3_pct', 'reach', 'reach_3'], kpi_metrics_summary: 'Reach or Reach (%)'},
  {key: 'guaranteed_page_views', label: 'Guaranteed Page Views', kpi_metrics: ['pv'], kpi_metrics_summary: 'Page Views', ignoreValidator: true},
  {key: 'blended_cpv', label: 'Blended CPV', kpi_metrics: ['reach_pct', 'reach_3_pct', 'reach', 'reach_3'], kpi_metrics_summary: 'Reach or Reach (%)'},
  {key: 'performance_partnership', label: 'Performance Partnership', kpi_metrics: []},
  {key: 'hybrid', label: 'Others', kpi_metrics: []},
]
export const campaignTypeMap = CampaignTypeOptions.reduce((ret, next) => {
  return {...ret, [next.key]: next.label};
}, {});

export const platformMeta = [
  {key: 'overall', label: 'Overall'},
  {key: 'digital', label: 'Digital'},
  {key: 'tv', label: 'TV'},
  {key: 'social', label: 'Social'},
  {key: 'radio', label: 'Radio'},
  {key: 'ooh', label: 'Outdoor'},
]
export const platformLabelMap = {
  'overall': 'Overall',
  'digital': 'Digital',
  'tv': 'TV',
  'social': 'Social',
  'radio': 'Radio',
  'ooh': 'Outdoor',
  'others': 'Others'
}

export const platformSorted = ['overall', 'digital', 'tv', 'radio', 'social', 'ooh', 'others'];
export const dimensionLabelMap = {
  'sites': 'Site',
  'access_method': 'Access Method',
  'lineitem': 'Line Item',
  'ad_format': 'Ad Format',
  'ad_type': 'Ad Type',
  'creative': 'Creative',
  'meid_seg': 'User Segment',
  'age': 'Age',
  'gender': 'Gender',
  'interest': 'Interest',
  'grapeshot': 'Content Cateogry',
  'grape_shot': 'Content Cateogry',
  'buy_model': 'Buy Model',
  'channel': 'Channel',
  'station': 'Station',
  'timebelt': 'Timebelt',
  'daypart': 'Daypart',
  'date': 'Date',
  'program': 'Programme',
  'platform': 'Platform',
  'start_date': 'Start Date',
  'end_date': 'End Date',
  'goal': 'Goal',
  'influencer': 'Influencer',
  'influencer_size': 'Influencer Size',
  'social_platform': 'Social Platform',
  'category': 'Category',
  'post': 'Post',
  'handler': 'Handler',
  'agency': 'Agency',
  'location': 'Location',
  'content_type': 'Content Type',
}

export const filterDimensions = {
  tv: ['channel', 'daypart', 'timebelt', 'program'],
  radio: ['channel', 'station', 'daypart', 'timebelt'],
  digital: ['sites', 'access_method', 'lineitem', 'ad_format', 'buy_model', 'ad_type'],
  social: ['influencer', 'influencer_size', 'social_platform', 'category', 'handler', 'agency', 'content_type'],
}

export const getFilterDimensions = (platforms)  => {
  return _.uniq(_.flatten(platforms.map(p => filterDimensions[p] || [])));
}

export const campaignStatusMap = {
  'ON_TRACK': 'On Track',
  'OFF_TRACK': 'Off Track',
  'WARNING': 'Warning',
}
export const inflightStatusMap = {
  'DONE': 'Completed',
  'READY': 'Ready',
  'DELIVERING': 'Delivering',
}

export const releaseStatusMap = {
  'DRAFT': 'Draft',
  'RELEASED': 'Published',
}

export const attrMetaData = [
  {key: 'start_date', label: 'Start Date', formatter: v => moment(v).format('YYYY-MM-DD hh:mm A')},
  {key: 'end_date', label: 'Start Date', formatter: v => moment(v).format('YYYY-MM-DD hh:mm A')},
  {key: 'advertiser', label: 'Advertiser', formatter: v => v},
  {key: 'name', label: 'Campaign Name', formatter: v => v},
  {key: 'brand', label: 'Brand', formatter: v => v},
  {key: 'industry', label: 'industry', formatter: v => v},
]

export const metricMetaData = [
  {eg: 1000, key: 'impressions', label: 'Impressions', formatter: intFormatter, formatter2: uniquesFormatter, accumulatable: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 1000, key: 'display_impressions', label: 'Display Impressions', formatter: intFormatter, formatter2: uniquesFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 1000, key: 'video_impressions', label: 'Video Impressions', formatter: intFormatter, formatter2: uniquesFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 500, key: 'viewable_impressions', label: 'Viewable Impressions', formatter: intFormatter, formatter2: uniquesFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 1000, key: 'cumulative_impressions', label: 'Cumulative Impressions', formatter: intFormatter, formatter2: uniquesFormatter, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 200, key: 'clicks', label: 'Clicks', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 200, key: 'display_clicks', label: 'Display Clicks', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 200, key: 'video_clicks', label: 'Video Clicks', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 500, key: 'reach', label: 'Reach', formatter: intFormatter, formatter2: uniquesFormatter, isAccumulative: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 50, key: 'reach_pct', label: 'Reach (%)', formatter: v => pctFormatter(v / 100), isAccumulative: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 500, key: 'reach_3', label: 'Reach 3+', formatter: intFormatter, formatter2: uniquesFormatter, isAccumulative: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 50, key: 'reach_3_pct', label: 'Reach 3+ (%)', formatter: v => pctFormatter(v / 100), isAccumulative: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 50, key: 'frequency', label: 'Frequency', formatter: floatFormatter, isAccumulative: false, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 500, key: 'spend', label: 'Spend', formatter: moneyFormatter, isMoneyType: true, accumulatable: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 500, key: 'cumulative_spend', label: 'Cumulative Spend', formatter: moneyFormatter, isMoneyType: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 500, key: 'video_starts', label: 'Video Start', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 500, key: 'video_completions', label: 'Video Completion', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 500, key: 'video_engaged_views', label: 'Video Engaged View', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 200, key: 'video_completions_25pct', label: '25% Video Completion', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 300, key: 'video_completions_50pct', label: '50% Video Completion', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 400, key: 'video_completions_75pct', label: '75% Video Completion', formatter: intFormatter, accumulatable: true, supportPlatforms: ['digital']},
  {eg: 0.024, key: 'ctr', label: 'CTR', formatter: pct2Formatter, supportPlatforms: ['digital']},
  {eg: 0.024, key: 'display_ctr', label: 'Display CTR', formatter: pct2Formatter, supportPlatforms: ['digital']},
  {eg: 0.024, key: 'video_ctr', label: 'Video CTR', formatter: pct2Formatter, supportPlatforms: ['digital']},
  {eg: 0.015, key: 'cpc', label: 'Cost Per Click', formatter: moneyFormatter, isMoneyType: true, description: 'Cost Per Click', supportPlatforms: ['digital']},
  {eg: 3.5, key: 'cpv', label: 'Cost Per Reach', formatter: moneyFormatter, description: 'Cost per Reach (Spend / Reach)', isMoneyType: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 0.24, key: 'vcr', label: 'Video Completion Rate', formatter: pct2Formatter, description: 'Video Completion Rate', supportPlatforms: ['digital']},
  {eg: 0.18, key: 'ver', label: 'Video Engaged Rate', formatter: pct2Formatter, description: 'Video Engaged Rate', supportPlatforms: ['digital']},
  {eg: 0.12, key: 'vcr_25pct', label: '25% Completion Rate', formatter: pct2Formatter, description: '25% Video Completion Rate', supportPlatforms: ['digital']},
  {eg: 0.15, key: 'vcr_50pct', label: '50% Completion Rate', formatter: pct2Formatter, description: '50% Video Completion Rate', supportPlatforms: ['digital']},
  {eg: 0.18, key: 'vcr_75pct', label: '75% Completion Rate', formatter: pct2Formatter, description: '75% Video Completion Rate', supportPlatforms: ['digital']},
  {eg: 35, key: 'cpm', label: 'CPM', formatter: moneyFormatter, isMoneyType: true, description: 'Cost per thousant impressions (Spend * 1000 / impressions)', supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 100, key: 'spots', label: 'Spots', formatter: intFormatter, accumulatable: true, supportPlatforms: ['tv', 'radio']},
  {eg: 100, key: 'cumulative_spots', label: 'Cumulative Spots', formatter: intFormatter, formatter2: uniquesFormatter, supportPlatforms: ['tv', 'radio']},
  {eg: 500, key: 'impressions_per_spots', label: 'Impressions Per Spot', formatter: intFormatter, supportPlatforms: ['tv', 'radio']},
  // {key: 'pt', label: 'PT', formatter: intFormatter},
  // {key: 'opt', label: 'OPT', formatter: intFormatter},
  {eg: 500, key: 'total_spots', label: 'Total Spots', formatter: intFormatter, accumulatable: true, supportPlatforms: ['tv', 'radio']},
  {eg: 100, key: 'grp', label: 'GRP', formatter: intFormatter, accumulatable: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 100, key: 'cumulative_grp', label: 'Cumulative GRP', formatter: intFormatter, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 50, key: 'grp_pct', label: 'GRP (%)', formatter: v => pctFormatterWithoutSign(v / 100), accumulatable: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 6500, key: 'budget', label: 'Budget', formatter: moneyFormatter, isMoneyType: true, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},
  {eg: 'Delivering', key: 'pacing', label: 'Flight Status', formatter: v => inflightStatusMap[v] || v, supportPlatforms: ['overall', 'digital', 'tv', 'social', 'radio']},

  {eg: 100, key: 'likes', label: 'Likes', formatter: intFormatter, accumulatable: true, supportPlatforms: ['social']},
  {eg: 200, key: 'comments', label: 'Comments', formatter: intFormatter, accumulatable: true, supportPlatforms: ['social']},
  {eg: 50, key: 'likes_comments', label: 'Likes & Comments', formatter: intFormatter, accumulatable: true, supportPlatforms: ['social']},
  {eg: 5000, key: 'influencers', label: 'Influencers', formatter: intFormatter, accumulatable: true, supportPlatforms: ['social']},
  {eg: 500, key: 'engagement', label: 'Engagement', formatter: intFormatter, accumulatable: true, supportPlatforms: ['social']},
  {eg: 500, key: 'followers', label: 'Followers', formatter: intFormatter, accumulatable: false, supportPlatforms: ['social']},
  {eg: 34, key: 'cpe', label: 'Cost Per Engagement', formatter: moneyFormatter, supportPlatforms: ['social']},
  {eg: 34, key: 'engagement_rate', label: 'Engagement Rate', formatter: pct2Formatter, supportPlatforms: ['social']},
  {eg: 34, key: 'engagement_by_followers', label: 'Engagement By Followers', formatter: pct2Formatter, supportPlatforms: ['social']},
  {eg: 34, key: 'engagement_by_reach', label: 'Engagement By Reach', formatter: pct2Formatter, supportPlatforms: ['social']},
  {eg: 34, key: 'reach_rate', label: 'Reach Rate', formatter: pct2Formatter, supportPlatforms: ['social']},

  {eg: 1000, key: 'pv', label: 'Page Views', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'view_through_conversions', label: 'View Through Conversions', description: 'Datasource: GAM pixel data', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'click_through_conversions', label: 'Click Through Conversions', description: 'Datasource: GAM pixel data', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'advertiser_view_through_sales_sgd', label: 'View Through Sales (SGD)', description: 'Datasource: GAM pixel data', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'advertiser_click_through_sales_sgd', label: 'Click Through Sales (SGD)', description: 'Datasource: GAM pixel data', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'impressions_analyzed', label: 'Impressions Analyzed', description: 'Datasource: MOAT', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'valid_and_viewable', label: 'Valid & Viewable Impressions', description: 'Datasource: MOAT', formatter: intFormatter, supportPlatforms: ['digital']},
  {eg: 1000, key: 'in_view_percent', label: 'MOAT In-View %', description: 'Datasource: MOAT', formatter: pct2Formatter, supportPlatforms: ['digital']},
]

export const metricMap = metricMetaData.reduce((ret, next) => {
  return {...ret, [next.key]: next.label};
}, {});

export const SizeOptions = [
  {key: 'size1_5', label: '20%'},
  {key: 'size1_4', label: '25%'},
  {key: 'size1_3', label: '33%'},
  {key: 'size2_5', label: '40%'},
  {key: 'size1_2', label: '50%'},
  {key: 'size3_5', label: '60%'},
  {key: 'size2_3', label: '66%'},
  {key: 'size3_4', label: '75%'},
  {key: 'size4_5', label: '80%'},
  {key: 'size100', label: '100%'},
]

export const sites = [
  {key: 'ALL', label: 'ALL', connected: true},
  {key: 'cna', label: 'CNA', connected: true},
  {key: 'today', label: 'TODAY', connected: true},
  {key: '8world', label: '8world', connected: true},
  {key: '8days', label: '8days', connected: true},
  {key: 'mewatch', label: 'mewatch', connected: true},
  {key: 'melisten', label: 'melisten', connected: true},
  {key: 'merewards', label: 'merewards', connected: true},
  {key: 'berita', label: 'BERITA', connected: true},
  {key: 'seithi', label: 'Seithi', connected: true},
  {key: 'espn', label: 'ESPN', connected: true},
  {key: 'mothership', label: 'mothership', connected: true},
]

export const siteLabelMap = sites.reduce((ret, next) => {
  return {...ret, [next.key]: next.label};
}, {
  'meWATCH': 'mewatch',
  '8Days': '8days',
  '8World': '8world',
  'meLISTEN': 'melisten',
  'meREWARDS': 'merewards',
  'Berita': 'BERITA',
  'cna938': 'CNA938',
});

export const datePeriodWindows = [
  {key: 'last1quater', label: 'Last 3 Months'},
  {key: 'last6months', label: 'Last 6 Months'},
  {key: 'last1year', label: 'Last 12 Months'},
  {key: 'last2year', label: 'Last 24 Months'},
];

export const datePeriodWindowsFull = [
  // {key: 'today', label: 'Today'},
  // {key: 'yesterday', label: 'Yesterday'},
  {key: 'last7days', label: 'Last 7 Days'},
  // {key: 'last14days', label: 'Last 14 Days'},
  {key: 'last30days', label: 'Last 30 Days'},
  {key: 'last60days', label: 'Last 60 Days'},
  {key: 'last90days', label: 'Last 90 Days'},
  {key: 'last180days', label: 'Last 180 Days'},
  {key: 'last1year', label: 'Last 12 Months'}
];

export const dimensionValueMap = {
  ...siteLabelMap,
  ...platformLabelMap,
}

export const paginationValues = [5, 10, 20, 50, 100];

export const formatterMap = {
    integer: intFormatter,
    float: floatFormatter,
    money: moneyFormatter,
    pct: pctFormatter,
    percentage: pctFormatter,
}
