import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Aladin_Orange_Dark,
  Sunflower,
  Sunflower1,
  Grass,
  Aqua1,
  Grass1,
  BitterSweet1,
  PrimaryLightBlue,
  Aladin_BG,
  Aladin_Purple_Dark,
  Aladin_Purple_Light,
  MediumGray2,
  Aladin_BG_1,
  Aladin_Orange_Light,
  Aladin_Blue_Light,
  Aladin_BG_2,
  Aladdin_v2_orange1,
  Aladin_BG_TV_1,
  Aladin_BG_TV_2,
  Aladdin_v2_blue3
} from "../app/StyleCommon";
import {Dropdown} from "../uikit";
export const VisCanvas = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;

  &.editMode {
    height: calc(100vh - 210px);
    margin-right: 10px;
    padding-right: 10px;
    overflow: auto !important;

    .chart-wrapper:hover {
      //transition: all .2s ease;
      .chart-title {
        transform: translateX(18px);
      }

      //&.editor .drag-indicator {
      //  display: none;
      //}
    }

    .dashboard-section:hover {
      border: 1px dashed ${Aladin_Orange_Light};
      border-radius: 10px;

      .section-name {
        white-space: nowrap;
        padding-left: 10px;
        //background: rgba(243, 244, 246, 0.47) !important;
      }

      .section-name {
          //border-bottom: 1px dashed ${Aladin_Orange_Light};
      }
    }
  }

  .report-header {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 0px;
    position: relative;
    color: ${DarkGray1};

    .bg {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom: 0;
      background-image: url("/img/report/header2.png");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      //opacity: .8;
    }

    .title {
      z-index: 1;
      color: white;
      border-radius: 10px;
      padding: 10px;
    }

    .logo {
      z-index: 1;
      height: 50px;
      margin-right: 30px;
    }
  }

  &.empty {
    position: relative;

    &:before {
      display: block;
      content: 'No Data Available Yet';
      color: ${DarkGray};
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
    }

    .chart-wrapper {
      filter: blur(2px) grayscale(100);
      opacity: .2;
    }
  }

  .dashboard-section {
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    border: 1px solid transparent;

    &:hover {
      .section-del-btn {
        opacity: 1 !important;
      }
    }


    .section-name {
      padding: 5px 10px 5px 0;
      box-sizing: border-box;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid transparent;
      transition: all ease 0.5s;

      &.hasBG {
        padding-left: 5px;
      }

      .section-name-editor {
        outline: none;
        min-width: 100px;
        flex-grow: 1;

        &:focus {
          border-bottom: 1px dotted ${Aladin_Orange_Light};
        }
      }

      .section-btn,
      .section-del-btn {
        opacity: 0;
        cursor: pointer;

        i {
          font-size: 18px;
          color: ${MediumGray2};
          margin-left: 10px;
          margin-top: 5px;

          &:hover {
            color: ${Aladin_Orange_Dark};
          }
        }
      }
    }
  }

  .dashboard-nosource {
    opacity: 50%;
  }

  .section-filters {
    margin: 10px 0;
    padding: 10px;
    display: flex;
    background: ${Aladin_BG};
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    box-sizing: border-box;

    .filter-item {
      //flex-grow: 1;
      margin: 0 10px;
      width: calc(20% - 20px);
      overflow: hidden;
      box-sizing: border-box;

      .select-box {
        width: 100%;
        margin: 10px 0;
        overflow: hidden;

        .toggler {
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          background: white;
        }
      }
    }
  }

  .chart-list {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    &.showDrop {
      width: 98% !important;
    }

    &.showDrop .chart-wrapper {
      border: 1px dotted ${Grass};
      //transition: all .3s ease;
    }
  }

  .chart-wrapper {
    cursor: inherit !important;
    margin: 10px 20px 10px 0;
    min-width: 80px;
    //min-height: 60px;
    border: 3px solid transparent;
    background: ${Aladin_BG};
    border-radius: 3px;
    position: relative;
    box-sizing: border-box;
    //transition: all .2s ease;
    //display: flex;
    //flex-direction: column;
    //justify-items: center;
    //justify-content: space-between;
    @media screen and (max-width: 768px) {
      width: 100% !important;
      box-sizing: border-box;
      margin-right: 0;
    }

    &.size1_5 {
      width: calc(20% - 20px);
      //min-height: 100px;
    }

    &.size2_5 {
      width: calc(40% - 20px);
      //min-height: 100px;
    }

    &.size3_5 {
      width: calc(60% - 20px);
      //min-height: 100px;
    }

    &.size4_5 {
      width: calc(80% - 20px);
      //min-height: 100px;
    }

    &.size1_3 {
      width: calc(33.3% - 20px);
    }

    &.size2_3 {
      width: calc(66.6% - 20px);
    }

    &.size1_4 {
      width: calc(25% - 20px);
    }

    &.size1_2 {
      width: calc(50% - 20px);
    }

    &.size3_4 {
      width: calc(75% - 20px);
    }

    &.size100 {
      width: 100%;
    }

    &.grid-line-head .droparea.pre {
      top: -22px;
      height: 20px;
      left: 0;
      right: auto;
      bottom: auto;

      &:before {
        transform: translate(-50%, -50%) rotate(0);
      }
    }

    &.grid-line-tail .droparea.tail {
      bottom: -22px;
      height: 20px;
      right: 0;
      left: auto;
      top: auto;

      &:before {
        transform: translate(-50%, -50%) rotate(0);
      }

    }

    &.dragging {
      border: none;

      .droparea {
        display: none;
      }
    }

    .chart-title {
      margin-bottom: 10px;
      margin: 5px 10px 5px 10px;
      transition: all .2s ease;
      font-weight: bold;
      outline: none;
      color: ${DarkGray};

      &:focus {
        border-bottom: 1px solid ${Aladin_Orange_Dark};
      }
    }

    .drag-indicator {
      position: absolute;
      left: 2px;
      top: 3px;
      cursor: move;
      z-index: 2;
      opacity: 0;
      color: ${MediumGray2};

      span {
        font-size: 22px;
      }

      &.dragging {
        border: 1px dashed #f6aa42;
        background: rgba(255, 255, 255, .5);
        border-radius: 10px;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        span, i {
          display: none
        }
      }
    }

    &:hover {
      .chart-container-menu,
      .drag-indicator {
        opacity: 1 !important;
      }

    }

    .chart-container-menu {
      opacity: 0;
      padding: 2px 5px;
      position: absolute;
      right: 2px;
      top: 2px;
      color: ${DarkGray1};
      cursor: pointer;
      z-index: 10;

      i {
        font-size: 18px;
      }

      &:hover {
        color: ${Aladin_Orange_Dark};
      }
    }

    .component-loading {
      img {
        opacity: .3;
        width: 100%;
        height: auto;
      }
    }

    &:hover .rdw-editor-wrapper .rdw-editor-toolbar {
      visibility: visible !important;
    }

    .rdw-editor-wrapper {
      padding: 10px;
      min-height: inherit;

      .rdw-editor-toolbar {
        visibility: hidden !important;
        position: absolute;
        left: 0;
        z-index: 2;
        transform: translateY(-50px);
        flex-wrap: nowrap;
        white-space: nowrap;
      }

      .rdw-editor-main {
        background: transparent;
        line-height: 1.5em;
      }

    }

    .droparea {
      background: ${LightBlue};
      border-radius: 5px;
      visibility: hidden;
      box-sizing: border-box;
      position: absolute;
      border: 1px dashed ${BlueJeans};
      top: 0;
      bottom: 0;

      &.hover {
        border: 3px dashed ${PrimaryLightBlue};
        background: ${Grass} !important;
      }

      &:before {
        content: 'Drop visualisation here';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: ${MediumGray1};
        font-size: 12px;
      }

      &.pre {
        z-index: 1;
      }

      &.showDrop {
        visibility: visible;
      }

      &.highlight {
        visibility: visible;
        background: white;
      }

      &.sep {
        width: 20px;
        right: -20px;
        height: auto;;

        &.pre {
          right: auto;
          left: 0px;
        }

        &:before {
          content: 'Drop area';
          white-space: nowrap;
          line-height: 1em;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &.square {
        margin: 0;
        left: 0;
        right: 0;
        position: relative;
        height: 80px;
        min-width: 100px;
        width: 100% !important;

        &:before {
          content: 'Drop visualisation here';
        }

        &.hover {
          border: 3px dashed ${PrimaryLightBlue};
        }
      }
    }
  }

  .new-section-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1
  }
`


export const ComponentMenu = styled.div`
  max-height: 80vh;
  overflow-y: auto;

  .width-selector,
  .metric-selector {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .selected {
      color: ${Aladin_Orange_Dark};
      font-weight: bold;
    }
    .disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: .5;
    }

    > div {
      padding: 3px 8px 3px 5px;
      border: 1px solid ${Aladin_BG};
      background: ${Aladin_BG};
      border-radius: 5px;
      margin-right: 5px;
      font-size: 11px;
      //font-weight: bold;
      cursor: pointer;
      margin-bottom: 5px;
      position: relative;
      &:hover {
        background: ${Aladin_BG};
        color: ${Aladin_Orange_Dark};
        .close-btn {
          opacity: 1;
        }
      }
      .close-btn {
        opacity: 0;
        position: absolute;
        right: -7px;
        top: -6px;
        font-size: 13px;
        background-color: #fff;
        border-radius: 50%;
      }
      &.isClient {
        font-style: italic;
        &:before {
          content: '* ';
        }
      }
    }
  }

  .menu-option {
    padding: 6px 10px;
    margin-bottom: 3px;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: rgba(247, 247, 252, 0.42);
    }

    &:nth-child(even) {
      background-color: white;
    }

    i {
      margin-right: 5px;
      font-size: 18px;
      color: ${Aladin_Orange_Dark};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${LightGray1};
    }

    .label {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 12px;
      //padding: 2px 5px 2px 5px;
      //background-color: #f1f2f3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${LightGray1};
    }

    &.flex {
      display: flex;
      align-items: center;
    }

    &.danger {
      //background-color: #f69f8e;
      //&:hover {
      //  background-color: #f69f8e !important;
      //}
    }

    &.action:hover {
      background: ${Aladin_BG};
    }
  }
`

export const VisPanel = styled.div`
  background: ${Aladin_BG};
  border-radius: 5px;
  border: 1px solid ${Aladin_BG_1};
  width: 320px;
  margin-left: 0px;
  margin-top: 30px;
  position: relative;
  flex: none;
  box-shadow: -1px 0 5px rgba(0, 0, 0, .02);
  height: calc(100vh - 205px);
  overflow: auto;
  @media screen and (max-width: 768px) {
    position: absolute;
    left: auto !important;
    right: 0 !important;
    top: 0% !important;
    bottom: 0 !important;
    min-height: inherit !important;
    width: auto;
    width: 50% !important;
    z-index: 2;
    margin-left: 0;
    &.closed {
      width: 25px !important;
    }
  }

  &.closed {
    width: 25px;
    cursor: pointer;

    &:hover {
      background: ${Aladin_BG_1};
    }

    .vispanel-body, .vispanel-header {
      display: none;
    }
  }

  .vispanel-header {
    background: transparent;
    padding: 10px 0 10px 10px;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    .btn {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .vispanel-actions {
    position: absolute;
    right: 4px;
    top: 4px;
    text-align: right;

    button {
      margin-left: 10px;
    }

    .vispanel-expand {
      cursor: pointer;

      i {
        font-size: 20px;

      }
    }
  }

  .vispanel-body {
    padding: 5px 10px;
    //max-height: calc(100vh - 200px);
    //overflow-y: auto;
  }

  .searchBox {
    margin: 10px 0;
    position: relative;
    min-width: 250px;

    i {
      position: absolute;
      left: 10px;
      top: 6px;
      color: ${Aladin_Orange_Dark};
    }

    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border: none;
      outline: none;
      border-bottom: 1px solid ${MediumGray};
        //background: ${LightGray};
      box-sizing: border-box;
      outline: none;
      width: 100%;
      border-radius: 5px 5px 0 0;
      background: rgba(255, 255, 255, .5);

      &:focus {
        border-radius: 0px;
        border-color: ${Aladin_Orange_Dark};
      }
    }
  }

  .vis-group {
    border-left: 1px solid ${Aladin_BG_2};
    background: white;
    margin-bottom: 5px;
    box-shadow: 1px 1px 2px rgba(0,0,0,.1);
    .vis-group-title {
      margin: 0;
      cursor: pointer;
      padding: 5px 10px;
      background: rgb(247, 247, 252); //rgb(231, 238, 246);
      //background-color: #fafbfc;
      //border-bottom: 1px solid #f4c168;
      border-radius: 0px 5px 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 14px;
      }

      &:hover {
        background: rgb(231, 238, 246, .8);
      }
    }

    .vis-list {
      padding-left: 10px;
    }
  }

  .vis-list {
    display: flex;
    flex-wrap: wrap;
    background: white;
  }

  .vis {
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
    vertical-align: top;
    width: 100%;
    border-radius: 5px;
    opacity: .9;
    background: white;

    &:hover {
      transform: scale(1.01);
      transform-origin: 0 0;
    }

    &.inUse {
      opacity: .5;
      .chart-title {
        color: ${DarkGray};
      }
      &:hover {
        opacity: 0.6;
      }
    }

    &.disabled {
      //opacity: .4;
      position: relative;

      &:before {
        content: 'Used';
        position: absolute;
        right: 5px;
        top: 10px;
        font-size: 12px;
        background: ${Aladin_BG_1};
        padding: 5px 5px;
      }
    }

    &:hover {
      opacity: 1;
    }

    &.dragging {
      opacity: .5;

      * {
        opacity: .5;
      }
    }

    //&:nth-child(even) {
    //  margin-left: 15px;
    //}
    display: inline-block;
    cursor: move !important;

    .basic {
      width: auto;
    }

    //.detail-chart {
    //  display: none;
    //}
    &.size1_5 {
      width: 45%;
      margin: 8px 2%;
      background: ${Aladin_BG};
      border-radius: 5px;
      border: 1px solid ${MediumGray};

      > div {
        border: none;
        transform: scale(.9);
      }

      .chart-title {
        white-space: normal;
      }
    }

    .vis-name {
      font-size: 12px;
      font-weight: bold;
      margin: 10px 0;

      i {
        margin-right: 5px;
        font-weight: bold;
        color: ${MediumGray1};
      }
    }

    .vis-wrapper {
      background: ${Aladin_BG};
      border: 1px solid transparent;
      border-radius: 5px;
      //margin: 10px 0;
      width: 98%;
      border: 1px solid ${MediumGray};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-x: hidden;
      cursor: move;

      * {
        pointer-events: none;
      }

      .thumbnail {
        width: 100%;
        height: 150px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      .bottom-opacity {
        opacity: 0.4;
        filter: blur(2px);
      }
    }
  }
`

export const ChartDialogFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  .labelText {
    font-weight: normal;
    font-size: 12px;
  }

  .labelText:first-child {
    border-right: 1px solid #434a54;
    margin-right: 8px;
    padding-right: 6px;
    line-height: 10px;
  }
`