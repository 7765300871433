import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  moneyFormatterGenerator,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {Aladin_BG, Aladin_Orange_Dark, Aladin_Orange_Light, DarkGray, Grass1, MediumGray1} from "../app/StyleCommon";
import {dimensionValueMap, metricMetaData, platformLabelMap, siteLabelMap} from "../utils/metadata";
import {getContextChartScale} from "./util";
import {NoDataComponent} from "./noDataComponent";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    let {data, cfg, currency} = this.props;
    if(!data || !data.find(d => !!d.value)) {
      return <NoDataComponent {...this.props} />
    }
    return (
      <ChartWrapper className="chart">
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    if(!this.containerRef.current) {
      return;
    }
    let {data, cfg, context, currency} = this.props;
    const {metric, dimension, platform} = cfg;
    data = _.cloneDeep(data);
    data.forEach(d => {
      if(d.label) {
        d.label = d.label + ` (${d.key})`;
      } else {
        d.label = siteLabelMap[d.key] || d.key;
      }
    })
    data = data.filter(d => !!d.value);

    const metricMeta = context.mixedMetaData.find(d => d.key === metric);
    let formatter = getMetricFormatter(metricMeta, currency);
    // data = _.sortBy(data, _.property('value')).reverse().slice(0, 5).reverse();
    data = _.sortBy(data, _.property('value'));
    if(dimension === 'age') {
      let ageSequence = ['age-<18', 'age-18-24', 'age-25-34', 'age-35-44', 'age-45-54', 'age-55-64', 'age-65+']
      data = _.sortBy(data, d => ageSequence.indexOf(d.key)).reverse();
      data = data.filter(d => d.key !== 'age-<18');
    }
    const container = this.containerRef.current;
    let height = Math.max(this.containerRef.current.parentElement.offsetHeight || 0, 260);
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    if(data.length < 10) {
      height = data.length * 30 + 35;
    } else {
      height = 10 * 30 + 35;
    }
    height = Math.max(height, 260);
    // if(data.length === 1) {
    //   height =  100;
    // } else if(data.length === 2) {
    //   height =  150;
    // }
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      // color: ['#abcdf4'],
      color: ['#6eb3c4'],
      tooltip: {
        trigger: 'axis',
        position: 'top',
        confine: true,
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        extraCssText: 'max-width:auto; white-space:pre-wrap;',
        valueFormatter: function (value) {
          return formatter(value);
          // let value = d.value;
          // if(value.length > 18){
          //   return value.substring(0, 15) + '...';
          // }
          // return value;
        }
      },
      grid: {
        top: 20,
        left: data.length > 12 ? '55' : '35',
        right: 65,
        bottom: 15,
        containLabel: true
      },
      dataZoom: [
        {
          show: data.length > 10,
          id: 'dataZoomY',
          type: 'slider',
          filterMode: 'none',
          left: 10,
          yAxisIndex: [0],
          start: Math.min(100 * (1 - 10 / data.length), 100),
          end: 100,
          zoomLock: false,
          brushSelect: false,
          borderColor: Aladin_BG,
          backgroundColor: Aladin_BG,
          fillerColor: 'rgba(155,191,196,0.24)',
          textStyle: { color: 'transparent'},
          handleStyle: {
            // color: '#f3d4a9',
            color: 'transparent',
            borderColor : 'transparent',
          },
          moveHandleStyle: {
            color: 'transparent',
            borderColor : 'white',
          },
          brushStyle: {
          },
          dataBackground : {
            lineStyle: {
              color: '#8fd3f4',
            },
            areaStyle: {
              color: '#8fd3f4',
            }
          },
          selectedDataBackground : {
            lineStyle: {
              color: '#4f8f98',
            },
            areaStyle: {
              color: '#4f8f98',
            }
          }
        }
      ],
      xAxis: {
        show: false,
        type: 'value',
        boundaryGap: [0, 0.01],
        splitNumber: 2,
        offset: 2,
        axisLabel: {
          formatter: metricMeta.formatter2 || formatter
        },
      },
      yAxis: {
        type: 'category',
        data: data.map(d => d.label),
        axisLine: {
          onZero: false,
          lineStyle: {
            color: '#6eb3c4',
            fontWeight: 'bold'
          }
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          color: DarkGray,
          overflow: 'ellipsis',
          fontSize: 12,
          formatter: value => {
            let line = data.find(d => d.key === value) || {};
            let str = line.label || dimensionValueMap[value] || value || '';

            if(str.length > 15){
              return str.substring(0, 15) + '...';
            }
            return str;
          }
        },
      },
      series: [
        {
          type: 'bar',
          data: data.map(d => d.value),
          barWidth: 18,
          barGap: 12,
          // label: {
          //   show: true,
          //   position: 'right',
          //   formatter: d => formatter(d.value),
          // },
          label: {
            show: true,
            position: 'right',
            formatter: d => {
              let dataPoint = data[d.dataIndex] || {};
              let isDemoReport = ['age', 'gender'].includes(dimension);
              let sumValue = _.sum(data.map(d => Number(d[metric] || 0)));
              if(!isDemoReport && metricMeta.accumulatable) {
                return data.length < 6 ?  `{a|${formatter(d.value)}}\n{b| (${pctFormatter(d.value / Number(sumValue))})}` : `{a|${formatter(d.value)}} {b| (${pctFormatter(d.value / Number(sumValue))})}`
              } else if(isDemoReport && dataPoint[`total_${metric}`]){
                // return pctFormatter(d.value / Number(dataPoint[`total_${metric}`]));
                let sumValue = _.sum(data.map(d => Number(d[metric] || 0)));
                return pctFormatter(d.value / sumValue);
              }
              else {
                return formatter(d.value)
              }
            },
            rich: {
              a: {
                align: 'left',
                fontSize: 12
              },
              b: {
                align: 'left',
                color: '#535353',
                fontSize: 10
              }
            }
          },
          itemStyle: {
            borderRadius: 0,
            // color: '#FEAE65',
            color: '#6eb3c4',
            // color: new echarts.graphic.LinearGradient(1, 1, 0, 1, [
            //   { offset: 0, color: '#f6e0a7' },
            //   { offset: 0.5, color: '#f1cb6d' },
            //   { offset: 1, color: '#f1cb6d' }
            // ])
          },
        }
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
