import React from 'react';
import {findDOMNode} from 'react-dom';
import {ScrollTableWraper} from "./index.style";

export default class extends React.Component {
  render() {
    return (
      <ScrollTableWraper {...this.props}>
        <div ref="viewport">
          {this.props.children}
        </div>
        <div ref="pusher"/>
      </ScrollTableWraper>
    )
  }

  adjust() {
    if(this.props.noAdjust) {
      return;
    }
    if (!this.table) {
      return;
    }
    let {innerHeight: winHeight, scrollY} = window;
    let {height: tableHeight, top: tableToTop} = this.table.getBoundingClientRect();

    var viewportHeight = winHeight - tableToTop;

    if (viewportHeight > tableHeight) {
      viewportHeight = tableHeight;
    } else if (viewportHeight < 0) {
      viewportHeight = 0;
    }
    if (this.viewport.style.height === viewportHeight + 'px') {
      return;
    }
    this.pusher.style.height = (tableHeight - viewportHeight) + 'px';
    this.viewport.style.height = viewportHeight + 'px';
    if(tableHeight - viewportHeight < 5) {
      this.viewport.style.height = viewportHeight + 15 + 'px';
    }
  }

  componentDidMount() {
    this.wrapper = findDOMNode(this);
    this.table = this.wrapper.querySelector(this.props.target || 'table');
    this.viewport = this.refs.viewport;
    this.pusher = this.refs.pusher;
    window.addEventListener('scroll', this.adjust.bind(this)); // capture phase
    window.addEventListener('resize', this.adjust.bind(this)); // capture phase
    // this.adjust();
    if (this.props.monitor) {
      this.timmer = setInterval(() => {
        this.adjust();
      }, 100)
    } else {
      setTimeout(() => {
        this.adjust();
      }, 100)
    }

  }

  componentDidUpdate() {
    this.adjust();
    setTimeout(() => {
      this.adjust();
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.adjust.bind(this));
    window.removeEventListener('resize', this.adjust.bind(this));
    this.timmer && clearInterval(this.timmer);
  }
}