import styled from 'styled-components';

export const DropdownStyledWrapper = styled.div`
  position: relative;
  &.inline {
    display: inline-block;
  }
  .dropdown-toggle {
    display: flex;
    border: none;
    padding: 0;
    overflow: visible;
    cursor: pointer;
    >img {
      width: 12px;
      height: 12px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 3px;
      vertical-align: middle;
      margin-top: 4px;
      opacity: .8;
    }
  }
  .menu {
    right: 0;
    left: auto;
    top: 22px;
    position: absolute;
    min-width: 150px;
    font-size: 14px;
    font-weight: normal;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.16);
    white-space: nowrap;
    z-index: 300;
    color: #546379;
    background-color: white;
    padding: 10px 0;
    &.hidden {
      display: none;
    }
    &.hidden {
      display: none;
    }

    .menu-item {
      cursor: pointer;
      &:hover {
        color: #8cc152;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }
      a {
        padding: 10px 25px 10px 15px;
        display: inline-block;
        display: flex;
        align-items: center;
      }
      i {
        margin-right: 5px;
        display: inline-block;
        width: 20px;
        text-align: center;
        vertical-align: middle;
      }
    }
    .menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px 5px;
    }

    .options {
      list-style-type: none;
      list-style: none;
      margin: 0;
      padding: 0;
      padding-bottom: 10px;
      overflow: auto;
      max-height: 300px;
      &.empty {
        margin: 10px;
        color: #9BA3B0;
      }
    }
    li.option {
      font-weight: 300;
      font-size: 14px;
      color: #555;
      cursor: pointer;
      white-space: nowrap;
      min-width: 240px;
      max-width: 600px;
      white-space: normal;
      transition: all 0.2s ease;
      align-items: center;
      display: flex;
      position: relative;
      &.disabled {
        opacity: .5;
        pointer-events: none;
        cursor: not-allowed;
      }
      &:hover {
        background-color: #8cc152;
        color: white;
      }
      &.checked  {
        color: #8cc152;
        font-weight: bold;
        &:hover {
          background-color: #f1f1f1;
        }
        &:before {
          content: "\\f00c";
          font: normal normal normal 14px/1 FontAwesome;
          position: absolute;
          left: 18px;
          top: 50%;
          color: #8cc152;
          transform: translateY(-50%);
        }
      }
      label {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 20px;
        position: relative;
        word-break: break-word;
        cursor: pointer;
      }
      input[type=checkbox],
      input[type=radio] {
        visibility: hidden;
        cursor: pointer;
        margin-top: 2px;
        margin-right: 10px;
        flex: none;
      }

      input[type=checkbox] {
        font-size: 14px;
        opacity: .9;
        flex-shrink: 0;
        flex: none;
        &:checked {
          opacity: 1
        }
      }

    }
  }
`;