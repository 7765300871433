import React, {Component, createContext} from 'react';
import {post, get} from '../utils/request';
import {cacheResource} from '../utils/cache';
import Splash from '../uikit/splash/index';

const AppContext = createContext();

  export const getProviderInstance = () => AppContext.providerInstance;

export class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {appState: {}};
    this.syncSession();
    AppContext.providerInstance = this;
  }

  render() {
    const {sessionSynced} = this.state.appState;
    if (!sessionSynced) {
      return <Splash/>;
    }
    const {appState} = this.state;
    const setAppState = this.setAppState.bind(this);
    const login = this.login.bind(this);
    const logout = this.logout.bind(this);
    const syncSession = this.syncSession.bind(this);
    return (
      <AppContext.Provider value={{appState, setAppState, login, logout, syncSession, sessionSynced}}>
        {this.props.children}
      </AppContext.Provider>
    )
  }

  syncSession(newSession, callback) {
    if (newSession) {
      this.setAppState({sessionSynced: true, session: newSession}, callback);
    } else {
      return get(`/api/session`)
        .then(session => {
          session.rights = session.rights || [];
          if (!session.error) {
            this.setAppState({sessionSynced: true, session: session});
          } else {
            this.setAppState({sessionSynced: true});
          }
        })
        .catch(e => {
          this.setAppState({sessionSynced: true});
        });
    }
  }

  login(userId, password, rememberMe, client) {
    this.setAppState({loading: true});
    post('/api/authenticate', {userId, password, rememberMe, client})
      .then(session => {
        session.rights = session.rights || [];
        this.setAppState({session: session, loading: false});
      })
      .catch(e => {
        this.setAppState({error: e.message, loading: false});
      });
  }

  logout() {
    this.setAppState({loading: true});
    // window.sessionStorage.clear();
    // this.setAppState({session: null});
    // window.location.href = '/login';
    window.logout = true; // mark logout so that new login will not go to previous page
    return post('/api/logout', {})
      .then(session => {
        this.setAppState({session: null, error: null, loading: null});
        // window.location.href = '/login';
      }).catch(e => {
        this.setAppState({session: null, error: null, loading: null});
        window.location.href = '/login';
        // this.setAppState({error: e.message});
      });

  }

  setAppState(newState, callback) {
    this.setState({appState: {...this.state.appState, ...newState}}, callback);
  }
}

export const withConsumer = BaseComponent => {
  const defaultAppState = {};
  return class extends Component {
    render() {
      return (
        <AppContext.Consumer>
          {context => {
            return (
              <BaseComponent appState={defaultAppState} {...context} {...this.props} {...this.state} />
            );
          }}
        </AppContext.Consumer>
      );
    }
  }
};