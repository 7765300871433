import React, { useRef, useState } from "react";
import { Container, Wrapper } from "./accordion.style";

const AccordionItem = ({ question, children, isOpen, onClick }) => {
  const contentHeight = useRef();
  return (
    <Wrapper>
      <button
        className={`question-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <p className="question-content">{question}</p>
        {isOpen ? <i class="fa fa-caret-up" aria-hidden="true"></i>: <i class="fa fa-caret-down" aria-hidden="true"></i>}
      </button>

      <div
        ref={contentHeight}
        className="answer-container"
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <p className="answer-content">{children}</p>
      </div>
    </Wrapper>
  );
};

const Accordion = ({data}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Container>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          children={item.children}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </Container>
  );
};

export default Accordion;
