import React, {Component, useEffect} from 'react';
import {
  Aladin_Blue_Dark,
  Aladin_Orange_Dark, Aladin_Orange_Light,
  Aladin_BG_1, Aladin_Purple_Dark, Aladin_Purple_Light, Aladin_Red_Dark, Aladin_Red_Light,
  BitterSweet,
  BlueJeans,
  DarkGray, DarkGray1, GrapeFruit, Grass,
  LightGray1,
  MediumGray1, Mint, Sunflower, MediumGray, MediumGray2,
} from "../app/StyleCommon";
import styled from "styled-components";
import {Grass1, LightGray} from "../app/StyleCommon";
import {intFormatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import {attrMetaData, metricMetaData, platformLabelMap} from "../utils/metadata";
import _ from "lodash";
import * as echarts from "echarts";
import classNames from "classnames";
import {defaultTooltip, defaultWhiteTooltip, defaultWhiteTooltipTop} from "../uikit/tooltip";
import moment from "moment/moment";


export default class extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {data, benchmark, cfg = {}} = this.props;
    const meta = attrMetaData.find(d => d.key === cfg.metric) || {};
    const {formatter = (v => v)} = meta;
    return (
      <KeyMetric>
        <div className={classNames("basic")} style={{width: '100%'}}>
          <div className="chart-title top">{meta.label || data.key}</div>
          <div  className="bottom">
            {!data.value && <span>Data Not Available</span>}
            {!!data.value && formatter(data.value)}
          </div>
        </div>
      </KeyMetric>
    )
  }
}

export const KeyMetric = styled.div`
  text-align: left;
  padding: 5px 10px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  .basic {
    align-self: flex-start;
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 0px !important;
    margin-left: 0px !important;
    font-weight: normal;
    white-space: nowrap;
    color: ${MediumGray2};
  }
  .bottom {
    display: flex;
    align-items: center;
    color: ${DarkGray};
    font-weight: bold;
    font-size: 18px;
  }
  .desc {
    font-size: 12px;
    color: ${DarkGray};
  }
`;