import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import {SelectWrapper, SelectMenu, SelectToggler, MenuGroup, MenuItem, MenuGroupHeader} from './index.style'
import {FixedHook} from './hooks';

export default class extends Component {
  static defaultProps = {
    disabled: false,
    selected: [],
    defaultLabel: 'Select Option',
    labelPrefix: '',
    allSelectedLabel: undefined,
    labelIgnoreAllSelectedGroup: false,
    groupClosable: false, //if this is true, each group can perform open|close action
    groups: undefined, //if group is not empty, will render in group mode
    data: [], //if groups is empty, will render single group mode using 'data' array.
    multi: false, //multiple selection mode
    mutuallyExclusive: false, //if true, only one group allowed to be selected.
    showGroupBatch: false, //if true, every group will have a select all button.
    onChange: _ => undefined, // change handler
    disableFunc: _ => false, //no disable item
    searchable: false,
    inputable: false,
    toggleLableFunc: undefined,
    useFixed: false
  };

  constructor(props) {
    super(props);
    this.state = {active: false, }
    this.menuRef = React.createRef();
    this.wrapperRef = React.createRef();
    // if(props.groups && props.groups.length && typeof props.selected === 'string' ) {
    //   let selectedGroup = props.groups.find(g => !!(g.data || []).find(d => d.key === props.selected));
    //   if(selectedGroup && selectedGroup.key) {
    //     this.state.openState = {[selectedGroup.key]: true};
    //   }
    // }
  }


  // shouldComponentUpdate(nextProps, nextState) {
  //   // return false;
  //   return !_.isEqual(nextProps, this.props) || (this.state.active !== nextState.active);
  // }

  render() {
    const {
      className, disabled, selected = [], defaultLabel, labelPrefix,
      unSelectable, searchable, inputable, toggleLableFunc,
      tabIndex, useFixed, showCloseBtn, style, groups
    } = this.props;
    const {active} = this.state;
    let toggleLabel = this.serializeSelected();
    if (toggleLableFunc) {
      toggleLabel = toggleLableFunc(selected);
    }
    toggleLabel = toggleLabel || defaultLabel;
    const showClearBtn = (unSelectable && selected && !!selected.length);
    const {keyword} = this.state;
    return (
      <SelectWrapper ref={this.wrapperRef} className={`select-box ${className}`} disabled={disabled}
                     tabIndex={tabIndex} style={style} {...(_.pick(this.props, ['onMouseOver', 'onMouseLeave']))}>
        <SelectToggler
          className={`toggler ${active ? 'active' : ''} ${showClearBtn ? 'has-clear-btn' : ''} ${searchable ? 'searchable' : ''}`}
          title={typeof toggleLabel === 'string' ? toggleLabel : ''}
          disabled={disabled}
          unSelectable={unSelectable}
          onClick={e => this.handleToggle(e)}>
          {this.renderSelectedGroupIcon()} {labelPrefix} {toggleLabel}
          {
            (!!searchable || !!inputable) && !disabled &&
            <input
              type="text"
              className="search-input"
              autoComplete="false"
              placeholder={inputable ? 'Input...' : 'Search...'}
              value={(inputable ? selected : keyword) || ''}
              onFocus={e => {
                e.target.select();
                setTimeout(() => {
                  this.setState({active: true});
                }, 100)
              }}
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                this.setState({keyword: e.target.value});
                if (inputable) {
                  this.props.onChange(e.target.value);
                } else if (groups) {
                  this.setState({openState: groups.reduce((ret, next) => ({...ret, [next.key]: true}), {})})
                }
              }}/>
          }
          {showClearBtn &&
          <i className="clear-all fa fa-times" onClick={e => this.props.onChange(undefined)}/>
          }
          <i className="caret fa fa-caret-down"/>
        </SelectToggler>
        {this.renderGroups()}
        {!!useFixed && <FixedHook wrapperRef={this.wrapperRef} fixedRef={this.menuRef} offset={{top: 35}}/>}
      </SelectWrapper>
    );
  }

  renderGroups() {
    const {menuHeader, menuFooter, unSelectable, showCloseBtn, showEmptyGroup, searchFunc} = this.props;
    const {keyword} = this.state;
    const keywordLowerCase = (keyword || '').toLowerCase();

    const isMatch = (d, keyword) => {
      if(keyword === 'male' && /female/ig.test(d.label || '')) {
        return false;
      }
      return !keyword || (String(d.label || '')).toLowerCase().indexOf(keyword) >= 0 || (searchFunc && searchFunc(d, keyword));
    }
    let {groups, data} = this.props;

    if (groups && groups.length) {
      groups = groups.map(g => {
        return {
          ...g,
          data: g.data.filter(d => isMatch(d, keywordLowerCase))
        }
      });
      if(!showEmptyGroup || !!keyword) {
        groups = groups.filter(g => {
          return g.data.length || (keyword !== 'male' && g.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) ||
            (keyword === 'male' && g.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 && !/female/ig.test(g.label || ''));
        })
      }
      if (groups.length) {
        return (
          <SelectMenu className="menu" active={this.state.active}>
            {menuHeader}
            {groups.map(g => this.renderGroup(g))}
            {!!showCloseBtn && <div className="close-menu-btn" onClick={e => this.setState({active: false})}>Close</div>}
            {menuFooter}
          </SelectMenu>
        )
      }
    } else {
      data = data.filter(d => isMatch(d, keywordLowerCase));
      if (data.length) {
        return (
          <SelectMenu ref={this.menuRef} className="menu" active={this.state.active}>
            {menuHeader}
            {this.renderGroup({data: data})}
            {!!showCloseBtn &&
            <div className="close-menu-btn" onClick={e => this.setState({active: false})}>Close</div>}
            {menuFooter}
          </SelectMenu>
        )
      }
    }
  }

  renderGroup(g) {
    const {data, label: groupName, key: groupKey, icon} = g;
    const {selected, multi, showGroupBatch, allSelectedLabel, disableFunc, groupClosable, itemRenderer} = this.props;
    const {openState = {}} = this.state;
    const open = openState[groupKey];
    let groupSelectedInfo = ''
    if (showGroupBatch) {
      const inGroupSelected = selected.filter(v => data.find(d => d.key === v));
      if (inGroupSelected.length !== data.length) {
        groupSelectedInfo = `(${inGroupSelected.length} / ${data.length})`;
      }
    }

    return (
      <MenuGroup className="menu-group" key={groupKey || 'none'}>
        {
          groupName &&
          <MenuGroupHeader
            closable={groupClosable}
            onClick={e => this.handleGroupHeaderClick(e, groupKey, open)}>
            <h5>
              {icon && <i className={icon} style={{marginRight: '8px'}}/>}
              <span>{groupName} {groupSelectedInfo}</span>
            </h5>
            {groupClosable && <i className="caret fa fa-caret-down"/>}
          </MenuGroupHeader>
        }
        {
          !g.data || !g.data.length &&
            <div className="empty-group">-- no data --</div>
        }
        <ul className={`${groupClosable && !open ? 'closed' : 'open'}`}>
          {
            showGroupBatch &&
            <MenuItem key={groupKey}>
              <input
                type="checkbox"
                checked={data.every(d => selected.indexOf(d.key) >= 0)}
                onChange={e => this.handleGroupBatch(e, groupKey, e.target.checked)}/>
              <span>All {_.capitalize(groupKey)}</span>
            </MenuItem>
          }
          {data.map((d, i) => {
            let active = multi ? (selected || []).indexOf(d.key) >= 0 : selected === d.key;
            return (
              <MenuItem
                key={groupKey + d.key}
                title={typeof d.label === 'string' ? d.label : ''}
                disabled={disableFunc(d)}
                multi={multi}
                selected={active}
                className={active ? 'selected' : ''}>
                <input
                  disabled={disableFunc(d)}
                  type={multi ? 'checkbox' : 'radio'}
                  onClick={e => this.handleRadioBtnClick(e, d.key)}
                  checked={multi ? (selected || []).indexOf(d.key) >= 0 : selected === d.key}
                  onChange={e => this.handleChange(e, d.key, e.target.checked)}/>
                {itemRenderer ? itemRenderer(d, i) : d.label}
              </MenuItem>
            )
          })}
        </ul>
      </MenuGroup>
    );
  }

  handleGroupHeaderClick(e, groupKey, open) {
    const {openState} = this.state;
    this.setState({openState: {...openState, [groupKey]: !open}});
  }

  renderSelectedGroupIcon() {
    const {data, groups, selected} = this.props;
    if (groups && selected) {
      let currentGroup = groups.find(g => g.data.some(d => selected.indexOf(d.key) >= 0));
      if (currentGroup && currentGroup.icon) {
        return <i className={currentGroup.icon}/>
      }
    } else if (typeof selected === 'string') {
      let selectItem = data.find(d => d.key === selected);
      if (selectItem && typeof selectItem.icon === 'string') {
        return <img className="icon" src={selectItem.icon}/>
      }
    }
  }

  serializeSelected() {
    const {inputable,togglerTagMode,  groups, data, multi, selected, defaultLabel, mutuallyExclusive, allSelectedLabel, labelIgnoreAllSelectedGroup} = this.props;
    let allData = data;
    let searchableStyled = <span className="default-label">{defaultLabel}</span>
    if (groups && groups.length > 0 && !mutuallyExclusive) {
      let significants = groups.filter(g => {
        return g.data.some(d => {
          if (multi) {
            return selected.indexOf(d.key) < 0 && !g.data.every(d => selected.indexOf(d.key) >= 0);
          } else {
            return selected === d.key;
          }
        });
      });
      let selectedOption = _.flatten(significants.map(g => g.data)).filter(d => selected.indexOf(d.key) >= 0);
      if (selectedOption.length) {
        return selectedOption.map(d => d.label).join(', ');
      }
    } else if (groups && groups.length > 0 && mutuallyExclusive) {
      allData = _.flatten(groups.map(g => g.data));
    }
    if (multi) {
      if (allSelectedLabel && selected.length === allData.length) {
        return allSelectedLabel;
      } else if (groups && labelIgnoreAllSelectedGroup) {

      } else {
        if(togglerTagMode) {
          return (selected || [])
            .map(key => allData.find(d => d.key === key))
            .filter(d => !!d)
            .map(d => <span className={"tag-value"}>{d.label}</span>)
          // .join(', ') || searchableStyled;
        } else {
          return (selected || [])
            .map(key => allData.find(d => d.key === key))
            .filter(d => !!d)
            .map(d => d.label)
          .join(', ') || searchableStyled;
        }
      }
    } else {
      let item = allData.find(d => !!d && d.key === selected);
      return item ? item.label : ((inputable  && selected && selected.length > 0) ? selected : searchableStyled);
    }
  }

  handleToggle(e) {
    const {disabled} = this.props;
    if (!disabled) {
      this.setState({active: !this.state.active});
    }
  }

  handleGroupBatch(e, groupKey, checked) {
    const {groups, data} = this.props;
    let newSelected = this.props.selected;
    if (!groups) {
      newSelected = checked ? data.map(d => d.key) : [];
    } else {
      let currentGroup = groups.find(g => g.key === groupKey);
      //get rest selected
      newSelected = newSelected.filter(key => !currentGroup.data.find(d => d.key === key));
      if (checked) {
        newSelected = newSelected.concat(currentGroup.data.map(d => d.key));
      }
    }
    this.setState({keyword: null});
    this.props.onChange(newSelected);
  }

  handleChange(e, key, checked) {
    const {groups, multi, mutuallyExclusive, autoClose} = this.props;
    if (multi) {
      let newSelected = this.props.selected || [];
      if (checked) {
        if (groups && mutuallyExclusive) {
          let currentGroup = groups.find(g => g.data.find(d => d.key === key));
          newSelected = newSelected.filter(key => currentGroup.data.find(d => d.key === key));
        }
        newSelected.push(key);
      } else {
        newSelected = newSelected.filter(k => k !== key);
      }
      this.setState({keyword: null});
      this.props.onChange(newSelected);
      if(autoClose) {
        this.setState({active: false});
      }
    } else {
      this.props.onChange(key);
      this.setState({active: false, keyword: null});
    }
  }

  handleRadioBtnClick(e, key) {
    const {selected, multi, itemClicked} = this.props;
    const {active} = this.state;
    if (active && !multi && selected === key) {
      this.setState({active: false})
    }
    if(itemClicked) {
      itemClicked(e, key);
      if (active) {
        this.setState({active: false})
      }
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.setState({active: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        // console.error(e);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
}