import React from 'react';
import styled, {css} from 'styled-components';
import {
  MediumGray,
  FontSizeMedium,
  Mint1,
  BitterSweet1,
  Mint,
  BitterSweet,
  LightGray,
  LightGray1,
  DarkGray1,
  BlueJeans,
  BlueJeans1,
  MediumGray1,
  Aqua1,
  Grass1,
  Aladin_BG,
  Aladin_BG_1,
  Aladin_Orange_Dark,
  DarkGray
} from "../app/StyleCommon";
import {Spinner} from '../uikit/splash/index.style';

export const Wrapper = styled.div`
  background-color: white;
  padding: 10px 20px;

  h3 {
    display: flex;
    align-items: center;
    color: ${DarkGray1};
    margin-bottom: 30px;

    img {
      margin-right: 20px;
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
  }

  .tab-menu-wrapper {
      // background: ${LightGray};
    // padding: 5px 10px;
  }

  .role-module {
    margin-top: 20px;

    .custom-check-box {
      cursor: pointer;

      i {
        font-size: 18px;
        padding: 5px;

        &:hover {
          color: ${Grass1};
          background: ${LightGray};
        }

        &.checked {
          color: ${Grass1};
        }

        &.unchecked {
          color: ${BitterSweet1};
        }
      }
    }

    table {
      border-radius: 15px;

      thead {

        tr:first-child th {
          background: ${Aladin_BG};
          //background: linear-gradient(to left, #ececfa, #f8f9fa);
          text-align: left !important;
          border-bottom: none;

          * {
            font-weight: bold !important;
            margin-left: -5px !important;
          }
        }

        th {
          background: white;
          text-align: center;
          font-size: 12px;
          border-bottom: 1px solid ${LightGray1};
        }

        th:first-child {
          background: ${Aladin_BG};
          //background: linear-gradient(25deg, #eeeef8, #f8f9fa);
          min-width: 150px;
        }
      }

      tbody {
        td {
          background: white;
        }

        td:first-child {
          background: ${Aladin_BG};
          //background: linear-gradient(to right, #ececfa, #f8f9fa);
        }
      }
    }
  }

  .user-management-module,
  .audit-module {
    margin-top: 30px;
    table {
      border-radius: 15px;
      margin-top: 10px;
      thead {
        tr:first-child th {
          background: white;
        }

        th {
          background: white;
          font-size: 12px;
          border-bottom: 1px solid ${MediumGray};
        }
      }

      tbody {
      }
    }
    button {
      margin-left: 10px;      
    }
    .actions {
      a {
        cursor: pointer;
        margin: 0 5px;
        color: ${DarkGray};
        &:hover {
          color: ${Grass1};
          color: ${Aladin_Orange_Dark};
        }
        i {
          font-size: 20px;
          font-weight: normal !important;
        }
      }
    }
  }
`;

export const TableController = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 15px 0;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  .actions {
    display: flex;
    align-items: center;
    i {
      font-size: 16px !important;
    }
    button {
      display: flex;
      margin-left: 8px !important;
      margin-right: 0px !important;
    }
  }
  .searchBox {
    flex-grow: 1;
    i {
      position: absolute;
      left: 20px;
      top: 15px;
    }
    input {
      padding: 6px 10px 6px 30px;
      font-size: 12px;
      border-radius: 5px;
      background: ${LightGray};
      border: none;
      border: 1px solid ${MediumGray};
      width: 100%;
      box-sizing: border-box;
      outline: none;
      &:focus {
        border-color: ${BlueJeans};
        box-shadow: inset 0 0 3px rgba(0,0,0,.1);
      }
    }
  }
  button {
    //background-image: linear-gradient(20deg, burlywood, darksalmon, gold) !important;
    //box-shadow: 0px 1px 2px sandybrown;
    margin-left: 20px;
    text-decoration: none !important;

  }
  .select-box {
    margin-left: 20px;
    margin-right: 20px;
    .menu-header {
      padding: 10px 10px;
      margin-bottom: 10px;
      background: ${LightGray};
    }
    .toggler {
      border: none;
      padding: 0;
      display: flex;
      padding: 4px 0;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      width: auto;
      min-width: inherit;
      i {
        z-index: 1;
        display: none;
      }
    }
    .menu {
      max-height: 400px;
      right: 0;
      left: auto;
      overflow:auto;
    }
  }
`;