import React from 'react'
import {post} from '../utils/request';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper, SubmitBtn} from './login.style';
import {Link} from "react-router-dom";

export default class Login extends React.Component {

  state = {loading: false, error: null}

  forgotPassword(e) {
    e.preventDefault();
    var {email} = this.state;
    this.setState({success: false, loading: true, error: null});
    post('/api/forgotPW', {email})
      .then(result => {
        this.setState({loading: false, success: true});
      }).catch(e => {
      console.error(e);
      this.setState({loading: false, error: e.message});
    });
  }

  render() {
    const {userId, error, spinning, loading} = this.state;
    let hour = new Date().getHours();
    let daypart = (hour >= 5 && hour < 11) ? 'morning' : ((hour >= 11 && hour < 17) ? 'afternoon' : 'evening')
    // daypart = 'morning'
    return (
      <LoginWrapper className={daypart}>
        <form>
          <div className="form-title">
            <img src="/img/login/logo_white.png" />
          </div>
          <h3>Reset your password</h3>
          <p>Enter your user email address and we will send you a password reset link.</p>
          {
            !!error && <div className="error-msg">{error}</div>
          }
          <div>
            {this.state.success && <p style={{color: 'green'}}>New password will be sent to your email.</p>}
          </div>
          <div className="field">
            <input ref="userInput"
                   value={userId}
                   type="text"
                   placeholder="Mediacorp Email"
                   onChange={e => this.setState({email: e.currentTarget.value})}/>
          </div>

          <SubmitBtn  className={daypart} onClick={e => this.forgotPassword(e)}>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Reset Password
          </SubmitBtn>
          <div className="bottom-links">
            <Link to={'/login'}>Back</Link>
          </div>
        </form>
      </LoginWrapper>
    )
  }
}