import React, {Component, useEffect} from 'react';
import moment from 'moment';
import _ from 'lodash';
import ColorScale from "color-scales";
import {
  Aladin_BG, Aladin_BG_1, Aladin_BG_2, Aladin_Orange_Dark, Aladin_Orange_Lighter, BlueJeans, DarkGray,
  DarkGray1, Grass1, LightGray1, MediumGray,
  MediumGray1, MediumGray2,
} from "../app/StyleCommon";
import Table from "../uikit/table";
import classnames from "classnames";
import styled, {css} from 'styled-components';
import MonthPanel from '../uikit/DateSelector/MonthPanel';
import createTooltip, {
  defaultTooltip,
  defaultWhiteTooltip, defaultWhiteTooltipLeft,
  defaultWhiteTooltipRight,
  defaultWhiteTooltipTop
} from "../uikit/tooltip";
import {moneyFormatter} from "../utils/formatter";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &.size100 .month-panel {
    width: 50%;
  }

  &.column1 .month-panel {
    width: 100%;
  }

  &.column2 .month-panel {
    width: 50%;
  }

  &.column3 .month-panel {
    width: 33%;
  }

  &.column4 .month-panel {
    width: 25%;
  }

  &.column4 .month-panel {
    width: 25%;
  }

  .month-panel {
    width: 100%;
    min-width: 250px;
    box-sizing: border-box;

    .month-nav {
      .month-nav-btn {
        visibility: hidden
      }
    }

    table {
      min-width: 240px;
    }

    .date {
      min-height: 50px;
      border: none;
      color: ${DarkGray1} !important;
      background: transparent !important;
      font-size: 12px;
      vertical-align: top;
      min-width: 40px;
      position: relative;
      &.selected {
        color: ${DarkGray1} !important;
      }

      &.invalid {
        pointer-events: inherit;
      }

      .date-body {
        padding: 8px 2px 5px 2px;
        font-size: 12px;
        font-size: ${MediumGray1};
      }
      //&:nth-child(7n+2) .date-header,
      ////&:nth-child(7n+3) .date-header,
      //&:nth-child(7n+4) .date-header,
      ////&:nth-child(7n+5) .date-header,
      //&:nth-child(7n+6) .date-header{
      //  opacity: .1;
      //}
      &:hover .date-header {
        opacity: 1;
      }

      .date-header {
        opacity: .1;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(3px, 2px);
        z-index: 0;
        //background: rgba(255,255,255,.5);
        border-radius: 5px;
        padding: 0 2px;
        font-weight: normal;
        color: ${DarkGray} !important;
        padding: 0px;
        border-radius: 5px 5px 0 0;
        color: ${DarkGray} !important;
        font-size: 9px;
      }
    }

    .date-custom {
      border: 1px solid ${MediumGray};
      border-radius: 5px;
      background: #f1f2f3;
      &.empty .date-header {
        display: none;
      }
      &.done {
        background: rgba(89, 168, 187, 0.27);
      }

      &.future {
        border: 1px solid rgba(231, 172, 69, 0.63);;
        background: rgba(244, 193, 104, 0.8);
      }
    }


  }
}
`;

export default class extends Component {

  constructor(props) {
    super(props);
  }
  render() {
    let {data, cfg} = this.props;
    let {size, pagination_type} = cfg;
    const {period, data: dailyData} = data;
    let months = _.uniq(dailyData.map(d => d.date.substring(0, 7) + '-01'));
    if(months.length < 2 && pagination_type!== 'column1') {
      months.push(moment(months[0]).add(1, 'month').format('YYYY-MM-DD'));
    }
    if(!pagination_type) {
      pagination_type = ['column1', 'column2', 'column3', 'column4'][((months.length - 1) % 4)];
      if(months.length >= 4) {
        pagination_type = 'column4';
      }
    }
    const groupByDate = _.groupBy(dailyData, _.property('date'));
    const whitespace = <div style={{height: '15px'}}/>
    const maxSpots = _.max(dailyData.map(d => d.spots || (d.pt || 0) + (d.opt || 0) + (d.vc|| 0)));
    const colorScale = v => new ColorScale(0, maxSpots, ['#6eb3c4', '#436265'], 1).getColor(v).toHexString();
    const colorScaleBG = v => new ColorScale(0, maxSpots, ['#f6fbfd', '#b2d5e3'], 1).getColor(v).toHexString();
    const colorScaleBGFuture = v => new ColorScale(0, maxSpots, ['#faf4ef', '#f8e3d4'], 1).getColor(v).toHexString();
    return (
      <Wrapper className={classnames(size, pagination_type, {multi: months.length > 1})}>
        {
          months.map((monthDate, monthIndex) => {
            return (
              <MonthPanel
                key={monthDate}
                period={period}
                date={monthDate}
                dateRenderer={(date, displayDate, cIndex) => {
                  let item = (groupByDate[date] || [])[0] || {};
                  let spots = item.spots || (item.pt || 0) + (item.opt || 0) + (item.vc|| 0);
                  let defaultTooltip = defaultWhiteTooltipTop;
                  if(cIndex < 2 && monthIndex % (pagination_type.substring(6)) === 0) {
                    defaultTooltip = defaultWhiteTooltipRight
                  }
                  else if (cIndex >= 5 && (monthIndex + 1) % (pagination_type.substring(6)) === 0) {
                    defaultTooltip = defaultWhiteTooltipLeft
                  }
                  return (
                    <div
                      style={{background: !spots ? 'rgba(255,255,255,.3)':  (item.future ? colorScaleBGFuture(spots) :colorScaleBG(spots))}}
                      className={classnames('date-custom', {empty: !item.spots, done: (item.spots && !item.future), future: item.future})}
                      onMouseOver={!item.programs ? undefined : defaultTooltip.onMouseOver({tipContent: (
                          <div style={{maxHeight: '400px', overflow: 'auto'}}>
                            <p>{spots} Spots ({moment(date).format('MMM DD YYYY')})</p>
                            {
                              (item.programs. length ? item.programs : []).map(d => {
                                let date = moment(d.date).format('hh:mm A');
                                return (
                                  <div key={d.date} style={{fontSize: '11px', marginBottom: '5px'}}>
                                    <div style={{color: DarkGray}}>{d.channel} -> {date !== "Invalid date" ? date : ''} -> <span>{d.title} ({d.marketing_scheme_type || d.spot_type})</span></div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )})}
                      onMouseLeave={defaultTooltip.onMouseOut()}>
                      <div className={"date-header"}>{displayDate}</div>
                      <div className={"date-body"}>
                        {!!spots &&
                          <div style={{color: colorScale(spots)}}>
                            {spots}
                            {/*{months.length < 4  && <sub style={{fontSize: '8px'}}>&nbsp;spots</sub>}*/}
                          </div>
                        }
                        {!spots && <div style={{height: '20px'}}>{whitespace}</div>}
                      </div>
                    </div>
                  )
                }}
                onDateSelected={e => null}
                onDragSelection={e => null}/>
            )
          })
        }

      </Wrapper>
    )
  }
}
