import React from 'react';
import {findDOMNode} from 'react-dom';
import {Link, withRouter, useHistory} from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';
import {post, get} from '../../utils/request';
import {
  AutoCompleteWrapper,
  SearchBox,
  NoData,
  Dropdown,
  DropdownItem,
  DropdownGroupTitle,
  ShowMore,
  ShowMoreLink,
  SitesAvailableWrapper
} from "./index.style";
import {Hint} from '../index'
import {Spinner} from "../splash/index.style";
import classNames from "classnames";
import {withConsumer} from "../../app/ApplicationContext";
import {inflightStatusMap, platformLabelMap} from "../../utils/metadata";
import {moneyFormatter} from "../../utils/formatter";
import {CondirmDialog} from "../../uikit";
import MsqTable from './msqTable';
import Highlighter from 'react-highlight-words';


export function Hightlight(props) {
  return (
    <Highlighter searchWords={(props.keyword || '').split(' ')}
                 autoEscape={true}
                 textToHighlight={props.text || (props.children.join(' '))}/>
  )
}


export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {active: false, activeMode: props.activeMode};
    this.searchDebounced = _.debounce(this.search.bind(this), 500);
  }

  componentWillReceiveProps(props) {
    this.setState({
      ...this.state,
      activeMode: props.activeMode,
      keyword: props.value
    });
  }

  componentDidUpdate() {
    const {activeIndex = 0} = this.state;
    if (this.dropdown) {
      this.dropdown.scrollTop = activeIndex * 40;
    }
  }

  render() {
    const {placeholder,disabled, selected, selectedRaw, variant="", selectedItemRenderer} = this.props;
    const {activeMode, data, active, focused, wideMode, searching, keyword, error} = this.state;
    return (
      <AutoCompleteWrapper activeMode={activeMode} className={this.props.className}>
        <SearchBox searching={searching} onKeyDown={e => this.handleKeyDown(e)}>
          {
            !!selectedRaw && !focused&& !!selectedItemRenderer &&
            <span className="select-value" title={selectedItemRenderer(selectedRaw)}>{selectedItemRenderer(selectedRaw)}</span>
          }
          {
            (!selectedRaw || !selectedItemRenderer) && !focused  &&
            <span className="select-value" title={selected}>{selected}</span>
          }
          {
            !!selectedRaw && !!focused  &&
            <span className="select-value peep" title={selected}>{selected}</span>
          }

          <input
            ref="searchbox"
            type="text"
            disabled={disabled}
            className={classNames({show: (focused || !selected), wideMode})}
            placeholder={placeholder}
            value={keyword || ''}
            onFocus={e => {
              this.setState({focused: true});
              this.onFocusHandler(e);
              this.searchDebounced();
            }}
            onBlur={e => {
              this.setState({focused: false});
            }}
            onInput={e => {
              this.setState({keyword: e.target.value, activeIndex: undefined});
              this.searchDebounced();
            }}/>
          {!!searching && <Spinner className="spinner" size="S"/>}

          {!searching && <i className="fa fa-search" onClick={e => {
            this.refs.searchbox.focus();
            this.setState({activeMode: true});
          }}/>}
          {
            !!error &&
            <Hint className="hint-wrapper" pos="bottom" width="100px" type="error" content={error}/>
          }
        </SearchBox>
        {
          active && data &&
          this.renderDropdown()
        }
      </AutoCompleteWrapper>
    )
  }

  renderDropdown() {
    const {itemRenderer, closeOnItemClick, variantType, variant} = this.props;
    const {data, active, searching, keyword, activeIndex} = this.state;
    const {result} = data;
    const {session = {}} = this.props.appState || {};
    let canViewCampaignList = (session.rights || []).indexOf('View Campaign List Page') >= 0;
    let canViewReportList = (session.rights || []).indexOf('View Reports List') >= 0;
    let canViewUserList = (session.rights || []).indexOf('View System User List') >= 0;

    if (!result || Object.keys(result).every(k => !result[k].length)) {
      return (
        <Dropdown className="auto-complete-dropdown">
          <NoData>No Data Found...</NoData>
        </Dropdown>
      )
    }
    return (
      <Dropdown
        className="auto-complete-dropdown"
        innerRef={comp => this.dropdown = comp}>
        {Object.keys(result).map(type => {
          let items = result[type];
          if(type === "Users") {
            if (variantType === "planner") {
              let normalUsers = items.filter(user => !user.roles.includes('Viewer'));
              let viewerUsers = items.filter(user => user.roles.includes('Viewer'));
              let users = normalUsers.sort((a,b) => a.name.localeCompare(b.name)).concat(viewerUsers)
              items = users;
            } else {
              items = items.sort((a,b) => a.name.localeCompare(b.name))
            }
          }
          if (!items.length ||
            (type === 'campaigns' && !canViewCampaignList) ||
            (type === 'reports' && !canViewReportList) ||
            (type === 'users' && !canViewUserList)
          ) {
            return null;
          }
          return (
            <div key={type} className='dropdown-item'>
              <DropdownGroupTitle>{type.toLowerCase() === 'msqs' ? type.toUpperCase() : _.capitalize(type)}:</DropdownGroupTitle>
              {items.map((d, dIndex) => {
                if(itemRenderer) {
                  if(closeOnItemClick) {
                    return (
                      <div key={d.id + '_' + dIndex} onClick={e => {
                        this.setState({active: false})
                      }}>{itemRenderer.call(this, d)}</div>
                    )
                  }else {
                    return itemRenderer(d);
                  }
                }
                if (type === 'campaigns') {
                  return this.renderCampaignItems(d, keyword, dIndex);
                } else if (type === 'reports') {
                  return this.renderReportItems(d, keyword, dIndex);
                } else if (type === 'users') {
                  return this.renderUserItems(d, keyword, dIndex);
                } else if (type === 'msqs') {
                  return this.renderMsqItems(d, keyword, dIndex)
                }
              })}
              {variant && variant.toLowerCase() === "global" && items && items.length > 5 && this.renderViewAllBtn(keyword, type)}
              {/*{*/}
              {/*  items.length > 2 &&*/}
              {/*  <ShowMore onClick={e => this.showMore(type)}>*/}
              {/*    More {type} <i className="fa fa-angle-double-right"/>*/}
              {/*  </ShowMore>*/}
              {/*}*/}
            </div>
          )
        })}
      </Dropdown>
    )
  }

  renderCampaignItems(d, keyword, dIndex) {
    let inflightStatus = inflightStatusMap[d.inflight_status];
    let platforms = (d.platforms || []).filter(p => p.key !== 'overall').map(p => platformLabelMap[p.key]).join(', ');
    return (
      dIndex < 5 && <DropdownItem key={d.id}>
        <Link to={`/campaign/details?id=${d.id}`}>
          <div>
            <div style={{marginBottom: '5px'}}>
              <Hightlight keyword={keyword}> ({d.name} ({d.id})</Hightlight>
            </div>
          </div>
          <div className="desc">
            <b className='mr4'><Hightlight keyword={keyword}> {moment(d.start_date).format('MMM DD YYYY')}</Hightlight></b>
            -
            <b className='ml4'><Hightlight keyword={keyword}> {moment(d.end_date).format('MMM DD YYYY')}</Hightlight></b>
            <span className='mr4'><Hightlight keyword={keyword}> ({platforms})</Hightlight>,</span>
            <span className='mr4'>Advertiser: <b><Hightlight keyword={keyword}> {d.advertiser}</Hightlight></b></span>
            <span className='mr4'>Inflight Status: <b><Hightlight keyword={keyword}> {inflightStatus}</Hightlight></b></span>
          </div>
        </Link>
      </DropdownItem>
    )
  }

  renderReportItems(d, keyword, dIndex) {
    return (
      dIndex < 5 && <DropdownItem key={d.id}>
      <Link to={`/report/builder?id=${d.id}`}>
        <div>
          <div style={{marginBottom: '5px'}}><Hightlight keyword={keyword}> {d.name}</Hightlight></div>
        </div>
        <div className="desc">
          <span className='mr4'>Created at: <b><Hightlight keyword={keyword}> {moment(d.create_date).format('MMM DD YYYY hh:mm A')}</Hightlight></b></span>
          -
          <span className='ml4 mr4'>Last updated <b><Hightlight keyword={keyword}> {moment(d.update_date).fromNow()}</Hightlight></b></span>
          <span>Creator: <b><Hightlight keyword={keyword}> {d.creator}</Hightlight></b></span>
        </div>
      </Link>
    </DropdownItem>
    )
  }

  renderUserItems(d, keyword, dIndex) {
    return (
      dIndex < 5 && <DropdownItem key={d.id}>
        <Link to={`/admin/user/settings?id=${d.id}`}><Hightlight keyword={keyword}> {d.name} ({d.id})</Hightlight></Link>
      </DropdownItem>
    )
  }

  renderMsqItems(d, keyword, dIndex) {
    let platformsGrouped = _.values(_.mapValues(_.groupBy(d.platforms || [], item => {
      return item.platform + item.platform_campaign_id + item.name;
    }), (arr, key) => {
      return arr.reduce((ret, next) => {
        if(ret) {
          return {
            ...ret,
            net: (ret.net || 0) + (next.net || 0),
            start_date: _.min([ret.start_date, next.start_date]),
            end_date: _.max([ret.end_date, next.end_date]),
          }
        } else {
          return next;
        }
        return ret;
      }, null)
    }));
    return (
      dIndex < 5 && <DropdownItem key={d.id} onClick={() => this.openStream('single', d.id, keyword)}>
        <div className='msq-item-option'>
          <span className="msq-name">
          <Hightlight keyword={keyword}> {d.name}, MSQ ID: ${d.id}</Hightlight>
          </span>
          <span className="msq-tag-list">
            <span className="msq-tag">
            <Hightlight keyword={keyword}> (Period: {moment(d.start_date).format('DD MMM YYYY')} - {moment(d.end_date).format('DD MMM YYYY')})</Hightlight>
            </span>
            <span className="msq-tag">
            <Hightlight keyword={keyword}> Advertiser: {d.advertiser}</Hightlight>
            </span>
            <span className="msq-tag">
            <Hightlight keyword={keyword}> Net: {moneyFormatter(d.budget, d.currency)} {d.currency}</Hightlight>
            </span>
          </span>
        </div>
        <ul className="msq-platform-list">
          {_.sortBy(platformsGrouped, _.property('platform')).filter(p => p.platform !== 'overall').map(p => {
            return (
              <li key={p.platform_campaign_id}>
                <div className="msq-tag-list">
                  <span className="msq-tag">
                  <Hightlight keyword={keyword}> {platformLabelMap[p.platform]} - {p.platform_campaign_id} - {p.name} - {p.product}</Hightlight>
                  </span>
                  <span className="msq-tag">
                  <Hightlight keyword={keyword}> Period: {moment(p.start_date).format('DD MMM YYYY')} - {moment(p.end_date).format('DD MMM YYYY')}</Hightlight>
                  </span>
                  <span className="msq-tag">
                  <Hightlight keyword={keyword}> Net: {moneyFormatter(p.net, p.currency)} {p.currency}</Hightlight>
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
      </DropdownItem>
    )

}

  renderViewAllBtn(keyword, type) {
    return type !== "msqs" ? <ShowMoreLink href={this.getLink(keyword, type)}>View All<i class="material-symbols-outlined">keyboard_double_arrow_right</i></ShowMoreLink>: <ShowMoreLink onClick={() => this.openStream('all', '', keyword)}>View All<i class="material-symbols-outlined">keyboard_double_arrow_right</i></ShowMoreLink>
  }

  getLink(keyword, type) {
    if(type === "campaigns") {
        return `/campaigns?search=${keyword}`
      }
      if(type === "reports") {
        return `/reporting?search=${keyword}`
      }
      if(type === "users") {
        return `/admin/users?search=${keyword}`
      }
  }

  onFocusHandler(e) {
    if (!this.state.wideMode) {
      this.setState({wideMode: true});
    }
  }

  onBlurHandler(e) {
    const {wideMode, active} = this.state;
    if (wideMode && !active) {
      this.setState({wideMode: false});
    }
  }

  handleCancelClicked(e) {
    if (this.props.onCancel) {
      this.props.onCancel(e);
    }
    this.setState({activeMode: false})
  }

  showMore(type) {
    this.props.history.push({
      pathname: `/${type}`,
      state: {}
    });
  }

  findIcon(property) {
    return null;
  }

  openStream(type, id, keyword) {
    const {data} = this.state;
    const {msqs} = data.result;

    let getMsqById = msqs.find(x => x.id === id)?.id;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: 'MSQ Search',
      width: '900px',
      hideCancel: true,
      hideOK: true,
      // onConfirm: () => CondirmDialog.showNextScreen(<>Hello</>, '1'),
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <MsqTable id={type==="all" ? null: getMsqById} type={type} keyword={keyword} confirmInfo={CondirmDialog}/>
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }


  search() {
    const {api = '/api/search', apiParams = {}, searchWithoutKeyword, limit} = this.props;
    const {keyword} = this.state;
    this.setState({error: false, data: undefined, active: false});
    if (keyword || searchWithoutKeyword) {
      this.setState({searching: true});
      post(api, {keyword: keyword, limit: limit || 50, offset: 0, ...apiParams})
        .then(searchResult => {
          this.setState({data: searchResult, active: true, searching: false});
        })
        .catch(e => {
          this.setState({error: e.message, active: false, searching: false});
        });
    } else {
      this.setState({active: false, data: null});
    }
  }

  handleKeyDown(e) {
    const {data, activeIndex, keyword, type} = this.state;
    const {items = [], count} = data || {};
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38) { //up
      this.setState(prevState => {
        let newIndex = (prevState.activeIndex || 0) - 1;
        return {activeIndex: newIndex < 0 ? 0 : newIndex}
      })
    }
    else if (e.keyCode === 40) { //down
      this.setState(prevState => {
        let newIndex = isNaN(prevState.activeIndex) ? 0 : prevState.activeIndex + 1;
        return {activeIndex: newIndex >= items.length ? items.length - 1 : newIndex}
      })
    }
    else if (e.keyCode === 13 && activeIndex >= 0) {
      e.preventDefault();
      let {type} = items[activeIndex] || {};
      window.location.href = `/${type}?`;
    }
  }

  handleClickOutside(event) {
    let {active} = this.state;
    if (active) {
      try {
        const self = findDOMNode(this)
        if (!self || !self.contains(event.target)) {
          this.setState({active: false, wideMode: false});
        }
      } catch (e) {
        //happens when the dom is already destroyed
        // console.error(e);
      }
    }
  }
git 
  componentDidMount() {
    this.search = _.debounce(this.search.bind(this), 400);
    document.addEventListener('click', this.handleClickOutside.bind(this), true); // capture phase
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside.bind(this), true);
  }
});
