import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {
  FilterTagWrapper,
  FilterDropdown
} from './FilterBox.style';
import {SelectStyled} from "./select.style";
import moment from "moment";
import {Btn} from "../uikit";
import DatePicker from "react-datepicker";
import {campaignStatusMap, inflightStatusMap, platformLabelMap, releaseStatusMap} from "../utils/metadata";
const opMap = {
  $match: 'Contains',
  $nmatch: 'Do not Contains',
  $contain: 'Contains',
  $ncontain: 'Do not Contains',
  $is: 'Is',
  $isnot: 'Is not',
  $in: 'is',
  $nin: 'Is not',
  $lt: 'less than',
  $gt: 'greater than',
}
export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.togglerRef = React.createRef();
    this.menuRef = React.createRef();
    this.state = {
      filters: props.filters || [],
      activeTag: null,
      dropdownKey: Date.now(),
      selected: {
        column: null,
        op: null,
        value: null,
      }
    }
  }

  mapFilterValue(column, key, value) {
    value = value || `''`;
    if(key === 'platform_keys') {
      return platformLabelMap[value] || value;
    }
    else if(key === 'inflight_status') {
      return inflightStatusMap[value] || value;
    }
    else if(key === 'health_status') {
      return campaignStatusMap[value] || value;
    }
    else if(key === 'release_status') {
      return releaseStatusMap[value] || value;
    }
    else if (column.options2) {
      let optionMeta = column.options2.find(opt => opt.key === value);
      if(optionMeta) {
        return optionMeta.label;
      }
    }
    return value;
  }

  render() {
    const {filterMetadata, supportedColumns} = this.props;
    const {filters, selected, activeTag, dropdownKey} = this.state;
    const activeTagIndex = filters.findIndex(f => f.key === activeTag);
    return (
        <FilterTagWrapper>
          <i className="caret fa fa-filter"/>
          <div className="tag-list" ref={this.togglerRef}>
            {
              filters.map((d, index) => {
                let val = d.value;
                let column = supportedColumns.find(column => column.key === d.key);
                if(Array.isArray(val)) {
                  val = val.map(v => this.mapFilterValue(column, d.key, v)).join(', ');
                } else {
                  val = this.mapFilterValue(column, d.key, val);
                }
                return (
                  <div className="tag" key={d.key}>
                  <span onClick={e => {
                    this.setState({activeTag: activeTag ? null : d.key})
                  }}>
                    <strong>{column.label}</strong>
                    <span style={{margin: '0 5px'}}>{String(opMap[d.op] || d.op).toLowerCase()}</span>
                    <strong>{val}</strong>
                  </span>
                    <i className="fa fa-times" onClick={e => {
                      filters.splice(index, 1);
                      this.setState({filters: filters, activeTag: null});
                      this.props.onChange(filters);
                    }}/>
                    {
                      activeTag === d.key &&
                      this.renderCriteria(d, column,
                        (filter => {
                          Object.keys(filter).forEach(k => {
                            d[k] = filter[k];
                          })
                          this.setState({filters});
                        }).bind(this),
                        ((e) => {
                          this.setState({filters, activeTag: null});
                          this.props.onChange(filters);
                        }).bind(this)
                      )
                    }
                  </div>
                )
              })
            }
            <FilterDropdown key={dropdownKey} onToggerClicked={() => {
              this.setState({activeTag: null});
            }} toggler={(
              <div style={{width: '120px'}}> Add filter</div>
            )}>

              {
                !selected.column &&
                <div style={{overflow: 'auto', maxHeight: '400px'}}>
                  <h3>Columns: </h3>
                  {supportedColumns.filter(d => !!d.filterOps).map(d => {
                    return (
                      <div
                        className="menu-item"
                        key={d.key}
                        onClick={e => {
                          this.setState({selected: {column: d, op: d.filterOps[0]}});
                        }}>{d.label}</div>
                    )
                  })
                  }
                </div>
              }
              {
                !!selected.column && this.renderCriteria(selected, selected.column, filter => {
                    if(!filter) {
                      return this.setState({selected: {}})
                    }
                    this.setState({selected: {...selected, ...filter}})
                  }, () => {
                    filters.push({key: selected.column.key, op: selected.op, value: selected.value});
                    this.setState({dropdownKey: Date.now(), filters: filters, selected: {}, activeTag: null});
                    this.props.onChange(filters);
                  }
                )

              }
            </FilterDropdown>
          </div>
          {
            filters.length > 0 &&
            <a className="clearAll" onClick={e => {
              this.setState({filters: [], selected: {}});
              this.props.onChange([]);
            }}>
              <i className="fa fa-times"/> Clear Filters
            </a>
          }
        </FilterTagWrapper>

    )
  }

  renderCriteria(filter, column, onChange, onApply) {
    const {filterMetadata} = this.props;
    const isMulti = ['$in', '$nin', '$contain', '$ncontain'].indexOf(filter.op) >= 0;
    return (
      <div className="criteria"  ref={this.menuRef}>
        <div className="criteria-header">
          <div>{column.label}</div>
          <a onClick={e => {
            this.setState({selected: {}, activeTag: null});
          }}><i className="fa fa-times" aria-hidden="true"></i></a>
        </div>
        <div className="criteria-op">
          <SelectStyled
            plainStyle={true}
            selected={filter.op}
            data={column.filterOps.map(op => {
              return {key: op, label: opMap[op] || op};
            })}
            onChange={op => onChange({op: op})}
            useFixed={true}/>
        </div>
        <div className="criteria-value">
          {
            (filter.op === '$match' || filter.op === '$nmatch') &&
            <input
              type="text"
              value={filter.value}
              placeholder={'keyword'}
              onChange={e => onChange({value: e.currentTarget.value})}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onApply(e);
                }
              }}
            />
          }
          {
            (['$gt', '$lt'].indexOf(filter.op) >= 0) &&
            <>
              {
                column.datatype !== 'datetime' &&
                  <input
                    type="number"
                    value={filter.value}
                    onChange={e => onChange({value: e.currentTarget.value})}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        onApply(e);
                      }
                    }}
                  />
              }
              {
                column.datatype === 'datetime' &&
                <DatePicker
                  selected={filter.value ? moment(filter.value).toDate() : null}
                  onChange={date => {
                    onChange({value: date ? moment(date).format('YYYY-MM-DD') : null})
                  }}
                  showTimeSelect={false}
                  timeCaption="date"
                  dateFormat="MMMM d, yyyy"/>
              }
            </>
          }
          {
            (['$is', '$isnot', '$in', '$nin', '$contain', '$ncontain'].indexOf(filter.op) >= 0) &&
            <SelectStyled
              multi={isMulti}
              plainStyle={true}
              useFixed={true}
              autoClose={true}
              searchable={true}
              selected={filter.value || (isMulti ? [] : null)}
              data={column.options2 || (filterMetadata[column.key] || column.options || []).map(k => {
                let option = ({
                  key: k, label: ({...releaseStatusMap, ...inflightStatusMap, ...campaignStatusMap})[k] || k
                });
                if(column.key === 'platform_keys') {
                  option.label = platformLabelMap[k]
                }
                return option;
              })}
              onChange={v => onChange({value: v})}/>
          }
        </div>
          {
            !!onApply &&
            <div className="apply-btn">
              <Btn
                type="primary"
                disabled={filter.value === undefined}
                onClick={e => {
                  this.setState({selected: {}});
                  onApply(e)
              }}>Apply</Btn>
            </div>
        }
      </div>
    )
  }

  handleClick(e) {
    var toggler = this.togglerRef.current;
    var menu = this.menuRef.current;
    if (!toggler || !menu) {
      return;
    }
    let outSideClicked = toggler !== e.target && !toggler.contains(e.target) && menu !== e.target && !menu.contains(e.target);
    if (this.state.activeTag && outSideClicked) {
      this.setState({activeTag: null});
    }
    if (Object.keys(this.state.selected).length > 1 && outSideClicked) {
      this.setState({selected: {}, dropdownKey: Date.now()});
    }
  }

  componentWillMount() {
    if (global.document) {
      global.document.addEventListener('click', this.handleClick.bind(this), true); // capture phase
    }
  }

  componentWillUnmount() {
    if (global.document) {
      global.document.removeEventListener('click', this.handleClick.bind(this), true);
    }
  }
  componentWillReceiveProps(props) {
    this.setState({selected: {}})
  }
})