import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';


export const supportedColumns = [
  {
    hideDefault: true,
    key: 'index',
    label: 'No.',
    align: 'center',
    sortable: true,
    style: {paddingTop: '12px', maxWidth: '20px'},
    renderer: (d, i, context) => ((context.page - 1) * context.PAGE_SIZE) + i + 1
  },
  {
    key: 'id',
    label: 'Email',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: (d, i, context) => {
      const {session} = context;
      let canModifyUser = session.rights.indexOf('Modify User') >= 0;
      if(!canModifyUser) {
        return d.id;
      }
      return <Link to={`/admin/user/settings?id=${d.id}`}>{d.id}</Link>
    }
  },
  {
    key: 'name',
    label: 'User Name',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
  },
  {
    key: 'sales_code',
    label: 'User Id',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: d => (d.sales_code || '-')
  },
  {
    key: 'department',
    label: 'Department',
    align: 'left',
    sortable: true,
    filterOps: ['$match', '$nmatch'],
    renderer: d => (d.department || '-')
  },
  {
    key: 'roles',
    label: 'Roles',
    align: 'left',
    sortable: true,
    filterOps: ['$contain', '$ncontain'],
    options2: ['Administrator', 'Expert User Level 2', 'Expert User Level 1', 'Viewer'].map(k => ({key: k, label: k})),
    renderer: d => (d.roles || []).join(', ')
  },
  {
    key: 'create_date',
    label: 'Created Date',
    align: 'left',
    sortable: true,
    renderer: d => {
      return moment(d.create_date || d.register_date).format('MMM DD YYYY, hh:mm A');
    },
  },
  {
    key: 'lastLogin',
    label: 'Last Login Date',
    align: 'left',
    sortable: true,
    renderer: d => d.lastLogin ? moment(d.lastLogin).format('DD MMM YYYY HH:mm'): '--'
  }
]