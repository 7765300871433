import React, {Component} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {withConsumer} from '../app/ApplicationContext';
import {MainWrapper, SideMenuWrapper, HeaderSection, UserMenuDropdown} from './index.style.aladdin';
import {LogoWrapper, SideMenuList, SideMenuItem, BodySection, BodyMask, Breadcrumb} from './index.style.aladdin';
import menuData from './menu';
import {CondirmDialog, Loader} from '../uikit/index';
import createTooltip from '../uikit/tooltip/index';
import SearchBox from '../uikit/search/index';
import Notification from "./notification";
import Chat from "./chat";
import Faq from "./faq";
import classNames from "classnames";

let cachedOpen = window.innerWidth >= 2000;
export default withConsumer(class extends Component {

  constructor(props) {
    super(props);
    this.state = {open: cachedOpen, closeMap: {}};
    this.tooltip = createTooltip({
      targetSelector: 'dynamic',
      tipCreator: d => `<strong>${d.label}</strong>`,
      directionFunc: 'right'
    });
  }

  useTVDemoTheme(session) {
    //temp solution to make sure it display correctly on tv.
    //these people may do demo on a tv screen,
    return window.innerWidth > 1900 && window.innerWidth < 1925 && session && ['demo', 'yangzhenghan', 'ernestchewch', 'mike', 'shoaib'].some(kw => String(session.userId).toLowerCase().indexOf(kw) >= 0);
    // return false;
    // return window.innerWidth > 1900 && session.userId.indexOf('demo') >= 0;
  }

  render() {
    const {activeItem, confirmInfo, withChat} = this.props;
    const {appState, loading} = this.props;
    const {session = {}, tvDemoTheme} = appState || {};
    const {open, closeMap} = this.state;
    const isMobile = window.innerWidth <= 768;
    const menuDataFlat = _.flatten(menuData.map(d => [d, ...((d.data || []).map(x => ({...x, parent: d.key})))]));
    let hour = new Date().getHours();
    let daypart = (hour >= 5 && hour < 11) ? 'morning' : ((hour >= 11 && hour < 17) ? 'afternoon' : 'evening')
    daypart = 'evening';
    if (!session) {
      return (
        <MainWrapper>
          {this.props.children}
        </MainWrapper>
      );
    }
    return (
      <MainWrapper open={open} tvDemoTheme={this.useTVDemoTheme(session)} withChat={withChat}>
        {
          !!confirmInfo &&
          <CondirmDialog type="fixed" fullScreen={true} {...confirmInfo} />
        }
        <HeaderSection id="header">
          {/*<div className="searchBox">*/}
          {/*  <i className="fa fa-search"/>*/}
          {/*  <input className="search-box" type="search" placeholder="Search..."/>*/}
          {/*</div>*/}
          <SearchBox className="searchBox" variant="global" placeholder="Search..."/>
          <div className="right-side">

            {/*<Faq/>*/}
            <Notification/>
            <UserMenuDropdown toggler={
              <div className="toggler-flex">
                {/*<div className="name">*/}
                {/*  <div>{session.name}</div>*/}
                {/*  /!*<div>{session.userId}</div>*!/*/}
                {/*</div>*/}
                {/*<img src={`/img/user.png`} alt="avatar"/>*/}
                <div className="initial">{this.getUserInitial(session.name)}</div>
                {/*<i className="caret fa fa-ellipsis-v"/>*/}
              </div>
            }>
              <div className="user-info">
                <div className="name">{session.name || 'Demo User'}</div>
                <div className="email">{session.userId || 'demo@mediacorp.com.sg'}</div>
              </div>
              <div className="menu-item">
                <Link to="/profile"><i className="fa fa-address-book"/>My Profile</Link>
              </div>
              <div className="menu-item">
                <a onClick={e => this.props.logout()}><i className="fa fa-sign-out"/>Sign Out</a>
              </div>
            </UserMenuDropdown>
          </div>
        </HeaderSection>
        <SideMenuWrapper id="sidebar">
          <LogoWrapper className={daypart}>
            <a onClick={e => {
              this.setState({open: !open}, () => {
                cachedOpen = this.state.open;
              })
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 210)
            }}>
              {open && <i className="fa fa-bars"/>}
              {!open && <img src={`/img/login/logo_short_morning.png`} style={{width: '32px', marginLeft: '-10px', cursor: 'pointer', borderRadius: '50%', border: '1px solid rgba(255,255,255,.05)'}}/>}
            </a>
            <Link className="logo" to={'/'}>
              <div>
                {/*<div><img src="/img/occa_logo.png"/> <strong>OCCA</strong></div>*/}
                <div>
                  {daypart === 'morning' && <img src="/img/login/logo1.png"/>}
                  {daypart === 'afternoon' && <img src="/img/login/logo3.png"/>}
                  {daypart === 'evening' && <img src="/img/login/logo2.png"/>}
                  {/*<img src="/img/logo/aladdin1.png"/>*/}
                  {/*<strong style={{marginLeft: '5px'}}>Aladdin Analytics</strong>*/}
                </div>
                {/*<div style={{fontSize: '9px'}}>Aladdin Analytics</div>*/}
              </div>
            </Link>
          </LogoWrapper>
          <SideMenuList activeItem={activeItem}>
            {menuDataFlat.filter(item => {
              if(item.hiddenFunc){
                return !item.hiddenFunc(session);
              }
              return true;
            }).map(item => {
              let isSelected = !item.isParent && activeItem === item.key;
              return (
                <React.Fragment key={item.key}>
                  {!!item.leadingText && <div className="menu-lead">{item.leadingText}</div>}
                  {!!item.leadingTextFunc && !!item.leadingTextFunc(session) && <div className="menu-lead">{item.leadingTextFunc(session)}</div>}
                  <SideMenuItem
                    className={classNames(item.key, daypart)}
                    disabled={item.disabled}
                    key={item.key}
                    selected={isSelected}
                    to={item.href}
                    ischild={item.isChild}
                    isparent={item.isParent}
                    onMouseOver={(!open && !isMobile) ? this.tooltip.onMouseOver(item) : undefined}
                    onMouseLeave={(!open && !isMobile) ? this.tooltip.onMouseOut() : undefined}
                    hide={item.isChild && closeMap[item.parent]}
                    onClick={e => {
                      if(item.disabled) {
                        e.stopPropagation();
                        e.preventDefault();
                        return;
                      }
                      if(isMobile) {
                        cachedOpen = false;
                        this.setState({open: false});
                      }
                      if (item.key === 'logout') {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.logout();
                      }
                    }}>
                    {item.icon}
                    <span>
                    {/*{!!item.isChild && <i class="fa fa-minus" aria-hidden="true"></i>}*/}
                      {item.label}
                  </span>
                    {
                      !!item.isParent &&
                      <i className="caret fa fa-caret-down"
                         style={{padding: '0px 10px', cursor: 'pointer'}}
                         onClick={e => {
                           e.stopPropagation();
                           e.preventDefault();
                           this.setState({closeMap: {...closeMap, [item.key]: !closeMap[item.key]}})
                         }}/>
                    }

                  </SideMenuItem>
                </React.Fragment>
              );
            })}
          </SideMenuList>
          {/*<div className="copyright">©2021 Mediacorp Pte Ltd</div>*/}
        </SideMenuWrapper>
        {
          isMobile && <BodyMask visible={open} onClick={e => {
            this.setState({open: !open})
          }}/>
        }
        <BodySection id="main">
          {this.renderBreadcrumb(this.props.breadcrumb)}
          {
            (appState.loading || this.props.loading) && <Loader className="fixedNexttoMenu"/>
          }
          {this.props.children}
          {/*<div className="body-footer">@Consumer Insights & Analytics</div>*/}
        </BodySection>
        {withChat && <Chat />}
      </MainWrapper>
    );
  }

  getUserInitial(name) {
    return _.compact((name || 'Anonymous User').replace(/[^a-zA-Z]/g, ' ').split(' ')).map(str => _.capitalize(str[0] || 'Z')).join('').slice(0, 2);
  }

  renderBreadcrumb(data) {
    if(!data || data.length < 2) {
      return null;
    }
    return (
      <Breadcrumb>
        <li><Link to='/'><i className="fa fa-home"/>{!data && <span>&nbsp;&nbsp;Dashboard</span>}</Link></li>
        {
          (data || []).map((item, index) => {
            return (
              <React.Fragment key={index}>
                {
                  index < data.length &&
                  <li className="seperator"><i className="fa fa-angle-right"/></li>
                }
                {
                  index < data.length - 1 &&
                  <li><Link to={item.path}>{item.label}</Link></li>
                }
                {
                  index === data.length -1 &&
                  <li>{item.label}</li>
                }

              </React.Fragment>
            )
          })
        }
      </Breadcrumb>
    )
  }
});