export const cacheResource = function (key, value, ttl) {
  //our cache for main str
  if (window.localStorage && window.JSON) {
    try {
      var keyStr = JSON.stringify(key);
      var valueWrapper = {
        value: value,
        time: new Date().getTime(),
        ttl: ttl || -1
      };
      var valueWrapperStr = JSON.stringify(valueWrapper);
      window.localStorage.setItem(keyStr, valueWrapperStr);
    } catch (e) {
      console.error(e)
    }
  }
}

export const getFromCache = function (key) {
  if (window.localStorage && window.JSON) {
    var keyStr = JSON.stringify(key);
    var valueWrapperStr = window.localStorage.getItem(keyStr);
    if (valueWrapperStr) {
      try {
        var valueWrapper = JSON.parse(valueWrapperStr);
        return (valueWrapper || {}).value;
        // //not expire yet
        // if (valueWrapper && valueWrapper.ttl === -1) {
        //   return valueWrapper.value;
        // } else if (valueWrapper && (valueWrapper.ttl + valueWrapper.time) >= new Date().getTime()) {
        //   return valueWrapper.value;
        // } else if (valueWrapper && (valueWrapper.ttl + valueWrapper.time) < new Date().getTime()) {
        //   window.localStorage.removeItem(keyStr);
        // }
      } catch (e) {
        console.error(e);
      }
    }
  }
};

// try {
//   //auto clean expired data
//   setInterval(function () {
//     for (var key in window.localStorage) {
//       if (window.localStorage.hasOwnProperty(key)) {
//         let wob = JSON.parse(window.localStorage.getItem(key));
//         if (wob && wob.ttl && wob.ttl !== -1 && (wob.ttl + wob.time) < new Date().getTime()) {
//           window.localStorage.removeItem(key);
//         }
//       }
//     }
//   }, 10000);
// } catch (e) {
//   console.error(e);
// }