import styled, {css} from 'styled-components';
import {
  FontSizeMedium,
  Mint1,
  BitterSweet1,
  Mint,
  BitterSweet,
  BlueJeans,
  DarkGray1,
  FontSizeSmall,
  BlueJeans1,
  Grass,
  Grass1,
  DarkGray,
  LightGray,
  LightGray1,
  MeOrange,
  MeOrange1,
  Aladin_Orange_Dark,
  Aladin_Orange_Light, Aladin_Purple_Light, Aladin_Red_Light, Aladin_Red_Dark
} from "../../app/StyleCommon";


export const Btn = styled.button`
  &&& {
    padding: 5px 20px;
    min-width: 90px;
    background: ${BlueJeans1};
    border: none;
    border-radius: 5px;
    font-size: ${FontSizeMedium};
    font-weight: bold;
    outline: none;
    color: white;
    cursor: pointer !important;
    transition: background 250ms ease-in-out, transform 150ms ease;
    i {
      margin-right: 5px;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    a {
      text-decoration: none;
      color: white;
    }
    ${props => props.size === 'small' && css`
      padding: 3px 5px;
      min-width: 60px;
      margin-right: 0px !important;
      font-size: ${FontSizeSmall};
      height: 25px;
    `}

    ${props => props.type === 'link' && css`
      box-shadow: none;
      background: transparent;
      color: ${Aladin_Orange_Dark};
      min-width: auto;
      padding: 2px 4px;
      text-decoration: underline;
      &:hover {
        box-shadow: none !important;
        a {
          color: ${Aladin_Orange_Dark} !important;
        }
      }
    `}
    ${props => props.type === 'blacklink' && css`
      box-shadow: none;
      background: transparent;
      color: ${DarkGray};
      min-width: auto;
      padding: 2px 4px;
      &:hover {
        box-shadow: none !important;
        a {
          color: ${DarkGray1} !important;
        }
      }
    `}
    ${props => props.type === 'success' && `background-color: ${Grass};`}
    ${props => props.type === 'primary' && `background-color: ${Aladin_Orange_Dark};`}
    ${props => props.type === 'danger' && `background-color: ${BitterSweet};`}
    ${props => props.type === 'reset' && `background-color: ${LightGray1}; color: ${DarkGray1}; border: 1px solid ${LightGray1}`}
    &:hover {
      ${props => props.type === 'success' && `background-color: ${Grass}`}
      ${props => props.type === 'primary' && `background-color: ${Aladin_Orange_Light}`}
      ${props => props.type === 'danger' && `background-color: ${BitterSweet}`}
      //box-shadow: inset 0 -1px 12px rgba(255,255,255,.5);
    }
    ${props => props.size === 'mini' && css`
      padding: 4px 10px;
      font-size: 12px;
      min-width: auto;
    `}
    &:disabled {
      //pointer-events: none;
      cursor: not-allowed !important;
      opacity: .6;
      filter: grayscale(1);
      background-image: inherit !important;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export default Btn;