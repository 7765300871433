import React, {Component, useEffect} from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  Aladin_Blue_Dark,
  Aladin_Blue_Light, Aladin_Orange_Light, Aladin_Purple_Light,
  BlueJeans,
  BlueJeans1, colorsAladdin, DarkGray, DarkGray1, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {intFormatter, pctFormatter, uniquesFormatter} from "../utils/formatter";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    return (
      <div className="chart" style={{width: '100%'}}>
        <div ref={this.containerRef}/>
      </div>
    )
  }

  renderChart() {
    let {data, total, labelMap = {}} = this.props;
    data = _.sortBy(data, _.property('value')).slice(0, 6);
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'svg', height: 220});
    let option = {
      // textStyle: {fontFamily: 'sans-serif, "Open Sans"'},
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      legend: {
        show: false
      },
      grid: {
        left: '5',
        right: '0',
        bottom: '0',
        top: '0',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        show: false
      },
      yAxis: {
        type: 'category',
        data: (data.map(d => d.key).slice(0, 6)),
        axisTick: {
          show: false
        },
        axisLabel: {
          color: DarkGray,
          overflow: 'ellipsis',
          fontSize: 12,
          formatter: function (value) {//use formatter to check if length of string is greater than 20 and maintain its width.
            value = labelMap[value] || value;
            if(value.length > 12){
              return value.substring(0, 12) + '...';
            }
            return value;
          }
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            // color: '#6ec6f1',
            color: '#6eb3c4',
            fontWeight: 'bold'
          }
        },
      },
      // visualMap: {
      //   orient: 'horizontal',
      //   left: 'center',
      //   min: _.min(data.map(d => d.value)),
      //   max: _.max(data.map(d => d.value)),
      //   text: ['High Score', 'Low Score'],
      //   // Map the score column to color
      //   dimension: 0,
      //   show: false,
      //   inRange: {
      //     // color: [Aladin_Blue_Light,  Aladin_Orange_Light],
      //     color: ['#efcf97', '#efa973'],
      //     // color: ['#d3e2f6','#a2c4ef'],
      //     // color: ['#f8e5f7','#f16da8']
      //   }
      // },
      series: {
        barMaxWidth: 20,
        barGap: '20%',
        type: 'bar',
        data: data,
        roundCap: true,
        showBackground: false,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.05)'
        },
        itemStyle: {
          borderWidth: 2,
          // borderRadius: [0, 3, 3, 0],
          color: '#6eb3c4',
          // color: new echarts.graphic.LinearGradient(1, 1, 0, 1, [
          //   { offset: 0, color: '#f1cb6d' },
          //   { offset: 0.5, color: '#f09819' },
          //   { offset: 1, color: '#fd8bd9' }
          // ])
        },
        encode: {
          x: 'value',
          y: 'key'
        },
        label: {
          show: true,
          position: 'insideLeft',
          offset: [5, 0],
          color: 'rgba(255,255,255, .9)',
          formatter: params => {
            return '$' + uniquesFormatter(params.value, 2);
          }
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value, i) => {
          return uniquesFormatter(value)
        },
        enterable: true
      },
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}
