import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {
  BlueJeans,
  BlueJeans1,
  Dark,
  DarkGray,
  DarkGray1,
  LightBlue,
  LightGray,
  LightGray1,
  MediumGray,
  MediumGray1,
  Sunflower,
  Sunflower1,
  Aqua1,
  Grass1,
  BitterSweet1,
  BitterSweet,
  Aladin_BG_1,
  Aladin_Orange_Dark,
  Aladin_Orange_Light,
  Aladin_BG,
  Grass,
  Aladin_Red_Dark
} from "../app/StyleCommon";
import {Dropdown} from "../uikit/index";
import {Spinner} from '../uikit/splash/index.style';

export const PageWrapper = styled.div`
  margin: 20px 50px;
  display: flex;
  >div {
    flex-wrap: nowrap;
  }
  .data-table {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden !important;
    .link {
      color: ${Aladin_Orange_Dark};
      border-right: 1px solid ${LightGray1};
      padding-right: 8px;
      margin-right: 8px;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
    }
    .link-download {
      border-right: 1px solid ${Aladin_Orange_Dark};
      padding-right: 8px;
      margin-right: 8px;
      line-height: 14px;
      font-weight: 600;
      cursor: pointer;
    }
    .alignCenter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .actions {
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: flex-start;
  }
  h3 {
    font-size: 18px;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 16px;
  }

  .dropZone {
    width: 40%;
    margin-right: 24px;
  }
  
  .instructionzone {
    width: 60%;
    margin-left: 24px;
    h4 {
      margin-top: 10px;
    }
    .instructions {
      list-style-type: disc;
      margin-bottom: 40px;
      li {
        margin-left: 20px;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  .filesUploaded {
    width: 100%;
    h4 {
      margin-top: 0px;
      font-size: 14px;
      // color: ${MediumGray1}
    }
  }
  
`;

export const NoDataFound = styled.div`
  margin: 20px 50px 20px 50px;
  padding: 12px;
  border: 1px solid #ffe8d6;
  border-radius: 12px;
  width: calc(100% - 120px);
  background-color: rgb(240 162 100 / 10%);
  color: ${Aladin_Orange_Dark};
  font-weight: 600;
  
`

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  > div {
    overflow: hidden;
    cursor: pointer;
    min-height: 400px !important;
    height: auto !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #818181;
    background-color: rgba(0,0,0,.01);
    border-color: rgb(229, 222, 222) !important;
    position: relative;
    &:hover {
      // background-color: ${LightGray1};
      background-color: rgb(248,231,209);
    }
    .dropzone-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 70%;
      text-align: center;
      font-weight: 600;
      .desktop-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        margin: 12px 0px; 
        p {
          margin: 0 0 4px 0 !important;
        }
      }
      i {
        font-size: 48px
      }
      a {
        background-color: ${Aladin_Orange_Dark} !important;
        display: block;
        padding: 6px 12px;
        font-size: 12px;
        border: 2px solid #fff;
        text-decoration: none !important;
        color: #fff !important;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 6px;
        max-width: 100px;
        &:hover {
          color: ${Aladin_Orange_Dark} !important;
          border: 2px solid ${Aladin_Orange_Dark};
          background-color: #fff !important;
        }
      }
    }
    .droped-file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-all;
      i {
        margin-right: 10px;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
      }
    }
    .uploadIcon {
      margin-right: 10px;
    }
  }
`;

export const DownloadBtn = styled.span`
  cursor: pointer;
  border-right: 1px solid ${LightGray1};
  padding-right: 8px;
  margin-right: 8px;
  span {
    font-size: 20px
  }
`

export const DeleteBtn = styled.span`
  cursor: pointer;
  i {
    font-size: 14px
  }
`

export const SampleFileWrapper = styled.div`
  h4 {
    font-size: 14px;
    font-weight: 600;
  }
  .download-file {
    display: flex;
    align-items: center
    font-weight: 600;
    margin-bottom: 8px;
    span {
      margin-right: 6px;
      font-size: 20px;
    }
    &:hover {
      span, a {
        color: ${Aladin_Orange_Light}
      }
      a {
        text-decoration: none;
      }
    }
  }
  img {
    width: -webkit-fill-available;
  }
`
export const SelectedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  .data-table > table {
    .dropdown-toggle {
      visibility: hidden;
    }
    &:hover {
      .dropdown-toggle {
        visibility: visible;
      } 
    }
  }
`

export const DailogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 1000px;
  h3 {
    font-size: 16px;
    margin-top: -5px;
    padding-bottom: 4px;
  }
  .error {
    color: red;
  }
  .subhead {
    margin-bottom: 24px;
    b {
      color: ${Aladin_Orange_Dark}; 
    }
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .subText {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .buttons {
    margin-top: 16px;
  }
  .primaryButton {
    padding: 5px 20px;
    min-width: 90px;
    background-color: ${Aladin_Orange_Dark};
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    color: white;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    cursor: pointer !important;
  }
  .reset {
    padding: 5px 20px;
    min-width: 90px;
    background: rgb(230, 233, 237);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    color: rgb(67, 74, 84);
    cursor: pointer !important;
    margin-right: 10px;
  }
  button{
    &:disabled {
    cursor: not-allowed !important;
    opacity: .6;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    background-image: inherit !important;
    }
  }
  .custom-metric-wrapper {
    margin-top: -100px;
    background: #fff;
    position: relative;
    z-index: 1000;
  }
`

export const MainDailogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  h3 {
    font-size: 16px;
    margin-top: -5px;
    padding-bottom: 4px;
  }
  .subhead {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .primaryButton {
    padding: 5px 20px;
    min-width: 90px;
    background-color: ${Aladin_Orange_Dark};
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    color: white;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    cursor: pointer !important;
    display: flex;
  }
  .reset {
    padding: 5px 20px;
    min-width: 90px;
    background: rgb(230, 233, 237);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    color: rgb(67, 74, 84);
    cursor: pointer !important;
    margin-right: 10px;
  }
  .uploadIcon {
    margin-right: 6px;
    font-size: 16px;
  }
`

export const DeleteDailogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;

  .subhead {
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
  .primaryButton {
    padding: 5px 20px;
    min-width: 90px;
    // background: rgb(241, 161, 100);
    background-color: ${Aladin_Orange_Dark};
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    color: white;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    cursor: pointer !important;
  }
  .reset {
    padding: 5px 20px;
    min-width: 90px;
    background: rgb(230, 233, 237);
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    transition: background 250ms ease-in-out 0s, transform 150ms ease 0s;
    color: rgb(67, 74, 84);
    cursor: pointer !important;
    margin-right: 10px;
  }
`

export const ConversionHeader =  styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  label {
    display: block;
    margin-right: 10px;
  }
  select {
    margin-left: 16px;
    font-size: 11px;
    height: 18px;
    max-width: 50px;
  }
  .dropdown-toggle {
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    i {
      font-size: 16px;
      padding: 4px 10px 0 10px;
      border-radius: 50%;
      color: #ccc;
    }
    &:hover i {
      color: ${Aladin_Orange_Dark};
    }
  }
`

export const ActionButtons =  styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 999;
  position: relative
`

export const UploadError =  styled.div`
  margin-top: 12px;
  color: rgb(233, 87, 63);
  font-size: 14px;
  font-weight: 600;
  background-color: #fceeee;
  border: 1px solid rgb(255 216 209);
  padding: 12px;
  border-radius: 6px;

  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }

  p {
    margin-top: 0;
    font-size: 14px;
    font-weight: bold;

    b {
      text-transform: uppercase;
    }
  }

  ul {
    list-style-type: disc !important;
    display: flex;
    padding-left: 12px;
    li {
      margin-right: 24px;
    }
  }
`

export const CustomMetricWrapper =  styled.div`
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  padding: 24px 0 0 0;
  height: calc(100% - 100px);
  margin-top: -20px;
  box-sizing: border-box;
  z-index: 1000;

  form {
    display: flex;
    flex-wrap: wrap;
  }
  label {
    font-weight: 600;
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #434A54;
  }
  .metricName, .description, .formatters, .formulaBuilder {
    margin-bottom: 24px;
  }
  .formatters {
    .container {
      display: flex;
      flex-wrap: wrap;
    }
    .form-radio {
      display: flex;
      flex-wrap: wrap;
      display: block;
      width: 49%;
      color: #434a54;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        display: inline-block;
        font-weight: 600;
      }
    }
  }
  .formulaBuilder {
    span {
      font-size: 12px;
      font-weight: normal;
      color: ${MediumGray1}
    }
  }
  .instructions {
    font-size: 14px;
    font-weight: normal;
    white-space: normal;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 24px;
  }
`

export const FormulaBuilderWrapper =  styled.div`
  margin-bottom: 16px;
  .fBheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    label {
      font-weight: bold;
      display: inline-block;
      font-size: 14px;
      color: #434A54;
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 0px !important;
    }
    span {
      font-size: 12px;
      font-weight: normal;
      color: ${MediumGray1};
      margin-left: 8px;
      margin-right: 8px;
    }
    i {
      font-size: 16px;
    }
  }
  .formula-textarea {
    position: relative;
  }
  .formulaDisabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${MediumGray};
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const OperatorContainer =  styled.div`
  position: relative;
  width: 20px;
  font-size: 18px;
  margin-right: 8px;
  div.disabled {
    color: ${MediumGray1};
  }
  .container {
    top: 22px;
    right: 0px;
    min-width: auto;
    position: absolute;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    z-index: 1000;
    display: flex;
    font-size: 18px;
    background-color: white;
    flex-wrap: wrap;
    width: 100px;
    span {
      width: 50px;
      text-align: center;
      &:hover {
        background-color: ${Aladin_Orange_Light};
        i {
          color: white;
        }
        img {
          filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(308deg) brightness(103%) contrast(101%);
        }
      }
    }
    i {
      padding: 12px;
      font-weight: normal;
      color: ${Aladin_Orange_Dark};
      cursor: pointer;
    }

    img {
      width: 18px;
      padding: 12px 12px 12px 6px;
      filter: invert(83%) sepia(5%) saturate(6808%) hue-rotate(318deg) brightness(97%) contrast(94%);
      cursor: pointer;
    }
  }
`


export const DropdownToggler =  styled.div`
  position: fixed;
  .menu {
    top: 28px;
    width: 180px;
    right: 0;
    min-width: auto;
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    ${props => props.index && css`
      left: 0px
    `}
  }
  .menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    padding: 10px 15px;
    font-weight: bold;
  }
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    cursor: pointer;
    font-weight: normal;
    &:hover {
      background: rgb(247, 247, 252);
      color: black;
    }
    >label {
      display: inline-block;
      width: 120px;
      margin-right: 10px;
      flex: none;
    }
    i {
      padding-left: 0;
      padding-right: 6px;
      font-size: 8px;
      font-weight: normal;
      visibility: hidden;
      margin-right: 4px;
      color: ${Aladin_Orange_Light}; 
    }
    i.visible {
      visibility: visible;
    }
  }
  .menu-item-selected {
    font-weight: 600;
    color: ${Aladin_Orange_Dark}; 
    &:hover {
      color: ${Aladin_Orange_Dark}; 
    }
  }
  
`

export const MetricsSelectorWrapper =  styled.div`
  position: relative;
  
  .container {
    padding: 4px 12px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    background-color: rgb(230, 233, 237);
    color: rgb(67, 74, 84);

    &:hover {
      background: ${Aladin_Orange_Dark};
      color: white;
    }
    i {
      margin-left: 8px;
    }
   
  }
  .container.disabled {
    background:${MediumGray};
    border-color:${MediumGray}
  }
  .menu {
    top: 27px;
    width: 180px;
    right: 0;
    // height: 200px;
    min-width: auto;
    position: absolute;
    overflow-y: auto;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.16);
    z-index: 1000;
  }
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 15px;
    font-weight: bold;
    background-color: white;
    border-bottom: 1px solid rgb(241, 241, 241);
    cursor: pointer;
    font-weight: bold;
    position: relative;
    .tooltip {
      position: absolute;
      top: 29px;
      right: -50px;
      z-index: 1001;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 8px;
      border-radius: 4px;
      opacity: 0.8;
    }
    &:hover {
      background: rgb(247, 247, 252);
      color: black;
    }
    >label {
      display: inline-block;
      width: 120px;
      margin-right: 10px;
      flex: none;
    }
    i {
      position: absolute;
      right: 8px;
      padding-left: 0;
      padding-right: 6px;
      font-size: 12px;
      font-weight: normal;
      margin-right: 4px;
      color: ${Aladin_Orange_Light}; 
      &:hover {
        color: ${Aladin_Orange_Dark}; 
      }
    }
  }
`

export const AddCustomMetricBtn =  styled.button`
  background-color: rgb(230, 233, 237);
  color: rgb(67, 74, 84);
  border: 0;
  border-radius: 5px;;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  width: fit-content;
  cursor: pointer;
  i {
    margin-right: 2px;
    font-size: 12px;
  }
  &:hover {
    background-color: rgb(244, 193, 104);
    color: white;
  }
`

export const FormWrapper = styled.form`
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  input[type=text],
  input[type=password],
  input[type=email],
  textarea{
    font-size: 14px;
    width: 100%;
    padding: 4px 6px;
    box-sizing: border-box;
    transition: all .3s ease;
    vertical-align: middle;
    background: none;
    outline: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #ddd;
    &:focus {
      border-bottom: 1px solid #aaa;
    }
  }
  textarea {
    font-size: 12px;
    border: 1px solid #ddd;
  }
  input[type=submit] {
    margin-top: 20px;
    background-color: ${Grass};
    box-shadow: 1px 1px 5px rgba(255,255,255,.1), 1px 1px 15px rgba(255,255,255,.05);
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    vertical-align: middle;
    min-width: 150px;
    height: 30px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${Grass1};
    }
  }
  .details {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 24px;

    > .field {
      width: 100%;
    }
  }
`;


export const FormField = styled.div`
  .form-control:focus-within {
    color: ${Aladin_Orange_Dark};
  }
  .form-control {
    span {
      font-weight: 600;
    }
  }
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: ${Aladin_Orange_Light};
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    border: 2px solid ${Aladin_Orange_Light};
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    
  }
  input[type="radio"]::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${Aladin_Orange_Dark};
  }
  input[type="radio"]:checked::before {
    transform: scale(1);
    label span {
      color: ${Aladin_Orange_Dark}
    }
  }
  > .cancel {
    background-color: #E6E9ED !important;
    color: #434A54 !important;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-directon: row;
  justify-content: end;

  .field-buttons {
    display: flex;
    flex-direction: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
  }
  }
`;

export const ErrorContainer = styled.div`
  color ${BitterSweet};
  font-size: 12px;
  text-align: right;
  font-weight: bold;
  display: none;
  }
`;