import styled, {css} from 'styled-components';
import {Aladin_Red_Dark, Aladin_Red_Light, BlueJeans1, LightGray, MediumGray} from "../../app/StyleCommon";

export const TabContainerWrapper = styled.div.attrs({
  'data-rp': 'tab-container-wrapper'
})`
  position: relative;
  background-color: white;
`;
export const TabMenuWrapper = styled.ul`
  display: flex;
  position: relative;
  font-size: 12px;
  flex-wrap: wrap;
  font-weight: bold;
  //padding: 0px 14px;
  position: relative;
  ${props => props.noMenuMargin && css`
    padding: 0 5px;
  `}
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 0;
    bottom: 0px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    left: 0;
    right: 0;
    background-color: transparent;
    box-shadow: 0px 3px 10px rgba(0,0,0,.1);
  }
  .active-slider {
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #8cc152;
    bottom: -1px;
    transition: all .3s ease-in-out;
    border-radius: 2px;
  }
  @media screen and (max-width: 992px) {
    .tab-menu-item {
      margin-right: 5px;
      font-size: 12px;
    }
  }
`;
export const TabMenuItem = styled.li`
  display: inline-block;
  color: #555;
  padding: 10px 20px 5px 10px;
  margin-right: 25px;
  cursor: pointer;
  border-bottom: 5px solid transparent;
  min-width: 30px;
  ${props => props.active && css`
    border-bottom: 5px solid ${Aladin_Red_Light};
    background: #fff2f0;
    border-radius: 0 20px 0 0;
  `}
  @media screen and (max-width: 667px) {
    margin-right: 10px;
    padding: 5px 10px 5px 0;
  }
`;

export const TabItemsWrapper = styled.div`
  list-style-type: none;
  padding-top: 5px;
`;
export const TabItem = styled.div`
  display: none;
  ${props => props.active && 'display: block;'}
  ${props => props.hidden && 'display: none;'}
`;