import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import classnames from 'classnames';
import moment from 'moment';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Dark1, Aladin_Blue_Light,
  Aladin_Orange_Dark,
  Aladin_BG_1, Aladin_Purple_Light,
  BlueJeans1,
  DarkGray,
  Grass1,
  MediumGray,
  MediumGray1, Aqua1, Aqua, BlueJeans, BlueJeans0
} from "../app/StyleCommon";
import _ from "lodash";
import {datePeriodWindows, dimensionValueMap, siteLabelMap,} from "../utils/metadata";
import {SelectStyled} from "../campaigns/select.style";
import {getContextChartScale} from "./util";

export default class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      unit: 'daily'
    }
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    const {unit} = this.state;
    return (
      <ChartWrapper className="chart">
        <div className="chart-header">
          <div className="chart-legent">
          </div>
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data, cfg, context, currency} = this.props;
    const {campaign = {}} = context || {};
    let {metric, dimension, unit, type : visType = 'bar', } = cfg;
    const container = this.containerRef.current;
    let metricMeta = context.mixedMetaData.find(d => d.key === metric);
    data = _.cloneDeep(data);
    data.groupedData = _.mapValues(data.groupedData, (dimensionData, dimensionValue) => {
      dimensionData = _.sortBy(dimensionData, d => d.date || d.key);
      if(cfg.cumulative && metricMeta.accumulatable) {
        for(let i = 1; i< dimensionData.length ; i++) {
          dimensionData[i][metric] = (dimensionData[i-1][metric] || 0) + (dimensionData[i][metric] || 0);
        }
      }
      if(metric.startsWith('reach') || (cfg.cumulative && metricMeta.accumulatable)) {
        for(let i = dimensionData.length - 1; i> 0; i--) {
          if(dimensionData[i][metric] === dimensionData[i-1][metric]) {
            dimensionData[i][metric] = undefined
          }
        }
      }
      return dimensionData;
    })

    const height = 250;
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    const colors = ['#4f8f98', '#FEAE65'];
    let option = {
      // color: ['#4f8f98', '#f1a164','#f4c168','#ec685c', '#436265', '#4f8f98', '#6eb3c4','#2D6A4F', '#40916C','#532B88', '#9B72CF', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1],
      color: data.topDimensionValues.length > 7 ?
        ['#4f8f98','#6eb3c4','#ec685c', '#f1a164', '#f4c168', '#2F184B','#532B88', '#9B72CF','#1B4332', '#2D6A4F', '#40916C', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1]:
        ['#4f8f98', '#f1a164','#f4c168','#ec685c', '#436265', '#9B72CF', '#40916C'],
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value) => {
          return getMetricFormatter(metricMeta)(value);
        },
      },
      grid: {
        bottom: '40',
        left: '60px',
        right: '30px',
      },
      legend: {
        left: 10,
        top: 0,
        show: true,
        itemWidth: 16,
        itemHeight: 10,
        height: '100px',
        align: 'left',
      },
      xAxis: {
        type: 'category',
        data: data.dates,
        axisLine: {
          onZero: false,
          lineStyle: {
            fontWeight: 'bold'
          }
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          formatter: value => {
            if(!value) return '';
            if(unit === 'daily') {
              return moment(value).format('DD, MMM');
            } else {
              return value;
            }
          }
        }
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          splitNumber: 2,
          nameLocation: 'start',
          nameGap: 20,
          alignTicks: true,
          nameTextStyle: {
          },
          axisTick: {
            inside: false, length: 5
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            inside: true,
            margin: -40,
            fontWeight: 'bold',
            formatter: value => {
              if(!value) return '';
              return (metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(value);
            }
          }
        }
      ],
      series: (data.topDimensionValues || []).map(dimensionValue => {
        const maxGroupSum = _.max(data.dates.map((date, index) => {
          return _.sum(data.topDimensionValues.map(k => data.groupedData[k][index][metric]));
        }))
        return {
          name: dimensionValueMap[dimensionValue] || dimensionValue,
          type: visType || 'bar',
          stack: dimension,
          emphasis: {
            focus: 'series'
          },
          data: (data.groupedData[dimensionValue] || []).map(d => d[metric]),
          barMaxWidth: 200,
          barWidth: '70%',
          label: {
            show: (data.dates.length >= 10) ? false : true,
            position: 'inside',
            fontSize: 11,
            color: '#3d3737',
            backgroundColor: 'rgba(255,255,255,.5)',
            borderRadius: 5,
            padding: [1, 0, 1, 0],
            formatter: (d) => {
              if(!maxGroupSum || !d.value || d.value / maxGroupSum < 0.1) {
                return '';
              }
              return ' ' + ((metricMeta.formatter2 || getMetricFormatter(metricMeta, currency))(d.value)) + ' '
            }
          },
          itemStyle: {
            opacity: 0.9,
          },
          areaStyle: {opacity: 0.08},
        }
      })
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
  .chart-actions {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 1;
    .toggler {
      border: none;
      background: ${Aladin_BG_1};
      padding: 2px 40px 2px 10px;
      width: auto;
    }
  }
`;

export const UnitItem = styled.span`
  padding: 0 5px;
  cursor: pointer;
  color: ${DarkGray};
  &:after {
    content: '';
    display: inline-block;
    height: 10px;
    width: 2px;
    margin-left: 8px;
    background: ${MediumGray};
  }
  &:last-child:after {
    display: none;
  }
  &.selected {
    color: ${BlueJeans1};
    font-weight: bold;
  }
`;
