import React from 'react'
import {withConsumer} from '../app/ApplicationContext';
import {Redirect, Link} from 'react-router-dom';
import {post} from '../utils/request';
import {LoginWrapper, SubmitBtn} from './login.style';
import {Spinner} from "../uikit/Loader/index.style";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  verify() {
    const {session, setAppState} = this.props.appState;
    let {code} = this.state;
    this.setState({loading: true});
    post('/api/login_verify', {
      userId: session.userId,
      code: code,
    })
      .then((mfaResult) => {
        if(mfaResult.success) {
          delete session.require2FA;
          setAppState({session: session});
          this.setState({loading: false});
        } else {
          this.setState({loading: false, mfaError: mfaResult.error})
        }

      })
      .catch(e => {
        console.error(e);
        this.setState({loading: false, mfaError: e.message});
      });
  }

  resend(e) {
    e.preventDefault();
    e.stopPropagation();
    const {session, setAppState} = this.props.appState;
    let {code} = this.state;
    this.setState({resending: true});
    post('/api/resend_2fa', {
      userId: session.userId,
      whichApp: 'digitaloptimiser',
    })
      .then((mfaResult) => {
        this.setState({resending: false, successMessage: "Verification code is sent, please check."})
      })
      .catch(e => {
        this.setState({resending: false, successMessage: null, mfaError: 'Failed to send verification code, please try again.'})
      });
  }

  render() {
    const {session} = this.props.appState;
    const {error, loading, code, mfaError, successMessage, resending} = this.state;
    const {from} = this.props.location.state || {from: {pathname: '/'}}
    if (session && !session.require2FA) {
      return <Redirect to={from}/>;
    }
    let hour = new Date().getHours();
    let daypart = (hour >= 5 && hour < 11) ? 'morning' : ((hour >= 11 && hour < 17) ? 'afternoon' : 'evening')
    // daypart = 'afternoon'
    return (
      <LoginWrapper className={daypart}>
        <form onSubmit={e => {
          e.preventDefault();
          this.verify(e)
        }}>
          <div className="form-title">
            <img src="/img/login/logo_white.png" />
          </div>
          <h3>2FA Verification</h3>
          {
            !!error && <div className="error-msg">{error}</div>
          }
          <div>
            {this.state.success && <p style={{color: 'green'}}>Your password has been changed.</p>}
          </div>
          <div className="field">
            <label className="info-label">Please Check your email box, get the 2FA verify code and fill in below to proceed.</label>
            <input ref="codeInput"
                   value={code}
                   type="text"
                   placeholder="Verify Code"
                   onChange={e => this.setState({code: e.currentTarget.value})}/>
          </div>

          <SubmitBtn className={daypart}>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Verify
          </SubmitBtn>
          <div className="bottom-links">
            <Link to="/resend_2fa" onClick={e => this.resend(e)}>
              {
                resending &&
                <Spinner className="spinner" type="mini"/>
              }
              Resend Verification Code
            </Link>
            <Link to={'/login'} onClick={e => {
              this.props.logout();
            }}>Back</Link>
          </div>
        </form>
      </LoginWrapper>
    )
  }
})