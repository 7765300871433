import React from 'react'
import _ from 'lodash'
import MainLayout from '../layout/index';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper,  Spinner} from './index.style';
import {Table, TableScrollWrapper, TabContainer, Select, Btn, ProgressBar, CondirmDialog, Loader} from "../uikit/index";
import {supportedColumns} from "../campaigns/columns";
import moment from "moment/moment";
import {MediumGray1} from "../app/StyleCommon";
import {Link} from "react-router-dom";
import {TableController} from "./index.style";
import UserManagement from "./users";
import {post} from "../utils/request";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";

export default withConsumer(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      mode: 'read',
    }
  }
  render() {
    const {data, loading, error} = this.state;
    const {mode, privileges = [], roles = []} = this.state;
    const tableRows =  _.flatten(privileges.map(d => ([d, ...d.data])));

    const {session} = this.props.appState;
    let canViewList = session.rights.indexOf('View System Roles and priviledges') >= 0;
    let canEditRoles = session.rights.indexOf('Edit System Roles and priviledges') >= 0;
    return (
      <div className="role-module">
        {
          !data && !error && canViewList &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryRoleData`}
            params={{}}
            successHandler={data => {
              this.setState({
                data: data,
                roles: _.cloneDeep(data.roles),
                privileges: data.privileges
              });
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          loading && <Loader/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !canViewList && <ErrorMessage message={'Not Allowed'}/>
        }
        {
          !!data && canViewList &&
          <TableController>
            <div>
              Role assignments:
              {mode ==='modify' && <strong style={{marginLeft: '10px'}}>Click the 'tick and untick' icon to assign / de-assign the privilege from the role.</strong>}
            </div>
            {
              canEditRoles &&
              <div className="actions">
                {
                  mode === 'modify' &&
                  <Btn type="primary" onClick={e => this.saveRoles()}><i className="material-symbols-outlined">save_as</i> Save</Btn>
                }
                {
                  mode === 'modify' &&
                  <Btn type="reset" onClick={e => this.resetRoles()}>Cancel</Btn>
                }
                {
                  mode === 'read' &&
                  <Btn type="primary" onClick={e => this.setState({mode: 'modify'})}><i className="fa fa-edit"/> Modify</Btn>
                }

                {/*<Btn type="link"><i className="fa fa-plus"/> Role</Btn>*/}
              </div>
            }

          </TableController>
        }
        {
          !!data && canViewList &&
          <TableScrollWrapper className="role-table">
            <Table
              headerGroups={[
                {key: '1', label: '', colSpan: 1},
                {key: '2', label: <div style={{textAlign: 'center', fontWeight: 'normal'}}>&nbsp;Roles</div>, colSpan: roles.length},
              ]}
              columns={[
                {
                  key: 'priv',
                  label: 'Privilege',
                  align: 'left',
                  sortable: true,
                  style: {maxWidth: '200px'},
                  renderer: d => {
                    if(d.cat) {
                      return <strong>{d.cat}</strong>
                    } else {
                      return <div style={{marginLeft: '20px'}}>{d.priv}</div>
                    }
                  }
                },
                ...(roles.map((r, roleIndex) => {
                  return {
                    key: r.role,
                    label: r.role,
                    align: 'center',
                    sortable: true,
                    renderer: (d, priviledgeIndex) => {
                      if(d.cat) return null;
                      let checked = r.privileges.indexOf(d.id) >= 0;
                      return (
                        <div className="custom-check-box">
                          {mode ==='read' && checked && <i className="material-symbols-outlined">done</i>}
                          {mode ==='modify' && checked && <i className="material-symbols-outlined checked" onClick={e => {
                            r.privileges = r.privileges.filter(p => p !== d.id);
                            this.setState({roles});
                          }}>done</i>}
                          {mode ==='modify' && !checked && <i className="material-symbols-outlined unchecked" onClick={e => {
                            r.privileges.push(d.id);
                            this.setState({roles});
                          }}>close</i>}
                        </div>
                      )
                    }
                  };
                }))
              ]}
              rows={tableRows}
              noDataText={<div style={{height: '110px', marginTop: '50px'}}>No Role Found</div>}
            />
          </TableScrollWrapper>
        }
      </div>
    )
  }

  resetRoles() {
    const {data} = this.state;
    this.setState({
      mode: 'read',
      roles: _.cloneDeep(data.roles),
    })
  }
  saveRoles() {
    const {mode, privileges = [], roles = []} = this.state;
    this.setState({loading: true});
    post(`/api/saveRoleData`, {roles, privileges}).then(result => {
      this.setState({loading: false, mode: 'read'});
      CondirmDialog.showAlert('Role settings saved.');
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }
})